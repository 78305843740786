import * as React from "react";
import { localizedDateComparator } from "../../services/localized.service";
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, IconButton } from "@mui/material";
import { plPL } from "../../localization/plPL";
// import { plPL } from "@mui/x-data-grid";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FAB, Portal, Text } from "react-native-paper";
import { View } from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import {
  deleteResource,
  getAll,
  getStats,
} from "../../services/participationforms.service";
import { Auth, AuthContext } from "../../auth-context";
import _ from "lodash";
import CustomDialog from "../../components/CustomDialog";
import baseStyles from "../../styles/base";
import { Loading } from "../../components/Loading";

const theme = createTheme({}, plPL);

const headerNames = new Map();
headerNames.set("_id", "ID");
headerNames.set("description", "description");

export default function ParticipationForms() {
  const [items, setItems] = React.useState([]);
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const [showSpinner, setshowSpinner] = React.useState(false);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");
  const [dialogConfirmDelete, setDialogConfirmDelete] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState("");

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const columns: GridColumns = [
    {
      field: "_id",
      headerName: headerNames.get("_id"),
      //  width: 90
    },

    {
      field: "createdAt",
      headerName: "Data utworzenia",
      editable: false,
      type: "string",
      align: "right",
      width: 170,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "updatedAt",
      headerName: "Data modyfikacji",
      editable: false,
      type: "string",
      width: 170,
      align: "right",
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "completedAt",
      headerName: "Data zakończenia",
      editable: false,
      type: "string",
      width: 170,
      align: "right",
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "userId",
      headerName: "Użytkownik",
      width: 200,
      editable: false,
      valueGetter: (params) => {
        return params.row.userId
          ? params.row.userId.firstName + " " + params.row.userId.lastName
          : "";
      },
      renderCell: (params: GridRenderCellParams<string>) => {
        // console.log(`userId params ${JSON.stringify(params.row.userId)}`);

        const value =
          params.row.userId &&
          params.row.userId.firstName &&
          params.row.userId.lastName
            ? params.row.userId.firstName + " " + params.row.userId.lastName
            : "";
        return (
          <Text testID={params.row.userId} style={{ marginLeft: 16 }}>
            {`${value}`}
          </Text>
        );
      },
    },
    {
      field: "translatorId",
      width: 200,
      headerName: "Ostatnia modyfikacja",
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        const value = params.row.translatorId
          ? params.row.translatorId.firstName +
            " " +
            params.row.translatorId.lastName
          : "";
        return (
          <Text testID={params.row.translatorId} style={{ marginLeft: 16 }}>
            {`${value}`}
          </Text>
        );
      },
    },
    {
      field: "completed",
      headerName: "Gotowy?",
      valueGetter: ({ value }) => (value && value === true ? "TAK" : "NIE"),
      renderCell: (params: GridRenderCellParams<string>) => (
        <Text
          testID={"status" + params.row._id}
          style={{
            color: params.value === "NIE" ? "red" : "green",
          }}
        >
          {params.value}
        </Text>
      ),
      // width: 100,
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Akcje",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        if (auth?.isAdmin)
          return [
            <GridActionsCellItem
              data-testid={"edit_" + row.userId?.email}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                console.log(`navigate to participationForm: ${row.id}`);
                handleClick(row._id);
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              data-testid={"delete_" + row._id}
              hidden={auth?.isAdmin ? false : true}
              label="Delete"
              // style={{ display: auth?.isAdmin ? "flex" : "hidden" }}
              onClick={() => {
                setDialogConfirmDelete(true);
                setItemToDelete(row._id);
              }}
              color="inherit"
            />,
          ];
        else
          return [
            <GridActionsCellItem
              data-testid={"edit_" + row.supportDescription}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                handleClick(id);
              }}
              color="inherit"
            />,
          ];
      },
    },
  ];

  const navigation = useNavigation();

  React.useEffect(() => {
    releaseUserMedia();

    if (_.isEmpty(auth)) {
      //   console.log("[Users] useEffect (no auth yet), auth:", auth);
      return;
    } else {
      //   console.log("[Users] useEffect (with auth), auth:", auth);
      if (items.length === 0) {
        console.log("fetching data using ", auth);

        getAll()
          .then((freshItems) => {
            console.log(
              `[ParticipationForms] useEffect (with auth), freshItems:`,
              freshItems.length
            );
            setshowSpinner(false);
            setItems(freshItems);
          })
          .catch((error) => {
            console.log(`caught1 ${JSON.stringify(error)}`);
            setshowSpinner(false);
            setDialogErrorVisible(true);
            if (error && error.response && error.response.data)
              setDialogErrorMessage(error.response.data.error);
            else setDialogErrorMessage(JSON.stringify(error));
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, []);

  const newItem = () => {
    console.log("new item");
    navigation.navigate("ParticipationForm");
  };

  const confirmedDeleteResource = (id: string) => {
    deleteResource(id)
      .then((response) => {
        console.log(`[ParticipationForm] support form deleted:`, response);
        setItemToDelete("");
        setDialogConfirmDelete(false);
        getAll()
          .then((freshItems) => {
            console.log(
              `[ParticipationForm] useEffect (with auth), fresh items:`,
              freshItems
            );
            setItems(freshItems);
          })
          .catch((error) => {
            console.log("error:", error);
            alert("Błąd pobierania aktualnej listy formularzy wsparcia");
          });
      })
      .catch((err) => {
        console.log("error:", err);
        setItemToDelete("");
        alert("Błąd w trakcie usuwania formularza wsparcia");
      });
  };

  const handleClick = (id: string) => {
    // console.log('event ', JSON.stringify(event.target));
    navigation.navigate("ParticipationForm", { id: id });
  };

  const downloadStats = async () => {
    try {
      setshowSpinner(true);
      await getStats();
      setshowSpinner(false);
    } catch (error) {
      setshowSpinner(false);

      console.error(JSON.stringify(error));
      setDialogErrorMessage("Plik ze statystykami nie jest dostępny.");
      setDialogErrorVisible(true);
    }
  };

  function CustomExport() {
    return (
      <Button
        // variant="outlined"
        startIcon={<DownloadIcon fontSize="inherit" />}
        onClick={downloadStats}
      >
        Eksportuj
      </Button>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        <CustomExport />
        {/* <GridToolbarExport /> */}
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ flex: 1, height: "100%", width: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        <View
          testID="ParticipationFormsScreen"
          style={{ flex: 1, display: "none" }}
        ></View>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <CustomDialog
          visible={dialogConfirmDelete}
          dialogType={"YesOrNo"}
          dialogActionNo={() => setDialogConfirmDelete(false)}
          dialogActionYes={() => confirmedDeleteResource(itemToDelete)}
          dialogTitle={"Ostrzeżenie"}
          dialogContent={"Czy na pewno chcesz usunąć formularz wsparcia?"}
          testID="error_dialog"
        />
        {auth?.isTranslator && (
          <Portal>
            <FAB
              visible={isFocused}
              icon="plus"
              testID="create_support_form"
              style={baseStyles.addItemFab}
              onPress={newItem}
            />
          </Portal>
        )}
        <DataGrid
          sortingOrder={["desc", "asc"]}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          style={{ backgroundColor: "#fff" }}
          rows={items}
          getRowId={(row) => row._id}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
        />
      </ThemeProvider>
    </div>
  );
}
