import React from "react";
import { Platform, View } from "react-native";
import {
  SELF_VIDEO_SIZE_MOBILE_HEIGHT,
  SELF_VIDEO_SIZE_MOBILE_WIDTH,
  SELF_VIDEO_SIZE_WEB_HEIGHT,
  SELF_VIDEO_SIZE_WEB_WIDTH,
} from "../../styles/base";
import { Headline, Subheading } from "react-native-paper";
import { ConnectedUsersView } from "../ConnectedUsersView";
import { ConnectedUserDTO } from "../../frontend_domain";
import { useWindowSize } from "../../hooks/window_size";
import { StyleSheet } from "react-native";

interface TranslatorNotInCallViewProps {
  connectedUsers: Map<string, ConnectedUserDTO>;
  callPeer: (receiverId: string) => void;
  ownStatus: string;
}

export default function TranslatorNotInCallView({
  connectedUsers,
  callPeer,
  ownStatus,
}: TranslatorNotInCallViewProps) {
  const shortScreen = useWindowSize().shortScreen;
  const width = useWindowSize().width;

  return (
    <View style={styles.externalWrapper}>
      <View
        style={{
          display: "flex",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "auto",
          alignItems: "flex-end",
          padding: 5,
        }}
      >
        {/* <Headline>Zalogowani użytkownicy</Headline> */}
      </View>

      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "auto",
          width: shortScreen ? width - SELF_VIDEO_SIZE_WEB_WIDTH : undefined,
          left: shortScreen ? SELF_VIDEO_SIZE_WEB_WIDTH : undefined,
        }}
      >
        {connectedUsers?.size === 0 && (
          <Subheading>Brak zalogowanych użytkowników</Subheading>
        )}

        <ConnectedUsersView
          users={connectedUsers}
          call={callPeer}
          ownStatus={ownStatus}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  externalWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    margin: 0,
    top:
      Platform.OS === "web"
        ? -1 * SELF_VIDEO_SIZE_WEB_HEIGHT
        : -1 * SELF_VIDEO_SIZE_MOBILE_HEIGHT,
  },
});
