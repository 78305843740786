import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  AgeRange as AgeRangeEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface AgeRange {
  id: string;
  label: string;
}

export const ageRangeOptions = Object.keys(AgeRangeEnum).map((key) => ({
  id: key,
  label: AgeRangeEnum[key],
}));

interface AgeRangeComponentProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const AgeRangeComponent = ({
  resource,
  errors,
  setResource,
  onBlur,
}: AgeRangeComponentProps) => {
  return (
    <Grid item xs={12}>
      <Autocomplete
        options={ageRangeOptions}
        disablePortal
        id="age_range"
        required={true}
        value={resource.age_range ? resource.age_range : ""}
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            required={true}
            error={errors ? errors["age_range"] : false}
            fullWidth
            onBlur={onBlur}
            label="8. Wiek"
          />
        )}
        onChange={(event: any, newValue: AgeRange) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            age_range: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
