import React, { useEffect, useRef, useState } from "react";
import { Linking, Platform } from "react-native";
import { View, Image, Text, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native";
import { Button, Colors } from "react-native-paper";
import { DebugContext } from "../../debug-context";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import ValidatedTextInput from "../../components/ValidatedTextInput";
import { handleError, minPasswordStrength } from "../../utils";
import CustomDialog from "../../components/CustomDialog";
import { Loading } from "../../components/Loading";
import { requestReset, resetPassword } from "../../services/auth.service";
import { PasswordResetUpdateProps } from "../../navigation/types";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

type FormData = {
  password: string;
  password2: string;
  token: string;
};

export const COLORS = {
  dark: "#000",
  light: "#a5a5a5",
  white: "#fff",
  primary: "#28388f",
  secondary: "#64beff",
  pink: "#ff2d5f",
};

const PasswordResetUpdate = ({
  navigation,
  ...props
}: PasswordResetUpdateProps) => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [token, setToken] = useState("");

  let debug = useContext(DebugContext).debug;
  const [showSpinner, setshowSpinner] = React.useState(false);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  useEffect(() => {
    const routeToken = props.route.params
      ? props.route.params.token
      : undefined;
    console.log(`Token : ${JSON.stringify(token)}`);
    setToken(routeToken);
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur", // "onChange"
  });

  const hideDialog = () => {
    console.log("hide dialog");
    setDialogVisible(false);
    navigation.navigate("SignIn", { initial: false });
  };

  const onSubmit = async () => {
    if (!token) {
      setDialogErrorMessage(
        "Link do zmiany hasła jest nieaktualny. Rozpocznij zmianę hasła jeszcze raz."
      );
      setDialogErrorVisible(true);
      return;
    }

    console.log("react-form-hook validation ok ,request...");

    console.log(`data ${JSON.stringify(getValues("email"))}`);
    setshowSpinner(true);

    try {
      const result = await resetPassword(
        getValues("password"),
        getValues("password2"),
        token
      );

      if (result) {
        setshowSpinner(false);
        console.log(`response ${JSON.stringify(result.data)}`);
        setDialogMessage(result.data.message);
        setDialogVisible(true);
      }
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  return (
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      <KeyboardAwareScrollView
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
            flex: 1,
          },
        ]}
        contentContainerStyle={{
          flex: 1,
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <View
          testID="registration_container" // used i.e. in appium
          style={[
            // { backgroundColor: "orange" },
            Platform.OS === "web"
              ? styles.outerColumnContainer
              : styles.outerColumnContainerMobile,
          ]}
        >
          {showSpinner && <Loading loading={showSpinner} />}
          <CustomDialog
            visible={dialogVisible}
            hideDialog={hideDialog}
            dialogTitle={""}
            dialogContent={dialogMessage}
          />
          {/* errors */}
          <CustomDialog
            visible={dialogErrorVisible}
            hideDialog={hideDialogError}
            dialogTitle={"Błąd"}
            dialogContent={dialogErrorMessage}
            testID="error_dialog"
          />

          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="password"
              placeholder="Hasło"
              autoCorrect={false}
              required={true}
              rules={{
                validate: {
                  required: (value: boolean) => {
                    return value ? true : "To pole jest wymagane";
                  },
                  minPasswordStrength: minPasswordStrength(3),
                },
              }}
              errors={errors}
              control={control}
              secureTextEntry={true}
              leftIconName={"form-textbox-password"}
            />
          </View>

          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="password2"
              placeholder="Powtórz hasło"
              autoCorrect={false}
              rules={{
                validate: {
                  required: (value: boolean) => {
                    return value ? true : "To pole jest wymagane";
                  },
                  minPasswordStrength: minPasswordStrength(3),
                },
              }}
              errors={errors}
              required={true}
              control={control}
              secureTextEntry={true}
              leftIconName={"form-textbox-password"}
            />
          </View>

          <View
            style={[
              debug === true ? { backgroundColor: "yellow" } : {},
              {
                display: "flex",
              },
            ]}
          >
            <View
              style={[
                { padding: 10 },
                debug === true ? { backgroundColor: "blue" } : undefined,
              ]}
            >
              <Button
                testID="button_submit"
                onPress={handleSubmit(onSubmit)}
                mode="contained"
                color={Colors.blue500}
                labelStyle={{ color: "white", fontWeight: "bold" }}
                style={{ padding: 5 }}
              >
                {"Zmień hasło"}
              </Button>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default PasswordResetUpdate;

export const styles = StyleSheet.create({
  inputContainer: {
    padding: 15,
    maxWidth: Platform.OS === "web" ? 500 : undefined,
    minWidth: Platform.OS === "web" ? 500 : undefined,
    // minWidth: Platform.OS === "web" ? 400 : 0,
  },
  body: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    alignSelf: "center", // center itself horizontally(always in outer container)
    maxWidth: "80%", // healthy maxWidth for big screen but will be 95 % for small screen
    top: 100,
  },
  outerColumnContainerMobile: {
    display: "flex",
    height: "100%",
    overflow: "scroll",
    top: 50,
  },
  justifiedText: {
    textAlign: "justify",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  centeredText: {
    textAlign: "center",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  top: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    paddingTop: Platform.OS === "web" ? 50 : 10,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  middle: {
    paddingTop: Platform.OS === "web" ? 50 : 20,
    paddingBottom: Platform.OS === "web" ? 50 : 20,

    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  bottom: {
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
  logo: {
    width: Platform.OS === "web" ? 120 : 60,
    height: Platform.OS === "web" ? 70 : 35,
    margin: 10,
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    padding: 10,
    width: "100%",
    height: 300,
    display: "flex",
  },
});
