import React from "react";
import { Platform } from "react-native";
import {
  SELF_VIDEO_SIZE_MOBILE_HEIGHT,
  SELF_VIDEO_SIZE_WEB_HEIGHT,
} from "../../styles/base";
import Constants from "expo-constants";
import { StyleSheet } from "react-native";

import MyRTCView, { MediaStream } from "../MyRTCView";

type UserInCallRemoteVideoViewProps = {
  remoteStream: MediaStream;
  volumeOn: boolean;
  remoteCameraDisabled: boolean;
  serviceType?: string;
};

export default function UserInCallRemoteVideoView({
  remoteStream,
  volumeOn,
  remoteCameraDisabled,
  serviceType,
}: UserInCallRemoteVideoViewProps) {
  console.log(`[UserInCallRemoteVideoView] volumeOn? ${volumeOn}`);
  return (
    <MyRTCView
      mirror={false}
      zOrder={-1}
      videoId={"other"}
      style={{
        paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
        display: serviceType === "transcription" ? "none" : "flex",
        justifyContent: "center",
        maxWidth: "100%",
        top:
          Platform.OS === "web"
            ? -1 * SELF_VIDEO_SIZE_WEB_HEIGHT
            : -1 * SELF_VIDEO_SIZE_MOBILE_HEIGHT + 20,
        maxHeight: Platform.OS === "web" ? "100vh" : "90%", // xman leave space for appbar and statusbar
        zIndex: -100,
        width: "100%",
        height: "98%",
        position: "relative",
      }}
      stream={remoteStream}
      muted={!volumeOn}
      videoDisabled={remoteCameraDisabled}
    />
  );
}
