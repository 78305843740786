import _ from "lodash";
import React from "react";
import { List } from "react-native-paper";
import { getP2PDownloadRecordingLink } from "../../services/recordings.service";
import { DownloadFileWithProgress } from "../../components/DownloadFileWithProgress";

interface SingleRecordingProps {
  streamOf: any;
  key: number;
  setDisplayProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadProgress;
  callId: any;
  value: string;
  size: number;
}

export const SingleP2PRecording = ({
  streamOf,
  key,
  setDisplayProgress,
  setDownloadProgress,
  callId,
  value,
  size,
}: SingleRecordingProps) => {
  console.log(`[SingleRecording] key ${key}`);
  console.log(`[SingleRecording] key ${value}`);
  return (
    <List.Item
      title={streamOf}
      description={streamOf}
      key={key}
      right={(props) => {
        return (
          <>
            <DownloadFileWithProgress
              size={size}
              key={key}
              fileName={value}
              downloadLink={getP2PDownloadRecordingLink(callId, value)}
              progressIndication={setDisplayProgress}
              setDownloadProgress={setDownloadProgress}
            ></DownloadFileWithProgress>
          </>
        );
      }}
    ></List.Item>
  );
};
