import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { plPL } from "../../localization/plPL";
// import { plPL } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import { FAB, Portal, Text } from "react-native-paper";
import { View } from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { deleteUser, getAllUsers } from "../../services/user.service";
import { AuthContext } from "../../auth-context";
import _ from "lodash";
import CustomDialog from "../../components/CustomDialog";
import baseStyles from "../../styles/base";
import { Loading } from "../../components/Loading";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { localizedDateComparator } from "../../services/localized.service";

const theme = createTheme({}, plPL);

const headerNames = new Map();
headerNames.set("_id", "ID");
headerNames.set("role", "Rola");
headerNames.set("firstName", "Imię");
headerNames.set("fullName", "Imię i nazwisko");
headerNames.set("lastName", "Nazwisko");
headerNames.set("email", "email");
headerNames.set("status", "status");

export default function Users() {
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;
  const [users, setUsers] = React.useState([]);
  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const [showSpinner, setshowSpinner] = React.useState(false);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");
  const [dialogConfirmDelete, setDialogConfirmDelete] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState("");

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const columns: GridColumns = [
    { field: "_id", headerName: headerNames.get("_id"), width: 90 },
    {
      field: "actions",
      type: "actions",
      headerName: "Akcje",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        if (auth?.isAdmin)
          return [
            <GridActionsCellItem
              data-testid={"edit_" + row.email}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                handleClick(id);
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              data-testid={"delete_" + row.email}
              label="Delete"
              onClick={() => {
                setDialogConfirmDelete(true);
                setUserToDelete(id);
              }}
              color="inherit"
            />,
            row.role === "user" ? (
              <GridActionsCellItem
                icon={<PrintIcon />}
                data-testid={"print_registration_" + row.email}
                label="Print"
                onClick={() => {
                  handlePrintRegistrationInfo(id);
                }}
                color="inherit"
              />
            ) : (
              <></>
            ),
          ];
        else
          return [
            <GridActionsCellItem
              data-testid={"edit_" + row.email}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                handleClick(id);
              }}
              color="inherit"
            />,
          ];
      },
    },
    {
      field: "firstName",
      headerName: headerNames.get("firstName"),
      width: 100,
      editable: false,
    },
    {
      field: "lastName",
      headerName: headerNames.get("lastName"),
      width: 200,
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        // console.log(`lastName params ${JSON.stringify(params.row)}`);
        return (
          <Text
            testID={params.row.email + "_lastName"}
            style={{ marginLeft: 16 }}
          >
            {params.value}
          </Text>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 300,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Telefon",
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "role",
      headerName: headerNames.get("role"),
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Text style={{ marginLeft: 16 }}>
          {params.value === "admin"
            ? "Administrator"
            : params.value === "translator"
            ? "Tłumacz"
            : params.value === "transcriber"
            ? "Transkrybent"
            : params.value === "editor"
            ? "Filmowiec"
            : "Użytkownik"}
        </Text>
      ),
    },
    {
      field: "isParticipationFormFinished",
      headerName: "Formularz wypełniony",
      type: "string",
      valueGetter: (params) => {
        // const v = value && new Date(value).toLocaleString("pl-PL"),
        const v =
          params.row.isParticipationFormFinished.length === 1 &&
          params.row.isParticipationFormFinished[0] === true
            ? "Tak"
            : params.row.role === "user"
            ? "Nie"
            : "";
        return v;
      },
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <Text
            testID={"isParticipationFormFinished_" + params.row.email}
            style={{
              color:
                params.row.isParticipationFormFinished.length === 1 &&
                params.row.isParticipationFormFinished[0] === true &&
                params.row.role === "user"
                  ? undefined
                  : "red",
            }}
          >
            {params.row.isParticipationFormFinished.length === 1 &&
            params.row.isParticipationFormFinished[0] === true
              ? "Tak"
              : params.row.role === "user"
              ? "Nie"
              : ""}
          </Text>
        );
      },
      width: 100,
      editable: true,
    },
    {
      field: "isActive",
      headerName: "Aktywny",
      type: "string",
      valueGetter: (params) => {
        return params.value === true ? "Tak" : "Nie";
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <Text
          testID={"isActive_" + params.row.email}
          style={{
            color:
              params.value === "Nie" && params.row.role === "user"
                ? "red"
                : undefined,
          }}
        >
          {params.value}
        </Text>
      ),
      width: 100,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Data utworzenia",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "emailVerifiedAt",
      headerName: "Data potwierdzenia email",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "activatedAt",
      headerName: "Data aktywowania",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "participationFormCreatedAt",
      headerName: "Data pierwszego połączenia",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      sortComparator: localizedDateComparator,
      // valueGetter: ({ value }) =>
      //   value && new Date(value).toLocaleString("pl-PL"),
      valueGetter: ({ value }) => {
        // const v = value && new Date(value).toLocaleString("pl-PL"),
        const v = value.length === 1 ? value[0] : undefined;
        if (v) return new Date(v).toLocaleString("pl-PL");
        else return "";
      },
    },
    {
      field: "participationFormFinishedAt",
      headerName: "Data wypełnienia formularza korzystania z usług",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) => {
        // const v = value && new Date(value).toLocaleString("pl-PL"),
        const v = value.length === 1 ? value[0] : undefined;
        if (v) return new Date(v).toLocaleString("pl-PL");
        else return "";
      },
    },
    {
      field: "lastSuccessfulLoginAt",
      headerName: "Ostatnie udane logowanie",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "lastNotSuccessfulLoginAt",
      headerName: "Ostatnie nieudane logowanie",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "closedAt",
      headerName: "Data zamknięcia konta",
      editable: false,
      type: "string",
      align: "right",
      width: 200,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
  ];

  const navigation = useNavigation();

  React.useEffect(() => {
    releaseUserMedia();

    if (_.isEmpty(auth)) {
      return;
    } else {
      //   console.log("[Users] useEffect (with auth), auth:", auth);
      if (users.length === 0) {
        console.log("fetching data using ", auth);

        getAllUsers()
          .then((freshUsers) => {
            console.log(
              `[Users] useEffect (with auth), freshUsers:`,
              freshUsers
            );
            setshowSpinner(false);
            setUsers(freshUsers);
          })
          .catch((error) => {
            console.log(`caught1 ${JSON.stringify(error)}`);
            setshowSpinner(false);
            setDialogErrorVisible(true);
            if (error && error.response && error.response.data)
              setDialogErrorMessage(error.response.data.error);
            else setDialogErrorMessage(JSON.stringify(error));
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, [auth]);

  const newItem = () => {
    console.log("new item");
    navigation.navigate("Profile");
  };

  const confirmedDeleteUser = (id: string) => {
    deleteUser(id)
      .then((response) => {
        console.log(`[Users] user deleted:`, response);
        setUserToDelete("");
        setDialogConfirmDelete(false);
        getAllUsers()
          .then((freshUsers) => {
            console.log(
              `[Users] useEffect (with auth), freshUsers:`,
              freshUsers
            );
            setUsers(freshUsers);
          })
          .catch((error) => {
            console.log("error:", error);
            alert("Błąd pobierania aktualnej listy użytkowników");
          });
      })
      .catch((err) => {
        console.log("error:", err);
        setUserToDelete("");
        alert("Błąd w trakcie usuwania użytkownika");
      });
  };

  const handleClick = (id: string) => {
    // console.log('event ', JSON.stringify(event.target));
    navigation.navigate("Profile", { userId: id });
  };

  const handlePrintRegistrationInfo = (id: string) => {
    // console.log('event ', JSON.stringify(event.target));
    navigation.navigate("RegistrationInfo", { userId: id });
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        <View testID="UsersScreen" style={{ display: "none" }}></View>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <CustomDialog
          visible={dialogConfirmDelete}
          dialogType={"YesOrNo"}
          dialogActionNo={() => setDialogConfirmDelete(false)}
          dialogActionYes={() => confirmedDeleteUser(userToDelete)}
          dialogTitle={"Ostrzeżenie"}
          dialogContent={"Czy na pewno chcesz usunąć użytkownika?"}
          testID="error_dialog"
        />
        <Portal>
          <FAB
            visible={isFocused}
            icon="plus"
            testID="create_user"
            style={baseStyles.addItemFab}
            onPress={newItem}
          />
        </Portal>
        <Text nativeID="users_loaded" style={{ display: "none" }}>
          {users && users.length > 0 ? "true" : "false"}
        </Text>
        <DataGrid
          style={{ backgroundColor: "#fff" }}
          sortingOrder={["desc", "asc"]}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          rows={users}
          getRowId={(row) => row._id}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
        />
      </ThemeProvider>
    </div>
  );
}
