import {
  Autocomplete,
  Grid,
  TextField,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";

interface ComponentProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors: {};
}

export const GenderComponent = ({
  resource,
  setResource,
  errors,
}: ComponentProps) => {
  return (
    <Grid item xs={12}>
      <FormControl>
        <FormLabel id="gender" error={errors ? errors.gender : undefined}>
          6. Płeć
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={
            resource && resource.gender === "in_progress"
              ? "Mężczyzna"
              : resource.gender === "finished"
              ? "Kobieta"
              : resource.gender
              ? resource.gender
              : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              gender: event.target.value,
            });
          }}
        >
          <FormControlLabel
            value="Kobieta"
            control={<Radio />}
            label="Kobieta"
          />
          <FormControlLabel
            value="Mężczyzna"
            control={<Radio />}
            label="Mężczyzna"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
