import axios from "axios";
import config from "../oneconfig";

export const getAll = async () => {
  console.log(`[getAll]  default headers `);
  const response = await axios.get(config.api_url + "/recordingsConfCall");
  return response.data;
};

export const getRecording = async (callId: string) => {
  console.log(`[getAll]  default headers `);
  const response = await axios.get(config.api_url + `/recordings/${callId}`);

  return response.data;
};

export const downloadRecording = async (file: string) => {
  // try {
  console.log(`[getAll]  default headers `);

  axios({
    url: config.api_url + `/recordings/${file}`,
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};
export const getP2PDownloadRecordingLink = (callId: string, file: string) => {
  return `/p2pRecordings/${callId}/${file}`;
};

export const getMultiPartyDownloadRecordingLink = (file: string) => {
  return `/multiPartyRecordings/${file}`;
};
