import _ from "lodash";
import { SupportFormDocument } from "../../frontend_domain";

interface SupportType {
  id: string;
  label: string;
}

const supportTypes = [
  {
    id: "supportType_participationForm",
    label: "Wypełnianie formularza korzystania z usług",
  },
  {
    id: "supportType_jobAdContent",
    label: "Tłumaczenie treści ogłoszenia o pracę",
  },
  {
    id: "supportType_jobAdContact",
    label: "Tłumaczenie podczas kontaktu w sprawie ogłoszenia o pracę",
  },
  {
    id: "supportType_recruitment",
    label:
      "Tłumaczenie podczas rozmowy rekrutacyjnej i podczas omawiania szczegółów dotyczących zatrudnienia",
  },
  {
    id: "supportType_medicalSchedule",
    label: "Tłumaczenie podczas umawiania wizyty u lekarza medycyny pracy",
  },
  {
    id: "supportType_medicalExamine",
    label: "Tłumaczenie podczas badania u lekarza medycyny pracy",
  },
  {
    id: "supportType_healthAndSafety",
    label: "Tłumaczenie podczas szkolenia BHP",
  },
  {
    id: "supportType_everydayWork",
    label:
      "Tłumaczenie dotyczące codziennego funkcjonowania w pracy w ramach komunikacji ze słyszącym pracodawcą, przełożonym, kierownikiem, współpracownikami.",
  },
  {
    id: "supportType_documentTranslation",
    label:
      "Tłumaczenie umów o pracę, zakresu obowiązków, regulaminów, procedur i innych niezbędnych materiałów umożliwiających właściwe funkcjonowanie osoby komunikującej się w języku migowym na terenie zakładu pracy.",
  },
  {
    id: "supportType_other",
    label: "inne",
  },
  {
    id: "",
    label: "",
  },
];

export const supportTypesOptions = {
  options: supportTypes,
  getOptionLabel: (option: SupportType) =>
    option && option.label ? option.label : "",
};

export const updateSupportTypes = (
  newValues: Array<SupportType>,
  supportForm: SupportFormDocument,
  setSupportForm: Function
) => {
  let codes = Array<string>();
  newValues.forEach((element) => {
    codes.push(element.id);
  });

  console.log(`new support types codes ${JSON.stringify(codes)}`);

  console.log(`new support types ${JSON.stringify(newValues)}`);

  let currentSupportTypes = {};

  if (supportForm) {
    const supportFormKeys = Object.keys(supportForm) as Array<string>;
    supportFormKeys.reduce(function (_r, e: string): string {
      if (e && e.startsWith("supportType")) {
        // console.log(`e: ${e}`);
        if (codes.includes(e)) currentSupportTypes[e] = true;
        else currentSupportTypes[e] = false;
      }

      return "";
    });
  }
  console.log(`current stakeholders ${JSON.stringify(currentSupportTypes)}`);
  console.log(
    `update state ${JSON.stringify({
      ...supportForm,
      ...currentSupportTypes,
    })}`
  );

  setSupportForm({ ...supportForm, ...currentSupportTypes });
};

export const getSupportTypes = (supportForm: SupportFormDocument) => {
  let currentSupportTypes = Array<SupportType>();

  if (supportForm && Object.keys(supportForm).length > 0) {
    console.log(`supportForm ${JSON.stringify(supportForm)}`);
    // @ts-ignore
    Object.keys(supportForm).reduce(function (r, e) {
      // console.log(
      //   `r: ${r ? r : ""} e: ${e ? e : ""} value: ${
      //     supportForm[e] ? supportForm[e] : ""
      //   }`
      // );
      if (e && e.startsWith("supportType")) {
        /* console.log(
              `r: ${r ? r : ""} e: ${e ? e : ""} value: ${item[e] ? item[e] : ""}`
            ); */
        // console.log(`typeof  ${typeof supportForm[e]}`);
        if (supportForm[e] === true || supportForm[e] === "true") {
          //   console.log(`adding support type "${e}"`);
          const mappedLabel = mapSupportTypeToLabel(e);
          //   console.log(`adding label "${mappedLabel}"`);

          if (mappedLabel)
            currentSupportTypes.push({ id: e, label: mappedLabel });
        }
      }
    });
  }

  console.log(`currentSupportTypes ${JSON.stringify(currentSupportTypes)}`);
  console.log(`currentSupportTypes  typeof ${typeof currentSupportTypes}`);
  return currentSupportTypes;
};

const mapSupportTypeToLabel = (code: string) => {
  let found = "";
  supportTypes.forEach((value) => {
    if (value.id === code) {
      console.log(`adding mapped value ${value.label}`);
      found = value.label;
    }
  });
  return found;
};
