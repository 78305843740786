import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  inputCheckboxContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 15,
    minWidth: 300,
  },
  acceptanceText: {
    flex: 1,
    display: "flex",
    flexGrow: 5,
    paddingLeft: 10,
  },
  validationText: {
    flex: 1,
    flexGrow: 5,
    paddingLeft: 10,
    color: "red",
  },
});
