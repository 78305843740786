import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { StyleSheet } from "react-native";
import { ActivityIndicator, Portal } from "react-native-paper";
import { ProgressBar, Colors, Button, Text } from "react-native-paper";

export interface ProgressDialogProps {
  progressActual: number;
  progressStage?: string;
  progressMessage?: string | undefined;
  progressFinished: boolean;
  visible: boolean;
  onCancelAction?: Function | undefined;
  onFinishAction?: Function | undefined;
  finishedMessage: string;
  onOKAction: Function;
}

export const ProgressDialog = ({
  progressActual,
  progressStage,
  progressMessage,
  progressFinished, //xman sometimes 100% axios is misleading, if this is set await if done
  onCancelAction,
  onOKAction,
  finishedMessage,
  ...props
}: ProgressDialogProps) => {
  const [stage, setStage] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    // console.log(`use effect ${progressActual}`);
    setProgress(progressActual);

    setStage(progressStage ? progressStage : "");
    // setProgress(progressActual ? progressActual : 0);
    const messageToRender = progressMessage
      ? progressMessage
      : `Postęp ${progressActual ? (progressActual * 100).toFixed(2) : 0}%`;
    setMessage(messageToRender);
  }, [progressActual, progressStage, progressMessage]);

  console.log(
    `ProgressDialog rerender progress stage: ${stage}, value: ${
      progress ? progress.toFixed(2) : 0
    }, progressMessage: ${message}`
  );

  return (
    <Portal>
      <View style={styles.container}>
        {/* <ActivityIndicator size="large" style={styles.activityIndicator} /> */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: 300,
            backgroundColor: "white",
            borderColor: "gray",
            borderStyle: "solid",
            borderWidth: 1,
          }}
        >
          <ProgressBar
            style={{ height: 10, width: 300, backgroundColor: "orange" }}
            progress={progress}
            color={Colors.red800}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>{message}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>{stage}</Text>
          </View>
          {progress !== 1 && (
            <ActivityIndicator size="large" style={styles.activityIndicator} />
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {onCancelAction && progress < 1 && (
              <Button
                testID="button_cancel"
                style={styles.button}
                icon="alpha-x-box-outline"
                color="orange"
                mode="contained"
                onPress={() => onCancelAction()}
              >
                Anuluj
              </Button>
            )}

            {onOKAction && progress === 1 && (
              <Button
                testID="button_ok"
                // icon="camera"
                mode="contained"
                onPress={() => {
                  onOKAction();
                }}
              >
                {finishedMessage}
              </Button>
            )}
          </View>
        </View>
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",

    // backgroundColor: "orange",
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  button: {
    margin: 5,
  },
});
