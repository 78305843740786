import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  CountyDisabilityLevel as CountyDisabilityLevelEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface CountyDisabilityLevel {
  id: string;
  label: string;
}

export const countyDisabilityLevel = Object.keys(CountyDisabilityLevelEnum).map(
  (key) => ({
    id: key,
    label: CountyDisabilityLevelEnum[key],
  })
);

console.debug(`countyDisabilityLevel ${JSON.stringify(countyDisabilityLevel)}`);

interface CountyDisabilityLevelProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const CountyDisabilityLevel = ({
  resource,
  errors,
  setResource,
  onBlur,
}: CountyDisabilityLevelProps) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body1" id="county_disability_level">
        21. Posiadany stopień orzeczenia
      </Typography>
      <Autocomplete
        options={countyDisabilityLevel}
        disablePortal
        value={
          resource.county_disability_level
            ? resource.county_disability_level
            : ""
        }
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            required={true}
            error={errors ? errors["county_disability_level"] : false}
            fullWidth
            onBlur={onBlur}
            label="Stopień orzeczenia"
          />
        )}
        onChange={(event: any, newValue: CountyDisabilityLevel) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            county_disability_level: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
