export const plPL = {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          noRowsLabel: "Brak danych",
          noResultsOverlayLabel: "Nie znaleziono wyników.",
          errorOverlayDefaultLabel: "Wystąpił błąd.",
          toolbarDensity: "Wysokość wiersza",
          toolbarDensityLabel: "Wysokość wiersza",
          toolbarDensityCompact: "Kompakt",
          toolbarDensityStandard: "Standard",
          toolbarDensityComfortable: "Komfort",
          toolbarColumns: "Kolumny",
          toolbarColumnsLabel: "Zaznacz kolumny",
          toolbarFilters: "Filtry",
          toolbarFiltersLabel: "Pokaż filtry",
          toolbarFiltersTooltipHide: "Ukryj filtry",
          toolbarFiltersTooltipShow: "Pokaż filtry",
          toolbarQuickFilterPlaceholder: "Wyszukaj…",
          toolbarQuickFilterLabel: "Szukaj",
          toolbarQuickFilterDeleteIconLabel: "Wyczyść",
          toolbarExport: "Eksportuj",
          toolbarExportLabel: "Eksportuj",
          toolbarExportCSV: "Pobierz jako plik CSV",
          toolbarExportPrint: "Drukuj",
          toolbarExportExcel: "Pobierz jako plik Excel",
          columnsPanelTextFieldLabel: "Znajdź kolumnę",
          columnsPanelTextFieldPlaceholder: "Tytuł kolumny",
          columnsPanelDragIconLabel: "Zmień kolejność kolumn",
          columnsPanelShowAllButton: "Pokaż wszystko",
          columnsPanelHideAllButton: "Ukryj wszystko",
          filterPanelAddFilter: "Dodaj filtr",
          filterPanelDeleteIconLabel: "Usuń",
          filterPanelLinkOperator: "Operator logiczny",
          filterPanelOperators: "Operator",
          filterPanelOperatorAnd: "I",
          filterPanelOperatorOr: "Lub",
          filterPanelColumns: "Kolumny",
          filterPanelInputLabel: "Wartość",
          filterPanelInputPlaceholder: "Filtrowana wartość",
          filterOperatorContains: "zawiera",
          filterOperatorEquals: "równa się",
          filterOperatorStartsWith: "zaczyna się od",
          filterOperatorEndsWith: "kończy się na",
          filterOperatorIs: "równa się",
          filterOperatorNot: "różne",
          filterOperatorAfter: "większe niż",
          filterOperatorOnOrAfter: "większe lub równe",
          filterOperatorBefore: "mniejsze niż",
          filterOperatorOnOrBefore: "mniejsze lub równe",
          filterOperatorIsEmpty: "jest pusty",
          filterOperatorIsNotEmpty: "nie jest pusty",
          filterOperatorIsAnyOf: "jest jednym z",
          filterValueAny: "dowolny",
          filterValueTrue: "prawda",
          filterValueFalse: "fałsz",
          columnMenuLabel: "Menu",
          columnMenuShowColumns: "Pokaż wszystkie kolumny",
          columnMenuFilter: "Filtr",
          columnMenuHideColumn: "Ukryj",
          columnMenuUnsort: "Anuluj sortowanie",
          columnMenuSortAsc: "Sortuj rosnąco",
          columnMenuSortDesc: "Sortuj malejąco",
          columnHeaderFiltersLabel: "Pokaż filtry",
          columnHeaderSortIconLabel: "Sortuj",
          footerTotalRows: "Łączna liczba wierszy:",
          checkboxSelectionHeaderName: "Pole wyboru",
          checkboxSelectionSelectAllRows: "Zaznacz wszystkie wiersze",
          checkboxSelectionUnselectAllRows: "Odznacz wszystkie wiersze",
          checkboxSelectionSelectRow: "Zaznacz wiersz",
          checkboxSelectionUnselectRow: "Odznacz wiersz",
          booleanCellTrueLabel: "tak",
          booleanCellFalseLabel: "nie",
          actionsCellMore: "więcej",
          pinToLeft: "Przypnij do lewej",
          pinToRight: "Przypnij do prawej",
          unpin: "Odepnij",
          treeDataGroupingHeaderName: "Grupa",
          treeDataExpand: "pokaż elementy potomne",
          treeDataCollapse: "ukryj elementy potomne",
          groupingColumnHeaderName: "Grupa",
          expandDetailPanel: "Rozwiń",
          collapseDetailPanel: "Zwiń",
          rowReorderingHeaderName: "Porządkowanie wierszy",
          MuiTablePagination: { labelRowsPerPage: "Wierszy na stronę:" },
        },
      },
    },
  },
};
