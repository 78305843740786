import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import React, { FocusEventHandler } from "react";
import {
  ParticipationFormDocument,
  TypeOfEmployment as TypeOfEmploymentEnum,
  EmployerSector as EmployerSectorEnum,
  CompanySize as CompanySizeEnum,
  EmployerDisabilityType as EmployerDisabilityTypeEnum,
  EmployerCategoryPublic as EmployerCategoryPublicEnum,
  EmployerCategory3rdSector as EmployerCategory3rdSectorEnum,
} from "../../frontend_domain";
import LABELS from "./labels";

interface EmployerComponentProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  required?: boolean;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export interface EmployerSector {
  id: string;
  label: string;
}

export interface EmployerDisabilityType {
  id: string;
  label: string;
}

export interface EmployerCategoryPublic {
  id: string;
  label: string;
}

export interface CompanySize {
  id: string;
  label: string;
}

export interface EmployerCategory3rdSector {
  id: string;
  label: string;
}
export const companySizeOptions = Object.keys(CompanySizeEnum).map((key) => ({
  id: key,
  label: CompanySizeEnum[key],
}));

export const employerDisabilityTypeOptions = Object.keys(
  EmployerDisabilityTypeEnum
).map((key) => ({
  id: key,
  label: EmployerDisabilityTypeEnum[key],
}));

export const employerCategory3rdSectorOptions = Object.keys(
  EmployerCategory3rdSectorEnum
).map((key) => ({
  id: key,
  label: EmployerCategory3rdSectorEnum[key],
}));

export const employerCategoryPublicOptions = Object.keys(
  EmployerCategoryPublicEnum
).map((key) => ({
  id: key,
  label: EmployerCategoryPublicEnum[key],
}));

export const employerSectorOptions = Object.keys(EmployerSectorEnum).map(
  (key) => ({
    id: key,
    label: EmployerSectorEnum[key],
  })
);

export interface TypeOfEmployment {
  id: string;
  errors?: {};
  label: string;
}

export const employmentTypeOptions = Object.keys(TypeOfEmploymentEnum).map(
  (key) => ({
    id: key,
    label: TypeOfEmploymentEnum[key],
  })
);

console.debug(`employmentTypeOptions ${JSON.stringify(employmentTypeOptions)}`);

interface TypeOfEmploymentComponentProps {
  resource: ParticipationFormDocument;
  errors?: {};
  required?: boolean;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setResource: React.Dispatch<any>;
}

export const EmployerComponent = ({
  resource,
  errors,
  onBlur,
  required,
  setResource,
}: EmployerComponentProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant={"body1"} id="type_of_employment">
          {LABELS._26}
        </Typography>
        <Autocomplete
          options={employmentTypeOptions}
          disablePortal
          disabled={resource.work_status !== "Osoba pracująca"}
          value={resource.type_of_employment ? resource.type_of_employment : ""}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              fullWidth
              required={required ? required : false}
              error={errors ? errors["type_of_employment"] : false}
              onBlur={onBlur}
              label="Rodzaj zatrudnienia"
            />
          )}
          onChange={(event: any, newValue: TypeOfEmployment) => {
            console.log(`newValue ${JSON.stringify(newValue)}`);
            setResource({
              ...resource,
              type_of_employment: newValue ? newValue.label : "",
            });
          }}
        />
        <Typography variant="body1">{LABELS._27}</Typography>
        <TextField
          margin="normal"
          id="employer_name"
          required={required}
          error={errors ? errors["employer_name"] : false}
          fullWidth
          onBlur={onBlur}
          label="Nazwa pracodawcy"
          value={
            resource && resource.employer_name ? resource.employer_name : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              employer_name: event.target.value,
            });
          }}
        />

        <TextField
          margin="normal"
          id="employer_address"
          required={required}
          error={errors ? errors["employer_address"] : false}
          fullWidth
          onBlur={onBlur}
          label="Adres pracodawcy"
          value={
            resource && resource.employer_address
              ? resource.employer_address
              : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              employer_address: event.target.value,
            });
          }}
        />
        <Typography variant="body1" id="employer_sector">
          {LABELS._28}
        </Typography>

        <Autocomplete
          options={employerSectorOptions}
          disablePortal
          disabled={resource.work_status !== "Osoba pracująca"}
          value={resource.employer_sector ? resource.employer_sector : ""}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              required={required}
              error={errors ? errors["employer_sector"] : false}
              fullWidth
              onBlur={onBlur}
              label="Rodzaj pracodawcy"
            />
          )}
          onChange={(event: any, newValue: EmployerSector) => {
            console.log(`newValue ${JSON.stringify(newValue)}`);
            setResource({
              ...resource,
              employer_sector: newValue ? newValue.label : "",
            });
          }}
        />

        {resource.employer_sector === EmployerSectorEnum.PUBLICZNE && (
          <>
            <Autocomplete
              options={employerCategoryPublicOptions}
              disablePortal
              id="employer_category_public"
              value={
                resource.employer_sector
                  ? resource.employer_category_public
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  fullWidth
                  label="Rodzaj podmiotu publicznego"
                />
              )}
              onChange={(event: any, newValue: EmployerSector) => {
                console.log(`newValue ${JSON.stringify(newValue)}`);
                setResource({
                  ...resource,
                  employer_category_public: newValue.label,
                });
              }}
            />
          </>
        )}

        {resource.employer_sector === EmployerSectorEnum.PUBLICZNE &&
          resource.employer_category_public ===
            EmployerCategoryPublicEnum.INNE && (
            <TextField
              margin="normal"
              id="employer_category_3rd_sector_other"
              fullWidth
              label="Inna placówka. Jaka?"
              value={
                resource && resource.employer_category_public_other
                  ? resource.employer_category_public_other
                  : ""
              }
              onChange={(event) => {
                setResource({
                  ...resource,
                  employer_category_public_other: event.target.value,
                });
              }}
            />
          )}

        {resource.employer_sector === EmployerSectorEnum.TRZECI_SEKTOR && (
          <>
            <Autocomplete
              options={employerCategory3rdSectorOptions}
              disablePortal
              id="employer_sector"
              value={
                resource.employer_category_3rd_sector
                  ? resource.employer_category_3rd_sector
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  fullWidth
                  label="Rodzaj podmiotu trzeciego sektora"
                />
              )}
              onChange={(event: any, newValue: EmployerSector) => {
                console.log(`newValue ${JSON.stringify(newValue)}`);
                setResource({
                  ...resource,
                  employer_category_3rd_sector: newValue.label,
                });
              }}
            />
          </>
        )}

        {resource.employer_sector === EmployerSectorEnum.TRZECI_SEKTOR &&
          resource.employer_category_3rd_sector ===
            EmployerCategory3rdSectorEnum.INNY && (
            <TextField
              margin="normal"
              id="employer_category_3rd_sector_other"
              fullWidth
              label="Inny – jaki?"
              value={
                resource && resource.employer_category_3rd_sector_other
                  ? resource.employer_category_3rd_sector_other
                  : ""
              }
              onChange={(event) => {
                setResource({
                  ...resource,
                  employer_category_3rd_sector_other: event.target.value,
                });
              }}
            />
          )}
      </Grid>

      <Typography variant="body1">{LABELS._29}</Typography>

      <Autocomplete
        options={companySizeOptions}
        disablePortal
        id="employer_size"
        disabled={resource.work_status !== "Osoba pracująca"}
        value={resource.employer_size ? resource.employer_size : ""}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            required={required}
            error={errors ? errors["employer_size"] : false}
            fullWidth
            onBlur={onBlur}
            label="Wielkość pracodawcy"
          />
        )}
        onChange={(event: any, newValue: EmployerSector) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            employer_size: newValue ? newValue.label : "",
          });
        }}
      />
      <Typography variant="body1">{LABELS._30}</Typography>

      <Autocomplete
        options={employerDisabilityTypeOptions}
        disablePortal
        id="employer_disability_type"
        disabled={resource.work_status !== "Osoba pracująca"}
        value={
          resource.employer_disability_type
            ? resource.employer_disability_type
            : ""
        }
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            required={true}
            error={errors ? errors["employer_disability_type"] : false}
            fullWidth
            onBlur={onBlur}
            label=""
          />
        )}
        onChange={(event: any, newValue: EmployerSector) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            employer_disability_type: newValue ? newValue.label : "",
          });
        }}
      />
    </>
  );
};
