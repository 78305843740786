import React, { Fragment, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

export const eventRenderProps = (event, start, end, isSelected) => {
  let backGroundColor;
  if (event?.title === "Urlop") backGroundColor = "green";
  else if (event?.title === "L4") backGroundColor = "red";
  else if (event?.title === "inne") backGroundColor = "orange";

  if (backGroundColor) return { style: { backgroundColor: backGroundColor } };
  else return {};
};

export function EventAgenda({ event }) {
  //   console.log(`render EventAgenda ${JSON.stringify(event)}`);
  return (
    <span
      style={{
        color: event?.title === "inne" ? "black" : undefined,
      }}
    >
      <strong
        style={{
          color: event?.title === "inne" ? "black" : undefined,
        }}
      >
        {event.resourceId}
      </strong>
      <p>{event.desc}</p>
    </span>
  );
}
EventAgenda.propTypes = {
  event: PropTypes.object,
};

export function MyEvent({ event }) {
  //   console.log(`render event with title ${JSON.stringify(event.title)}`);
  return (
    <span>
      {event.desc && ":  " + event.desc + ": " + event.resourceId}
      {/* <strong>{event.resourceId}</strong> */}
    </span>
  );
}
MyEvent.propTypes = {
  event: PropTypes.object,
};

export const MyEventContainerWrapper = ({ children }) => {
  //   console.log(`MyEventContainerWrapper ${JSON.stringify(typeof children)}`);
  return React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });
};

export function EventWeek({ event }) {
  //   console.log(`render Event ${JSON.stringify(event)}`);
  return (
    <span>
      {/* <strong>{event.resourceId}</strong> */}
      {event.desc && ":  " + event.desc + ": " + event.resourceId}
    </span>
  );
}
EventWeek.propTypes = {
  event: PropTypes.object,
};
