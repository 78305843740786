import { useTheme } from "@react-navigation/native";
import React, { useLayoutEffect } from "react";
import { useContext, useEffect, useState, useRef } from "react";
import {
  Platform,
  SafeAreaView,
  View,
  KeyboardAvoidingView,
} from "react-native";
import RNRestart from "react-native-restart";
import { createRTCPeerConnection } from "./webrtc";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { Button, Colors } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import { deactivateKeepAwake, activateKeepAwake } from "expo-keep-awake";
import {
  RTCPeerConnection,
  RTCSessionDescription,
  RTCIceCandidate,
} from "react-native-webrtc-web-shim";
import { RemoteVideosWeb } from "./RemoteVideosWeb";
import { RemoteVideosMobile } from "./RemoteVideosMobile";
import { useNavigation } from "@react-navigation/native";
import { AuthContext, AuthContextType } from "../../auth-context";
import Chat from "../../components/Chat";
import CustomDialog from "../../components/CustomDialog";
import MyRTCView, { MediaStream } from "../../components/MyRTCView";
import { DebugContext } from "../../debug-context";
import {
  CONFCALL_STATUS,
  ConnectedUserDTO,
  ConnectedUsersDTO,
} from "../../frontend_domain";
import GLOBAL_LABELS from "../../labels";
import { MultiPartyConfCallProps } from "../../navigation/types";
import oneconfig from "../../oneconfig";
import CCButtonsSection from "./CCButtonsSection";
import { UADialogText } from "./components/UA";
import { waitingForParticipants } from "./components/Waiting";
import dStyles from "./styles";
import UAServiceNotActiveView from "../../components/UAServiceNotActiveView";

import { ChatMessage } from "../../components/Chat/ChatItem";
import _ from "lodash";
import { Loading } from "../../components/Loading";

export const SOCKET_URL = oneconfig.signaling_endpoint;
import axios from "axios";
import { handleError } from "../../utils";
import { useForm } from "react-hook-form";
import ValidatedTextInput from "../../components/ValidatedTextInput";
import { useWindowSize } from "../../hooks/window_size";
import {
  SELF_VIDEO_SIZE_MOBILE_HEIGHT,
  SELF_VIDEO_SIZE_MOBILE_WIDTH,
  SELF_VIDEO_SIZE_WEB_HEIGHT,
  SELF_VIDEO_SIZE_WEB_WIDTH,
} from "../../styles/base";

type FormData = {
  manualToken: string;
};

const MultiPartyConfCall = ({ ...props }: MultiPartyConfCallProps) => {
  const smallScreen = useWindowSize().smallScreen;
  console.log(`props.route ${JSON.stringify(props.route)}`);
  const token =
    props.route.params && props.route.params.token
      ? props.route.params.token
      : undefined;

  console.log(`token ${token}`);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
  });

  const [room, setRoom] = useState<string>();
  const [confCallStatus, setConfCallStatus] = useState<Boolean>();

  const conn = useRef(null); // signaling connection (websockets)
  const peerConnections = useRef(new Map<string, RTCPeerConnection>());
  const remoteStreams = useRef(new Map<string, MediaStream>());
  const outgoingDataChannels = useRef(new Map<string, RTCDataChannel>());
  const incomingDataChannels = useRef(new Map<string, RTCDataChannel>());
  const [remoteCamerasDisabled, setRemoteCamerasDisabled] = useState(
    new Map<string, boolean>()
  );

  const updateMap = (key, value) => {
    setRemoteCamerasDisabled((map) => new Map(map.set(key, value)));
  };

  const mediaRecorders = useRef(new Map<string, MediaRecorder>());
  const [remoteStreamsForRender, setRemoteStreamsForRender] = useState(
    new Map<string, MediaStream>()
  );

  const [update, setUpdate] = useState();
  // const yourConn = useRef(createRTCPeerConnection()); // RTCPeer connection

  const { localStream, getConfCallUserMedia, releaseUserMedia } =
    React.useContext(LocalStreamContext) as LocalStreamContextType;

  const localStreamRef = useRef(null);

  async function validateUA() {
    const url = `${oneconfig.api_url}/permanent-confcall/validateUA`;
    console.log(`validateUA... ${token}`);
    setshowSpinner(true);

    try {
      const responseValid = await axios({
        url: url,
        method: "get",
      });

      console.log(
        `confcall room response ... ${JSON.stringify(
          responseValid.data.permanentToken
        )}`
      );

      setshowSpinner(false);
      setDialogUAVisible(true);

      setConfCallStatus(true);
    } catch (error) {
      console.error(JSON.stringify(error));
      console.error(error.response.status);
      setConfCallStatus(false);

      if (error && error.response && error.response.status === 404) {
        setDialogErrorMessage("Nieprawidłowy identyfikator do konferencji.");
        setDialogErrorVisible(true);
      } else
        handleError(
          setshowSpinner,
          error,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
    }
  }

  function hideDialogUA() {
    setRoom("UA");
    setshowSpinner(false);
    setDialogUAVisible(false);
    setConfCallStatus(true);
  }

  async function validate(room: string) {
    const url = `${oneconfig.api_url}/permanent-confcall/validate/${room}`;
    console.log(`validating token... ${token}`);
    setshowSpinner(true);

    try {
      await axios({
        url: url,
        method: "get",
      });

      console.log(`confcall room response ... ${JSON.stringify(room)}`);

      setRoom(room);
      setshowSpinner(false);
      setConfCallStatus(true);
    } catch (error) {
      console.error(JSON.stringify(error));
      console.error(error.response.status);
      setConfCallStatus(false);

      if (error && error.response && error.response.status === 404) {
        setDialogErrorMessage("Nieprawidłowy identyfikator do konferencji.");
        setDialogErrorVisible(true);
      } else
        handleError(
          setshowSpinner,
          error,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
    }
  }

  useEffect(() => {
    console.log("Initial render in confcall");
    if (
      auth?.declaredRefugee === true ||
      token === "UA" ||
      props.route.name === "MultiPartyConfCallUA"
    ) {
      console.log(`confcall for UA`);

      validateUA();
    } else if (token) {
      console.log(`trying token from url ${token}`);

      validate(token);
    } else {
      console.log("No token in url");
    }

    activateKeepAwake();
  }, []);

  async function validateWithManualToken(data: FormData) {
    console.log(`validateWithManualToken ${JSON.stringify(data)}`);
    setRoom(data.manualToken);
    validate(data.manualToken);
  }

  useLayoutEffect(() => {
    // console.log(` tream`);
    if (!localStreamRef.current) return;
  }, [localStreamRef.current]);

  const { colors } = useTheme();
  const mounted = useRef(false);

  const navigation = useNavigation();
  const [showSpinner, setshowSpinner] = React.useState(false);

  useLayoutEffect(() => {
    return () => {
      (async () => {
        console.log(`[MultiPartyConfCall] layout effect ${localStream}`);
        console.log(`navigation ${JSON.stringify(navigation)}`);
        // if (Platform.OS === "web") window.location.reload();
        // else {
        //   RNRestart.Restart();
        // }

        console.log(`releasing media before unmounting the DOM ${localStream}`);
        releaseUserMedia();
        // stopRecorder(ownMediaRecorder);
        // stopRecorder(peerMediaRecorder);

        if (conn.current) {
          console.log("Disconnecting from p2p signaling.");
          //clearInterval(heartbeatIntervalFunction.current);

          conn.current.close();
          conn.current = undefined;
        }

        if (peerConnections.current) {
          console.log("Cleaning peer connections");
          peerConnections.current = new Map<string, RTCPeerConnection>();
        }
      })();
    };
  }, []);

  // cleanup resources while navigating to other screen
  // it is alreed off
  useFocusEffect(
    React.useCallback(() => {
      const unsubscribe = () => {
        console.log(`focus off... time to clean ${localStream}`);

        releaseUserMedia();
      };

      return () => unsubscribe();
    }, [])
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const [status, setStatus] = useState("Nothing yet");
  const [connectedUsers, setConnectedUsers] = useState<ConnectedUsersDTO>(
    new Map<string, ConnectedUserDTO>()
  );

  const [incomingCall, setIncomingCall] = useState(false);
  const [newChatNotification, setNewChatNotification] = useState(false);

  const connectedUser = useRef(null);

  const { auth, setAuth } = useContext(AuthContext) as AuthContextType;
  const { debug } = useContext(DebugContext);

  console.debug("auth ", auth);

  const [dialogRecordingVisible, setDialogRecordingVisible] = useState(false);
  const [recordingAccepted, setRecordingAccepted] = useState(
    auth && auth.isTranslator ? true : true
  );

  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogUAVisible, setDialogUAVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [dialogInfofVisible, setDialogInfoVisible] = useState(false);
  const [dialogInfoMessage, setDialogInfoMessage] = useState("");
  const [dialogBeforeReload, setDialogBeforeReload] = useState(false);
  const [dialogBeforeReloadMessage, setDialogBeforeReloadMessage] =
    useState("");

  const [cameraOn, setCameraOn] = useState(true);
  const toggleCamera = () => {
    localStream?.getVideoTracks().forEach((track) => {
      track.enabled = !cameraOn;
    });

    for (const [id, channel] of outgoingDataChannels.current.entries()) {
      console.log(`datachannel event, selfVideo: `, !cameraOn);
      channel.send(JSON.stringify({ selfVideo: !cameraOn }));
    }

    setCameraOn(!cameraOn);
  };

  const [micOn, setMicOn] = useState(true);
  const toggleMic = () => {
    localStream?.getAudioTracks().forEach((track) => {
      track.enabled = !micOn;
    });

    setMicOn(!micOn);
  };

  const toggleMuteLocalStream = () => {
    if (micOn && localStream) localStream.getAudioTracks()[0].enabled = false;
    else if (localStream) localStream.getAudioTracks()[0].enabled = false;

    toggleMic();
  };

  const [volumeOn, setVolumeOn] = useState(true);
  const toggleVolumeOn = () => {
    setVolumeOn(!volumeOn);
  };

  const [chatOn, setIsChatOn] = useState(false);
  const toggleChat = () => {
    setNewChatNotification(false);
    setIsChatOn((isChatOn) => !isChatOn);
  };
  const [incomingChatMessage, setIncomingChatMessage] = useState<ChatMessage>();

  const [formsOn, setFormsOn] = useState(false);
  const toggleForms = () => setFormsOn((formsOn) => !formsOn);

  const styles = dStyles(debug, chatOn);

  const onOpen = (x) => {
    console.log("Web socket connection on open", x);
  };

  useEffect(() => {
    try {
      // if (!localStream) {
      (async () => {
        console.log(`calling  getUserMedia form conf call screen`);
        getConfCallUserMedia();
      })();
      // }
    } catch (error) {
      console.error("Error during getUserMedia", error);
      console.error(error);
      console.error(`initLocalVideo `, error);
      setDialogErrorVisible(true);
      setDialogErrorMessage(
        `Brak dostępu do kamery/mikrofonu. Upewnij się, że urządzenia są włączone i nie blokowane przez przeglądarkę. Błąd z przeglądarki internetowej: "${error.message}"`
      );
    }
  }, []);

  useEffect(() => {
    async function preparePeerConnections() {
      console.info(
        `[MultiPartyConfCall] change in either localStream, connectedUsers ${connectedUsers.size}`
      );

      if (localStream) {
        console.log(
          `[MultiPartyConfCall] preparing peer connection for localStream ${localStream}`
        );

        if (connectedUsers.size === 0) {
          console.log(`set status to: ${CONFCALL_STATUS.WAITING}`);
          setStatus(CONFCALL_STATUS.WAITING);
        } else {
          console.log(
            "[MultiPartyConfCall]  adding stream to peerConnections..."
          );

          for (const [id, connectedUser] of connectedUsers.entries()) {
            console.info(
              `[MultiPartyConfCall] connected user ${JSON.stringify(
                connectedUser
              )}`
            );

            if (peerConnections.current.has(id)) {
              console.log(`${id} was before - no need to recreate PC`);
            } else {
              const newPC: RTCPeerConnection = createRTCPeerConnection();

              if (Platform.OS === "web") {
                window.numberOfPeerConnections = window.numberOfPeerConnections
                  ? window.numberOfPeerConnections + 1
                  : 1;
              }
              newPC.addStream(localStream);

              newPC.ondatachannel = function (event) {
                console.log("datachannel ondatachannel", JSON.stringify(event));

                const incomingDataChannel = event.channel;

                incomingDataChannel.onmessage = function (message) {
                  // console.log("datachannel remote on message", message);
                  if (message.data) {
                    const data = JSON.parse(message.data);
                    console.log(
                      `datachannel remote selfVideo of ${id} is: `,
                      data?.selfVideo
                    );

                    console.log(
                      `datachannel update map with ${id}, ${!data?.selfVideo}`
                    );

                    updateMap(id, !data?.selfVideo);
                  }
                };

                incomingDataChannels.current.set(id, incomingDataChannel);
              };

              const outgoingDataChannel = newPC.createDataChannel("data");

              outgoingDataChannel.onerror = (error) =>
                console.log("datachannel Error:", error);

              outgoingDataChannel.onmessage = (event) => {
                console.log(`datachannel received: ${event.data}`);
              };

              outgoingDataChannel.onopen = () => {
                console.log("datachannel open");
              };

              outgoingDataChannel.onclose = () => {
                console.log("datachannel close");
              };

              outgoingDataChannels.current.set(id, outgoingDataChannel);

              console.log(`onaddstream for ${id}`);
              newPC.onaddstream = (event) => {
                console.log(
                  `On Add Remote Stream ${id}, # peer connections ${peerConnections.current.size}`
                );

                const names = [...peerConnections.current.keys()];
                const sortedNames = names.sort();

                // newPC.myRemoteStream = event.stream;
                console.log(`[Remote stream] adding ${id} ${event.stream}`);

                remoteStreams.current.set(id, event.stream);
                setRemoteStreamsForRender(remoteStreams.current);

                setUpdate(Math.random()); // xman hack to cause rerender each time

                if (Platform.OS === "web" && auth?.role == "translator") {
                  // console.log(`On Add Remote Stream {}`);
                  handleRecorder(id, event.stream);
                  handleRecorder(auth?.email, localStream);
                }
              };

              if (newPC)
                newPC.addEventListener(
                  "peerConnectionOnRenegotiationNeeded",
                  (event) => {
                    console.info(
                      `[MultiPartyConfCall] peerConnectionOnRenegotiationNeeded `,
                      JSON.stringify(event)
                    );
                  }
                );
              newPC.onicecandidate = (event) => {
                if (event && event.candidate) {
                  // mobile
                  if (
                    event.candidate.candidate &&
                    event.candidate.candidate.candidate
                  )
                    console.info(
                      `[MultiPartyConfCall] created candidate !!! `,
                      JSON.stringify(event.candidate.candidate.candidate)
                    );
                  if (
                    event.candidate.candidate &&
                    !event.candidate.candidate.candidate
                  )
                    console.info(
                      `[MultiPartyConfCall] created candidate !!! `,
                      JSON.stringify(event.candidate.candidate)
                    );

                  const from = auth?.email;
                  const to = id;
                  console.log(
                    `[MultiPartyConfCall]  sending candidate ${from} to ${to}`
                  );

                  send(
                    {
                      type: "candidate",
                      candidate: event.candidate,
                      from,
                      to,
                    },
                    conn
                  );
                }
              };

              newPC.onconnectionstatechange = (event) => {
                console.log(
                  `ice event ${JSON.stringify(event)} ${newPC.connectionState}`
                );
              };

              peerConnections.current.set(id, newPC);

              console.log(`PC created for ${id}`);
              console.log(`PC # ${peerConnections.current.size}`);

              await new Promise(function (resolve) {
                setTimeout(resolve, 1000);
              });

              // making sure only one user is sending offer
              if (auth?.email < id) {
                console.log(`Sending offer to ${id}`);
                sendOffer(id);
              } else {
                console.log(`Other party ${id} should send offer`);
              }
            }
          }

          // cleanup disconnected peers
          for (const [id, pc] of peerConnections.current.entries()) {
            console.info(`pc  ${JSON.stringify(id)}`);

            if (!connectedUsers.has(id)) {
              console.log(`Removing PC ${id} as he is no longer connected`);
              peerConnections.current.delete(id);
              remoteStreams.current.delete(id);
              outgoingDataChannels.current.delete(id);
              incomingDataChannels.current.delete(id);
              remoteCamerasDisabled.delete(id);

              console.log(`Navigating once again to meeting room.`);

              if (auth?.declaredRefugee)
                navigation.navigate("MultiPartyConfCallUA", {
                  token: token,
                });
              else
                navigation.navigate("MultiPartyConfCall", {
                  token: token,
                });
              // setRemoteStreamsForRender(remoteStreams.current);

              console.log(
                `remoteStreams ref #size ${remoteStreams.current.size} `
              );
            }
          }
        }

        console.log(`set mic and camera on`);
        setMicOn(true);
        setCameraOn(true);
      } else {
        console.log(`no local stream`);
      }
    }

    preparePeerConnections();
  }, [connectedUsers, remoteStreamsForRender, remoteStreams]);

  useEffect(() => {
    if (!room) {
      console.log("No room provided. Skip registering for multi peer call.");
      return;
    }
    if (conn.current) {
      console.log(
        "Signaling already in place.  Skip registering for multi peer call."
      );

      return;
    }

    const signalingUrl = `${SOCKET_URL}${auth?.authToken}&room=${room}`;
    console.log(`Connecting to signaling server: ${signalingUrl}`);
    conn.current = new WebSocket(signalingUrl);
    // conn.current.onopen = () => console.log("ws opened");
    //@ts-ignore
    conn.current.onopen = onOpen;

    //@ts-ignore
    conn.current.onmessage = function (msg) {
      onMessage(msg);
    };

    //@ts-ignore
    conn.current.onclose = (closeEvent) => {
      // this can happen during logout so

      console.log("ws closed: ", closeEvent);

      if (mounted) {
        console.log("component mounted, current auth", auth);
        console.log(
          "component mounted, current navigation",
          navigation.getState()
        );

        if (
          auth &&
          closeEvent &&
          (closeEvent.code === 3001 || closeEvent.code === 3004)
        ) {
          try {
            let emptyAuth;
            setAuth(emptyAuth);
            // setDialogErrorVisible(true);
          } catch (error) {
            console.warn("Cannot remove auth.");
          }
        } else {
          setDialogErrorMessage("Rozłączono z serwerem.");
          try {
            // setAuth();
            // setDialogErrorVisible(true);
          } catch (error) {
            console.warn("Cannot remove auth.");
          }
        }
      }
    };

    //@ts-ignore
    conn.current.onerror = function (err) {
      console.log("Got error", err);
    };

    const wsCurrent = conn.current;

    return () => {
      //@ts-ignore
      wsCurrent.close();
    };
  }, [room]);

  const handleChatMessage = (data) => {
    const m = data;
    m.receivedAt = new Date();
    setIncomingChatMessage(m);
    if (chatOn === false) setNewChatNotification(true);
  };

  const hideDialogRecording = () => {
    setDialogRecordingVisible(false);
    setRecordingAccepted(true);
  };

  const hideDialogInfo = () => {
    setDialogInfoVisible(false);
  };

  const hideDialogBeforeReload = () => {
    if (Platform.OS === "web") window.location.reload();
    else {
      RNRestart.Restart();
    }
  };

  const hideDialogError = () => {
    console.log(`__close hideDialogError `, dialogErrorMessage);
    setDialogErrorVisible(false);

    if (dialogErrorMessage.startsWith("Błąd uwierzytelnienia.")) {
      console.log("get back to login screen");
      setAuth(undefined);
      navigation.navigate("SignIn");
    } else {
      if (Platform.OS === "web") window.location.reload();
    }
  };

  const displayRecordingInfo = () => {
    console.log("displayRecordingInfo");
    setDialogRecordingVisible(true);
  };

  const onMessage = (msg) => {
    const data = JSON.parse(msg.data);
    // console.log("Data --------------------->", data);
    switch (data.type) {
      case "users": // ck signaling
        console.log(`received list of users in MultiPeer Call`);
        const receivedUsers: Map<string, ConnectedUserDTO> = new Map<
          string,
          ConnectedUserDTO
        >();

        data.users.map((value) => {
          console.debug(`value: ${value}`);
          console.debug(`stringified: ${JSON.stringify(value)}`);
          const [id, v] = [...value];
          console.debug(`id: ${id}`);
          console.debug(`v: ${JSON.stringify(v)}`);
          receivedUsers.set(id, v);
        });

        if (receivedUsers.size > 0) {
          console.info("we have other users, set in confcall ");
          setStatus(CONFCALL_STATUS.IN_CONFCALL);
        }

        console.log(
          `checking if we need to remove PC of user who is no longer available`
        );

        console.info(
          `[MultiPartyConfCall] pcs#  : ${peerConnections.current.size}`
        );
        const existingIds = Array.from(peerConnections.current.keys());
        console.log(
          `[MultiPartyConfCall] currently we have pc of: ${existingIds}`
        );
        const newIds = Array.from(receivedUsers.keys());
        console.log(`[MultiPartyConfCall] connected ids: ${newIds}`);

        existingIds.forEach((id) => {
          if (!newIds.includes(id)) {
            console.log(`removing pc ${id}`);
            peerConnections.current.get(id).close();
            peerConnections.current.delete(id);
            // peerConnections.current.set(id, undefined);
            remoteStreams.current.delete(id);
            setRemoteStreamsForRender(remoteStreams.current);
            //xman todo stop refactor recoreders, it must exist in pair with PC now there is only one remote
          }
        });

        // if (peerConnections.current) {
        //   console.log("Cleaning peer connections");
        //   peerConnections.current = undefined;
        // }
        setConnectedUsers(receivedUsers);

        break;
      case "exitConfCall":
        console.log(`exitConfCall from ${data.name}`);
        break;
      case "chat": // ck signaling
        console.log("chat");
        handleChatMessage(data);
        break;
      case "offer": // webrtc signaling, just proxy (fully automatic, no customer interaction here)
        console.log("Received offer ", data.name);
        // console.debug("Received offer ", data.offer);
        handleOffer(data.offer, data.name);
        break;
      case "answer": // webrtc signaling just proxy (fully automatic, no customer interaction here)
        console.log("Answer");
        handleAnswer(data.answer, data.name);
        break;
      case "candidate": // webrtc signaling just proxy (fully automatic, no customer interaction here)
        console.log("Received candidate");
        handleCandidate(data.candidate, data.name);
        break;
      default:
        break;
    }
  };

  const send = (message, conn) => {
    console.debug("Sending message type", JSON.stringify(message.type));

    if (message.type === "chat")
      console.log(`chat content ${JSON.stringify(message)}`);

    if (conn && conn.current) conn.current.send(JSON.stringify(message));
    else console.warn("No socket connection.");
  };

  const sendOffer = (receiverId: string) => {
    console.log(`preparing offer for ${receiverId}`);
    // create an offer

    const receiver = peerConnections.current.get(receiverId);

    if (receiver) {
      receiver.createOffer().then((offer) => {
        peerConnections.current
          .get(receiverId)
          .setLocalDescription(offer)
          .then(() => {
            console.log(`offer is ready for ${receiverId}`);
            // console.log(offer);
            send(
              {
                type: "offer",
                offer,
                name: auth?.email, // webrtc
                from: auth?.email, // ck
                to: receiverId, // ck
              },
              conn
            );
          });
      });
    } else {
      console.log("receiver is not available");
    }
  };

  function handleRecorder(recordedParty: string, streamToRecord: MediaStream) {
    console.log(`[Media Recorder] handleRecorder ${recordedParty}`);

    if (!recordedParty)
      console.warn("Media Recorder] Don't know which recording it is ");

    if (recordedParty && auth?.role === "translator") {
      const recorderOptions = {
        mimeType: "video/webm",
        videoBitsPerSecond: 400000, // 0.2 Mbit/sec.
      };

      console.log(`in call steram to record ${typeof streamToRecord}`);
      if (!streamToRecord) {
        console.log(`[Media Recorder] stream is not ready yet`);
        return;
      } else {
        console.log(`[Media Recorder] creating stream`);
      }

      //@ts-ignore
      const mc = new MediaRecorder(streamToRecord);

      if (_.isEmpty(mc)) console.warn("mc is null");

      //@ts-ignore
      mc.start(5000); // 1000 - the number of milliseconds to record into each Blob

      //@ts-ignore
      mc.ondataavailable = async (event) => {
        console.debug("[Media Recorder] Got blob data:", event.data);
        if (event.data && event.data.size > 0) {
          console.debug(`event.type ${event.type} ${recordedParty}`);

          let reader = new FileReader();
          reader.onloadend = () => {
            send(
              {
                type: "recording",
                recordedParty: recordedParty,
                payload: reader.result,
              },
              conn
            );
          };
          reader.readAsDataURL(event.data);
        } else {
          console.log(`no data?  size: ${event.data.size}`);
        }
      };

      mediaRecorders.current.set(recordedParty, mc);
    }
  }

  const handleOffer = async (offer, name) => {
    console.log("handling offer from ", name);
    console.log("PC # ", peerConnections.current.size);

    const pc = peerConnections.current.get(name);

    console.log(`PC for party sending offer ${pc}`);

    if (pc) {
      console.log(`pc.setRemoteDescription (offer) received from ${name}`);
      pc.setRemoteDescription(offer)
        .then(function () {
          console.log(`prepare answer`);
          connectedUser.current = name;
          return peerConnections.current.get(name).createAnswer();
        })
        .then(function (answer) {
          peerConnections.current.get(name).setLocalDescription(answer);
          console.log(`sending answer to ${name}`);
          send(
            {
              type: "answer",
              name: auth?.email,
              answer,
              from: auth?.email,
              to: name,
            },
            conn
          );
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log(`PC not yet established...`);
    }
  };

  const handleAnswer = (answer, name) => {
    console.log(`Handle WebRTC answer from ${name}`);
    peerConnections.current
      .get(name)
      .setRemoteDescription(new RTCSessionDescription(answer));
  };

  const exitConfCall = () => {
    console.log(`leaving confcall`);

    send(
      {
        name: auth?.email,
        fromRole: auth?.role,
        to: room,
        type: "exitConfCall",
      },
      conn
    );

    if (Platform.OS === "web") window.location.reload();
    else {
      RNRestart.Restart();
    }
  };

  const handleCandidate = (candidate, name) => {
    const checkCandidate = new RTCIceCandidate(candidate);

    // console.log("Candidate from ----------------->", name, candidate);

    try {
      if (peerConnections.current) {
        const pc = peerConnections.current.get(name);
        if (pc) {
          peerConnections.current.get(name).addIceCandidate(
            checkCandidate,
            function () {
              console.log("Added Ice Candidate");
            },
            function (err) {
              if (
                err.name === "InvalidStateError" &&
                err.message === "The remote description was null"
              )
                console.info(
                  "Known issue while adding candidates: ",
                  err.name,
                  err.message
                );
              else
                console.warn("error adding candidate: ", err.name, err.message);
            }
          );
        } else {
          console.warn(`PC of ${name} is not added yet`);
        }
      } else {
        console.warn(`peerConnections.current undefined`);
      }
    } catch (error) {
      console.error(`error during adding iceCandidate`);
      console.error(JSON.stringify(error));
    }
  };

  console.debug(`pcs before render status: ${status}`);
  console.debug(
    `pcs before render pcs#  : ${
      peerConnections.current ? peerConnections.current.size : undefined
    }`
  );
  console.info(
    `remoteStreamsForRender before render ${remoteStreamsForRender.size}`
  );

  for (const [id, value] of remoteCamerasDisabled.entries()) {
    console.log(`datachannel remoteCamerasDisabled for ${id}: ${value}`);
  }

  if (props.route.name === "MultiPartyConfCallUA" && !room) {
    console.log("[MultiPartyConfCall] render waitForUAConfirm");
    return <UAServiceNotActiveView />;
  } else if (auth?.role === "translator" && !token && confCallStatus !== true) {
    console.log("[MultiPartyConfCall] render waitForRoomEmail");
    return waitForRoomEmail();
  } else if (auth?.role === "user" && status !== CONFCALL_STATUS.IN_CONFCALL) {
    console.log("[MultiPartyConfCall] render waitingForParticipants");
    return waitingForParticipants();
  } else {
    console.log("[MultiPartyConfCall] render confcallInProgress");
    console.log("[Remote stream] render confcallInProgress");

    if (Platform.OS === "web") return confcallInProgressWeb();
    else return confcallInProgressMobile();
  }

  function waitForUAConfirm() {
    console.log(`[MultiPartyConfCall] rendering waitForUAConfirm`);
    // alert("");
    return (
      <View
        testID="registration_container" // used i.e. in appium
        style={
          Platform.OS === "web"
            ? styles.outerColumnContainer
            : styles.outerColumnContainerMobile
        }
      >
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <CustomDialog
          visible={dialogUAVisible}
          hideDialog={hideDialogUA}
          dialogTitle={"Informacja"}
          render={UADialogText}
          testID="ua_dialog"
        />
        {/* fields */}
        <View
          testID="fields"
          style={[
            {
              paddingTop: 40,
              paddingLeft: 10,
              paddingRight: 10,
              width: Platform.OS == "web" ? "60vw" : "100%",
            },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        ></View>
        <View
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        ></View>
        <View
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        ></View>
      </View>
    );
  }

  function confcallInProgressWeb() {
    console.log(`datachannel confcallInProgress ${Platform.OS}`);
    return (
      <LocalStreamContext.Consumer>
        {() => (
          <SafeAreaView style={styles.body}>
            <View testID="ConfCallScreen" style={{ display: "none" }}></View>
            {showSpinner && <Loading loading={showSpinner} />}
            {/* recording dialog */}
            <CustomDialog
              visible={dialogRecordingVisible}
              hideDialog={hideDialogRecording}
              dialogTitle={"Info"}
              dialogContent={GLOBAL_LABELS.recordingInfo}
              testID="confirm_recording_button"
            />

            {/* errors */}
            <CustomDialog
              visible={dialogErrorVisible}
              hideDialog={hideDialogError}
              dialogTitle={"Błąd"}
              dialogContent={dialogErrorMessage}
              testID="error_dialog"
            />

            {dialogUAVisible && <UAServiceNotActiveView />}
            <CustomDialog
              visible={dialogInfofVisible}
              hideDialog={hideDialogInfo}
              dialogTitle={"Info"}
              dialogContent={dialogInfoMessage}
              testID="info_dialog"
            />

            <CustomDialog
              visible={dialogBeforeReload}
              hideDialog={hideDialogBeforeReload}
              dialogTitle={"Info"}
              dialogContent={dialogBeforeReloadMessage}
              testID="info_dialog_and_reload"
            />

            {/* contains conf call, chat and buttons */}
            <View
              nativeID="conf_call_view"
              style={{
                flex: 1,
                flexDirection: "column",
                borderRadius: 20,
                borderStyle: "solid",
                // backgroundColor: "yellow",
                padding: 5,
              }}
            >
              <View
                nativeID="conf_call_content_container"
                //@ts-ignore
                style={styles.confCallContentContainer}
              >
                <View
                  nativeID="conf_call_videos_container"
                  //@ts-ignore
                  style={
                    Platform.OS === "web" && smallScreen === false
                      ? styles.confCallVideosContainer
                      : styles.confCallVideosContainerMobile
                  }
                >
                  <MyRTCView
                    videoId={"self"}
                    style={
                      peerConnections.current &&
                      peerConnections.current.size < 2
                        ? styles.video2Self
                        : styles.video34
                    }
                    stream={cameraOn && localStream ? localStream : undefined}
                    muted={true}
                  />

                  {/* {listOfRemoteVideos} - web */}
                  {status === CONFCALL_STATUS.IN_CONFCALL &&
                    remoteStreams.current.size >= 1 && (
                      <RemoteVideosWeb
                        // remoteStreams={remoteStreamsForRender}
                        remoteStreams={remoteStreamsForRender}
                        remoteCamerasDisabled={remoteCamerasDisabled}
                        // peerConnections={peerConnections}
                      />
                    )}

                  {status !== CONFCALL_STATUS.IN_CONFCALL &&
                  auth?.isUser &&
                  connectedUsers.size > 0 ? (
                    <></>
                  ) : null}
                </View>

                <Chat
                  visible={chatOn}
                  room={room}
                  send={(msg) => {
                    send(msg, conn);
                  }}
                  incomingChatMessage={incomingChatMessage}
                />
              </View>

              {/* buttons */}
              <View
                nativeID="conf_call_buttons_view"
                //@ts-ignore
                style={styles.stickyFooter}
              >
                <CCButtonsSection
                  debug={debug}
                  displayRecordingInfo={displayRecordingInfo}
                  cameraOn={cameraOn}
                  toggleCamera={toggleCamera}
                  micOn={micOn}
                  toggleMic={toggleMuteLocalStream}
                  chatOn={true}
                  toggleChat={toggleChat}
                  formsOn={formsOn}
                  toggleForms={toggleForms}
                  status={status}
                  incomingChatMessage={newChatNotification}
                  incomingCall={incomingCall}
                  connectedUsers={connectedUsers}
                  volumeOn={volumeOn}
                  toggleVolumeOn={toggleVolumeOn}
                  exitConfCall={() => {
                    exitConfCall();
                  }}
                />
              </View>
            </View>
          </SafeAreaView>
        )}
      </LocalStreamContext.Consumer>
    );
  }

  function confcallInProgressMobile() {
    console.log(`datachannel confcallInProgress ${Platform.OS}`);
    return (
      <LocalStreamContext.Consumer>
        {() => (
          <KeyboardAvoidingView
            style={{
              flex: 1,
            }}
            behavior={Platform.OS === "android" ? "height" : "padding"}
            enabled
            // xman no idea why these values are here, set by trial and error
            // keyboardVerticalOffset={Platform.OS === "android" ? -180 : 80}
          >
            <SafeAreaView style={styles.body}>
              <View testID="ConfCallScreen" style={{ display: "none" }}></View>
              {showSpinner && <Loading loading={showSpinner} />}
              {/* recording dialog */}
              <CustomDialog
                visible={dialogRecordingVisible}
                hideDialog={hideDialogRecording}
                dialogTitle={"Info"}
                dialogContent={GLOBAL_LABELS.recordingInfo}
                testID="confirm_recording_button"
              />

              {/* errors */}
              <CustomDialog
                visible={dialogErrorVisible}
                hideDialog={hideDialogError}
                dialogTitle={"Błąd"}
                dialogContent={dialogErrorMessage}
                testID="error_dialog"
              />

              {dialogUAVisible && <UAServiceNotActiveView />}
              <CustomDialog
                visible={dialogInfofVisible}
                hideDialog={hideDialogInfo}
                dialogTitle={"Info"}
                dialogContent={dialogInfoMessage}
                testID="info_dialog"
              />

              <CustomDialog
                visible={dialogBeforeReload}
                hideDialog={hideDialogBeforeReload}
                dialogTitle={"Info"}
                dialogContent={dialogBeforeReloadMessage}
                testID="info_dialog_and_reload"
              />

              {/* contains conf call, chat and buttons */}
              <View
                nativeID="conf_call_view"
                style={{
                  flex: 1,
                  flexDirection: "column",
                  borderStyle: "solid",
                  // backgroundColor: "yellow",
                }}
              >
                <View
                  nativeID="conf_call_content_container"
                  //@ts-ignore
                  style={styles.confCallContentContainer}
                >
                  <View
                    nativeID="conf_call_videos_container"
                    //@ts-ignore
                    style={styles.confCallVideosContainerMobile}
                  >
                    <View
                      style={{
                        // height: "100%",
                        // width: "100%",
                        flex: 1,
                        flexDirection: "column",
                        // backgroundColor: "red",
                        // zIndex: 10,
                        // top: -120,
                        /*                      top:
                        Platform.OS === "web"
                          ? SELF_VIDEO_SIZE_WEB_HEIGHT * -1
                          : SELF_VIDEO_SIZE_MOBILE_HEIGHT, */
                        justifyContent: "space-between",
                        // position: "absolute",
                        zIndex: 0,
                        elevation: 0,
                      }}
                    >
                      <RemoteVideosMobile
                        remoteStreams={remoteStreamsForRender}
                        remoteCamerasDisabled={remoteCamerasDisabled}
                      />

                      <MyRTCView
                        videoId={"self"}
                        style={{
                          padding: 5,
                          width:
                            Platform.OS === "web"
                              ? SELF_VIDEO_SIZE_WEB_WIDTH
                              : SELF_VIDEO_SIZE_MOBILE_WIDTH,
                          height:
                            Platform.OS === "web"
                              ? SELF_VIDEO_SIZE_WEB_HEIGHT
                              : SELF_VIDEO_SIZE_MOBILE_HEIGHT,
                          zIndex: 2,
                          elevation: 2,
                          /* left:
                        Platform.OS === "web"
                          ? (-1 * SELF_VIDEO_SIZE_WEB_WIDTH) / 6
                          : (-1 * SELF_VIDEO_SIZE_MOBILE_WIDTH) / 6, */
                          left: 0,
                          top: 0, // xman no problem with app bar overlap on web
                          // marginTop: -20,
                          position: "absolute",
                          // backgroundColor: debug ? "yellow" : "undefined",
                          // backgroundColor: "yellow",
                          borderWeight: 1,
                        }}
                        stream={
                          cameraOn && localStream ? localStream : undefined
                        }
                        muted={true}
                      />
                    </View>
                  </View>

                  <Chat
                    visible={chatOn}
                    room={room}
                    send={(msg) => {
                      send(msg, conn);
                    }}
                    incomingChatMessage={incomingChatMessage}
                  />
                </View>

                {/* buttons */}
                <View
                  nativeID="conf_call_buttons_view"
                  //@ts-ignore
                  style={styles.stickyFooter}
                >
                  <CCButtonsSection
                    debug={debug}
                    displayRecordingInfo={displayRecordingInfo}
                    cameraOn={cameraOn}
                    toggleCamera={toggleCamera}
                    micOn={micOn}
                    toggleMic={toggleMuteLocalStream}
                    chatOn={true}
                    toggleChat={toggleChat}
                    formsOn={formsOn}
                    toggleForms={toggleForms}
                    status={status}
                    incomingChatMessage={newChatNotification}
                    incomingCall={incomingCall}
                    connectedUsers={connectedUsers}
                    volumeOn={volumeOn}
                    toggleVolumeOn={toggleVolumeOn}
                    exitConfCall={() => {
                      exitConfCall();
                    }}
                  />
                </View>
              </View>
            </SafeAreaView>
          </KeyboardAvoidingView>
        )}
      </LocalStreamContext.Consumer>
    );
  }

  function waitForRoomEmail() {
    return (
      <View
        testID="registration_container" // used i.e. in appium
        style={
          Platform.OS === "web"
            ? styles.outerColumnContainer
            : styles.outerColumnContainerMobile
        }
      >
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        {/* fields */}
        <CustomDialog
          visible={dialogUAVisible}
          hideDialog={hideDialogUA}
          dialogTitle={"Informacja"}
          render={() => UADialogText()}
          testID="ua_dialog"
        />
        <View
          testID="fields"
          style={[
            {
              paddingTop: 40,
              paddingLeft: 10,
              paddingRight: 10,
              width: Platform.OS == "web" ? "60vw" : "100%",
            },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <ValidatedTextInput
            name="manualToken"
            control={control}
            accessibilityLabel="Wpisz email użytkownika"
            rules={{
              required: "To pole jest wymagane",
            }}
            placeholder={"Wpisz email użytkownika"}
            errors={errors}
            leftIconName="calendar-outline"
          />
        </View>
        <View
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <Button
            testID="button_sign_in"
            onPress={handleSubmit(validateWithManualToken)}
            mode="contained"
            color={Colors.blue500}
            labelStyle={{ color: "white", fontWeight: "bold" }}
            style={{ padding: 5 }}
          >
            {"Dołącz"}
          </Button>
        </View>
        <View
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        ></View>
      </View>
    );
  }
};

export default MultiPartyConfCall;
