import React from "react";

export type ConnectionQualityContextType = {
  connectionQuality?: string;
  updateConnectionQuality: (state: string) => void;
};

// Context has been created
const ConnectionQualityContext =
  React.createContext<ConnectionQualityContextType>(
    {} as ConnectionQualityContextType
  );

// Provider
const ConnectionQualityProvider = ({ children }) => {
  const [connectionQuality, setConnectionQuality] = React.useState("");
  // console.warn(`in call provider ${connectionQuality}`);
  const updateConnectionQuality = (state: string) => {
    setConnectionQuality(state);
  };
  return (
    <ConnectionQualityContext.Provider
      value={{ connectionQuality, updateConnectionQuality }}
    >
      {children}
    </ConnectionQualityContext.Provider>
  );
};
export { ConnectionQualityContext, ConnectionQualityProvider };
