import React from "react";

import { View, Image } from "react-native";

export default function MenuLogo(props) {
  return (
    <Image
      source={require("../assets/adaptive-icon.png")}
      style={{
        width: 48,
        height: 48,
      }}
    />
  );
}
