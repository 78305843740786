import React from "react";
import { Platform } from "react-native";
import {
  SELF_VIDEO_SIZE_MOBILE_HEIGHT,
  SELF_VIDEO_SIZE_WEB_HEIGHT,
} from "../../styles/base";
import Constants from "expo-constants";
import { StyleSheet } from "react-native";

import MyRTCView, { MediaStream } from "../MyRTCView";

type UserInCallRemoteVideoViewProps = {
  remoteStream: MediaStream;
  volumeOn: boolean;
  remoteCameraDisabled: boolean;
};

export default function UserInCallRemoteVideoView({
  remoteStream,
  volumeOn,
  remoteCameraDisabled,
}: UserInCallRemoteVideoViewProps) {
  console.log(`remoteCameraDisabled ${remoteCameraDisabled} `);
  return (
    <MyRTCView
      showNoVideoIcon={true}
      noVideoIconStyle={{
        paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
        display: "flex",
        justifyContent: "center",
        maxHeight: Platform.OS === "web" ? "100vh" : "90%", // xman leave space for appbar and statusbar
        zIndex: -100,
        position: "relative",
        height: "auto",
      }}
      mirror={false}
      zOrder={-1}
      videoId={"other"}
      style={{
        paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
        display: "flex",
        justifyContent: "center",
        maxHeight: Platform.OS === "web" ? "100vh" : "90%", // xman leave space for appbar and statusbar
        zIndex: -100,
        width: "30vw",
        height: "98%",
        position: "relative",
      }}
      stream={remoteStream}
      muted={!volumeOn}
      videoDisabled={remoteCameraDisabled}
    />
  );
}
