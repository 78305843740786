//https://jquense.github.io/react-big-calendar/examples/index.html?path=/story/addons-drag-and-drop--example-2

import React, { Fragment, useMemo, useState, useCallback } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import config from "../../oneconfig";
import PropTypes from "prop-types";
import * as dates from "date-arithmetic";
import moment from "moment";
import MyToolbar from "./MyToolbar";
import {
  CalendarEventContext,
  CalendarEventContextType,
} from "../../calendar-event-context";
import axios from "axios";
import { handleError } from "../../utils";
import CustomDialog from "../CustomDialog";
import _, { now } from "lodash";
import { convertEvent } from "../../services/calendar-event";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import { ResourceStats } from "./ResourceStats";
import {
  eventRenderProps,
  MyEventContainerWrapper,
  MyEvent,
  EventAgenda,
  EventWeek,
} from "./MyEvent";
import { MyWeek } from "./MyWeek";

const DragAndDropCalendar = withDragAndDrop(Calendar);
// const DragAndDropCalendar = Calendar;

const lang = {
  pl: {
    week: "Tydzień",
    day: "Dzień",
    previous: "Poprzedni",
    next: "Następny",
    today: "Dzisiaj",
    month: "Miesiąc",
    work_week: "Tydzień roboczy",
    showMore: (total) => `+${total}`,
  },
};

interface MyCalendarProps {
  selectedDate: Date;
  selectedTranslators?: {
    name: string;
    selected: boolean;
  };
  calendarEvents?: [];
}

export default function MyCalendar({
  calendarEvents,
  ...props
}: MyCalendarProps) {
  const {
    selectedEvent,
    selectEvent,
    events,
    updateEvents,
    selectedDate,
    selectDate,
  } = React.useContext(CalendarEventContext) as CalendarEventContextType;
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");

  // const [selectedDate, setSelectedDate] = React.useState();
  const [editEvent, setEditEvent] = useState(undefined);
  const onNavigate = useCallback(
    (newDate) => {
      console.log("on navigate", newDate);
      selectDate(newDate);
      // setDate(newDate), [setDate]
    },
    [selectedDate]
  );

  const [resourcesMap, setResourcesMap] = useState([]);
  console.log(props);
  const localizer = momentLocalizer(moment);
  const [culture, setCulture] = useState("pl");

  const customDayPropGetter = (date) => {
    if (date.getDate() === 7 || date.getDate() === 15)
      return {
        style: {
          border: "solid 3px " + (date.getDate() === 7 ? "#faa" : "#afa"),
        },
      };
    else return {};
  };

  function MyWeek({
    date,
    localizer,
    max = localizer.endOf(new Date(), "day"),
    min = localizer.startOf(new Date(), "day"),
    scrollToTime = localizer.startOf(new Date(), "day"),
    ...props
  }) {
    const currRange = useMemo(
      () => MyWeek.range(date, { localizer }),
      [date, localizer]
    );

    return (
      <TimeGrid
        date={date}
        eventOffset={15}
        localizer={localizer}
        max={max}
        min={min}
        range={currRange}
        scrollToTime={scrollToTime}
        {...props}
      />
    );
  }

  const nowMoment = moment();
  const day = nowMoment.day();
  console.log(`day ${day}`);

  const defaultDate =
    day == 0 ? nowMoment.add(1, "d") : day == 6 ? nowMoment.add(2) : nowMoment;

  if (selectedDate) {
    console.log(`day defaultDate ${defaultDate.toLocaleString()}`);
  } else {
    selectDate(defaultDate);
  }
  console.log(`day defaultDate ${defaultDate.toLocaleString()}`);

  const MyDayColumnWrapper = ({ children, className, style, innerRef }) => {
    console.log(`MyDayColumnWrapper`);
    return (
      <div style={{ flex: 1 }}>
        <div
          className={className}
          // style={{ backgroundColor: "green" }}
          // style={[style, { backgroundColor: "green" }]}
          ref={innerRef}
        >
          {children}
        </div>
        <ResourceStats events={events} children={children} />
      </div>
    );
  };

  const { scrollToTime, components, formats, views } = useMemo(
    () => ({
      scrollToTime: moment().add(-1, "hour"),
      views: Object.keys(Views).map((k) => Views[k]),
      components: {
        agenda: {
          event: EventAgenda,
        },
        week: {
          event: EventWeek,
        },
        event: MyEvent,
        eventContainerWrapper: MyEventContainerWrapper,
        // dayColumnWrapper: MyDayColumnWrapper,
        // timeSlotWrapper: ColoredDateCellWrapper,
      },
      formats: {
        dayHeaderFormat: (date, culture, localizer) => {
          console.log(
            `format date ${localizer.format(date, "dddd MMMM Do", culture)}`
          );
          return localizer.format(date, "dddd MMMM Do", culture);
        },
      },
    }),
    []
  );

  const { messages } = useMemo(
    () => ({
      messages: lang["pl"],
    }),
    [culture]
  );

  React.useEffect(() => {
    // console.log('useEffect, for resourcesMap:', props.selectedTranslators);

    let filteredResources = Object.entries(props.selectedTranslators).filter(
      (e) => (e[1] ? { resourceId: e[0], resourceTitle: e[0] } : null)
    );

    // console.log('useEffect, after filter resourcesMap:', filteredResources);

    let update = Object.entries(filteredResources).map((e) =>
      // alert((e[1][0]))
      ({ resourceId: e[1][0], resourceTitle: e[1][0] })
    );

    // console.log('resourcesMap:', update);
    setResourcesMap(update);
  }, [props.selectedTranslators]);

  const moveEvent = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false,
    }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      const update = { ...event, start, end, resourceId };
      axios({
        url: `${config.api_url}/calendar-event/${event.id}`,
        method: "patch",
        data: update,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const updatedEvent = response.data;
        console.log(`updated event ${JSON.stringify(updatedEvent)}`);
        const updatedEvents = events?.filter((item) => item.id !== event.id);
        const convertedEvent = convertEvent(updatedEvent);
        if (updatedEvents) {
          updatedEvents.push(convertedEvent);
          updateEvents(updatedEvents);
        } else {
          updateEvents[convertedEvent];
        }
        console.log(`# events after  ${updatedEvents?.length}`);

        selectEvent(convertedEvent);
      });
    },
    [events]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      const update = { ...event, start, end };
      axios({
        url: `${config.api_url}/calendar-event/${event.id}`,
        method: "patch",
        data: update,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const updatedEvent = response.data;
        console.log(`updated event ${JSON.stringify(updatedEvent)}`);
        const updatedEvents = events?.filter((item) => item.id !== event.id);
        const convertedEvent = convertEvent(updatedEvent);
        if (updatedEvents) {
          updatedEvents.push(convertedEvent);
          updateEvents(updatedEvents);
        } else {
          updateEvents[convertedEvent];
        }
        console.log(`# events after  ${updatedEvents?.length}`);

        selectEvent(convertedEvent);
      });
    },
    [events]
  );

  const onSelectEvent = useCallback(
    (event) => {
      console.log(`event selected ${JSON.stringify(event)}`);
      // setEditEvent(event);
      selectEvent(event);

      /*  const r = window.confirm("Czy chesz usunąć wpis w kalendarzu?");
      if (r === true) {
        console.log("onSelectEvent:", event);
        setMyEvents((prev) => {
          let events = myEvents;
          console.log("onSelectEvent before:", events);
          const idx = events.indexOf(event);
          events.splice(idx, 1);
          console.log("onSelectEvent: after", events);
          return events;
        });
      } */
    },
    [events]
  );

  const handleSelectSlot = useCallback(
    (event) => {
      // const title = window.prompt('New Event name')
      // console.log('handleSelectSlot', JSON.stringify(event.start));
      // console.log('handleSelectSlot', JSON.stringify(event.end));
      // console.log('handleSelectSlot', JSON.stringify(event.resourceId));

      const calendarEvent = {
        id: Math.random(),
        title: " ",
        start: event.start,
        end: event.end,
        resourceId: event.resourceId,
      };

      console.log(`creating entry`);
      axios({
        url: config.api_url + "/calendar-event",
        method: "post",
        data: calendarEvent,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((savedItem) => {
          console.log("Created item", JSON.stringify(savedItem));
          const newEntry = convertEvent(savedItem.data);
          let updatedEvents = [...events, newEntry];
          console.log("Events now: ", updatedEvents.length);
          updateEvents(updatedEvents);
        })
        .catch((error) =>
          handleError(
            setshowSpinner,
            error,
            setDialogErrorMessage,
            setDialogErrorVisible
          )
        );
    },
    [events]
  );

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("SignIn");
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  // console.log(`selected translators in callendar `,props.selectedTranslators);
  // console.log(`selected translators in callendar ${resourcesMap.length} `,JSON.stringify(resourcesMap));

  // console.log(`calendarEvents: ${JSON.stringify(calendarEvents)}`);
  console.log(`myEvents # ${events?.length}`);

  const resourceInfo = (resource) => {
    //  console.log(`resource ${JSON.stringify(resource)}`);
    return `${resource.resourceId}`;
  };

  // const EventCellWrapper: EventWrapper = ({event}) =>
  //   React.cloneElement(Children.only(children), {
  //       style: {
  //           ...children.style,
  //           backgroundColor: 'lightgreen' : 'lightblue',
  //       },
  //   });

  return (
    <>
      <CustomDialog
        visible={dialogVisible}
        hideDialog={hideDialog}
        dialogTitle={""}
        dialogContent={dialogMessage}
      />
      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />

      {/*  <Calendar
        defaultDate={defaultDate}
        defaultView={Views.WEEK}
        events={events}
        localizer={localizer}
        components={components}
        timeslots={2}
        step={60}
      /> */}

      <DragAndDropCalendar
        culture={"pl"}
        // defaultDate={moment()}
        defaultDate={selectedDate ? selectedDate : defaultDate}
        eventPropGetter={eventRenderProps}
        // eventCellWrapper={EventCellWrapper}
        // dayPropGetter={customDayPropGetter}  // dni niepracujące
        defaultView={Views.DAY}
        // defaultView={Views.WORK_WEEK}
        timeslots={2}
        step={60}
        min={new Date(1972, 0, 1, 8, 0, 0)}
        max={new Date(1972, 0, 1, 20, 0, 1)}
        messages={messages}
        events={events}
        localizer={localizer}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        onSelectEvent={onSelectEvent}
        onSelectSlot={handleSelectSlot}
        resizable
        resourceIdAccessor="resourceId"
        resources={resourcesMap}
        resourceTitleAccessor={(resource) => resourceInfo(resource)}
        scrollToTime={scrollToTime}
        selectable
        components={components}
        formats={formats}
        views={views}
        onNavigate={onNavigate}
      />
    </>
  );
}
