import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";

type CheckboxComponentProps = {
  name: string;
  label: string;
  disabled?: Boolean;
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
};

export const CheckboxComponent = ({
  name,
  label,
  resource,
  errors,
  setResource,
  ...props
}: CheckboxComponentProps) => {
  // console.debug(`Checkbox: ${name} ${label}`);
  const myVar = name;

  return (
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel
          id={name}
          style={{ color: errors && errors[name] ? "red" : undefined }}
          control={
            <Checkbox
              size={"small"}
              id={`${name}_checkbox`}
              disabled={props.disabled ? props.disabled === true : false}
              // style={{ padding: 5, fontSize: 8 }}
              checked={resource && resource[name] === true ? true : false}
              onChange={(event) => {
                console.log(`${name} ${event.target.checked}`);
                setResource({
                  ...resource,
                  [myVar]: event.target.checked,
                });
              }}
            />
          }
          label={label}
        />
      </FormGroup>
    </Grid>
  );
};
