import React, { useEffect } from "react";
import { DebugContext, DebugContextType } from "../debug-context";
import { GLOBAL_LABELS } from "../labels";
import { isEmpty } from "lodash";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleSheet, View, Image, Platform, Linking } from "react-native";
import oneconfig from "../oneconfig";
import { CommonActions, useNavigation } from "@react-navigation/native";

import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import { AuthContext } from "../auth-context";
import { InCallContext, InCallContextType } from "../disable-menu-context";

export const MyDrawerMenu = (...props) => {
  const { auth } = React.useContext(AuthContext);
  const navigation = useNavigation();

  const { debug, toggleDebugFunction } = React.useContext(
    DebugContext
  ) as DebugContextType;

  const toggleDebugAndExit = () => {
    toggleDebugFunction();
    navigation.dispatch(CommonActions.goBack());
  };

  const nativeBuild =
    Platform.OS === "android"
      ? oneconfig.androidVersionCode
      : Platform.OS === "ios"
      ? oneconfig.iosBuildNumber
      : "web";

  const build = `${oneconfig.version}.${nativeBuild} ${oneconfig.ck_env}`;
  const version = oneconfig.version;

  return (
    <DrawerContentScrollView {...props}>
      <View
        // source={require("../assets/header-gradient.png")
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: 20,
          marginBottom: 20,
          backgroundColor: "rgb(255,255,255)",
          borderBottomWidth: 0,
        }}
      >
        <Image
          source={require("../assets/icon.png")}
          style={{
            width: 70,
            height: 70,
            borderRadius: 0,
            // borderWidth: 2,
          }}
        />
      </View>

      {isEmpty(auth) && Platform.OS === "web" && (
        <View testID="menu_signin">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="login-variant"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.signIn}
            onPress={() => navigation.navigate("SignIn")}
          />
        </View>
      )}

      {/* End of service - registration is disabled */}
      {/*  {(isEmpty(auth) || !auth) && (
        <View testID="menu_signup">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="account-box-outline"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.signUp}
            onPress={() => navigation.navigate("SignUp")}
          />
        </View>
      )} */}

      {Platform.OS === "web" && !isEmpty(auth) && auth.isTranslatorOrAdmin && (
        <View testID="menu_users">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="account-group"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.users}
            onPress={() => navigation.navigate("Users")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && auth.isAdmin && (
        <View testID="menu_connected_users">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="table-network"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.connectedUsers}
            onPress={() => navigation.navigate("ConnectedUsers")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && auth.isAdmin && (
        <View testID="menu_scheduled_confcalls">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="calendar-outline"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.confCallSchedule}
            onPress={() => navigation.navigate("ScheduledConfCalls")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && !auth?.isUser && (
        <View testID="menu_participation_forms">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="form-select"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.participationForms}
            onPress={() => navigation.navigate("ParticipationForms")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && !auth?.isUser && (
        <View testID="menu_support_forms">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="form-select"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.supportForms}
            onPress={() => navigation.navigate("SupportForms")}
          />
        </View>
      )}
      {!isEmpty(auth) && auth.declaredRefugee !== true && (
        <View testID="menu_translations">
          <DrawerItem
            activeTintColor="red"
            icon={() => (
              <MaterialCommunityIcons
                name="file-document-outline"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.translations}
            onPress={() => navigation.navigate("Translations")}
          />
        </View>
      )}

      {!isEmpty(auth) &&
        auth.isUserOrTranslator &&
        auth.declaredRefugee !== true && (
          <View testID="menu_ad_hoc_call">
            <DrawerItem
              icon={() => (
                <MaterialCommunityIcons
                  name="video-outline"
                  size={24}
                  color="gray"
                />
              )}
              label="Połączenie do 30 minut"
              onPress={() => navigation.navigate("P2PCall")}
            />
          </View>
        )}

      {auth?.declaredRefugee !== true && auth?.isUser && (
        <View testID="menu_multiparty_confcall">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="calendar-account"
                size={24}
                color="gray"
              />
            )}
            label="Połączenie do 4 godzin"
            onPress={() => navigation.navigate("MultiPartyConfCall")}
          />
        </View>
      )}
      {auth && auth.isTranslator && (
        <View testID="menu_multiparty_confcall">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="calendar-account"
                size={24}
                color="gray"
              />
            )}
            label="Połączenie do 4 godzin"
            onPress={() => navigation.navigate("MultiPartyConfCall")}
          />
        </View>
      )}

      {!isEmpty(auth) && auth.isUser && auth.declaredRefugee && (
        <View testID="menu_multiparty_ua">
          <DrawerItem
            icon={() => (
              <Image
                source={require("../assets/Flag_of_Ukraine.svg.png")}
                style={{
                  width: 24,
                  height: 16,
                  borderRadius: 0,
                }}
              />
            )}
            label="Ми з Вами"
            onPress={() => navigation.navigate("MultiPartyConfCallUA")}
          />
        </View>
      )}

      {auth && auth.isUserOrTranscriber && auth.declaredRefugee !== true && (
        <View testID="menu_transcription">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="account-edit-outline"
                size={24}
                color="gray"
              />
            )}
            label="Transkrypcja"
            onPress={() =>
              navigation.navigate("P2PTranscription", {
                mode: "transcription",
              })
            }
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && !auth.isUser && (
        <View testID="menu_calendar">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="calendar-month-outline"
                size={24}
                color="gray"
              />
            )}
            label={"Kalendarz (Google)"}
            onPress={() => navigation.navigate("Calendar")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && !auth.isUser && (
        <View testID="menu_calendar">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="calendar-month-outline"
                size={24}
                color="gray"
              />
            )}
            label={"Kalendarz"}
            onPress={() => navigation.navigate("TranslatorsCalendar")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && auth.isUser === false && (
        <View testID="menu_confcall_stats">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="view-list-outline"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.confCallStats}
            onPress={() => navigation.navigate("P2PStats")}
          />
        </View>
      )}

      {Platform.OS === "web" && !isEmpty(auth) && auth.isAdmin && (
        <View testID="menu_recordings">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="view-list-outline"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.multiPartyRecordings}
            onPress={() => navigation.navigate("MultiPartyRecordings")}
          />
        </View>
      )}
      {!isEmpty(auth) && (
        <View testID="menu_my_profile">
          <DrawerItem
            icon={() => (
              <MaterialCommunityIcons
                name="account-settings-outline"
                size={24}
                color="gray"
              />
            )}
            label={GLOBAL_LABELS.myProfile}
            onPress={() => navigation.navigate("Profile", { userId: auth?.id })}
          />
        </View>
      )}

      <View
        style={{
          marginVertical: 8,
          borderBottomColor: "#737373",
          borderBottomWidth: StyleSheet.hairlineWidth,
        }}
      />

      <View testID="menu_regulamin">
        <DrawerItem
          icon={() => (
            <MaterialCommunityIcons
              name="information-outline"
              size={24}
              color="gray"
            />
          )}
          label={"Regulamin"}
          onPress={() => navigation.navigate("Policy", { id: "regulamin" })}
        />
      </View>

      <View testID="menu_privacy">
        <DrawerItem
          icon={() => (
            <MaterialCommunityIcons
              name="information-outline"
              size={24}
              color="gray"
            />
          )}
          label={"Polityka prywatności"}
          onPress={() =>
            navigation.navigate("Policy", { id: "polityka_prywatnosci" })
          }
        />
      </View>

      <View testID="menu_info">
        <DrawerItem
          icon={() => (
            <MaterialCommunityIcons
              name="information-outline"
              size={24}
              color="gray"
            />
          )}
          label={GLOBAL_LABELS.info}
          onPress={() => navigation.navigate("Info")}
        />
      </View>
      <View
        style={{
          marginVertical: 8,
          borderBottomColor: "#737373",
          borderBottomWidth: StyleSheet.hairlineWidth,
        }}
      />

      <View testID="menu_contact">
        <DrawerItem
          icon={() => (
            <MaterialCommunityIcons name="email" size={24} color="gray" />
          )}
          label={"Kontakt"}
          onPress={() => navigation.navigate("Contact")}
        />
      </View>

      <View
        style={{
          marginVertical: 8,
          borderBottomColor: "#737373",
          borderBottomWidth: StyleSheet.hairlineWidth,
        }}
      />

      {oneconfig.debug_ui_enabled && (
        <DrawerItem
          label={`Debug UI ${debug == false ? "(OFF)" : "(ON)"}`}
          onPress={() => toggleDebugAndExit()}
        />
      )}

      {!isEmpty(auth) && (
        <DrawerItem
          icon={() => (
            <MaterialCommunityIcons
              name="account-off-outline"
              size={24}
              color="gray"
            />
          )}
          label={"Zamknij konto"}
          onPress={() => navigation.navigate("CloseAccount")}
        />
      )}

      {version && <DrawerItem label={`v.${build}`} onPress={() => 0} />}
    </DrawerContentScrollView>
  );
};
