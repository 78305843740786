import React, { useEffect, useRef, useState } from "react";
import { Linking, Platform, Text } from "react-native";
import { View, Image, StyleSheet } from "react-native";
import { ResizeMode, Video } from "expo-av";
import { SafeAreaView } from "react-native";
import { ScrollView } from "react-native";
import { DebugContext } from "../../debug-context";
import { useContext } from "react";
import { getPolicyVideoLink } from "../Policy";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

const Content = () => {
  const [uri, setUri] = useState();
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  useEffect(() => {
    releaseUserMedia();
  }, []);

  useEffect(() => {
    async function getEndOfService() {
      console.log(`fetch contact video link`);
      const response = await getPolicyVideoLink("end_of_service");

      console.log(`${response.data}`);
      if (response && response.data) setUri(response.data.url);
    }

    getEndOfService();
  }, [uri]);

  return (
    <View
      testID="contact_container"
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      <View style={styles.middle}>
        {Platform.OS === "web" && (
          <video
            style={styles.video}
            autoPlay={true}
            controls={true}
            src={uri}
          ></video>
        )}

        {uri && Platform.OS !== "web" && (
          <Video
            style={styles.videoMobile}
            source={{
              uri: uri,
            }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            isLooping
            shouldPlay
          />
        )}
      </View>
      <View style={styles.top}>
        <Text
          style={{
            display: "flex",
            flexGrow: 1,
            fontSize: Platform.OS === "web" ? 24 : 14,
            // paddingTop: 10,
            padding: 10,
            textAlign: "left",
            textAlignVertical: "center",
            // backgroundColor: "yellow",
          }}
        >
          Szanowni Państwo,
        </Text>
        <Text
          style={{
            display: "flex",
            flexGrow: 1,
            fontSize: Platform.OS === "web" ? 24 : 14,
            lineHeight: Platform.OS === "web" ? 30 : 20,
            // paddingTop: 10,
            padding: 10,
            textAlign: "left",
            textAlignVertical: "center",
            // backgroundColor: "yellow",
          }}
        >
          Informujemy, że z dniem 31.07.2023 roku pilotaż instrumentu, "Centrum
          Komunikacji - wsparcie dla osób z niepełnosprawnościami posługujących
          się językiem migowym w podjęciu oraz utrzymaniu aktywności zawodowej",
          który był testowany w ramach projektu "Włączenie wyłączonych - aktywne
          instrumenty wsparcia osób niepełnosprawnych na rynku pracy", zostaje
          zakończony. W związku z tym od dnia 1.08.2023 roku wideinfolinia CKJM
          nie będzie już dostępna dla Użytkowników.
        </Text>
        <Text
          style={{
            display: "flex",
            flexGrow: 1,
            fontSize: Platform.OS === "web" ? 24 : 14,
            lineHeight: Platform.OS === "web" ? 30 : 20,
            // paddingTop: 10,
            padding: 10,
            textAlign: "left",
            textAlignVertical: "center",
            // backgroundColor: "yellow",
          }}
        >
          Dziękujemy za udział w testowaniu.
        </Text>
        <Text
          style={{
            fontSize: Platform.OS === "web" ? 24 : 14,
            lineHeight: Platform.OS === "web" ? 30 : 20,
            textAlign: "left",
            padding: 10,
            textAlignVertical: "center",
          }}
          onPress={() => {
            console.log("Pressed");
            if (Platform.OS === "web") {
              Linking.openURL("mailto:kontakt@centrumkomunikacji.org");
            }
          }}
        >
          kontakt@centrumkomunikacji.org
        </Text>
      </View>
    </View>
  );
};

const Contact = () => {
  let debug = useContext(DebugContext).debug;
  return (
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <Content />
      </ScrollView>
    </SafeAreaView>
  );
};

export default Contact;

export const styles = StyleSheet.create({
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    alignSelf: "center", // center itself horizontally(always in outer container)
    maxWidth: "80%", // healthy maxWidth for big screen but will be 95 % for small screen
  },
  outerColumnContainerMobile: {
    display: "flex",
    height: "100%",
    overflow: "scroll",
  },
  justifiedText: {
    textAlign: "justify",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  centeredText: {
    textAlign: "center",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  top: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    paddingTop: Platform.OS === "web" ? 50 : 10,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  middle: {
    // width: Platform.OS === "web" ? "80%" : "100%",
    paddingTop: Platform.OS === "web" ? 50 : 20,
    paddingBottom: Platform.OS === "web" ? 50 : 20,
    // backgroundColor: "blue",
    // height: "100%",
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  bottom: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    // paddingTop: Platform.OS === "web" ? 50 : 20,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
  logo: {
    width: Platform.OS === "web" ? 120 : 60,
    height: Platform.OS === "web" ? 70 : 35,
    margin: 10,
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    // justifyContent: "center",
    padding: 10,
    width: "100%",
    height: 300,
    display: "flex",
    // borderStyle: "solid",
    // borderColor: "black",
    // position: "relative",
    // backgroundColor: "orange",
  },
});
