import React from "react";

export type DebugContextType = {
    debug: boolean;
    toggleDebugFunction: () => void;
};

// Context has been created
const DebugContext = React.createContext<DebugContextType>(
    {} as DebugContextType
);

// Provider
const DebugProvider = ({ children }) => {
    const [debug, setDebug] = React.useState(false);
    const toggleDebugFunction = () => {
        setDebug(!debug);
    };
    return (
        <DebugContext.Provider value={{ debug, toggleDebugFunction }}>
            {children}
        </DebugContext.Provider>
    );
};
export { DebugContext, DebugProvider };