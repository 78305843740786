import React, { useContext } from "react";
import { View, Text } from "react-native";
import { DebugContext } from "../../debug-context";
import { AuthContext, AuthContextType } from "../../auth-context";
import {
  SupportFormInCallType,
  SupportNoteDocument,
} from "../../frontend_domain";

interface SupportFormInCallProps {
  supportForm: SupportFormInCallType;
}

export default function SupportFormInCall({
  supportForm,
}: SupportFormInCallProps) {
  const debugContext = useContext(DebugContext);
  const { auth } = useContext(AuthContext) as AuthContextType;
  const debug = debugContext.debug;

  return (
    <View
      style={{
        flexDirection: "column",
        padding: 5,
        justifyContent: "space-between",
        height: "auto",
        borderColor: "black",
        borderStyle: "solid",
        borderRadius: 13,
        backgroundColor: "lightgrey",
        marginBottom: 5,
      }}
    >
      {/* timestamp */}
      <View
        style={{
          // flex: 1,
          // flexDirection: "row",
          // justifyContent: "flex-end",
          backgroundColor: debug ? "green" : "undefined",
        }}
      >
        <Text
          style={{ fontSize: 10, textAlign: supportForm ? "left" : "right" }}
        >
          {supportForm.supportDate
            ? supportForm.supportDate.toLocaleString("pl-PL")
            : ""}
        </Text>
      </View>

      {/* translator */}
      <View
        style={{
          flexDirection: "row",
          padding: 0,
          backgroundColor: debug ? "blue" : "undefined",
          justifyContent: "flex-start",
        }}
      >
        {supportForm && supportForm.translatorId && (
          <Text
            style={{ fontWeight: "bold" }}
            // style={styles.avatarLetters}
          >
            {supportForm.translatorId.firstName +
              " " +
              supportForm.translatorId.lastName}
          </Text>
        )}
      </View>

      <View
        style={{
          flexDirection: "row",
          padding: 0,
          backgroundColor: debug ? "blue" : "undefined",
          justifyContent: "flex-start",
        }}
      >
        <Text
          style={{ fontStyle: "italic", paddingTop: 5 }}

          // style={styles.avatarLetters}
        >
          {supportForm.formOfSupportCode}
        </Text>
      </View>

      {/* text */}
      <View
        style={{
          // backgroundColor: 'yellow',
          height: "auto",
          padding: 3,
        }}
      >
        <Text
          testID={`support_form_description_${supportForm._id}`}
          style={{
            textAlign: supportForm ? "left" : "right",
          }}
        >
          {supportForm.supportDescription}
        </Text>

        {supportForm.statusDescription && (
          <Text
            testID={`support_form_statusDescription_${supportForm._id}`}
            style={{
              textAlign: supportForm ? "left" : "right",
              paddingTop: 5,
            }}
          >
            {supportForm.statusDescription}
          </Text>
        )}

        {supportForm.notes &&
          supportForm.notes.map(function (note: SupportNoteDocument) {
            console.log(`Support note: ${JSON.stringify(note)}`);
            return (
              <>
                <Text
                  key={note.createdAt}
                  style={{ paddingTop: 5, paddingLeft: 20 }}
                >
                  {new Date(note.createdAt).toLocaleString("pl-PL")}{" "}
                  {note.translatorId
                    ? note.translatorId.firstName +
                      " " +
                      note.translatorId.lastName
                    : "?"}
                </Text>
                <Text style={{ paddingTop: 5, paddingLeft: 30 }}>
                  {note.note}
                </Text>
              </>
            );
          })}
      </View>
    </View>
  );
}
