import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { createContext, useEffect, useState } from "react";
import React from "react";
import _ from "lodash";

export type Auth = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  initials: string;
  isAdmin: boolean;
  isTranslator: boolean;
  isTranscriber: boolean;
  isUser: boolean;
  isUserOrTranslator: boolean;
  isTranslatorOrAdmin: boolean;
  isUserOrTranscriber: boolean;
  isEditor: boolean;
  authToken: string;
  declaredRefugee?: boolean;
  preferredWrittenCommunication: boolean;
};

export type AuthContextType = {
  auth: Auth | undefined;
  setAuth: (auth: Auth) => void;
};

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }) => {
  console.debug(`AuthProvider begin`);
  const [auth, setAuthState] = useState();

  // Update AsyncStorage & context state
  const setAuth = async (auth: Auth) => {
    try {
      if (auth) {
        AsyncStorage.setItem("auth", JSON.stringify(auth));
        // Configure axios headers
        configureAxiosHeaders(auth.authToken);
        setAuthState(auth);
      } else {
        setAuthState(auth);
        await AsyncStorage.removeItem("auth");
      }
      //  await AsyncStorage.removeItem("auth");
      // else
      // if (auth) await AsyncStorage.setItem("auth", JSON.stringify(auth));
      // else await AsyncStorage.removeItem("auth");
    } catch (error) {
      Promise.reject(error);
    }
  };

  useEffect(() => {
    console.log(`[AuthContext] trying to fetch auth from async storage`);

    AsyncStorage.getItem("auth").then((authDataString) => {
      console.info(`[AuthContext] got auth from async storage`);

      if (!_.isEmpty(authDataString) && authDataString !== "undefined") {
        const authData = JSON.parse(authDataString || "");
        console.log(
          `[AuthContext] setting authData ${JSON.stringify(authData)}`
        );
        console.log("[AuthContext] setting authData ******");

        // Configure axios headers
        if (authData) {
          configureAxiosHeaders(authData.authToken);
          setAuthState(authData);
        } else {
          console.log("[AuthContext] no auth data in local storage");
        }
        // }
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

const configureAxiosHeaders = (token: string) => {
  console.debug(
    `[AXIOS] default headers from async storage ${JSON.stringify(
      axios.defaults.headers.common
    )}`
  );
  axios.defaults.headers.common["x-access-token"] = token;
};
