import _ from "lodash";
import React from "react";
import { View, Text } from "react-native";
import { IconButton, List } from "react-native-paper";
import { styles } from "./styles";

interface ChatContentViewProps {
  chatContent: any;
}

export const ChatContentView = ({ chatContent }: ChatContentViewProps) => {
  console.log(`[ChatView] content`, chatContent);
  let list = new Array();
  if (chatContent) {
    chatContent.forEach((value: any) => {
      console.log(`chatContentItem: ${JSON.stringify(value)}`);

      list.push(
        <List.Item
          title={`${value.content}`}
          description={`${value.from}:   ${new Date(
            value.createdAt
          ).toLocaleString("pl-PL")}`}
          key={value._id}
          left={(props) => (
            <View style={styles.avatar}>
              <Text style={styles.avatarLetters}>{value.fromInitials}</Text>
            </View>
          )}
          /* right={(props) => (
            <View style={styles.avatar}>
              <Text style={styles.avatarLetters}>{o.fromInitials}</Text>
            </View>
          )} */
        ></List.Item>
      );

      // console.log(`list ${JSON.stringify(list)}`);
      return list;
    });
  } else {
    console.log(`chat is empty `);
  }

  console.log(`list ${list.length}`);

  if (!_.isEmpty(chatContent)) {
    console.log(`chatContent ${chatContent}`);

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-around",
          // backgroundColor: "blue",
          flexGrow: 1,
          maxHeight: "90%",
          overflow: "auto",
          // height: "auto",
          width: "100%",
        }}
      >
        {list}
      </View>
    );
  } else return <></>;
};
