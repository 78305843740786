import { StyleSheet } from 'react-native';

import MUI_THEME from '../../styles/index.native';

export const styles = StyleSheet.create({
	outerColumnContainer: {
		flex: 1,						    // flex with default direction 'row'
		marginTop: 10,					    // marginTop healthy margin
		flexDirection: 'column',            // override of default direction to 'column'
		alignSelf: 'center',			    // center itself horizontally(always in outer container)
		justifyContent: 'space-around',     // space around to fill all height available
		maxWidth: '80%',					// healthy maxWidth for big screen but will be 95 % for small screen
	},
	outerColumnContainerMobile: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	debug: {
		borderStyle: 'solid',
		borderWidth: 1,
		backgroundColor: "grey"
	},
	body: {
		paddingHorizantal: 20,
		flex: 1,
		backgroundColor: 'white',
	},
	inputContainer: {
		width: 400,
		padding: 5
	},
	inputContainerMobile: {
		// width: 300,
		padding: 5
	},
	inputChecboxContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 15,
	},
	acceptanceText: {
		flex: 1,
		paddingLeft: 10
	},

	btnPrimary: {
		backgroundColor: MUI_THEME.palette.primary.main,
		height: 50,
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 40,
		marginHorizontal: 30,
	},
	buttonText: {
		color: MUI_THEME.palette.primary.contrastText,
		fontWeight: 'bold',
		fontSize: 20,
	},

	btnSecondary: {
		flexDirection: 'row',
		height: 50,
		borderWidth: 1,
		borderColor: '#a5a5a5',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 5,
		marginVertical: 50,
		flex: 1,
	},
	btnImage: {
		width: 20,
		height: 20,
		marginLeft: 5,
	},
});
