import React, { useContext, useRef } from "react";
import { View, ScrollView, Platform, KeyboardAvoidingView } from "react-native";
import { IconButton, Portal, TextInput } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import ChatItem from "./ChatItem";
import { ChatMessage } from "./ChatItem";
import _ from "lodash";
import { DebugContext } from "../../debug-context";
import { AuthContext, AuthContextType } from "../../auth-context";
import { useWindowSize } from "../../hooks/window_size";

type ChatProps = {
  incomingChatMessage: ChatMessage | undefined;
  peer: string;
  room: string;
  visible: boolean;
  transcription?: boolean;
  incomingLiveChatMessage?: {};
  sendLiveChatMessage: (msg: string) => void;
  send: (msg: {}) => void;
};

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

export default function Chat({
  send,
  peer,
  room,
  visible,
  incomingChatMessage,
  transcription,
  incomingLiveChatMessage,
  sendLiveChatMessage,
}: ChatProps) {
  const smallScreen = useWindowSize().smallScreen;
  const [text, setText] = React.useState("");
  const debugContext = useContext(DebugContext);
  const { auth } = useContext(AuthContext) as AuthContextType;
  const [increment, increase] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [clearIncomingLiveMessage, setClearIncomingLiveMessage] =
    React.useState(false);

  const scrollViewRef = useRef();
  const inputElementRef = useRef();

  //   console.log(`[Chat] visible: `, visible);

  const receivedMessages = [];

  React.useEffect(() => {
    console.debug(`chat visible ${JSON.stringify(visible)}`);

    if (incomingChatMessage) {
      setClearIncomingLiveMessage(true);
      if (!receivedMessages.includes(incomingChatMessage.id)) {
        console.log("adding message to chat");
        setData([...data, incomingChatMessage]);
        increase(increment + 1);
      }

      receivedMessages.push(incomingChatMessage.id);
    } else {
      setData([]);
    }
  }, [incomingChatMessage]);

  React.useEffect(() => {
    if (incomingLiveChatMessage) {
      setClearIncomingLiveMessage(false);
      console.info(
        `incomingLiveChatMessage ${JSON.stringify(incomingLiveChatMessage)}`
      );
    } else {
    }
  }, [incomingLiveChatMessage]);

  const sendMessage = (text: string) => {
    if (!_.isEmpty(text)) {
      const message = {
        id: Date.now(),
        increment: increment,
        type: "chat",
        chatType: room ? "room" : "peer",
        from: auth?.email,
        fromInitials: auth?.initials,
        to: peer ? peer : room,
        content: text,
      } as ChatMessage;

      send(message);
      message.receivedAt = new Date();
      setData([...data, message]);
      increase(increment + 1);
      setText("");

      console.log(`inputElementRef.current ${inputElementRef.current}`);
      inputElementRef.current.focus();
    }
  };

  // console.log(`[Chat] render smallScreen: ${smallScreen}`);
  const inputTextUpdate = (text: string) => {
    setText(text);
    if (sendLiveChatMessage) sendLiveChatMessage(text);
  };

  let finalLiveContent = incomingLiveChatMessage;
  // if (finalLiveContent)
  //   finalLiveContent.content = incomingLiveChatMessage?.content + " ...";

  const innerChat = (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{
        flex: 1,
        bottom: 92,
        marginTop: 200,
        display: visible ? "flex" : "none",

        backgroundColor: "white",
        opacity: smallScreen
          ? Platform.OS === "web"
            ? "80%"
            : 0.9
          : undefined,
      }}
    >
      <ScrollView
        ref={scrollViewRef}
        onContentSizeChange={(contentWidth, contentHeight) => {
          scrollViewRef.current.scrollToEnd({ animated: false });
        }}
        style={{
          padding: 0,
          top: 0,
        }}
      >
        <View>
          {data.map((message, index) => {
            return (
              <ChatItem
                key={message.id}
                // receivedAt={new Date()}
                message={message}
              />
            );
          })}
        </View>
        {incomingLiveChatMessage && clearIncomingLiveMessage === false && (
          <View>
            <ChatItem
              key={incomingLiveChatMessage?.id}
              // receivedAt={new Date()}
              message={finalLiveContent}
            />
          </View>
        )}
      </ScrollView>

      {/* Input */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "stretch",
          // backgroundColor: "red",
          backgroundColor: "white",
          opacity: smallScreen
            ? Platform.OS === "web"
              ? "90%"
              : 0.9
            : undefined,
          // bottom: 64,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            padding: 10,
            flexGrow: 5,
            paddingBottom: Platform.OS === "android" ? 20 : 0,
          }}
        >
          <TextInput
            mode="flat"
            testID="chat_input"
            label="Pisz tutaj..."
            accessibilityLabel="Pisz tutaj..."
            ref={inputElementRef}
            forwardRef={inputElementRef}
            onFocus={() => console.log(`text input focused`)}
            value={text}
            blurOnSubmit={false}
            onChangeText={(text) => inputTextUpdate(text)}
            onSubmitEditing={() => sendMessage(text)}
          />
        </View>

        <View
          style={{
            flex: 1,
            opacity: smallScreen
              ? Platform.OS === "web"
                ? "90%"
                : 0.9
              : undefined,
            backgroundColor: "white",
            padding: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            icon="send"
            size={24}
            testID={"send_chat_message"}
            onPress={() => sendMessage(text)}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );

  return (
    <SafeAreaView
      testID="chat_window"
      style={[
        {
          display: visible ? "flex" : "none",
          flexGrow: transcription ? 3 : 1,
        },
      ]}
    >
      <ConditionalWrap
        condition={smallScreen}
        wrap={(wrappedChildren) => (
          // <></>
          <Portal id="modal_chat">{wrappedChildren}</Portal>
        )}
      >
        {innerChat}
      </ConditionalWrap>
    </SafeAreaView>
  );
}
