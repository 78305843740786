import React, { useContext, useState } from "react";
import {
  View,
  SafeAreaView,
  ScrollView,
  Platform,
  StyleSheet,
  Linking,
} from "react-native";
import axios from "axios";
import { Headline } from "react-native-paper";
import { PolicyProps } from "../../navigation/types";
import { DebugContext } from "../../debug-context";
import { ResizeMode, Video } from "expo-av";
import { IconButton, Button, Colors, Text } from "react-native-paper";
import { useEffect } from "react";
import oneconfig from "../../oneconfig";
import { handleError } from "../../utils";
import { Loading } from "../../components/Loading";
import CustomDialog from "../../components/CustomDialog";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

const Policy = ({ navigation, ...props }: PolicyProps) => {
  const video = React.useRef(null);
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [status, setStatus] = React.useState({});
  const [policyLink, setPolicyLink] = React.useState(null);
  const [policyTitle, setPolicyTitle] = React.useState(null);
  const id = props.route.params.id;
  const backToRoute = props.route.params.backToRoute;

  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  useEffect(() => {
    releaseUserMedia();
  }, []);

  console.log(` policy ${id}`);
  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const downloadConsentText = async (id: string) => {
    const response = await axios({
      url: `${oneconfig.api_url}/policy/${id}/text`,
      method: "GET",
    });

    console.log(`policy text response ${JSON.stringify(response)}`);
    const policyDocumentLink = response.data.url;

    console.log(`policy link ${JSON.stringify(policyDocumentLink)}`);

    await Linking.canOpenURL(policyDocumentLink);
    Linking.openURL(policyDocumentLink);
  };

  useEffect(() => {
    async function getPolicyLink(id: string) {
      try {
        setshowSpinner(true);
        console.log(`use effect loading policy ${id}`);
        const response = await getPolicyVideoLink(id);

        setshowSpinner(false);

        // console.log(`policy link response ${JSON.stringify(response)}`);
        if (response && response.data) {
          setPolicyLink(response.data.url);
          setPolicyTitle(response.data.title);
        }
      } catch (error) {
        console.log(JSON.stringify(error));
        handleError(
          setshowSpinner,
          error,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
      }
    }

    getPolicyLink(id);
  }, [id, policyLink, policyTitle]);

  // const link = `${host}${policies[id]}`;
  // console.log(`s3 link ${policyLink}`);
  return (
    // <SafeAreaView style={{ flex: 1 }}>
    <View style={styles.container}>
      {showSpinner && <Loading loading={showSpinner} />}

      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          // backgroundColor: "yellow",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
          // height: "50%",
          flexGrow: 0.1,
        }}
      >
        <Headline
          style={{
            display: "flex",
            flexGrow: 1,
            paddingTop: 40,
            textAlign: "center",
            textAlignVertical: "center",
            // backgroundColor: "yellow",
          }}
        >
          {policyTitle}
        </Headline>
        <IconButton
          icon="file-pdf-box"
          style={{
            display: "flex",
            flexGrow: 1,
            // backgroundColor: "Blue"
          }}
          size={64}
          color={Colors.blue500}
          onPress={() => downloadConsentText(id)}
        ></IconButton>
      </View>
      {Platform.OS === "web" && (
        <video
          style={styles.video}
          autoPlay={true}
          controls={true}
          src={policyLink}
        ></video>
      )}

      {Platform.OS !== "web" && (
        <Video
          ref={video}
          style={styles.videoMobile}
          source={{
            uri: policyLink,
          }}
          useNativeControls
          resizeMode={ResizeMode.CONTAIN}
          isLooping
          shouldPlay
        />
      )}
      <View>
        <Button
          icon="keyboard-backspace"
          mode="contained"
          style={{ margin: 5 }}
          onPress={() => {
            if (backToRoute) navigation.navigate(backToRoute);
            else navigation.goBack();
          }}
        >
          Powrót
        </Button>
      </View>
    </View>
    // </SafeAreaView>
  );
};

export default Policy;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "90%",

    // backgroundColor: "red",
    padding: 10,
    margin: 10,
    // background-color: rgba(0,0,0,.35);
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    flexGrow: 1,
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    justifyContent: "center",
    padding: 30,
    width: "100%",
    height: "80%",
    borderStyle: "solid",
    borderColor: "black",
    position: "relative",
    // backgroundColor: "orange",
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const getPolicyVideoLink = async (id: string) => {
  return await axios({
    url: `${oneconfig.api_url}/policy/${id}/video`,
    method: "GET",
  });
};

export const getPolicyVideoLinkUA = async (id: string) => {
  return await axios({
    url: `${oneconfig.api_url}/policy/${id}/video-ua`,
    method: "GET",
  });
};
