import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  WorkStatus as WorkStatusEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface WorkStatus {
  id: string;
  label: string;
}

export const workStatusOptions = Object.keys(WorkStatusEnum).map((key) => ({
  id: key,
  label: WorkStatusEnum[key],
}));

console.debug(`workStatusOptions ${JSON.stringify(workStatusOptions)}`);

interface WorkStatusComponentProps {
  resource: ParticipationFormDocument;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setResource: React.Dispatch<any>;
}

export const WorkStatus = ({
  resource,
  errors,
  setResource,
  onBlur,
}: WorkStatusComponentProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" id="work_status">
          {"17. Jaki jest Pani/Pana status na rynku pracy"}
        </Typography>
        <Autocomplete
          options={workStatusOptions}
          disablePortal
          value={resource.work_status ? resource.work_status : ""}
          isOptionEqualToValue={(option, value) => option.label === value}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              required={true}
              error={errors ? errors["work_status"] : false}
              fullWidth
              onBlur={onBlur}
              label="status na rynku pracy"
            />
          )}
          onChange={(event: any, newValue: WorkStatus) => {
            console.log(`newValue ${JSON.stringify(newValue)}`);
            setResource({
              ...resource,
              work_status: newValue ? newValue.label : "",
            });
          }}
        />
      </Grid>
    </>
  );
};
