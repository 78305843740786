import React from "react";

export const ResourceStats = ({ events, children }) => {
  console.log(`ResourceStats events ${typeof events}`);
  console.log(`ResourceStats children ${typeof children}`);

  window.i = children;

  // console.log(`input ${JSON.stringify(children[0][0].props.resource)}`);
  // Object.keys(children).forEach((k) =>
  //   console.log(`child attribute ${JSON.stringify(children[k])}`)
  // );

  /* Object.keys(children)
  .filter((k, i) => i >= 100 && i < 300)
  .forEach(k => console.log(children[k]));
 */
  // children.forEach((child) => {
  //   // console.log(child);
  // });
  return (
    <div style={{ flex: 1, paddingTop: 10, textAlign: "center" }}>
      {/* <div>{"Podsumowanie"}</div> */}
      <div>{`d: 6`}</div>
      <div>{`t: 30`}</div>
      <div>{`m: 124`}</div>
    </div>
  );
};
