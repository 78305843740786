import React from "react";
import { ScrollView, SafeAreaView } from "react-native";
import { styles } from "./styles";
import { SupportFormProps } from "../../navigation/types";
import SupportFormView from "./SupportFormView";

export default function SupportForm(props: SupportFormProps) {
  // console.log('Translation props? ', props);

  return (
    <SafeAreaView style={[styles.body]}>
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <SupportFormView {...props} />
      </ScrollView>
    </SafeAreaView>
  );
}
