import React, { useEffect } from "react";
import { SafeAreaView, ScrollView, Platform, View, Text } from "react-native";

import { styles } from "./styles";

import { ProfileProps } from "../../navigation/types";
import ProfileView from "./ProfileView";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

export default function Profile(props: ProfileProps) {
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  useEffect(() => {
    releaseUserMedia();
  }, []);

  return (
    <SafeAreaView style={[styles.body]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={[{ backgroundColor: "lightBlue" }]}
      >
        <ProfileView containerStyle={styles.inputContainerMobile} {...props} />
      </ScrollView>
    </SafeAreaView>
  );
}
