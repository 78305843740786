export const GLOBAL_LABELS = {
  signIn: "Zaloguj się",
  signUp: "Rejestracja",
  info: "Informacje o projekcie",
  contact: "Skontaktuj się z nami",
  myProfile: "Mój profil",
  users: "Użytkownicy",
  connectedUsers: "Połączeni użytkownicy",
  supportForms: "Formularze wsparcia",
  supportForm: "Formularz wsparcia",
  participationForms: "Formularze korzystania z usług",
  participationForm: "Formularz korzystania z usług",
  service: "Usługa wsparcia",
  privacyPolicy: "Polityka prywatności",
  termsOfService: "Regulamin",
  calendar: "Kalendarz",
  confCallSchedule: "Połączenia umawiane",
  translations: "Tłumaczenia dokumentów",
  translation: "Tłumaczenie",
  adHocCall: "Połączenie",
  multiPartyConfCall: "Połączenia umawiane",
  confCallStats: "Statystyki połączeń",
  adhocCallStats: "Statystyki połączeń ad hoc",
  signUpToLogIn: "Zarejestruj sie, aby się zalogować",
  alreadySignUpQuestion: "Masz już konto?",
  firstName: "Imię",
  lastName: "Nazwisko",
  password: "Hasło",
  save: "Zapisz",
  sentForTranslation: "Wyślij do tłumaczenia",
  sendForApproval: "Wyślij do zatwierdzenia",
  inProgres: "W realizacji",
  approve: "Zatwierdź",
  email: "Email",
  closeAccount: "Zamknij konto",
  webOnly: "Funkcja dostępna w przeglądarce internetowej",
  multiPartyRecordings: "Nagrania do 4h",
  recordingInfo:
    "Rozmowa jest nagrywana. Jeśli nie chcesz kontynuować zamknij aplikację lub okno przeglądarki.",
  longPlaceholder:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Est velit egestas dui id ornare arcu. Dignissim cras tincidunt lobortis feugiat vivamus at augue. Mattis pellentesque id nibh tortor id aliquet lectus proin. Nulla aliquet enim tortor at auctor urna nunc id cursus. Eget dolor morbi non arcu risus quis varius quam. Sagittis vitae et leo duis. Ipsum a arcu cursus vitae congue mauris. In ante metus dictum at tempor. Tellus pellentesque eu tincidunt tortor. Massa tincidunt dui ut ornare lectus sit amet est. Vestibulum lorem sed risus ultricies tristique nulla. Dui accumsan sit amet nulla facilisi. Auctor elit sed vulputate mi.",
};

export default GLOBAL_LABELS;
