import * as React from "react";
import { Colors, ToggleButton, Text, IconButton } from "react-native-paper";

interface ConnectionQualityProps {
  connectionQuality?: string;
}

export default function ConnectionQualityIcon({
  connectionQuality,
}: ConnectionQualityProps) {
  // console.log(`[ConnectionQualityIcon] _${connectionQuality}_`);
  return (
    <IconButton
      size={24}
      testID="connection_quality"
      color={
        connectionQuality === "good"
          ? "green"
          : connectionQuality === "bad"
          ? "red"
          : connectionQuality === "error"
          ? "red"
          : connectionQuality === "poor"
          ? "orange"
          : "grey"
      }
      style={{ display: "flex" }}
      icon={
        connectionQuality === "good"
          ? "access-point-network"
          : connectionQuality === "bad"
          ? "access-point-network-off"
          : connectionQuality === "error"
          ? "access-point-network-off"
          : connectionQuality === "poor"
          ? "access-point-network"
          : "access-point-network"
      }
    />
  );
}
