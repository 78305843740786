import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import _ from "lodash";
import { CheckboxComponent } from "./CheckboxComponent";

type DisabilityDatesProps = {
  resource: ParticipationFormDocument;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setResource: React.Dispatch<any>;
};

export const DisabilityDatesComponent = ({
  resource,
  errors,
  setResource,
  onBlur,
}: DisabilityDatesProps) => {
  return (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body1">
          24. Proszę podać daty ważności wszystkich posiadanych orzeczeń
        </Typography>
      </Grid>
      <Grid item xs={4} padding={1}>
        <CheckboxComponent
          disabled={false}
          name="disability_county"
          label="Powiatowy Zespół ds. Orzekania o Niepełnosprawności"
          resource={resource}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckboxComponent
          name="disability_county_forever"
          label="Bezterminowy"
          resource={resource}
          disabled={resource.disability_county !== true}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          label="Data ważności (powiat)"
          views={["year", "month", "day"]}
          disabled={
            resource.disability_county !== true ||
            resource.disability_county_forever === true
          }
          value={
            resource && resource.disability_expire_county
              ? resource.disability_expire_county
              : ""
          }
          onChange={(newValue) => {
            setResource({
              ...resource,
              disability_expire_county: newValue,
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                id={"disability_expire_county"}
                margin="normal"
                fullWidth
                error={false}
                {..._.omit(params, ["error"])}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={4} padding={1}>
        <CheckboxComponent
          disabled={false}
          name="disability_zus"
          label="ZUS"
          resource={resource}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckboxComponent
          name="disability_zus_forever"
          label="Bezterminowy"
          resource={resource}
          disabled={resource.disability_zus !== true}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          // disableFuture
          label="Data ważności (ZUS)"
          views={["year", "month", "day"]}
          disabled={
            resource.disability_zus !== true ||
            resource.disability_zus_forever === true
          }
          value={
            resource && resource.disability_expire_zus
              ? resource.disability_expire_zus
              : ""
          }
          onChange={(newValue) => {
            setResource({
              ...resource,
              disability_expire_zus: newValue,
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                id={"disability_expire_zus"}
                margin="normal"
                fullWidth
                error={false}
                {..._.omit(params, ["error"])}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={4} padding={1}>
        <CheckboxComponent
          disabled={false}
          name="disability_krus"
          label="KRUS"
          resource={resource}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckboxComponent
          name="disability_county_krus"
          label="Bezterminowy"
          resource={resource}
          disabled={resource.disability_krus !== true}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          // disableFuture
          label="Data ważności (KRUS)"
          views={["year", "month", "day"]}
          disabled={
            resource.disability_krus !== true ||
            resource.disability_krus_forever === true
          }
          value={
            resource && resource.disability_expire_krus
              ? resource.disability_expire_krus
              : ""
          }
          onChange={(newValue) => {
            setResource({
              ...resource,
              disability_expire_krus: newValue,
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                id={"disability_expire_krus"}
                margin="normal"
                fullWidth
                error={false}
                {..._.omit(params, ["error"])}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={4} padding={1}>
        <CheckboxComponent
          disabled={false}
          name="disability_military"
          label="Orzeczenie wojskowe"
          resource={resource}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckboxComponent
          name="disability_military_forever"
          label="Bezterminowy"
          resource={resource}
          disabled={resource.disability_military !== true}
          setResource={setResource}
        />
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          // disableFuture
          label="Data ważności (wojskowe)"
          views={["year", "month", "day"]}
          disabled={
            resource.disability_military !== true ||
            resource.disability_military_forever === true
          }
          value={
            resource && resource.disability_expire_military
              ? resource.disability_expire_military
              : ""
          }
          onChange={(newValue) => {
            setResource({
              ...resource,
              disability_expire_military: newValue,
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                id={"disability_expire_military"}
                margin="normal"
                fullWidth
                error={false}
                {..._.omit(params, ["error"])}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
