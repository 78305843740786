import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Iframe from "react-iframe";

const theme = createTheme();

import { DebugContext } from "../../debug-context";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Calendar() {
  const debugContext = React.useContext(DebugContext);
  const debug = debugContext.debug;

  return (
    <ThemeProvider theme={theme}>
      <Iframe
        url="https://docs.google.com/spreadsheets/d/13_32sl3wuxeha9S42MJgIlKbkbpoyCS3A9pUQys1pwo/edit?pli=1#gid=251183481"
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        position="relative"
      />
    </ThemeProvider>
  );
}
