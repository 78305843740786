import axios from "axios";
import config from "../oneconfig";

export const getAll = async () => {
  console.log(`[getAll] translations default headers `);
  const response = await axios.get(config.api_url + "/calls");
  return response.data;
};

export const getChatItems = async (callId: string) => {
  console.log(`[getAll] translations default headers `);
  const response = await axios.get(config.api_url + `/chat/${callId}`);
  return response.data;
};
