import { RTCPeerConnection } from "react-native-webrtc-web-shim";

export const createRTCPeerConnection = () => {
  return new RTCPeerConnection({
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "turn:54.93.72.179:80?transport=tcp", // Amazon Frankfurt
        credential: "7IhLYYeq5Ko7YQQHhvKO",
        username: "pzg",
      },
    ],
  });
};
