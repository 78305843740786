import React, { useLayoutEffect } from "react";
import { Text, Paragraph } from "react-native-paper";
import { View } from "react-native";

export const UADialogText = () => (
  <>
    <View>
      <Paragraph style={{ textAlign: "center" }}>
        Час роботи українського перекладача на інфолінії
      </Paragraph>
      <Paragraph style={{ textAlign: "center" }}>
        Вівторок 14-16, Четвер - 8-10
      </Paragraph>
      <Paragraph style={{ textAlign: "center" }}>{}</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>{}</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>{}</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>
        Tłumacz ukraińskiego języka migowego dostępny
      </Paragraph>
      <Paragraph style={{ textAlign: "center" }}>
        we wtorki 14-16 oraz w czwartki 8-10
      </Paragraph>
    </View>
  </>
);
