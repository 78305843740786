import React, { useContext } from "react";
import { StyleSheet, SafeAreaView, ScrollView, Platform } from "react-native";

import { COLORS } from "./styles.native";

import { SignInProps } from "../../navigation/types";
import { DebugContext } from "../../debug-context";
import Login from "../../components/Login";

const SignIn = ({ navigation }: SignInProps) => {
  let debug = useContext(DebugContext).debug;

  return (
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      {Platform.OS !== "web" && (
        <ScrollView
          showsVerticalScrollIndicator={false}
          bounces={false}
          style={[{ backgroundColor: "lightBlue" }]}
        >
          <Login />
        </ScrollView>
      )}

      {Platform.OS === "web" && <Login />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    alignSelf: "center", // center itself horizontally(always in outer container)
    maxWidth: "80%", // healthy maxWidth for big screen but will be 95 % for small screen
  },
  debug: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "grey",
  },
  body: {
    paddingHorizantal: 20,
    flex: 1,
    backgroundColor: COLORS.white,
  },
  inputContainer: {
    padding: 5,
  },
  textView: {
    flexDirection: "row",
    marginTop: 40,
    marginStart: 30,
  },

  title: {
    marginTop: 40,
    marginStart: 30,
  },
  welcome: {
    fontSize: 27,
    fontWeight: "bold",
    color: COLORS.dark,
  },
  orline: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 30,
    marginVertical: 20,
  },
});
export default SignIn;
