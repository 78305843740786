import _ from "lodash";

export type WebRTCInboundOutboundStats = {
  availableBitrateKbs: number; // only Safari for incoming stream
  utilizedBitrateKbs: number;
  width: number;
  height: number;
  framerate: number;
  askedWidth?: number; // only outbound
  askedHeight?: number; // only outbound
  askedFramerate?: number; // only outbound
  numberOfVideoStreams: number;
  limitation?: {};
  audio?: {};
};

export type WebRTCLocalOrRemoteStats = {
  candidateType: string;
  candidateProtocol: string;
  candidateRelayProtocol?: string;
  limitation?: {};
};

export type KeyWebRTCStats = {
  inbound: WebRTCInboundOutboundStats;
  outbound: WebRTCInboundOutboundStats;
  local: WebRTCLocalOrRemoteStats;
  remote: WebRTCLocalOrRemoteStats;
};

export const keyStatsFromReport = (report: {}): KeyWebRTCStats => {
  let keyStats: KeyWebRTCStats = {
    inbound: {},
    outbound: {},
    local: {},
    remote: {},
  };

  const probe = report["probes"][0];
  const audio = probe["audio"];
  const video = probe["video"];
  const data = probe["data"];
  const network = probe["network"];

  let numberOfInboundVideoStreams = 0;
  let numberOfOutboundVideoStreams = 0;

  /* console.log(video);
  console.log(typeof video); */

  for (const key of Object.keys(video)) {
    // console.log(JSON.stringify(video[key]));
    const direction = video[key].direction;

    if (direction === "inbound") numberOfInboundVideoStreams += 1;
    if (direction === "outbound") numberOfOutboundVideoStreams += 1;

    // console.log(`direction : ${direction}`);
    const dir = direction.slice(0, 3) === "out" ? "out" : "in";
    // console.log(`dir : ${dir}`);
    // console.log(`video size_of : ${video[key][`size_${dir}`]}`);
    keyStats[direction].width = video[key][`size_${dir}`].width;
    keyStats[direction].height = video[key][`size_${dir}`].height;
    keyStats[direction].framerate = video[key][`size_${dir}`].framerate;

    if (direction === "outbound") {
      keyStats[direction].limitation = video[key].limitation_out;
      keyStats[direction].askedWidth = video[key][`size_pref_${dir}`].width;
      keyStats[direction].askedHeight = video[key][`size_pref_${dir}`].height;
      keyStats[direction].askedFramerate =
        video[key][`size_pref_${dir}`].framerate;
    }
  }

  keyStats.inbound.availableBitrateKbs = data.delta_kbs_bandwidth_in;
  keyStats.inbound.utilizedBitrateKbs = data.delta_kbs_in;
  keyStats.outbound.availableBitrateKbs = data.delta_kbs_bandwidth_out;
  keyStats.outbound.utilizedBitrateKbs = data.delta_kbs_out;

  keyStats.inbound.numberOfVideoStreams = numberOfInboundVideoStreams;
  keyStats.outbound.numberOfVideoStreams = numberOfOutboundVideoStreams;

  keyStats.local.candidateType = network.local_candidate_type;
  keyStats.local.candidateProtocol = network.local_candidate_protocol;
  keyStats.local.candidateRelayProtocol = _.isEmpty(
    network.local_candidate_relay_protocol
  )
    ? "brak"
    : network.local_candidate_relay_protocol;

  keyStats.remote.candidateType = network.remote_candidate_type;
  keyStats.remote.candidateProtocol = network.remote_candidate_protocol;

  return keyStats;
};
