import React, { useContext } from "react";
import { View, Text, Image, TouchableOpacity, Linking } from "react-native";
import { Avatar, IconButton, TextInput, Button } from "react-native-paper";
import CheckBox from "expo-checkbox";

import { styles } from "./styles";
import { downloadPublic } from "../../services/download";
import { useController } from "react-hook-form";
import { Link, useNavigation } from "@react-navigation/native";
import axios from "axios";
import oneconfig from "../../oneconfig";
import { getPolicyVideoLink, getPolicyVideoLinkUA } from "../../screens/Policy";

interface ValidatedPolicyCheckboxProps {
  // value: boolean,
  toggle: (value: boolean) => void;
  label: string;
  consent: string;
  // download: string;
  // policyTranslation: string;
  errors: any;
  name: string;
  control: any;
  rules: {};
}

export default function ValidatedPolicyCheckbox(
  props: ValidatedPolicyCheckboxProps
) {
  const { field } = useController({
    control: props.control,
    defaultValue: false,
    rules: props.rules,
    name: props.name,
  });
  const navigation = useNavigation();
  // console.log(`checkbox value ${JSON.stringify(field.value)}`);
  // console.log(
  //   `props.policyTranslation ${JSON.stringify(props.policyTranslation)}`
  // );
  // console.log(`props.errors ${JSON.stringify(props.errors)}`);
  // console.log(`props ${JSON.stringify(props.name)}`);
  // console.log(`props ${JSON.stringify(props.errors[props.name])}`);

  const downloadConsentText = async (id: string) => {
    const response = await axios({
      url: `${oneconfig.api_url}/policy/${id}/text`,
      method: "GET",
    });

    console.log(`policy text response ${JSON.stringify(response)}`);
    const policyLink = response.data.url;

    console.log(`policy link ${JSON.stringify(policyLink)}`);

    await Linking.canOpenURL(policyLink);
    Linking.openURL(policyLink);
  };

  return (
    <View style={styles.inputCheckboxContainer}>
      <CheckBox
        value={field.value}
        testID={field.name}
        nativeID={field.name}
        color={field.value ? "green" : "red"}
        onChange={field.onChange}
        onValueChange={field.onChange}
      />

      <Text style={[styles.acceptanceText, {}]}>{props.label}</Text>

      {props.errors[props.name] &&
      props.errors[props.name].type == "required" ? (
        <Text style={styles.validationText}>Oświadczenie jest wymagane.</Text>
      ) : (
        <></>
      )}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          icon="file-document-outline"
          size={20}
          onPress={() => downloadConsentText(props.consent)}
        />

        <TouchableOpacity
          onPress={async () => {
            try {
              const response = await getPolicyVideoLink(props.consent);
              const policyLink = response.data.url;
              console.log(`policyLink ${policyLink}`);

              await Linking.canOpenURL(policyLink);
              Linking.openURL(policyLink).catch((err) =>
                console.error("An error occurred", err)
              );
            } catch (error) {
              alert(`Błąd sieci ${error ? error.message : ""}`);
            }
          }}
        >
          <Image
            resizeMode="cover"
            style={{
              height: 40,
              width: 40,
            }}
            source={require("../../assets/rece-pl3.png")}
          ></Image>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={async () => {
            try {
              const response = await getPolicyVideoLinkUA(props.consent);
              const policyLink = response.data.url;
              console.log(`policyLink ${policyLink}`);

              await Linking.canOpenURL(policyLink);
              Linking.openURL(policyLink).catch((err) =>
                console.error("An error occurred", err)
              );
            } catch (error) {
              alert(`Błąd sieci ${error ? error.message : ""}`);
            }
          }}
        >
          <Image
            resizeMode="cover"
            style={{
              height: 40,
              width: 40,
              // tintColor: "#0018ae",
            }}
            source={require("../../assets/rece-ua.png")}
          ></Image>
        </TouchableOpacity>

        {/* </View> */}
      </View>
    </View>
  );
}
