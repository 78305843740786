import * as React from "react";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { plPL } from "../../localization/plPL";
// import { plPL } from "@mui/x-data-grid";
import config from "../../oneconfig";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import { View } from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import {
  getAll,
  getMultiPartyDownloadRecordingLink,
} from "../../services/recordings.service";
import { Auth, AuthContext } from "../../auth-context";
import _ from "lodash";
import CustomDialog from "../../components/CustomDialog";
import { Loading } from "../../components/Loading";
import { localizedDateComparator } from "../../services/localized.service";
import { handleError } from "../../utils";
import { DownloadFileWithProgress } from "../../components/DownloadFileWithProgress";
import { ProgressDialog } from "../../components/ProgressDialog";
import axios from "axios";
import oneconfig from "../../oneconfig.generic";

const theme = createTheme({}, plPL);

const headerNames = new Map();
headerNames.set("_id", "ID");
headerNames.set("description", "description");

export default function MultiPartyRecordings() {
  const [items, setItems] = React.useState([]);
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [displayProgress, setDisplayProgress] = React.useState(false);
  const [progressFinished, setProgressFinished] = React.useState(false);
  const [downloadProgress, setDownloadProgress] = React.useState(0);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const prefix = config.api_url as string;

  const columns: GridColumns = [
    {
      field: "_id",
      headerName: headerNames.get("_id"),
      //  width: 90
    },
    {
      field: "createdAt",
      headerName: "Data utworzenia nagrania",
      editable: false,
      type: "string",
      width: 200,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "room",
      headerName: "Pokój",
      width: 200,
      editable: false,
    },
    {
      field: "recorded",
      width: 200,
      headerName: "Nagranie",
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Akcje",
      width: 100,
      cellClassName: "actions",
      renderCell: (params: GridRenderCellParams<string>) => {
        // console.log(`download file size ${params.row.size}`);

        return (
          <DownloadFileWithProgress
            size={params.row.size}
            sizeRequest={async () => {
              const r = await axios({
                url:
                  prefix +
                  `/multiPartyRecordingsFileSize/${params.row.fileName}`,
                method: "GET",
                // responseType: "application/json",
              });

              console.log(`result, ${JSON.stringify(r)}`);
              console.log(`result, ${JSON.stringify(r.data)}`);
              console.log(`result, ${JSON.stringify(r.data.files)}`);
              console.log(`result, ${JSON.stringify(r.data.files[0])}`);
              console.log(`result, ${JSON.stringify(r.data.files[0].size)}`);
              console.log(`result, ${JSON.stringify(r.data.files.length)}`);
              if (r && r.data && r.data.files && r.data.files.length > 0) {
                const returnVal = r.data.files[0].size;
                console.log(`returnVal, ${JSON.stringify(returnVal)}`);
                return returnVal;
              }
            }}
            key={params.row.fileName}
            fileName={params.row.fileName}
            downloadLink={getMultiPartyDownloadRecordingLink(
              params.row.fileName
            )}
            progressIndication={setDisplayProgress}
            setDownloadProgress={(value) => {
              console.log(`setDownloadProgress value: ${value}`);
              setDownloadProgress(value);
            }}
          ></DownloadFileWithProgress>
        );
      },
    },
    {
      field: "fileName",
      headerName: "Nazwa pliku ",
      width: 1000,
      editable: true,
    },
  ];

  const navigation = useNavigation();

  React.useEffect(() => {
    releaseUserMedia();

    if (_.isEmpty(auth)) {
      //   console.log("[Users] useEffect (no auth yet), auth:", auth);
      return;
    } else {
      //   console.log("[Users] useEffect (with auth), auth:", auth);
      if (items.length === 0) {
        console.log("fetching data using ", auth);

        getAll()
          .then((freshItems) => {
            console.log(
              `[Recordings] useEffect (with auth), freshItems:`,
              freshItems.length
            );
            setshowSpinner(false);
            setItems(freshItems);
          })
          .catch((error) => {
            console.log(`caught1 ${JSON.stringify(error)}`);
            setshowSpinner(false);
            setDialogErrorVisible(true);
            if (error && error.response && error.response.data)
              setDialogErrorMessage(error.response.data.error);
            else setDialogErrorMessage(JSON.stringify(error));
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, []);

  const handleClick = (id: string) => {
    // console.log('event ', JSON.stringify(event.target));
    navigation.navigate("MultiPartyRecordings", { id: id });
  };

  return (
    <div style={{ flex: 1, height: "100%", width: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        <View
          testID="RecordingsScreen"
          style={{ flex: 1, display: "none" }}
        ></View>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />

        {displayProgress && (
          <ProgressDialog
            visible={true}
            progressActual={downloadProgress}
            progressFinished={progressFinished}
            onFinishAction={() => {}}
            finishedMessage={"OK"}
            onCancelAction={() => {
              console.log(`cancel request`);
              setDisplayProgress(false);
              navigation.navigate("MultiPartyRecordings");
            }}
            onOKAction={() => {}}
          />
        )}
        <DataGrid
          style={{ backgroundColor: "#fff" }}
          rows={items}
          getRowId={(row) => row._id}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
        />
      </ThemeProvider>
    </div>
  );
}
