import axios from "axios";
import config from "../oneconfig";

export const getAll = async () => {
  try {
    const response = await axios.get(config.api_url + "/scheduled-confcall");
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const getAllForUser = async (userId: string) => {
  try {
    console.log(`get all forms for user ${userId}`);
    const response = await axios.get(
      config.api_url + `/support-forms-for-user/${userId}`
    );

    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const getResource = async (id: string) => {
  try {
    const response = await axios.get(
      config.api_url + `/scheduled-confcall/${id}`
    );
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const deleteResource = async (id: string) => {
  try {
    const response = await axios.delete(
      config.api_url + `/scheduled-confcall/${id}/delete`
    );
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const createResource = async (data: any) => {
  const createResourceUrl = `${config.api_url}/scheduled-confcall`;
  console.log(`create support form via url  ${createResourceUrl} `);

  return await axios({
    url: createResourceUrl,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateResource = async (data: any) => {
  const updateUrl = `${config.api_url}/scheduled-confcall/${data._id}`;
  console.log(`update  via  ${updateUrl} `);

  return await axios({
    url: updateUrl,
    method: "patch",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
