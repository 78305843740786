import React, { useContext } from "react";
import { View, Image, TouchableOpacity, Text, Linking } from "react-native";
import { Avatar, IconButton, TextInput, Button } from "react-native-paper";

import { styles } from "./styles";
import { Link, useNavigation } from "@react-navigation/native";
import axios from "axios";
import oneconfig from "../../oneconfig";
import { getPolicyVideoLink, getPolicyVideoLinkUA } from "../../screens/Policy";

interface PolicyInfoProps {
  label: string;
  consent: string;
}

export const downloadConsentText = async (id: string) => {
  const response = await axios({
    url: `${oneconfig.api_url}/policy/${id}/text`,
    method: "GET",
  });

  console.log(`policy text response ${JSON.stringify(response)}`);
  const policyLink = response.data.url;

  console.log(`policy link ${JSON.stringify(policyLink)}`);

  // await Linking.canOpenURL(policyLink);
  Linking.openURL(policyLink);

  // downloadPublic(policyLink);
};

export default function PolicyInfo(props: PolicyInfoProps) {
  const navigation = useNavigation();
  // console.log(`checkbox value ${JSON.stringify(field.value)}`);
  // console.log(
  //   `props.policyTranslation ${JSON.stringify(props.policyTranslation)}`
  // );
  // console.log(`props.errors ${JSON.stringify(props.errors)}`);
  // console.log(`props ${JSON.stringify(props.errors[props.name])}`);

  return (
    <View style={styles.inputCheckboxContainer}>
      <Text style={[styles.acceptanceText, {}]}>{props.label}</Text>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          icon="file-document-outline"
          size={20}
          onPress={() => downloadConsentText(props.consent)}
        />

        <TouchableOpacity
          onPress={async () => {
            const response = await getPolicyVideoLink(props.consent);
            const policyLink = response.data.url;
            console.log(`policyLink ${policyLink}`);
            await Linking.canOpenURL(policyLink);
            Linking.openURL(policyLink).catch((err) =>
              console.error("An error occurred", err)
            );
          }}
        >
          <Image
            resizeMode="cover"
            style={{
              height: 40,
              width: 40,
              // tintColor: "#0018ae"
            }}
            source={require("../../assets/rece-pl3.png")}
          ></Image>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={async () => {
            const response = await getPolicyVideoLinkUA(props.consent);
            const policyLink = response.data.url;
            console.log(`policyLink ${policyLink}`);
            await Linking.canOpenURL(policyLink);
            Linking.openURL(policyLink).catch((err) =>
              console.error("An error occurred", err)
            );
          }}
        >
          <Image
            resizeMode="cover"
            style={{ height: 40, width: 40 }}
            source={require("../../assets/rece-ua.png")}
          ></Image>
        </TouchableOpacity>
      </View>
    </View>
  );
}
