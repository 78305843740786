import _ from "lodash";
import React from "react";
import { View } from "react-native";
import { SingleP2PRecording } from "./SingleP2PRecording";
import { ProgressDialog } from "../../components/ProgressDialog";
import { useNavigation } from "@react-navigation/native";
interface RecordingViewProps {
  recordingContent: any;
}

export const RecordingView = ({ recordingContent }: RecordingViewProps) => {
  const [displayProgress, setDisplayProgress] = React.useState(false);
  const [progressFinished, setProgressFinished] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [progressStage, setProgressStage] = React.useState("");
  const navigation = useNavigation();

  if (_.isEmpty(recordingContent)) return <></>;

  console.log(`[RecordingView] content`, recordingContent);
  const recording = recordingContent.recording;
  const callId = recordingContent.callId;

  console.log(`[RecordingView] content`, recording.files);
  let list = new Array();
  if (recording && recording.files && recording.files.length > 0) {
    recording.files.forEach((value: any, key: number) => {
      console.log(`[RecordingView] item key ${JSON.stringify(key)}`);
      console.log(`[RecordingView] item value: ${JSON.stringify(value)}`);
      const fileParts = value.fileName.split("_");
      const streamOf = fileParts[fileParts.length - 1];
      console.log(`streamOf: ${JSON.stringify(streamOf)}`);

      list.push(
        <SingleP2PRecording
          streamOf={streamOf}
          key={key}
          setDisplayProgress={setDisplayProgress}
          setDownloadProgress={setUploadProgress}
          callId={callId}
          value={value.fileName}
          size={value.size}
        ></SingleP2PRecording>
      );

      return list;
    });
  } else {
    console.log(`chat is empty `);
  }

  console.log(`list size ${list.length}`);

  if (!_.isEmpty(recording)) {
    console.log(`recording ${JSON.stringify(recording)}`);

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-around",
          // backgroundColor: "blue",
          flexGrow: 1,
          // maxHeight: "90%",
          overflow: "auto",
          // height: "auto",
          width: "100%",
        }}
      >
        {displayProgress && (
          <ProgressDialog
            visible={true}
            progressActual={uploadProgress}
            progressStage={progressStage}
            progressFinished={progressFinished}
            onFinishAction={() => {}}
            finishedMessage={"OK"}
            onCancelAction={() => {
              console.log(`cancel request`);
              setDisplayProgress(false);
              navigation.navigate("P2PStats");
            }}
            onOKAction={() => {}}
          />
        )}

        {list}
      </View>
    );
  } else return <></>;
};
