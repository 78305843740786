import React, { useContext } from "react";
import {
  View,
  Text,
  SafeAreaView,
  ScrollView,
  Platform,
  StyleSheet,
} from "react-native";
import axios from "axios";

import { DebugContext } from "../../debug-context";
import { ResizeMode, Video } from "expo-av";

import {
  IconButton,
  Button,
  Headline,
  Paragraph,
  Subheading,
} from "react-native-paper";
// import { styles } from "../../styles";
import { useEffect } from "react";
import oneconfig from "../../oneconfig";
import { useNavigation } from "@react-navigation/native";

const RegistrationSecondStep = () => {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const [videoLink, setVideoLink] = React.useState(null);
  let debug = useContext(DebugContext).debug;

  const navigation = useNavigation();

  useEffect(() => {
    async function getRegistrationVideo() {
      const response = await axios({
        url: `${oneconfig.api_url}/registration-movie`,
        method: "GET",
        // responseType: "application/json",
      });

      // console.log(`policy link response ${JSON.stringify(response)}`);
      if (!videoLink)
        if (response && response.data) setVideoLink(response.data.url);
    }

    getRegistrationVideo();
  }, [videoLink]);

  // const link = `${host}${policies[id]}`;
  // console.log(`s3 link ${policyLink}`);
  return (
    // <SafeAreaView style={{ flex: 1 }}>
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <View
          testID="registration_container" // used i.e. in appium
          style={
            Platform.OS === "web"
              ? styles.outerColumnContainer
              : styles.outerColumnContainerMobile
          }
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "yellow",
              justifyContent: "center",
              alignItems: "center",
              // minHeight: /* 200 */,
              // maxHeight: "40%",
              width: Platform.OS === "web" ? "100%" : "100%",
              paddingLeft: Platform.OS === "web" ? 20 : 5,
              paddingRight: Platform.OS === "web" ? 20 : 5,
              paddingTop: Platform.OS === "web" ? 60 : 10,
              paddingBottom: Platform.OS === "web" ? 60 : 10,
              // height: "50%",
              flexGrow: 0.1,
            }}
          >
            <Headline
              testID="second_step_email_confirmed"
              style={{ color: "green" }}
            >
              Dziękujemy za potwierdzenie Twojego adres adres e-mail.
            </Headline>
            <Paragraph> </Paragraph>
            <Paragraph>
              Teraz Centrum Komunikacji Języka Migowego będzie sprawdzać
              poprawność informacji podanych w trakcie rejestracji.
            </Paragraph>
            <Paragraph>
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                Może to potrwać do 24h roboczych.
              </Text>
            </Paragraph>
            <Paragraph style={{ fontWeight: "bold" }}>
              Po pozytywnej weryfikacji konta użytkownika poinformujemy Cię o
              tym w kolejnym mailu.
            </Paragraph>
            <Paragraph> </Paragraph>
            <Paragraph>
              Podczas pierwszego połączenia uzupełnisz z tłumaczem Formularz
              korzystania z usług Centrum Komunikacji.
            </Paragraph>
            <Paragraph>
              Będzie również sprawdzana Twoja tożsamość dlatego przygotuj
              dokument ze zdjęciem.
            </Paragraph>
            <Paragraph> </Paragraph>
            <Paragraph>
              W przypadku połączenia z komputera/laptopa preferowana
              przeglądarka to Google Chrome.
            </Paragraph>
            <Paragraph>
              Aplikację Centrum Komunikacji możesz pobrać ze sklepu Apple Store
              i Google Play.
            </Paragraph>
          </View>
          {Platform.OS === "web" && (
            <video
              style={styles.video}
              autoPlay={true}
              controls={true}
              src={videoLink}
            ></video>
          )}

          {Platform.OS !== "web" && (
            <Video
              ref={video}
              style={styles.videoMobile}
              source={{
                uri: videoLink,
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              isLooping
              shouldPlay
            />
          )}
          <View>
            <Button
              icon="keyboard-backspace"
              mode="contained"
              style={{ margin: 5 }}
              onPress={() => {
                navigation.navigate("SignIn", { initial: false });
              }}
            >
              Powrót
            </Button>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default RegistrationSecondStep;

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center", // center itself horizontally(always in outer container)
    // justifyContent: 'space-around',     // xman space around to fill all height available, commented out because on large screens it takes to spread
  },
  outerColumnContainerMobile: {
    display: "flex",
    flexGrow: 1,
    minHeight: 400,
    maxHeight: "100%",
    overflow: "scroll",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "red",
    padding: 10,
    margin: 10,
    // background-color: rgba(0,0,0,.35);
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    justifyContent: "center",
    padding: 30,
    width: "100%",
    minHeight: 200,
    borderStyle: "solid",
    borderColor: "black",
    position: "relative",
    // backgroundColor: "orange",
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
