import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { handleError } from "../../utils";
import CustomDialog from "../../components/CustomDialog";
import config from "../../oneconfig";
import MyCalendar from "../../components/MyCalendar";
import TranslatorsSelection from "../../components/MyCalendar/TranslatorsSelection";
import { DebugContext } from "../../debug-context";
import {
  CalendarEventContext,
  CalendarEventContextType,
} from "../../calendar-event-context";
import _ from "lodash";
import { getTranslatorsForSearch } from "../../services/user.service";
import { convertEvent } from "../../services/calendar-event";
import { useNavigation } from "@react-navigation/native";
import SelectedEventSection from "./SelectedEventSection";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const theme = createTheme();

export default function TranslatorsCalendar() {
  const [translators, setTranslators] = React.useState([]);
  const { selectedEvent, selectEvent, events, updateEvents } = React.useContext(
    CalendarEventContext
  ) as CalendarEventContextType;
  const debugContext = React.useContext(DebugContext);

  const debug = debugContext.debug;
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");
  const navigation = useNavigation();

  const updateSelectedTranslators = (translators: {}) => {
    // console.log('update selected translators' + JSON.stringify(translators));
    setTranslators(translators);
  };

  React.useEffect(() => {
    if (translators.length === 0) {
      console.log("fetching data");
      getData();
    } else {
      // setSelectedTranslators(translators);
      console.log("translators and calendar events already fetched");
    }
  }, [translators, events]);

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("SignIn");
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  function reducer(acc: any, cur: any) {
    return { ...acc, [cur.name]: cur.selected };
  }

  const getData = async () => {
    try {
      setshowSpinner(false);
      const translatorsFromDatabase = await getTranslatorsForSearch();
      console.debug(
        `translatorsFromDatabase ${JSON.stringify(translatorsFromDatabase)}`
      );

      let update = translatorsFromDatabase.map(function (itm) {
        // console.log('itm', itm);
        return {
          name: itm.name,
          selected: true,
        };
      });
      const response = await axios.get(config.api_url + "/calendar-event");
      const calendarEvents = response.data;
      console.log(`fetched calendarEvents # ${calendarEvents.length}`);

      setTranslators(update.reduce(reducer, {}));

      const bigReactCalendarEvents = calendarEvents.map(convertEvent);
      /*   console.log(
        `converted calendarEvents ${JSON.stringify(bigReactCalendarEvents)}`
      ); */

      updateEvents(bigReactCalendarEvents);
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  console.log("****Translators****", translators);
  console.log("****selectedEvent****", selectedEvent);
  console.log("****calendarEvents****", events);
  return (
    <ThemeProvider theme={theme}>
      {/* <div style={{ height: '100vh', margin: '10px' }}>
        <MyCalendar />
      </div> */}

      <div id="main" style={{ height: "100%" }}>
        <div
          style={{
            // flex: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor: debug ? "red" : undefined,
            justifyContent: "stretch",
            alignItems: "stretch",
            padding: 5,
            // width: "95vw",
            // overflow: "scroll",
          }}
        >
          <CustomDialog
            visible={dialogVisible}
            hideDialog={hideDialog}
            dialogTitle={""}
            dialogContent={dialogMessage}
          />
          {/* errors */}
          <CustomDialog
            visible={dialogErrorVisible}
            hideDialog={hideDialogError}
            dialogTitle={"Błąd"}
            dialogContent={dialogErrorMessage}
            testID="error_dialog"
          />

          <MyCalendar selectedTranslators={translators}></MyCalendar>
          {/* </div> */}
          <div
            id="bottom_part"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              padding: 5,
              justifyContent: "space-between",
              // backgroundColor: "purple",
            }}
          >
            {/* Szczegóły wydarzenia*/}
            <div
              id="right_bottom_part"
              style={{
                display: "flex",

                padding: 20,
                alignItems: "stretch",
                justifyContent: "stretch",
                flexGrow: 1,
              }}
            >
              {!_.isEmpty(selectedEvent) && (
                <SelectedEventSection selectedEvent={selectedEvent} />
              )}
            </div>

            {/* Wybór tłumaczy*/}
            <div
              id="right_bottom_part"
              style={{
                display: "flex",
                // backgroundColor: "green"
              }}
            >
              <TranslatorsSelection
                events={events ? events : []}
                translators={translators}
                updateTranslators={updateSelectedTranslators}
              />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
