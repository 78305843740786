import {
  Autocomplete,
  Grid,
  TextField,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";

interface ComponentProps {
  fieldName: string;
  label: string;
  resource: ParticipationFormDocument;
  errors?: {};
  setResource: React.Dispatch<any>;
}

export const SensitiveQuestionComponent = ({
  fieldName,
  label,
  resource,
  errors,
  setResource,
}: ComponentProps) => {
  /*  console.debug(
    `SensitiveQuestionComponent: ${fieldName} ${label} ${
      resource[fieldName]
    } errors: ${errors ? errors[fieldName] : undefined}`
  ); */
  return (
    <Grid item xs={12}>
      <FormControl>
        <FormLabel
          // color={"error"}
          // color={errors && errors[fieldName] === true ? "error" : "warning"}
          error={errors ? errors[fieldName] : undefined}
          id={fieldName}
        >
          {label}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={`${fieldName}-radio-buttons-group`}
          value={resource && resource[fieldName] ? resource[fieldName] : ""}
          onChange={(event) => {
            console.log(
              `YesNoComponent newValue for ${fieldName} ${event.target.value}`
            );
            var val = event.target.value;
            var obj = {};
            obj[fieldName] = val;
            setResource({
              ...resource,
              ...obj,
            });
          }}
        >
          <FormControlLabel
            value={"Tak"}
            control={<Radio size="small" />}
            label="Tak"
          />
          <FormControlLabel
            value="Odmawiam podania informacji"
            control={<Radio size="small" />}
            label="Odmawiam podania informacji"
          />
          <FormControlLabel
            value={"Nie"}
            control={<Radio size="small" />}
            label="Nie"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
