import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { NavigationContainer } from "@react-navigation/native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "./styles.css";

import * as React from "react";
import {
  Colors,
  DefaultTheme,
  Portal,
  Provider as PaperProvider,
} from "react-native-paper";
import { DebugProvider } from "./debug-context";
import { DisableMenuProvider } from "./disable-menu-context";
import { ConnectionQualityProvider } from "./connection-quality-context";

import MyDrawer from "./navigation/MyDrawer";
import "@expo/match-media";
import { AuthProvider } from "./auth-context";
import oneconfig from "./oneconfig";
import { MediaStream } from "react-native-webrtc-web-shim";

import { LocalStreamProvider } from "./localstream-context";
import { CalendarEventProvider } from "./calendar-event-context";

interface AppState {
  debug: boolean; //replace any with suitable type
  toggleDebug: () => void;
  localStream: MediaStream;
  getUserMedia: () => void;
  releaseUserMedia: () => void;
}

interface AppProps {
  withWindowSize: () => void;
}

const paperTheme = {
  ...DefaultTheme,
  roundness: 2,
  version: 3,
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.blue500,
  },
};

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const LocalStreamContext = React.createContext({
  stream: MediaStream,
  getUserMedia: () => {},
});

console.log(`App settings oneconfig ${JSON.stringify(oneconfig)}`);
class App extends React.Component<AppProps, AppState> {
  render() {
    return (
      <AuthProvider>
        <PaperProvider theme={paperTheme}>
          <SafeAreaProvider>
            <DisableMenuProvider value={false}>
              <ConnectionQualityProvider value={""}>
                <NavigationContainer>
                  <DebugProvider value={false}>
                    <CalendarEventProvider value={undefined}>
                      <LocalStreamProvider>
                        <Portal.Host>
                          <MyDrawer />
                        </Portal.Host>
                      </LocalStreamProvider>
                    </CalendarEventProvider>
                  </DebugProvider>
                </NavigationContainer>
                <StatusBar style="auto" />
              </ConnectionQualityProvider>
            </DisableMenuProvider>
          </SafeAreaProvider>
        </PaperProvider>
      </AuthProvider>
    );
  }
}

export default App;
