import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import React, { FocusEventHandler } from "react";
import {
  ParticipationFormDocument,
  CompanySize as CompanySizeEnum,
} from "../../frontend_domain";
import LABELS from "./labels";

interface EconomicActivityComponentProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  required?: boolean;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export interface CompanySize {
  id: string;
  label: string;
}
export const companySizeOptions = Object.keys(CompanySizeEnum).map((key) => ({
  id: key,
  label: CompanySizeEnum[key],
}));

export const EconomicActivityComponent = ({
  resource,
  errors,
  onBlur,
  required,
  setResource,
}: EconomicActivityComponentProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" id={"economic_activity_start"}>
          {LABELS._31}
        </Typography>
        <DesktopDatePicker
          label=""
          views={["year", "month", "day"]}
          value={
            resource && resource.economic_activity_start
              ? resource.economic_activity_start
              : ""
          }
          onChange={(newValue) => {
            setResource({
              ...resource,
              economic_activity_start: newValue,
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                margin="normal"
                fullWidth
                required={required ? required : false}
                error={errors ? errors["economic_activity_start"] : false}
                onBlur={onBlur}
                {..._.omit(params, ["error"])}
              />
            );
          }}
        />

        <Typography variant="body1" id="economic_activity_name">
          {LABELS._32}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          required={required ? required : false}
          error={errors ? errors["economic_activity_name"] : false}
          onBlur={onBlur}
          label="Nazwa"
          value={
            resource && resource.economic_activity_name
              ? resource.economic_activity_name
              : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              economic_activity_name: event.target.value,
            });
          }}
        />

        <TextField
          margin="normal"
          id="economic_activity_address"
          fullWidth
          required={required ? required : false}
          error={errors ? errors["economic_activity_address"] : false}
          onBlur={onBlur}
          label="Adres"
          value={
            resource && resource.economic_activity_address
              ? resource.economic_activity_address
              : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              economic_activity_address: event.target.value,
            });
          }}
        />

        <Typography variant="body1" id="economic_activity_size">
          {LABELS._33}
        </Typography>

        <Autocomplete
          options={companySizeOptions}
          disablePortal
          value={
            resource.economic_activity_size
              ? resource.economic_activity_size
              : ""
          }
          isOptionEqualToValue={(option, value) => option.label === value}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              fullWidth
              required={required ? required : false}
              error={errors ? errors["economic_activity_size"] : false}
              onBlur={onBlur}
              label=""
            />
          )}
          onChange={(event: any, newValue: CompanySize) => {
            console.log(`newValue ${JSON.stringify(newValue)}`);
            setResource({
              ...resource,
              economic_activity_size: newValue ? newValue.label : undefined,
            });
          }}
        />
        <Typography variant="body1" id="economic_activity_sector">
          {LABELS._34}
        </Typography>

        <TextField
          margin="normal"
          fullWidth
          required={required ? required : false}
          error={errors ? errors["economic_activity_sector"] : false}
          onBlur={onBlur}
          label=""
          value={
            resource && resource.economic_activity_sector
              ? resource.economic_activity_sector
              : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              economic_activity_sector: event.target.value,
            });
          }}
        />
      </Grid>
    </>
  );
};
