import React from "react";
import { View } from "react-native";
import { StyleSheet } from "react-native";
import { ActivityIndicator, Portal } from "react-native-paper";

export const Loading = (props) => {
  console.debug(`is loading ${JSON.stringify(props)}`);
  return (
    <Portal>
      <View style={styles.container}>
        <ActivityIndicator size="large" style={styles.activityIndicator} />
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
