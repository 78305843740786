import { Moment } from "moment";
import React from "react";

export type CalendarEvent = {
  id: string;
  title: string;
  start: string;
  end: string;
  resourceId: string;
  description: string;
};

export type CalendarEventContextType = {
  selectedEvent?: CalendarEvent;
  events?: CalendarEvent[];
  selectEvent: (e: CalendarEvent) => void;
  updateEvents: (enwEvents: CalendarEvent[]) => void;
  selectedDate?: Moment;
  selectDate: (m: Moment) => void;
};

// Context has been created
const CalendarEventContext = React.createContext<CalendarEventContextType>(
  {} as CalendarEventContextType
);

// Provider
const CalendarEventProvider = ({ children }) => {
  const [selectedEvent, setCalendarEvent] = React.useState(undefined);
  const [selectedDate, setSelectedDate] = React.useState(undefined);
  const [events, setEvents] = React.useState([]);
  const selectEvent = (e?: CalendarEvent) => {
    // console.log(`selectEvent called with ${JSON.stringify(e)}`);
    setCalendarEvent(e);
  };

  const selectDate = (m?: Moment) => {
    // console.log(`selectEvent called with ${JSON.stringify(e)}`);
    setSelectedDate(m);
  };

  const updateEvents = (newEvents?: CalendarEvent[]) => {
    // console.log(`selectEvent called with ${JSON.stringify(newEvents)}`);
    setEvents(newEvents);
  };

  return (
    <CalendarEventContext.Provider
      value={{
        selectedEvent,
        selectEvent,
        events,
        updateEvents,
        selectedDate,
        selectDate,
      }}
    >
      {children}
    </CalendarEventContext.Provider>
  );
};
export { CalendarEventContext, CalendarEventProvider };
