import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { Button } from "react-native-paper";

export interface CustomDialogProps {
  visible: boolean;
  dialogTitle: string;
  dialogContent?: string;
  dialogType?: string;
  otherLabel?: string;
  hideDialog?: () => void | undefined;
  dialogActionYes?: () => void;
  dialogActionNo?: () => void;
  dialogActionOther?: () => void;
  testID?: string;
  render?: () => ReactElement;
}

export default function CustomDialog(props: CustomDialogProps) {
  // console.log('CustomDialog visible ', visible);
  // console.log('CustomDialog visible type ', typeof (visible));
  // console.log('hideDialog', hideDialog);
  let v = props.visible === true ? true : false;
  // console.log('CustomDialog  v', v);
  // console.log("DialogContent ", props.dialogContent);

  const dialogType = props.dialogType ? props.dialogType : "OK";
  return (
    <Dialog
      open={v}
      onClose={props.hideDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
      <DialogContent>
        {/*   */}
        {props.render && props.render()}
        {_.isEmpty(props.render) && (
          <DialogContentText id="alert-dialog-description">
            {props.dialogContent}
          </DialogContentText>
        )}
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={hideDialog}>Disagree</Button> */}
        {(dialogType === "YesOrNo" || dialogType === "YesNoOther") && (
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Button
              testID="button_no"
              style={{ margin: 5 }}
              mode="contained"
              labelStyle={{ color: "white" }}
              onPress={props.dialogActionNo}
            >
              Nie
            </Button>
            <Button
              testID="button_yes"
              mode="contained"
              style={{ margin: 5 }}
              labelStyle={{ color: "white" }}
              onPress={props.dialogActionYes}
            >
              Tak
            </Button>

            {dialogType === "YesNoOther" && (
              <Button
                testID="button_other"
                mode="contained"
                style={{ margin: 5 }}
                labelStyle={{ color: "white" }}
                onPress={props.dialogActionOther}
              >
                {props.otherLabel}
              </Button>
            )}
          </View>
        )}

        {dialogType === "OK" && (
          <Button
            testID="button_ok"
            mode="contained"
            labelStyle={{ color: "white" }}
            onPress={props.hideDialog}
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
