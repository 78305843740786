import {
  Autocomplete,
  Grid,
  TextField,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";

interface ComponentProps {
  fieldName: string;
  label: string;
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  required?: boolean;
}

export const YesNoComponent = ({
  fieldName,
  label,
  resource,
  errors,
  required,
  setResource,
}: ComponentProps) => {
  // console.log(`YesNoComponent: ${fieldName} ${resource[fieldName]}`);
  return (
    <Grid item xs={12}>
      <FormControl id={fieldName}>
        <FormLabel error={errors ? errors[fieldName] : undefined}>
          {label}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={`${fieldName}-radio-buttons-group`}
          value={
            resource && resource[fieldName] === true
              ? true
              : resource[fieldName] === false
              ? false
              : ""
          }
          onChange={(event) => {
            console.log(
              `YesNoComponent newValue for ${fieldName} ${typeof event.target
                .value} ${event.target.value}`
            );
            var val = event.target.value === "true" ? true : false;
            var obj = {};
            obj[fieldName] = val;
            console.log(
              `YesNoComponent newValue for ... ${JSON.stringify(obj)} `
            );
            setResource({
              ...resource,
              ...obj,
            });
          }}
        >
          <FormControlLabel
            value={true}
            control={<Radio size="small" />}
            label="Tak"
          />
          <FormControlLabel
            value={false}
            control={<Radio size="small" />}
            label="Nie"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
