import React, { useContext, useState } from "react";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import { styles } from "./styles";
import { DebugContext } from "../../debug-context";
import CustomDialog from "../../components/CustomDialog";
import { ScheduledConfCallProps } from "../../navigation/types";
import oneconfig from "../../oneconfig";
import _ from "lodash";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createResource,
  getResource,
  updateResource,
} from "../../services/scheduled-confcall.service";
import { AuthContext, AuthContextType } from "../../auth-context";
import { Loading } from "../../components/Loading";
import LABELS from "../../labels";

import { handleError } from "../../utils";
import "dayjs/locale/pl";

import { Grid, Link, TextField, Typography } from "@mui/material";
import { ScheduledConfCall } from "../../frontend_domain";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { RecordingView } from "../P2PStats/RecordingView";
import { getRecording } from "../../services/recordings.service";

interface SearchUserType {
  name: string;
  pesel: string;
  firstName: string;
  lastName: string;
  email: string;
  _id: string;
}

export default function ScheduledConfCallView({
  navigation,
  ...props
}: ScheduledConfCallProps) {
  const { auth } = React.useContext(AuthContext) as AuthContextType;

  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");

  const [recordingOn, setRecordingOn] = React.useState(false);
  const [recordingContent, setRecordingContent] = React.useState({});

  // @ts-ignore
  const [resource, setResource] = React.useState<ScheduledConfCall>({});

  const debug = useContext(DebugContext).debug;
  const [errors, setErrors] = React.useState({});
  const [validationMessages, setValidationMessages] = React.useState({});

  React.useEffect(() => {
    async function load() {
      // console.log("!!!!SupportFormView useEffect props ", props);
      const resourceId = props.route.params ? props.route.params.id : undefined;
      console.log("!!!!ScheduledConfCall resourceId ", resourceId);
      if (resourceId) {
        setshowSpinner(true);
        const resource = await getResource(resourceId);
        setshowSpinner(false);
        console.log(
          `[ScheduledConfCallView] resource ${JSON.stringify(resource)}`
        );
        console.log(`[ScheduledConfCallView] ${resource._id}`);
        console.log(`[ScheduledConfCallView] createdAt: ${resource.createdAt}`);

        for (const attribute in resource) {
          console.log(`setAttribute ${attribute}: ${resource[attribute]}`);

          if (attribute.endsWith("At") && attribute) {
            console.log(`updating format for ${attribute}`);
            resource[attribute] === resource[attribute]
              ? new Date(resource[attribute]).toLocaleString("pl-PL")
              : "";
          }
        }
        console.log(`resource ${JSON.stringify(resource)}`);
        setResource(resource);

        getRecording(resource.token)
          .then((result) => {
            console.log(`[recordings content] ${JSON.stringify(result)}`);
            setRecordingOn(true);
            setRecordingContent({ recording: result, callId: resource.token });
          })
          .catch((error) => {
            console.log("error:", error);
            alert("Błąd w trakcie pobierania listy połączeń.");
          });
      }
    }

    load();
  }, []);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const validate = async () => {
    let validationErrors = {};
    let helperMessages = {};

    if (_.isEmpty(resource?.scheduledStartAt)) {
      validationErrors["scheduledStartAt"] = true;
      helperMessages["scheduledStartAt"] = 'Data rozpoczęcia jest wymagana"';
    }

    if (_.isEmpty(resource?.scheduledFinishAt)) {
      validationErrors["scheduledFinishAt"] = true;
      helperMessages["scheduledFinishAt"] = 'Data zakończenia jest wymagana"';
    }

    setErrors(validationErrors);
    setValidationMessages(helperMessages);

    console.log(`validation result ${JSON.stringify(validationErrors)}`);
    if (Object.keys(validationErrors).length === 0) return true;
    else return false;
  };

  const onSubmit = async () => {
    const isValidated = await validate();

    console.log(`isValidated ${JSON.stringify(isValidated)}`);
    if (isValidated) {
      let result;
      setshowSpinner(true);
      try {
        console.log("executing request");
        console.log("executing create translation");

        if (resource?._id) result = await updateResource(resource);
        else result = await createResource(resource);

        console.log(`request done ${JSON.stringify(resource)}`);

        if (result) {
          setshowSpinner(true);
          setDialogMessage("Zapisano zmiany");
          setDialogVisible(true);
        }
      } catch (error) {
        handleError(
          setshowSpinner,
          error,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
      }
    }
  };

  // console.log(`item to render ${JSON.stringify(item)}`);

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("ScheduledConfCalls");
  };

  /*  if (showSpinner && props.route.params.id) return <Loading />;
  else */
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pl"}>
      <View testID="form_container" style={styles.outerColumnContainer}>
        {showSpinner === true && <Loading />}

        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        {/* confirmation */}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={"Zapisano zmiany"}
        />
        <View
          testID="fields"
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" margin="normal" style={{ padding: 20 }}>
                Rozmowa do 4 godzin (do 4 osób)
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {" "}
            </Grid>
          </Grid>
          {resource?._id && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  label="Id"
                  fullWidth
                  name="id"
                  value={resource ? resource._id : ""}
                  disabled={true}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Data utworzenia"
                  name="createdAt"
                  value={resource ? resource.createdAt : ""}
                  disabled={true}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Data modyfikacji"
                  name="updatedAt"
                  disabled={true}
                  value={resource ? resource.updatedAt : ""}
                ></TextField>
              </Grid>
            </Grid>
          )}

          {resource?._id && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Stworzone przez"
                  name="createdBy"
                  disabled={true}
                  fullWidth
                  value={
                    resource && resource.createdBy
                      ? resource.createdBy?.firstName +
                        " " +
                        resource.createdBy?.lastName
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Zmienione przez"
                  name="updatedBy"
                  disabled={true}
                  fullWidth
                  value={
                    resource && resource.updatedBy
                      ? resource.updatedBy?.firstName +
                        " " +
                        resource.updatedBy?.lastName
                      : ""
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DesktopDateTimePicker
                disablePast={true}
                label="Data i godzina rozpoczęcia"
                value={
                  resource && resource.scheduledStartAt
                    ? resource.scheduledStartAt
                    : ""
                }
                onChange={(newValue) => {
                  setResource({
                    ...resource,
                    scheduledStartAt: newValue,
                  });
                  // setValue(newValue);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      id={"scheduledStartAt"}
                      margin="normal"
                      error={false}
                      fullWidth
                      {...params}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DesktopDateTimePicker
                disablePast={true}
                label="Data i godzina zakończenia"
                value={
                  resource && resource.scheduledFinishAt
                    ? resource.scheduledFinishAt
                    : ""
                }
                onChange={(newValue) => {
                  console.log(`DesktopDateTimePicker ${newValue}`);
                  setResource({
                    ...resource,
                    scheduledFinishAt: newValue,
                  });
                  // setValue(newValue);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      id={"scheduledFinishAt"}
                      margin="normal"
                      error={false}
                      fullWidth
                      {...params}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          {resource?._id && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  id="token"
                  fullWidth
                  rows={4}
                  label="Token do spotkania"
                  value={resource && resource.token ? resource.token : ""}
                  disabled={true}
                />
              </Grid>
            </Grid>
          )}
          {resource?._id && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  rows={4}
                  disabled={true}
                  label="Link"
                  value={
                    resource && resource.token
                      ? `${oneconfig.marketing_url}?confcall=${resource.token}`
                      : ""
                  }
                />
              </Grid>
            </Grid>
          )}

          {resource?._id && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link
                  id="confcall_link"
                  href="#"
                  target="_blank"
                  variant={"h6"}
                  onClick={() => {
                    Linking.openURL(
                      `${oneconfig.marketing_url}?confcall=${resource.token}`
                    );
                  }}
                >
                  {`${oneconfig.marketing_url}?confcall=${resource.token}`}
                </Link>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <View
                style={{
                  display: recordingOn ? "flex" : "none",
                  height: "100%",
                  // width: "100%",
                  flex: 1,
                  // paddingTop: 100,

                  padding: "5%",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "lightgray",
                }}
              >
                <RecordingView recordingContent={recordingContent} />
              </View>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              {!auth?.isUser && (
                <View
                  style={[
                    debug === true ? { backgroundColor: "orange" } : undefined,
                    styles.btnPrimary,
                  ]}
                >
                  <TouchableOpacity onPress={onSubmit} testID={"button_update"}>
                    <Text style={styles.buttonText}>{LABELS.save}</Text>
                  </TouchableOpacity>
                </View>
              )}
              <Grid item xs={4}></Grid>
            </Grid>
          </Grid>
        </View>
      </View>
    </LocalizationProvider>
  );
}
