import React, { useContext } from "react";
import { Button, IconButton } from "react-native-paper";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { useCallback } from "react";
import { View, Text } from "react-native";
import { DisableMenuContext } from "../disable-menu-context";

export default function MenuIcon(props) {
  const { menuDisabled } = useContext(DisableMenuContext);
  const navigation = useNavigation();
  const openDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.openDrawer());
  }, []);

  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <IconButton
        color={menuDisabled ? "grey" : "black"}
        icon="menu"
        testID={"menu_icon"}
        size={24}
        onPress={menuDisabled ? () => {} : openDrawer}
      />
    </View>
  );
}
