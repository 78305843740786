import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  KrusDisabilityLevel as KrusDisabilityLevelEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface KrusDisabilityLevel {
  id: string;
  label: string;
}

export const krusDisabilityLevelOptions = Object.keys(
  KrusDisabilityLevelEnum
).map((key) => ({
  id: key,
  label: KrusDisabilityLevelEnum[key],
}));

// console.log(
//   `krusDisabilityLevelOptions ${JSON.stringify(krusDisabilityLevelOptions)}`
// );

interface KrusDisabilityLevelProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const KrusDisabilityLevel = ({
  resource,
  errors,
  setResource,
  onBlur,
}: KrusDisabilityLevelProps) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body1">
        23. Posiadany stopień orzeczenia lekarza Orzecznika KRUS
      </Typography>
      <Autocomplete
        options={krusDisabilityLevelOptions}
        disablePortal
        id="krus_disability_level"
        value={
          resource.krus_disability_level ? resource.krus_disability_level : ""
        }
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            fullWidth
            required={true}
            error={errors ? errors["krus_disability_level"] : false}
            onBlur={onBlur}
            label="Stopień orzeczenia Krus"
          />
        )}
        onChange={(event: any, newValue: KrusDisabilityLevel) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);

          setResource({
            ...resource,
            krus_disability_level: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
