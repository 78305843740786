import React from "react";
import { View, Text, SafeAreaView, Platform } from "react-native";
import MenuIcon from "../components/MenuIcon";
import MenuLogo from "../components/MenuLogo";
import MenuUserInfo from "../components/MenuUserInfo";
import Constants from "expo-constants";

export const SMALL_MENU_WIDTH = 1280;
export const VERY_SMALL_MENU_WIDTH = 800;

const MyHeader = (props) => {
  const smallScreen = window.matchMedia(
    `(max-width: ${SMALL_MENU_WIDTH}px)`
  ).matches;

  const verySmallScreen = window.matchMedia(
    `(max-width: ${VERY_SMALL_MENU_WIDTH}px)`
  ).matches;

  return (
    <SafeAreaView>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MenuIcon />
          <MenuLogo />

          <Text style={{ fontSize: 18, padding: 5 }}>
            {verySmallScreen
              ? ""
              : smallScreen
              ? "Centrum Komunikacji"
              : "Centrum Komunikacji Języka Migowego"}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            // backgroundColor: "blue",
          }}
        >
          <MenuUserInfo />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default MyHeader;
