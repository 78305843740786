import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  PolishLevel as PolishLevelEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface PolishLevel {
  id: string;
  label: string;
}

export const polishLevelOptions = Object.keys(PolishLevelEnum).map((key) => ({
  id: key,
  label: PolishLevelEnum[key],
}));

console.debug(`polishLevelOptions ${JSON.stringify(polishLevelOptions)}`);

interface PolishLevelComponentProps {
  resource: ParticipationFormDocument;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setResource: React.Dispatch<any>;
}

export const SelfPolishLevelAssesment = ({
  resource,
  errors,
  setResource,
  onBlur,
}: PolishLevelComponentProps) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body1" id="polish_level">
        {"16. Jak ocenia Pan/Pani swoją znajomość języka polskiego"}
      </Typography>
      <Autocomplete
        options={polishLevelOptions}
        disablePortal
        value={resource.polish_level ? resource.polish_level : ""}
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            required={true}
            error={errors ? errors["polish_level"] : false}
            fullWidth
            onBlur={onBlur}
            label="Znajomość języka polskiego"
          />
        )}
        onChange={(event: any, newValue: PolishLevel) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            polish_level: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
