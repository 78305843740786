import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { View, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native";
import { ScrollView } from "react-native";
import { DebugContext } from "../../debug-context";
import { useContext } from "react";
import { getPolicyVideoLink } from "../Policy";
import { Button, Colors, Text, TextInput } from "react-native-paper";
import { closeOwnAccount } from "../../services/user.service";
import { handleError } from "../../utils";
import { Loading } from "../../components/Loading";
import CustomDialog from "../../components/CustomDialog";

const Content = () => {
  const [uri, setUri] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");
  const [reason, setReason] = React.useState("");

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  useEffect(() => {
    console.log(`fetch contact video link`);
    getPolicyVideoLink("project_info")
      .then((response) => {
        console.log(
          `fetch contact video link ${JSON.stringify(response.data)}`
        );

        setUri(response.data.url);
      })
      .catch((error) => console.log(error));
  }, [uri]);

  return (
    <View
      testID="close_account_container"
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      {showSpinner && <Loading loading={showSpinner} />}
      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />
      <View style={styles.top}>
        {confirmed === false && (
          <>
            <Text style={[styles.centeredText, { fontSize: 20 }]}>
              Jesteś pewien że chcesz zamknąć konto?
            </Text>

            <TextInput
              mode="flat"
              testID="reason_input"
              style={{ marginBottom: 20 }}
              label="Prosimy o podanie powodu zamknięcia konta..."
              accessibilityLabel="Prosimy o podanie powodu zamknięcia konta..."
              onFocus={() => console.log(`text input focused`)}
              multiline={true}
              numberOfLines={2}
              value={reason}
              blurOnSubmit={false}
              onChangeText={(text) => setReason(text)}
              // onSubmitEditing={() => sendMessage(text)}
            />

            <Button
              icon="close-outline"
              mode="contained"
              color={Colors.red500}
              onPress={async () => {
                try {
                  const response = await closeOwnAccount(reason);

                  console.log(
                    `close own account response ${JSON.stringify(response)}`
                  );
                  if (response.message === "User inactivated.")
                    setConfirmed(true);
                } catch (error) {
                  console.log(`got error ${JSON.stringify(error)}`);
                  handleError(
                    setshowSpinner,
                    error,
                    setDialogErrorMessage,
                    setDialogErrorVisible
                  );
                }
              }}
            >
              Potwierdzam zamknięcie konta
            </Button>
          </>
        )}

        {confirmed === true && (
          <>
            <Text style={styles.centeredText}>
              Twoje konto zostało właśnie zablokowane.
            </Text>
            <Text style={styles.centeredText}>
              Pełne zamknięcie konta może potrwać do 3 dni roboczych o czym
              zostaniesz powiadomiony oddzielnym mailem.
            </Text>
            <Text style={styles.centeredText}>
              Twoje dane będą nadal przechowywane w celach statystycznych
              zgodnie z Regulaminem oraz Polityką Prywatności Centrum
              Komunikacji w ramach projektu "Włączenie wyłączonych - aktywne
              instrumenty wsparcia osób niepełnosprawnych na rynku pracy".
            </Text>
          </>
        )}
      </View>
    </View>
  );
};

const CloseAccount = () => {
  let debug = useContext(DebugContext).debug;
  return (
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <Content />
      </ScrollView>
    </SafeAreaView>
  );
};

export default CloseAccount;

export const styles = StyleSheet.create({
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    alignSelf: "center", // center itself horizontally(always in outer container)
    // justifyContent: 'space-around',     // xman space around to fill all height available, commented out because on large screens it takes to spread
    maxWidth: "80%", // healthy maxWidth for big screen but will be 95 % for small screen
  },
  outerColumnContainerMobile: {
    display: "flex",
    height: "100%",
    overflow: "scroll",
  },
  justifiedText: {
    textAlign: "justify",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  centeredText: {
    textAlign: "center",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  top: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    paddingTop: Platform.OS === "web" ? 50 : 10,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  middle: {
    // width: Platform.OS === "web" ? "80%" : "100%",
    paddingTop: Platform.OS === "web" ? 50 : 20,
    paddingBottom: Platform.OS === "web" ? 50 : 20,
    // backgroundColor: "blue",
    // height: "100%",
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  bottom: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    // paddingTop: Platform.OS === "web" ? 50 : 20,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
  logo: {
    width: Platform.OS === "web" ? 120 : 60,
    height: Platform.OS === "web" ? 70 : 35,
    margin: 10,
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    // justifyContent: "center",
    padding: 10,
    width: "100%",
    height: 300,
    display: "flex",
    // borderStyle: "solid",
    // borderColor: "black",
    // position: "relative",
    // backgroundColor: "orange",
  },
});
