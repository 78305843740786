import React from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { IconButton, List, Text } from "react-native-paper";
import {
  ConnectedUsersDTO,
  ConnectedUserDTO,
  CALL_STATUS,
} from "../../frontend_domain";

interface ConnectedUsersViewProps {
  users: ConnectedUsersDTO;
  call?: Function;
  ownStatus: string;
  paddingTop?: number;
}

export const ConnectedUsersView = ({
  users,
  call,
  ownStatus,
  paddingTop,
}: ConnectedUsersViewProps) => {
  // console.debug(`[ConnectedUsersView] users`, users);
  // console.log(`[ConnectedUsersView] status`, status);
  let list = new Array();
  if (users && users.size > 0) {
    users.forEach((value: ConnectedUserDTO, key: string) => {
      return list.push(
        <List.Item
          testID={key}
          title={`${value.firstName} ${value.lastName} ${key}`}
          titleNumberOfLines={2}
          description={
            value.status === CALL_STATUS.IN_CALL && value.peer
              ? value.status + " " + value.peer
              : value.status
          }
          // style={{ minHeight: 900 }}
          key={key}
          left={(props) =>
            call ? (
              <IconButton
                icon="phone"
                size={24}
                color={value.status === CALL_STATUS.READY ? "green" : "grey"}
                disabled={ownStatus !== CALL_STATUS.READY}
                onPress={
                  value.status === CALL_STATUS.READY
                    ? () => {
                        call(key, value.role);
                      }
                    : undefined
                }
              />
            ) : (
              <></>
            )
          }
          right={(props) => (
            <>
              {value.role === "translator" && value.mode === "pjm" && (
                <IconButton icon={"account-tie-voice-outline"} size={24} />
              )}
              {value.role === "user" && value.mode === "pjm" && (
                <IconButton icon={"ear-hearing-off"} size={24} />
              )}
              {value.role === "transcriber" && (
                <IconButton icon={"account-edit-outline"} size={24} />
              )}
              {value.role === "user" && value.mode === "transcription" && (
                <IconButton icon={"account-cowboy-hat-outline"} size={24} />
              )}
              {value.platform === "web" && (
                <>
                  <IconButton icon={"web"} size={24} />
                </>
              )}
              {value.platform === "android" && (
                <>
                  <IconButton icon={"android"} size={24} />
                </>
              )}
              {value.platform === "ios" && (
                <>
                  <IconButton icon={"apple-ios"} size={24} />
                </>
              )}
            </>
          )}
        ></List.Item>
      );
    });
  }

  return (
    <>
      <ScrollView
        nativeID="connected-users-scroll-view"
        contentContainerStyle={{
          // display: "flex",
          flex: 1,
          // backgroundColor: "red",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "space-between",
          height: "auto",
          width: "80%",
          paddingTop: paddingTop ? paddingTop : undefined,
        }}
        style={{
          display: "flex",
          height: "auto",
          width: "80%",
        }}
      >
        {list}
      </ScrollView>
    </>
  );
};
