import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { plPL } from "../../localization/plPL";
// import { plPL } from "@mui/x-data-grid";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FAB, Portal, Text } from "react-native-paper";
import { View } from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import {
  deleteResource,
  getAll,
} from "../../services/scheduled-confcall.service";
import { Auth, AuthContext } from "../../auth-context";
import _ from "lodash";
import CustomDialog from "../../components/CustomDialog";
import baseStyles from "../../styles/base";
import { Loading } from "../../components/Loading";

const theme = createTheme({}, plPL);

const headerNames = new Map();
headerNames.set("_id", "ID");
headerNames.set("description", "description");
// headerNames.set("role", "Rola");
// headerNames.set("firstName", "Imię");
// headerNames.set("fullName", "Imię i nazwisko");
// headerNames.set("lastName", "Nazwisko");
// headerNames.set("status", "status");

export default function ScheduledConfCalls() {
  const [items, setItems] = React.useState([]);
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  const now = new Date();
  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const [showSpinner, setshowSpinner] = React.useState(false);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");
  const [dialogConfirmDelete, setDialogConfirmDelete] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState("");

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const columns: GridColumns = [
    {
      field: "_id",
      headerName: headerNames.get("_id"),
      //  width: 90
    },
    {
      field: "createdAt",
      headerName: "Data utworzenia",
      editable: false,
      type: "dateTime",
      width: 200,

      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "scheduledStartAt",
      headerName: "Data rozpoczęcia",
      editable: false,
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "scheduledFinishAt",
      headerName: "Data zakończenia",
      editable: false,
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },

    {
      field: "token",
      headerName: "Token",
      renderCell: (params: GridRenderCellParams<boolean>) => (
        <Text>{params.value}</Text>
      ),
      width: 300,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Akcje",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        if (auth?.isAdmin) {
          console.log(
            `row.scheduledStartAt  ${typeof row.scheduledStartAt} ${
              row.scheduledStartAt
            }`
          );
          console.log(`now   ${typeof now} ${now}`);
          console.log(
            `row.scheduledFinishAt ${typeof row.scheduledFinishAt}  ${
              row.scheduledFinishAt
            }`
          );
          return [
            <GridActionsCellItem
              data-testid={`edit_${
                row.scheduledStartAt &&
                row.scheduledFinishAt &&
                now > new Date(row.scheduledStartAt) &&
                now < new Date(row.scheduledFinishAt)
                  ? "active"
                  : "inactive"
              }_${row._id}`}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                handleClick(id);
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <DeleteIcon
                  color={
                    row.scheduledStartAt &&
                    row.scheduledFinishAt &&
                    now > new Date(row.scheduledStartAt) &&
                    now < new Date(row.scheduledFinishAt)
                      ? "error"
                      : "info"
                  }
                />
              }
              data-testid={`delete_${
                row.scheduledStartAt &&
                row.scheduledFinishAt &&
                now > new Date(row.scheduledStartAt) &&
                now < new Date(row.scheduledFinishAt)
                  ? "active"
                  : "inactive"
              }_${row._id}`}
              hidden={auth?.isAdmin ? false : true}
              label="Delete"
              // style={{ display: auth?.isAdmin ? "flex" : "hidden" }}
              onClick={() => {
                setDialogConfirmDelete(true);
                setItemToDelete(row._id);
              }}
            />,
          ];
        } else
          return [
            <GridActionsCellItem
              data-testid={"edit_" + row.supportDescription}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                handleClick(id);
              }}
              color="inherit"
            />,
          ];
      },
    },
  ];

  const navigation = useNavigation();

  React.useEffect(() => {
    releaseUserMedia();

    if (_.isEmpty(auth)) {
      //   console.log("[Users] useEffect (no auth yet), auth:", auth);
      return;
    } else {
      //   console.log("[Users] useEffect (with auth), auth:", auth);
      if (items.length === 0) {
        console.log("fetching data using ", auth);

        setshowSpinner(true);

        getAll()
          .then((freshItems) => {
            console.log(
              `[ScheduledConfCalls] useEffect (with auth), freshItems:`,
              freshItems.length
            );
            setshowSpinner(false);
            setItems(freshItems);
          })
          .catch((error) => {
            console.log(`caught1 ${JSON.stringify(error)}`);
            setshowSpinner(false);
            setDialogErrorVisible(true);
            if (error && error.response && error.response.data)
              setDialogErrorMessage(error.response.data.error);
            else setDialogErrorMessage(JSON.stringify(error));
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, []);

  const newItem = () => {
    console.log("new item");
    navigation.navigate("ScheduledConfCall");
  };

  const confirmedDeleteResource = (id: string) => {
    deleteResource(id)
      .then((response) => {
        console.log(`[ScheduledConfCalls] support form deleted:`, response);
        setItemToDelete("");
        setDialogConfirmDelete(false);
        getAll()
          .then((freshItems) => {
            console.log(
              `[ScheduledConfCalls] useEffect (with auth), fresh items:`,
              freshItems
            );
            setItems(freshItems);
          })
          .catch((error) => {
            console.log("error:", error);
            alert("Błąd pobierania aktualnej listy");
          });
      })
      .catch((err) => {
        console.log("error:", err);
        setItemToDelete("");
        alert("Błąd w trakcie usuwania");
      });
  };

  const handleClick = (id: string) => {
    // console.log('event ', JSON.stringify(event.target));
    navigation.navigate("ScheduledConfCall", { id: id });
  };

  return (
    <div style={{ flex: 1, height: "100%", width: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        <View
          testID="ScheduledConfCallScreen"
          style={{ flex: 1, display: "none" }}
        ></View>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <CustomDialog
          visible={dialogConfirmDelete}
          dialogType={"YesOrNo"}
          dialogActionNo={() => setDialogConfirmDelete(false)}
          dialogActionYes={() => confirmedDeleteResource(itemToDelete)}
          dialogTitle={"Ostrzeżenie"}
          dialogContent={"Czy na pewno chcesz usunąć ?"}
          testID="error_dialog"
        />
        <Portal>
          <FAB
            visible={isFocused}
            icon="plus"
            testID="create_scheduled_confcall"
            style={baseStyles.addItemFab}
            onPress={newItem}
          />
        </Portal>
        <DataGrid
          style={{ backgroundColor: "#fff" }}
          rows={items}
          getRowId={(row) => row._id}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
        />
      </ThemeProvider>
    </div>
  );
}
