const LABELS = {
  _26: "26. Rodzaj zatrudnienia",
  _27: "27. Proszę podać dane pracodawcy, u którego jest Pan/-Pani zatrudniony/zatrudniona",
  _28: "28. Proszę zaznaczyć rodzaj pracodawcy",
  _29: "29. Wielkość podmiotu zatrudniającego",
  _30: "30. Rodzaj podmiotu z uwagi na status/rodzaj rynku",
  _31: "31. Proszę podać od kiedy prowadzona jest działalność gospodarcza",
  _32: "32. Dane prowadzonej działalności",
  _33: "33. Wielkość prowadzanej działalności gospodarczej",
  _34: "34. Proszę podać branże w której wykonywana jest działalność gospodarcza",
  GDPR_CLAUSE_HEADER:
    "OŚWIADCZENIE O WYRAŻENIU ZGODY NA PRZETWARZANIE DANYCH OSOBOWYCH",
  GDPR_CLAUSE_TEXT:
    "Oświadczam, że wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w „Formularzu korzystania z usług”, dla potrzeb pilotażu instrumentu „Centrum Komunikacji” w ramach Projektu „Włączenie wyłączonych – aktywne instrumenty wsparcia osób niepełnosprawnych na rynku pracy” Nr projektu: POWR.02.06.00-00-0065/19, zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych – RODO, Dz. U. UE. L. 2016.119.1 z dnia 4 maja 2016 r.) oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018r.",
};

export default LABELS;
