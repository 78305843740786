import * as React from "react";
import {
  CalendarEvent,
  CalendarEventContext,
  CalendarEventContextType,
} from "../../calendar-event-context";
import { View } from "react-native";
import { useForm } from "react-hook-form";
import { DebugContext } from "../../debug-context";
import { Button, IconButton, TextInput } from "react-native-paper";
import axios from "axios";
import { handleError } from "../../utils";
import config from "../../oneconfig";
import CustomDialog from "../../components/CustomDialog";
import { convertEvent } from "../../services/calendar-event";

type SelectedEventSectionProps = {
  // selectedEvent: CalendarEvent;
};

export default function SelectedEventSection({}: // selectedEvent,
SelectedEventSectionProps) {
  const debug = React.useContext(DebugContext).debug;
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");

  const { selectedEvent, selectEvent, events, updateEvents } = React.useContext(
    CalendarEventContext
  ) as CalendarEventContextType;

  const [description, setDescription] = React.useState(
    selectedEvent?.description ? selectedEvent.description : ""
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,

    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur", // "onChange"
  });

  const onRemove = async (id?: string) => {
    console.log(`remove ${JSON.stringify(id)}`);
    /* alert("on remove"); */
    try {
      axios({
        url: `${config.api_url}/calendar-event/${id}/delete`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((deletedItem) => {
        console.log(`deleted event ${JSON.stringify(deletedItem)}`);
        console.log(`# events before ${events?.length}`);
        const updatedEvents = events?.filter((item) => item.id !== id);
        console.log(`# events after  ${updatedEvents?.length}`);
        updateEvents(updatedEvents);
        selectEvent(undefined);
        /*  let updatedEvents = [...myEvents, savedItem];
        console.log("Events now: ", updatedEvents.length);
        setMyEvents(updatedEvents); */
      });
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  const onSubmit = async (event: {}) => {
    console.log(`modify ${JSON.stringify(event)}`);
    /* alert("on remove"); */
    try {
      axios({
        url: `${config.api_url}/calendar-event/${event.id}`,
        method: "patch",
        data: event,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const updatedEvent = response.data;
        console.log(`updated event ${JSON.stringify(updatedEvent)}`);
        const updatedEvents = events?.filter((item) => item.id !== event.id);
        const convertedEvent = convertEvent(event);
        if (updatedEvents) {
          updatedEvents.push(convertedEvent);
          updateEvents(updatedEvents);
        } else {
          updateEvents[convertedEvent];
        }
        console.log(`# events after  ${updatedEvents?.length}`);

        selectEvent(convertedEvent);
        /*  let updatedEvents = [...myEvents, savedItem];
        console.log("Events now: ", updatedEvents.length);
        setMyEvents(updatedEvents); */
      });
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  React.useEffect(() => {
    setDescription(selectedEvent?.description);
  }, [selectedEvent]);

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("SignIn");
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  // console.log(`selectedEvent.description ${selectedEvent.description}`);
  return (
    <>
      <CustomDialog
        visible={dialogVisible}
        hideDialog={hideDialog}
        dialogTitle={""}
        dialogContent={dialogMessage}
      />
      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "blue",
          justifyContent: "stretch",
          alignItems: "stretch",
          flexGrow: 10,
        }}
      >
        <View
          style={{
            display: "flex",
          }}
        >
          {debug && (
            <TextInput
              multiline={true}
              style={{ margin: 10, flex: 1 }}
              value={JSON.stringify(selectedEvent)}
            ></TextInput>
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            // backgroundColor: "green",
            alignItems: "stretch",
            justifyContent: "space-evenly",
          }}
        >
          <View
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              // backgroundColor: "gray",
              alignItems: "stretch",
              justifyContent: "space-around",
            }}
          >
            <TextInput
              style={{ margin: 10, flex: 1 }}
              label="Początek"
              disabled={true}
              value={
                selectedEvent?.start
                  ? selectedEvent.start.toLocaleString("pl-PL", {
                      timeZone: "Europe/Warsaw",
                    })
                  : ""
              }
            />
            <TextInput
              style={{ margin: 10, flex: 1 }}
              label="Koniec"
              disabled={true}
              value={
                selectedEvent?.end
                  ? selectedEvent.end.toLocaleString("pl-PL", {
                      timeZone: "Europe/Warsaw",
                    })
                  : ""
              }
            />
            <TextInput
              style={{ margin: 10, flex: 1 }}
              label="Tłumacz"
              disabled={true}
              value={selectedEvent.resourceId}
            />
          </View>

          <View
            style={{
              display: "flex",
              padding: 5,
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              icon="calendar-check-outline"
              testID={`edit_event_${selectedEvent?.id}`}
              mode="contained"
              onPress={() =>
                onSubmit({ ...selectedEvent, title: "Tłumacz dostępny" })
              }
              style={{ margin: 10, flex: 1 }}
            >
              Dostępny
            </Button>

            <Button
              icon="palm-tree"
              testID={`edit_event_${selectedEvent?.id}`}
              mode="contained"
              color={"green"}
              onPress={() => onSubmit({ ...selectedEvent, title: "Urlop" })}
              style={{ margin: 10, flex: 1 }}
            >
              Urlop
            </Button>

            <Button
              icon="emoticon-sick-outline"
              testID={`delete_event_${selectedEvent?.id}`}
              color={"red"}
              mode="contained"
              onPress={() => onSubmit({ ...selectedEvent, title: "L4" })}
              style={{ margin: 10, flex: 1 }}
            >
              L4
            </Button>

            <Button
              icon="alert-circle-outline"
              testID={`delete_event_${selectedEvent?.id}`}
              color={"orange"}
              mode="contained"
              onPress={() => onSubmit({ ...selectedEvent, title: "inne" })}
              style={{ margin: 10, flex: 1 }}
            >
              Inne
            </Button>
          </View>
          <View
            style={{
              padding: 5,
              paddingTop: 50,
              flexDirection: "row",
              // backgroundColor: "purple",
              alignItems: "stretch",
              justifyContent: "space-around",
            }}
          >
            <TextInput
              label="Dodatkowe informacje"
              value={description ? description : ""}
              disabled={false}
              style={{ margin: 10, flex: 1 }}
              // disabled={true}
              numberOfLines={5}
              multiline={true}
              onChangeText={(description) => setDescription(description)}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                padding: 20,
                // backgroundColor: "yellow",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon="calendar-check-outline"
                testID={`edit_event_${selectedEvent?.id}`}
                mode="contained"
                onPress={() => onSubmit({ ...selectedEvent, description })}
                style={{ margin: 10 }}
              >
                Zapisz
              </Button>

              <Button
                icon="calendar-remove-outline"
                testID={`delete_event_${selectedEvent?.id}`}
                color={"red"}
                mode="contained"
                onPress={() => {
                  onRemove(selectedEvent?.id);
                }}
                style={{ margin: 10 }}
              >
                Usuń
              </Button>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}
