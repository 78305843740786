import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { FocusEventHandler } from "react";
import { ParticipationFormDocument } from "../../frontend_domain";
import { AddressTypeComponent } from "./AddressType";
import { VoivodeshipComponent } from "./Voivodeship";

type AddressProps = {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errors?: {};
};

export const Address = ({
  resource,
  errors,
  onBlur,
  setResource,
}: AddressProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {"9. Miejsce zamieszkania/zameldowania"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="none"
          id="address_street"
          fullWidth
          label="Ulica"
          onBlur={onBlur}
          required={true}
          error={errors ? (errors["address_street"] ? true : false) : undefined}
          value={
            resource && resource.address_street ? resource.address_street : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              address_street: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          margin="none"
          id="address_house_no"
          fullWidth
          label="Nr domu"
          required={true}
          onBlur={onBlur}
          error={
            errors ? (errors["address_house_no"] ? true : false) : undefined
          }
          value={
            resource && resource.address_house_no
              ? resource.address_house_no
              : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              address_house_no: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          margin="none"
          id="address_flat_no"
          fullWidth
          label="Nr lokalu"
          value={
            resource && resource.address_flat_no ? resource.address_flat_no : ""
          }
          onChange={(event) => {
            setResource({
              ...resource,
              address_flat_no: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="none"
          id="postal_code"
          fullWidth
          onBlur={onBlur}
          label="Kod pocztowy"
          required={true}
          error={errors ? (errors["postal_code"] ? true : false) : undefined}
          value={resource && resource.postal_code ? resource.postal_code : ""}
          onChange={(event) => {
            setResource({
              ...resource,
              postal_code: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="none"
          id="city"
          fullWidth
          onBlur={onBlur}
          label="Miejscowość"
          required={true}
          error={errors ? (errors["city"] ? true : false) : undefined}
          value={resource && resource.city ? resource.city : ""}
          onChange={(event) => {
            setResource({
              ...resource,
              city: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="none"
          id="county"
          fullWidth
          onBlur={onBlur}
          label="Powiat"
          required={true}
          error={errors ? (errors["county"] ? true : false) : undefined}
          value={resource && resource.county ? resource.county : ""}
          onChange={(event) => {
            setResource({
              ...resource,
              county: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="none"
          id="community"
          fullWidth
          onBlur={onBlur}
          label="Gmina"
          error={errors ? (errors["community"] ? true : false) : undefined}
          required={true}
          value={resource && resource.community ? resource.community : ""}
          onChange={(event) => {
            setResource({
              ...resource,
              community: event.target.value,
            });
          }}
        />
      </Grid>

      <AddressTypeComponent
        resource={resource}
        errors={errors}
        onBlur={onBlur}
        setResource={setResource}
      />
      <VoivodeshipComponent
        resource={resource}
        errors={errors}
        onBlur={onBlur}
        setResource={setResource}
      />
    </Grid>
  );
};
