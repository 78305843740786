import React from "react";
import { useController } from "react-hook-form";
import { TextInput, Text } from "react-native-paper";
import { StyleSheet } from "react-native";

type ValidatedTextInput = {
  name: string;
  accessibilityLabel: string;
  control: any;
  placeholder: string;
  leftIconName: string;
  autoCorrect?: boolean | undefined;
  errors: any;
};

const ValidatedTextInput = ({
  name,
  control,
  placeholder,
  leftIconName,
  errors,
  ...props
}) => {
  const { field } = useController({
    control,
    defaultValue: "",
    rules: props.rules,
    name,
  });

  return (
    <>
      <TextInput
        value={field.value}
        autoCapitalize="none"
        accessibilityLabel={placeholder}
        testID={name}
        nativeID={name}
        onChangeText={field.onChange}
        onBlur={field.onBlur}
        autoCorrect={props.autoCorrect ? props.autoCorrect : true}
        disabled={props.disabled}
        label={
          props.rules && props.rules["required"]
            ? placeholder + " *"
            : placeholder
        }
        placeholder={placeholder}
        secureTextEntry={props.secureTextEntry}
        left={
          <TextInput.Icon
            role="none"
            ariaRole="none"
            ariaDisabled="true"
            disabled
            name={leftIconName}
          />
        }
      />

      {errors[name] && errors[name].type == "required" ? (
        <Text nativeID={"validation_error_" + name} style={styles.required}>
          Pole jest wymagane.
        </Text>
      ) : (
        <></>
      )}

      {errors[name] && errors[name].type == "pattern" ? (
        <Text nativeID={"validation_error_" + name} style={styles.required}>
          Pole ma nieprawidłową wartość
        </Text>
      ) : (
        <></>
      )}

      {errors[name] && errors[name].type == "validate" ? (
        <Text nativeID={"validation_error_" + name} style={styles.required}>
          Pole ma nieprawidłową wartość
        </Text>
      ) : (
        <></>
      )}

      {errors[name] &&
      !["validate", "required", "pattern"].includes(errors[name].type) ? (
        <Text nativeID={"validation_error_" + name} style={styles.required}>
          {" "}
          {errors[name].message
            ? errors[name].message
            : "Pole ma nieprawidłową wartość"}
        </Text>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  required: { color: "red", wordWrap: "break-word" },
});

export default ValidatedTextInput;
