import React from "react";
import { ScrollView, SafeAreaView } from "react-native";
import { styles } from "./styles";
import { ParticipationFormProps } from "../../navigation/types";
import ParticipationFormView from "./ParticipationFormView";
import { useReactToPrint } from "react-to-print";
import { FAB, Portal } from "react-native-paper";
import baseStyles from "../../styles/base";

export default function ParticipationForm(props: ParticipationFormProps) {
  // console.log('Translation props? ', props);

  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => {
      console.log("printing...");
      return componentRef.current;
    },
  });

  return (
    <SafeAreaView style={[styles.body]}>
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <Portal>
          <FAB
            icon="printer-outline"
            testID="printer-outline"
            style={baseStyles.rightTop}
            onPress={handlePrint}
          />
        </Portal>

        <ParticipationFormView ref={componentRef} {...props} />
      </ScrollView>
    </SafeAreaView>
  );
}
