import { StyleSheet } from "react-native";

import MUI_THEME from "../../styles/index.native";

export const styles = StyleSheet.create({
  inputCheckboxContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 15,
    minWidth: 200,
  },
  acceptanceText: {
    padding: 4,
    fontSize: 16,
    flex: 1,
    paddingLeft: 10,
  },
  validationText: {
    flex: 1,
    paddingLeft: 10,
    color: "red",
  },
});
