import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument, SelfDeafType } from "../../frontend_domain";

type SelfDeafTypeProps = {
  resource: ParticipationFormDocument;
  errors?: {};
  setResource: React.Dispatch<any>;
};

export const SelfDeafTypeComponent = ({
  resource,
  errors,
  setResource,
}: SelfDeafTypeProps) => {
  return (
    <Grid item xs={12} style={{ paddingTop: 15 }}>
      <FormControl>
        <Typography variant="body1">
          {
            "14. Proszę określić do jakiej grupy Pan/Pani przynależy poprzez dokończenie zdania: Jestem osobą:"
          }
        </Typography>

        <RadioGroup
          name="self_deaf_radio-buttons-group"
          value={
            resource.self_deaf_type
              ? resource.self_deaf_type
              : SelfDeafType.NO_ANSWER
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            setResource({
              ...resource,
              self_deaf_type: newValue,
            });
          }}
        >
          <FormControlLabel
            value="Brak odpowiedzi"
            control={<Radio size={"small"} />}
            label="Brak odpowiedzi"
          />
          <FormControlLabel
            value="Głucha"
            control={<Radio size={"small"} />}
            label="Głucha"
          />
          <FormControlLabel
            value="Słabosłysząca"
            control={<Radio size={"small"} />}
            label="Słabosłysząca"
          />
          <FormControlLabel
            value="Głuchoniewidoma"
            control={<Radio size={"small"} />}
            label="Głuchoniewidoma"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
