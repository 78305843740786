import React, { useEffect } from "react";
import { Platform } from "react-native";

import { mediaDevices } from "react-native-webrtc-web-shim";

const VIDEO_CONSTRAINTS = {
  audio: true,
  video: true,
};

const VIDEO_CONSTRAINTS_CONFCALL = {
  audio: true,
  video: true,
};

export type LocalStreamContextType = {
  localStream: MediaStream | undefined;
  getUserMedia: (constraints: {}) => void;
  getConfCallUserMedia: () => void;
  releaseUserMedia: () => void;
};

// Context has been created
const LocalStreamContext = React.createContext<LocalStreamContextType>(
  {} as LocalStreamContextType
);

// Provider
const LocalStreamProvider = ({ children }) => {
  const [localStream, setLocalSteam] = React.useState<MediaStream>();
  let releaseMediaFlag = false;

  const getUserMedia = async (constraints: {}) => {
    try {
      console.log(`LocalStreamProvider, calling getUserMedia`);

      if (Platform.OS === "web") {
        const supportedConstraints = mediaDevices.getSupportedConstraints();

        for (const constraint of Object.keys(supportedConstraints)) {
          console.debug(`supported constraint: ${constraint}`);
        }
      }
      const stream = await mediaDevices.getUserMedia(
        constraints ? constraints : VIDEO_CONSTRAINTS
      );

      console.log(`LocalStreamProvider, got stream: ${stream}`);

      if (stream && releaseMediaFlag) {
        console.log(
          `releaseMediaFlag, ${stream ? stream.getTracks().length : "?"}`
        );
        releaseMediaFlag = false;
        stream.getTracks().forEach((element: MediaStreamTrack) => {
          console.log(`stopping ${element}`);
          element.stop();
        });
        setLocalSteam(undefined);
      } else {
        setLocalSteam(stream);
      }
    } catch (error) {
      console.error("Error during getUserMedia", error);
      console.error(error);
      console.error(`initLocalVideo `, error);
      alert(
        `Brak dostępu do kamery/mikrofonu. Upewnij się, że urządzenia są włączone i nie blokowane przez przeglądarkę. Błąd z przeglądarki internetowej: "${error.message}"`
      );
    }
  };

  const getConfCallUserMedia = async () => {
    try {
      console.log(`LocalStreamProvider, calling getUserMedia`);

      const stream = await mediaDevices.getUserMedia(
        VIDEO_CONSTRAINTS_CONFCALL
      );
      console.log(`LocalStreamProvider, got stream: ${stream}`);

      if (releaseMediaFlag) {
        console.log(`releaseMediaFlag, ${stream.getTracks().length}`);
        releaseMediaFlag = false;
        stream.getTracks().forEach((element: MediaStreamTrack) => {
          console.log(`stopping ${element}`);
          element.stop();
        });
        setLocalSteam(undefined);
      } else {
        setLocalSteam(stream);
      }
    } catch (error) {
      console.error("Error during getUserMedia", error);
      console.error(error);
      console.error(`initLocalVideo `, error);
      alert(
        `Brak dostępu do kamery/mikrofonu. Upewnij się, że urządzenia są włączone i nie blokowane przez przeglądarkę. Błąd z przeglądarki internetowej: "${error.message}"`
      );
    }
  };

  const releaseUserMedia = async () => {
    console.debug(`trying to release user media... ${localStream}`);

    if (!localStream) {
      console.debug(`to late localStream is undefined...`);
    } else
      localStream?.getTracks().forEach((element) => {
        element.stop();
      });
  };

  return (
    <LocalStreamContext.Provider
      value={{
        localStream,
        getUserMedia,
        getConfCallUserMedia,
        releaseUserMedia,
      }}
    >
      {children}
    </LocalStreamContext.Provider>
  );
};
export { LocalStreamContext, LocalStreamProvider };
