import * as React from "react";
import { Text } from "react-native";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FAB, Portal } from "react-native-paper";
import { useIsFocused } from "@react-navigation/native";
import CustomDialog from "../../components/CustomDialog";
import { useNavigation } from "@react-navigation/native";
import { deleteItem, getAll } from "../../services/translation.service";
import { AuthContext } from "../../auth-context";
import _ from "lodash";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Loading } from "../../components/Loading";
import { mapTranslationStatus } from "../../frontend_domain";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { plPL } from "../../localization/plPL";
import baseStyles from "../../styles/base";
import { handleError } from "../../utils";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { localizedDateComparator } from "../../services/localized.service";

const theme = createTheme({}, plPL);

const Translations = () => {
  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [results, setResults] = React.useState([]);

  console.log(`navigation translations ${JSON.stringify(navigation)}`);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");

  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  React.useEffect(() => {
    releaseUserMedia();
  }, []);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const headerNames = new Map();
  headerNames.set("_id", "ID");
  headerNames.set("documentFileName", "Nazwa pliku");
  headerNames.set("translationFileName", "Nazwa tłumaczenia");
  headerNames.set("status", "Status");
  headerNames.set("description", "Opis dokumentu");

  const columns: GridColumns = [
    { field: "_id", headerName: headerNames.get("_id"), width: 50 },
    {
      field: "actions",
      type: "actions",
      headerName: "Akcje",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        // console.log(`id: ${id}, row: ${row.description}`);

        if (auth?.isAdmin)
          return [
            editAction(row, editItem, id),
            <GridActionsCellItem
              icon={<DeleteIcon />}
              data-testid={"delete_" + row.description}
              label="Delete"
              onClick={() => {
                handleDeleteClick(id);
              }}
              color="inherit"
            />,
          ];
        else return [editAction(row, editItem, id)];
      },
    },
    {
      field: "userId",
      headerName: "Użytkownik",
      type: "string",
      editable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<string>) => {
        // console.log(`userId params ${JSON.stringify(params.row.userId)}`);

        const value =
          params.row.userId &&
          params.row.userId.firstName &&
          params.row.userId.lastName
            ? params.row.userId.firstName + " " + params.row.userId.lastName
            : "";
        return (
          <Text testID={params.row.userId} style={{ marginLeft: 16 }}>
            {`${value}`}
          </Text>
        );
      },
    },
    {
      field: "description",
      headerName: headerNames.get("description"),
      type: "string",
      editable: false,
      width: 200,
    },
    {
      field: "status",
      headerName: headerNames.get("role"),
      width: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Text
          style={{
            color: params.value === "submitted" ? "red" : undefined,
          }}
        >
          {mapTranslationStatus(params.value)}
        </Text>
      ),
    },
    {
      field: "documentFileName",
      headerName: headerNames.get("documentFileName"),
      type: "string",
      editable: false,
      width: 200,
    },
    {
      field: "translationFileName",
      headerName: headerNames.get("translationFileName"),
      type: "string",
      editable: false,
      width: 200,
    },

    {
      field: "createdAt",
      headerName: "Data utworzenia",
      editable: false,
      type: "string",
      align: "right",
      width: 160,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "submittedAt",
      headerName: "Data wysłania pliku",
      editable: false,
      type: "string",
      align: "right",
      width: 160,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "inProgressAt",
      headerName: "Data rozpoczęcia tłumaczenia",
      editable: false,
      type: "string",
      align: "right",
      width: 160,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "toVerifyAt",
      headerName: "Data wysłania do weryfikacji",
      editable: false,
      type: "string",
      align: "right",
      width: 160,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "translatedAt",
      headerName: "Data tłumaczenia",
      editable: false,
      type: "string",
      align: "right",
      width: 160,
      sortComparator: localizedDateComparator,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
  ];

  const hideDialog = () => {
    setDialogVisible(false);
  };
  const handleDeleteClick = (id: string) => {
    setshowSpinner(true);
    deleteItem(id)
      .then((response) => {
        console.log(`[Translations] translation deleted:`, response);

        getAll()
          .then((freshItems) => {
            setshowSpinner(false);
            console.log(
              `[Translations] useEffect (with auth), freshUsers:`,
              freshItems
            );
            setResults(freshItems);
          })
          .catch((error) => {
            setshowSpinner(false);
            console.log("error:", error);
            alert("Błąd pobierania aktualnej listy tłumaczeń");
          });
      })
      .catch((err) => {
        setshowSpinner(false);
        console.log("error:", err);
        alert("Błąd w trakcie usuwania tłumaczenia");
      });
  };
  const editItem = (id: string) => {
    console.log(`edit item... ${id}`);
    //setDialogVisible(true);
    navigation.navigate("Translation", { translationId: id });
  };

  const newItem = () => {
    console.log("new item");
    navigation.navigate("Translation");
  };

  React.useEffect(() => {
    if (_.isEmpty(auth)) {
      return;
    } else {
      console.log("[Translation] useEffect (with auth), auth:", auth);
      setshowSpinner(true);
      if (results.length === 0) {
        console.log("fetching data using ", auth);

        getAll()
          .then((results) => {
            setshowSpinner(false);
            console.log(`[Resources] useEffect (with auth), results:`, results);
            setResults(results);
          })
          .catch((error) => {
            handleError(
              setshowSpinner,
              error,
              setDialogErrorMessage,
              setDialogErrorVisible
            );
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, [auth]);

  return (
    // {/* <Surface> */}
    <div style={{ height: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <Portal>
          <FAB
            visible={isFocused}
            icon="plus"
            testID="create_item"
            style={baseStyles.addItemFab}
            onPress={newItem}
          />
        </Portal>
        <DataGrid
          style={{ backgroundColor: "#fff" }}
          rows={results}
          getRowId={(row) => row._id}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
        />
      </ThemeProvider>
    </div>
  );
};

export default Translations;
function editAction(row: any, editItem: (id: string) => void, id) {
  return (
    <GridActionsCellItem
      data-testid={"edit_" + row.description}
      icon={<EditIcon />}
      label="Edit"
      className="textPrimary"
      onClick={() => {
        editItem(id);
      }}
      color="inherit"
    />
  );
}
