import React, { useContext, useRef, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Platform,
} from "react-native";
import { styles } from "./styles";
import { Autosave } from "react-autosave";
import { DebugContext } from "../../debug-context";
import CustomDialog from "../../components/CustomDialog";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AgeRangeComponent } from "./AgeRange";
import { WorkStatus as WorkStatusEnum } from "../../frontend_domain";

import { GenderComponent } from "./Gender";
import { YesNoComponent } from "./YesNo";
import { Address } from "./Address";
import { SelfDeafTypeComponent } from "./SelfDeafTypeComponent";
import { SelfCommunicative } from "./SelfCommunicative";
import { SelfDisabilityTypeAssessment } from "./SelfDisabilityTypeAssessment";
import { SelfPolishLevelAssesment } from "./SelfPolishAssesment";
import { WorkStatus } from "./WorkStatus";
import { SensitiveQuestionComponent } from "./SensitiveQuestionComponent";
import { CountyDisabilityLevel } from "./CountyDisabilityLevel";
import { ZusDisabilityLevel } from "./ZusDisabilityLevel";
import { KrusDisabilityLevel } from "./KrusDisabilityLevel";
import { DisabilityDatesComponent } from "./DisbilityDatesComponent";
import { EducationComponent } from "./EducationComponent";
import { EmployerComponent } from "./EmployerComponent";
import { EconomicActivityComponent } from "./EconomicActivityComponent";

import {
  createResource,
  getResource,
  getResourceByEmail,
  updateResource,
} from "../../services/participationforms.service";
import { validatePolish } from "validate-polish";
import { AuthContext, AuthContextType } from "../../auth-context";
import { Loading } from "../../components/Loading";
import LABELS from "./labels";
import GLOBAL_LABELS from "../../labels";

import { handleError } from "../../utils";
import "dayjs/locale/pl";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import _ from "lodash";
import { CheckboxComponent } from "./CheckboxComponent";
import { RouteProps } from "react-router";
import axios from "axios";

type ParticipationFormProps = {
  userId: string;
  email: string;
  resourceId: string;
  visible?: boolean;
  autosave?: boolean;
  route: RouteProps;
  transcription?: boolean;
};

const ParticipationFormView = React.forwardRef(
  (
    {
      email,
      visible,
      autosave,
      resourceId,
      route,
      transcription,
    }: ParticipationFormProps,
    ref
  ) => {
    const { auth } = React.useContext(AuthContext) as AuthContextType;
    const scrollViewRef = useRef();
    const [showSpinner, setshowSpinner] = React.useState(false);
    const [quickSaveIndication, setQuickSaveIndication] = React.useState(false);

    const [formAutoSave, setFormAutosave] = React.useState(false);
    const [formVisible, setFormVisible] = React.useState(false);
    const [formPadding, setFormPadding] = React.useState(false);

    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
    const [dialogErrorMessage, setDialogErrorMessage] = useState("");

    // @ts-ignore
    const [resource, setResource] = React.useState<ParticipationForm>({});

    const debug = useContext(DebugContext).debug;
    const [errors, setErrors] = React.useState({});
    const [validationMessages, setValidationMessages] = React.useState({});
    const headerHeight = Platform.OS === "web" ? 64 : 24;

    React.useEffect(() => {
      async function load() {
        //  console.log("!!!!ParticipationFormView useEffect props: ", props);

        let naviEmail;
        let naviResourceId;

        setFormAutosave(
          autosave
            ? autosave
            : route && route.params
            ? route.params.autosave
            : false
        );

        if (!email)
          naviEmail = route && route.params ? route.params.email : undefined;

        if (!resourceId)
          naviResourceId = route && route.params ? route.params.id : undefined;

        // const resourceId = props.route.params ? props.route.params.id : undefined;

        // const email =
        let resource;

        if (resourceId) {
          console.log(`await getResource(resourceId);`);
          setFormVisible(true);
          resource = await getResource(resourceId);
        } else if (naviResourceId) {
          console.log(`await getResource(naviResourceId); ${naviResourceId}`);
          setFormVisible(true);
          setFormPadding(true);
          resource = await getResource(naviResourceId);
        } else if (email) {
          console.log(`await getResourceByEmail(email);`);
          resource = await getResourceByEmail(email);
          console.log(`await ${JSON.stringify(resource)}`);
        } else if (naviEmail) {
          console.log(`await getResourceByEmail(naviEmail);`);
          resource = await getResourceByEmail(naviEmail);
          setFormVisible(true);
          setFormPadding(true);
        }

        setshowSpinner(false);

        if (resource) {
          console.log(`[ParticipationFormView] resource ${resource._id}`);
          // console.log(`[ParticipationFormView] ${resource._id}`);
          console.log(
            `[ParticipationFormView] createdAt: ${resource.createdAt}`
          );

          for (const attribute in resource) {
            // console.log(`setAttribute ${attribute}: ${resource[attribute]}`);

            if (attribute.endsWith("At") && attribute) {
              console.log(`updating format for ${attribute}`);
              resource[attribute] === resource[attribute]
                ? new Date(resource[attribute]).toLocaleString("pl-PL")
                : "";
            }
          }
          setResource(resource);
        }
      }

      load();
    }, [email, autosave]);

    const hideDialogError = () => {
      console.log(`close hideDialogError`);
      setDialogErrorVisible(false);
    };

    const validate = () => {};

    const validateWholeForm = () => {
      let validationErrors = {};
      let helperMessages = {};

      if (_.isEmpty(resource?.userId)) {
        validationErrors["userId"] = true;
        helperMessages["userId"] = 'Wybierz użytkownika"';
      }

      console.log(`current pesel ${resource.pesel}`);
      if (!_.isEmpty(resource?.pesel)) {
        if (!validatePolish.pesel(resource?.pesel)) {
          validationErrors["pesel"] = true;
          helperMessages["pesel"] = 'Nieprawidłowy PESEL"';
        }
      }

      if (resource?.kk_confirmed !== true)
        validationErrors["kk_confirmed"] = true;
      if (_.isEmpty(resource?.kkEvidenceBy))
        validationErrors["kkEvidenceBy"] = true;
      if (_.isEmpty(resource?.phone)) validationErrors["phone"] = true;
      if (_.isEmpty(resource?.birth_date))
        validationErrors["birth_date"] = true;
      if (_.isEmpty(resource?.gender)) validationErrors["gender"] = true;
      if (_.isEmpty(resource?.pesel)) validationErrors["pesel"] = true;
      if (_.isEmpty(resource?.age_range)) validationErrors["age_range"] = true;
      if (_.isEmpty(resource?.address_street))
        validationErrors["address_street"] = true;
      if (_.isEmpty(resource?.address_house_no))
        validationErrors["address_house_no"] = true;
      if (_.isEmpty(resource?.postal_code))
        validationErrors["postal_code"] = true;
      if (_.isEmpty(resource?.city)) validationErrors["city"] = true;
      if (_.isEmpty(resource?.county)) validationErrors["county"] = true;
      if (_.isEmpty(resource?.community)) validationErrors["community"] = true;
      if (_.isEmpty(resource?.address_type))
        validationErrors["address_type"] = true;
      if (_.isEmpty(resource?.voivodeship))
        validationErrors["voivodeship"] = true;

      console.log(`validation disability ${resource?.disability}`);
      if (!(resource?.disability === true || resource?.disability === false)) {
        validationErrors["disability"] = true;
        console.log("disability? ", resource?.disability);
      }

      if (_.isEmpty(resource?.polish_level))
        validationErrors["polish_level"] = true;

      if (
        !(
          resource?.disability_01 ||
          resource?.disability_02 ||
          resource?.disability_03 ||
          resource?.disability_04 ||
          resource?.disability_05 ||
          (resource.disability_06 && resource?.disability_06 === true) ||
          resource?.disability_07 ||
          resource?.disability_08 ||
          resource?.disability_09 ||
          resource?.disability_10 ||
          resource?.disability_11 ||
          resource?.disability_12
        )
      ) {
        console.log(`set self_disability_level error`);
        // validationErrors["disability_06"] = resource && resource.disability_06;
        validationErrors["self_disability_level"] = true;
      } else {
        console.log(`remove self_disability_level error`);
        delete validationErrors["self_disability_level"];
      }

      if (_.isEmpty(resource?.county_disability_level))
        validationErrors["county_disability_level"] = true;
      if (_.isEmpty(resource?.zus_disability_level))
        validationErrors["zus_disability_level"] = true;
      if (_.isEmpty(resource?.krus_disability_level))
        validationErrors["krus_disability_level"] = true;
      if (_.isEmpty(resource?.work_status))
        validationErrors["work_status"] = true;

      if (_.isEmpty(resource?.ethnical_or_national_minority))
        validationErrors["ethnical_or_national_minority"] = true;

      if (
        !(
          resource?.homeless_or_impacted_by_homelessness === true ||
          resource?.homeless_or_impacted_by_homelessness === false
        )
      ) {
        validationErrors["homeless_or_impacted_by_homelessness"] = true;
        console.log(
          "homeless_or_impacted_by_homelessness? ",
          resource?.homeless_or_impacted_by_homelessness
        );
      }

      if (_.isEmpty(resource?.other_situation))
        validationErrors["other_situation"] = true;

      if (_.isEmpty(resource?.education)) validationErrors["education"] = true;
      if (_.isEmpty(resource?.date_of_education_finish))
        validationErrors["date_of_education_finish"] = true;

      if (resource.work_status === "Osoba pracująca") {
        if (_.isEmpty(resource?.type_of_employment))
          validationErrors["type_of_employment"] = true;
        if (_.isEmpty(resource?.employer_name))
          validationErrors["employer_name"] = true;
        if (_.isEmpty(resource?.employer_address))
          validationErrors["employer_address"] = true;

        if (_.isEmpty(resource?.employer_sector))
          validationErrors["employer_sector"] = true;
        if (_.isEmpty(resource?.employer_size))
          validationErrors["employer_size"] = true;
        if (_.isEmpty(resource?.employer_disability_type))
          validationErrors["employer_disability_type"] = true;
      }

      if (resource.work_status === "Osoba prowadząca działalność gospodarczą") {
        if (_.isEmpty(resource?.economic_activity_start))
          validationErrors["economic_activity_start"] = true;
        if (_.isEmpty(resource?.economic_activity_name))
          validationErrors["economic_activity_name"] = true;
        if (_.isEmpty(resource?.economic_activity_address))
          validationErrors["economic_activity_address"] = true;
        if (_.isEmpty(resource?.economic_activity_size))
          validationErrors["economic_activity_size"] = true;
        if (_.isEmpty(resource?.economic_activity_sector))
          validationErrors["economic_activity_sector"] = true;
      }

      if (
        _.isEmpty(resource?.kkEvidenceBy) ||
        (resource?.kkEvidenceBy && resource?.kkEvidenceBy.length === 0)
      ) {
        validationErrors["kkEvidenceBy"] = true;
        helperMessages["kkEvidenceBy"] =
          'Potwierdzenie przez tłumacza jest wymagane"';
      }

      if (!_.isEmpty(validationErrors)) {
        console.log(`errors:  ${JSON.stringify(validationErrors)}`);
        const firstErrorId = Object.keys(validationErrors)[0];
        console.log(`first error to scroll to:  ${firstErrorId}`);
        const element = document.getElementById(firstErrorId);
        if (element) {
          console.log(`scrolling to ... ${firstErrorId}`);
          // xman doesn't work every each time
          // element.scrollIntoView({ behavior: "smooth" });
          element.scrollIntoView();
        } else {
          console.log(`element ${firstErrorId} not found`);
        }
      }

      setErrors(validationErrors);
      setValidationMessages(helperMessages);

      console.log(`validation result ${JSON.stringify(helperMessages)}`);
      console.log(`validationErrors ${JSON.stringify(validationErrors)}`);

      if (Object.keys(validationErrors).length === 0) return true;
      else return false;
    };

    // show save info
    const save = async (isCompleted: boolean, showConfirmation: boolean) => {
      try {
        console.log(`save with validation`);
        console.log(`executing request, autosave? ${formAutoSave}`);

        let result;
        setQuickSaveIndication(true);
        console.log(`resource? ${resource?._id}`);
        if (resource?._id) {
          result = await updateResource({
            ...resource,
            completed: isCompleted,
          });

          console.log(`resource update answer? ${JSON.stringify(result)}`);
          console.log(`resource update completed? ${result.data.completed}`);
          console.log(
            `resource update typeof completed? ${typeof result.data.completed}`
          );
          setResource({
            ...resource,
            completed: result.data.completed,
            completedAt:
              result.data.completed === true
                ? result.data.completedAt
                : undefined,
          });
        } else result = await createResource(resource);

        if (showConfirmation) {
          setDialogMessage("Zapisano zmiany.");
          setDialogVisible(true);
        }

        console.log(`request done ${JSON.stringify(resource)}`);
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response.data.error ===
            "Translator is trying to modify completed participation form"
        ) {
          console.warn(error.response.data.error);
        } else
          handleError(
            setshowSpinner,
            error,
            setDialogErrorMessage,
            setDialogErrorVisible
          );
      }
    };

    const hideDialog = () => {
      setDialogVisible(false);
      setshowSpinner(false);
    };

    let kkClause1;
    let kkClause2;

    if (resource && resource.userId) {
      kkClause1 = `Ja ${resource.userId.firstName} ${resource.userId.lastName} zostałem pouczony i jestem świadomy/a odpowiedzialności karnej za składanie fałszywych oświadczeń.`;
    }
    if (auth?.firstName && auth?.lastName) {
      kkClause2 = `Stwierdzono zgodność osoby weryfikowanej z okazanym dokumentem tożsamości.`;
    }

    console.log(
      `current status ${typeof resource.completed} ${resource.completed}`
    );

    return (
      <SafeAreaView
        nativeID="participation_form_save_area_view"
        style={[
          styles.body,
          {
            backgroundColor: debug ? "yellow" : "undefined",
            // visible works in call ,formVisible in normal edit mode
            display: visible || formVisible ? "flex" : "none",
            flexGrow: transcription ? 3 : 1,
            paddingLeft: formPadding === true ? 100 : 0,
            paddingRight: formPadding === true ? 100 : 0,
          },
        ]}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pl"}>
          <ScrollView
            ref={ref}
            onContentSizeChange={(contentWidth, contentHeight) => {
              // scrollViewRef.current.scrollToEnd({ animated: false });
            }}
            style={{
              // backgroundColor: "pink",
              backgroundColor: "white",
              // height: 000,
              padding: 0,
              top: 0,
              borderStyle: quickSaveIndication ? "solid" : undefined,
              borderColor: quickSaveIndication ? "green" : undefined,
              borderWidth: quickSaveIndication ? 2 : formAutoSave ? 2 : 0,
            }}
          >
            {showSpinner === true && <Loading />}
            {formAutoSave && !(auth?.isTranslator && resource?.completed) && (
              <Autosave
                data={resource}
                interval={10000}
                onSave={() => {
                  console.log(`resource.completed ${resource.completed}`);
                  if (resource.completed !== true) {
                    console.log(`save via autosave (every 10s)`);
                    save(resource.completed, false);
                  }
                }}
              />
            )}
            {/* errors */}
            <CustomDialog
              visible={dialogErrorVisible}
              hideDialog={hideDialogError}
              dialogTitle={"Błąd"}
              dialogContent={dialogErrorMessage}
              testID="error_dialog"
            />
            {/* confirmation */}
            <CustomDialog
              visible={dialogVisible}
              hideDialog={hideDialog}
              dialogTitle={""}
              dialogContent={"Zapisano zmiany"}
            />
            {!resource && <></>}
            {resource && (
              <View
                testID="fields"
                style={[
                  { padding: 5 },
                  debug === true ? { backgroundColor: "blue" } : undefined,
                ]}
              >
                <Grid container style={{ minHeight: 10 }} spacing={0}>
                  <Grid item xs={12}>
                    <Typography paragraph={true} variant="body1">
                      {kkClause1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography paragraph={true} variant="body1">
                      {kkClause2}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxComponent
                      name="kk_confirmed"
                      label="Oświadczenie o odpowiedzialności karnej"
                      resource={resource}
                      setResource={setResource}
                      errors={errors}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      id="kkEvidenceBy"
                      required={true}
                      error={errors["kkEvidenceBy"] ? true : false}
                      // rules={{ required: true }}
                      fullWidth
                      label="Potwierdzone przez:"
                      value={
                        resource && resource.kkEvidenceBy
                          ? resource.kkEvidenceBy
                          : ""
                      }
                      onBlur={() => {
                        if (_.isEmpty(resource?.kkEvidenceBy))
                          setErrors({ ...errors, kkEvidenceBy: true });
                        else setErrors({ ...errors, kkEvidenceBy: false });
                      }}
                      onChange={(event) => {
                        setResource({
                          ...resource,
                          kkEvidenceBy: event.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography paragraph={true} variant="h6">
                    {LABELS.GDPR_CLAUSE_HEADER}
                  </Typography>
                  <Typography paragraph={true} variant="body1">
                    {LABELS.GDPR_CLAUSE_TEXT}
                  </Typography>
                </Grid>

                {/* metadane */}
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      fullWidth
                      label="Data utworzenia"
                      name="createdAt"
                      value={
                        resource
                          ? new Date(resource.createdAt).toLocaleString("pl-PL")
                          : ""
                      }
                      disabled={true}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      fullWidth
                      label="Data modyfikacji"
                      name="updatedAt"
                      disabled={true}
                      value={
                        resource
                          ? new Date(resource.updatedAt).toLocaleString("pl-PL")
                          : ""
                      }
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label="Stworzone przez"
                      margin="dense"
                      name="createdBy"
                      disabled={true}
                      fullWidth
                      value={
                        resource && resource.createdBy
                          ? resource.createdBy?.firstName +
                            " " +
                            resource.createdBy?.lastName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Zmienione przez"
                      name="updatedBy"
                      margin="dense"
                      disabled={true}
                      fullWidth
                      value={
                        resource && resource.updatedBy
                          ? resource.updatedBy?.firstName +
                            " " +
                            resource.updatedBy?.lastName
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  {/* użytkownik */}
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      label="1. Imię"
                      fullWidth
                      name="name"
                      value={
                        resource && resource.userId
                          ? resource.userId.firstName
                          : ""
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="none"
                      label="2. Nazwisko"
                      fullWidth
                      name="name"
                      value={
                        resource && resource.userId
                          ? resource.userId.lastName
                          : ""
                      }
                      disabled={true}
                    />
                  </Grid>
                  {/* telefon */}
                  <Grid item xs={12}>
                    <TextField
                      id="phone"
                      fullWidth
                      label="3. Numer telefonu"
                      onBlur={() => {
                        if (_.isEmpty(resource?.phone))
                          setErrors({ ...errors, phone: true });
                        else setErrors({ ...errors, phone: false });
                      }}
                      error={errors["phone"] ? true : false}
                      required={true}
                      value={resource && resource.phone ? resource.phone : ""}
                      onChange={(event) => {
                        setResource({
                          ...resource,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </Grid>
                  {/* email */}
                  <Grid
                    item
                    xs={12}
                    style={{ overflow: "hidden", margin: "0" }}
                  >
                    <TextField
                      // margin="none"
                      label="4. email"
                      fullWidth
                      name="email"
                      value={
                        resource && resource.userId && resource.userId.email
                          ? resource.userId.email
                          : ""
                      }
                      disabled={true}
                    />
                  </Grid>

                  {/* Data Urodzenia */}
                  <Grid item xs={12}>
                    <DesktopDatePicker
                      disableFuture={true}
                      label="5. Data urodzenia *"
                      views={["year", "month", "day"]}
                      value={
                        resource && resource.birth_date
                          ? resource.birth_date
                          : ""
                      }
                      onChange={(newValue) => {
                        setResource({
                          ...resource,
                          birth_date: newValue,
                        });
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            id={"birth_date"}
                            margin="none"
                            fullWidth
                            onBlur={validate}
                            error={errors["birth_date"] ? true : false}
                            // label="5. Data urodzenia *"
                            {..._.omit(params, ["error"])}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {/* Płeć */}
                  <GenderComponent
                    errors={errors}
                    resource={resource}
                    setResource={setResource}
                  />
                  {/* Pesel */}
                  <Grid item xs={12}>
                    <TextField
                      margin="none"
                      required={true}
                      id="pesel"
                      fullWidth
                      onBlur={validate}
                      label="7. PESEL"
                      error={errors["pesel"] ? true : false}
                      value={resource && resource.pesel ? resource.pesel : ""}
                      onChange={(event) => {
                        console.log(
                          `pesel  ${JSON.stringify(event.target.value)}`
                        );
                        setResource({
                          ...resource,
                          pesel: event.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <AgeRangeComponent
                    errors={errors}
                    onBlur={validate}
                    resource={resource}
                    setResource={setResource}
                  />
                </Grid>

                <Address
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />

                <YesNoComponent
                  fieldName="disability"
                  label="12. Osoba z niepełnosprawnością"
                  resource={resource}
                  errors={errors}
                  // setErrors={set}
                  setResource={setResource}
                ></YesNoComponent>
                <SelfDisabilityTypeAssessment
                  resource={resource}
                  errors={errors}
                  setResource={setResource}
                />
                <SelfDeafTypeComponent
                  resource={resource}
                  errors={errors}
                  setResource={setResource}
                />
                <SelfCommunicative
                  resource={resource}
                  errors={errors}
                  setResource={setResource}
                />

                <SelfPolishLevelAssesment
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />

                <WorkStatus
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />

                <SensitiveQuestionComponent
                  fieldName="ethnical_or_national_minority"
                  label="18. Osoba należąca do mniejszości narodowej lub etnicznej *"
                  resource={resource}
                  errors={errors}
                  setResource={setResource}
                />

                <YesNoComponent
                  fieldName="homeless_or_impacted_by_homelessness"
                  label="19. Osoba bezdomna lub dotknięta wykluczeniem z dostępu do mieszkań *"
                  resource={resource}
                  errors={errors}
                  setResource={setResource}
                />

                <SensitiveQuestionComponent
                  fieldName="other_situation"
                  label="20. Osoba w innej niekorzystnej sytuacji:"
                  resource={resource}
                  errors={errors}
                  setResource={setResource}
                />

                <CountyDisabilityLevel
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />
                <ZusDisabilityLevel
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />
                <KrusDisabilityLevel
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />

                <DisabilityDatesComponent
                  resource={resource}
                  errors={errors}
                  onBlur={validate}
                  setResource={setResource}
                />

                <EducationComponent
                  resource={resource}
                  onBlur={validate}
                  errors={errors}
                  setResource={setResource}
                />

                {resource.work_status === WorkStatusEnum.EMPLOYED && (
                  <EmployerComponent
                    resource={resource}
                    onBlur={validate}
                    errors={errors}
                    required={resource.work_status === "Osoba pracująca"}
                    setResource={setResource}
                  />
                )}

                {resource.work_status !== WorkStatusEnum.EMPLOYED && (
                  <>
                    <Typography variant="body1">
                      {LABELS._26} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._27} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._28} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._29} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._30} {" - Nie dotyczy"}
                    </Typography>
                  </>
                )}

                {resource.work_status ===
                  WorkStatusEnum.HAVING_ECONOMIC_ACTIVITY && (
                  <EconomicActivityComponent
                    resource={resource}
                    onBlur={validate}
                    errors={errors}
                    required={
                      resource.work_status ===
                      "Osoba prowadząca działalność gospodarczą"
                    }
                    setResource={setResource}
                  />
                )}

                {resource.work_status !=
                  WorkStatusEnum.HAVING_ECONOMIC_ACTIVITY && (
                  <>
                    <Typography variant="body1">
                      {LABELS._31} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._32} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._33} {" - Nie dotyczy"}
                    </Typography>
                    <Typography variant="body1">
                      {LABELS._34} {" - Nie dotyczy"}
                    </Typography>
                  </>
                )}

                <Grid item xs={12}>
                  <CheckboxComponent
                    disabled={false}
                    name="refugee"
                    label="Status uchodźcy"
                    resource={resource}
                    setResource={setResource}
                  />
                </Grid>
                {/* dodatkowe informacje */}
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    multiline={true}
                    rows={3}
                    label="Dodatkowe informacje"
                    fullWidth
                    id="additional_info"
                    name="additional_info"
                    value={
                      resource && resource.additional_info
                        ? resource.additional_info
                        : ""
                    }
                    onChange={(event) => {
                      setResource({
                        ...resource,
                        additional_info: event.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                  style={{
                    paddingBottom: 60,
                    /*   borderStyle: "solid",
                        borderColor: "gray",
                        borderWidth: 2,
                        margin: "1",
                        padding: "1", */
                  }}
                >
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={6}
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          id={"completed"}
                          control={
                            <Checkbox
                              size={"small"}
                              id={"completed_checkbox"}
                              checked={
                                resource && resource.completed === true
                                  ? true
                                  : false
                              }
                              disabled={
                                resource.completed && auth?.isTranslator
                              }
                              onChange={(event) => {
                                const validated = validateWholeForm();
                                if (validated === true) {
                                  /* setResource({
                                    ...resource,
                                    completed: event.target.checked,
                                  });
 */
                                  if (autosave) {
                                    console.log(
                                      `save via onChange completed without notification ${event.target.checked}`
                                    );
                                    save(event.target.checked, false);
                                  } else {
                                    console.log(
                                      `save via onChange completed _with_ notification ${event.target.checked}`
                                    );
                                    save(event.target.checked, true);
                                  }
                                }
                              }}
                            />
                          }
                          label={"Formularz jest kompletny"}
                        />
                      </FormGroup>
                    </Grid>

                    <Typography
                      paragraph={true}
                      variant="body1"
                      style={{ color: "red" }}
                    >
                      {!_.isEmpty(errors)
                        ? "Niektóre pola nie zostały wypełnione."
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="dense"
                      fullWidth
                      label="Data zakończenia"
                      name="completedAt"
                      value={
                        resource.completedAt
                          ? new Date(resource.completedAt).toLocaleString(
                              "pl-PL"
                            )
                          : ""
                      }
                      disabled={true}
                    ></TextField>
                  </Grid>

                  <Grid item xs={2}></Grid>
                  <Grid item xs={8} className="no-print">
                    {!auth?.isUser &&
                      !(auth?.isTranslator && resource?.completed) && (
                        <View
                          style={[
                            debug === true
                              ? { backgroundColor: "orange" }
                              : undefined,
                            styles.btnPrimary,
                          ]}
                        >
                          <TouchableOpacity
                            onPress={() => save(resource?.completed, true)}
                            testID={"button_update"}
                          >
                            <Text style={styles.buttonText}>
                              {GLOBAL_LABELS.save}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    <Grid item xs={4}></Grid>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </View>
            )}
          </ScrollView>
        </LocalizationProvider>
      </SafeAreaView>
    );
  }
);

export default ParticipationFormView;
