import React, { useEffect } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import { FAB, Portal } from "react-native-paper";
import { styles } from "./styles";
import baseStyles from "../../styles/base";
import { RegistrationInfoProps } from "../../navigation/types";
import RegistrationInfoView from "../../components/Registration/RegistrationInfoView";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { useReactToPrint } from "react-to-print";

export default function RegistrationInfo(props: RegistrationInfoProps) {
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => {
      console.log("printing...");
      return componentRef.current;
    },
  });

  useEffect(() => {
    releaseUserMedia();
  }, []);

  return (
    <SafeAreaView style={[styles.body]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={[{ backgroundColor: "lightBlue" }]}
      >
        <Portal>
          <FAB
            // visible={isFocused}
            icon="printer-outline"
            testID="printer-outline"
            style={baseStyles.rightTop}
            onPress={handlePrint}
          />
        </Portal>

        <RegistrationInfoView
          ref={componentRef}
          containerStyle={styles.inputContainerMobile}
          {...props}
        />
      </ScrollView>
    </SafeAreaView>
  );
}
