import { useState, useEffect } from "react";

const SMALL_SCREEN_WIDTH = 600;
const SHORT_SCREEN_WIDTH = 1000;

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: -1,
    height: -1,
    smallScreen: false,
    shortScreen: false,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        smallScreen: window.innerWidth < SMALL_SCREEN_WIDTH,
        shortScreen: window.innerHeight < SHORT_SCREEN_WIDTH,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
