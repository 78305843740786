import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  Voivodeship as VoivodeshipTypeEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface Voivodeship {
  id: string;
  label: string;
}

export const voivodeshipTypeOptions = Object.keys(VoivodeshipTypeEnum).map(
  (key) => ({
    id: key,
    label: VoivodeshipTypeEnum[key],
  })
);

interface ComponentProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const VoivodeshipComponent = ({
  resource,
  errors,
  setResource,
  onBlur,
}: ComponentProps) => {
  return (
    <Grid item xs={12}>
      <Autocomplete
        options={voivodeshipTypeOptions}
        disablePortal
        id="voivodeship"
        value={resource.voivodeship ? resource.voivodeship : ""}
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            fullWidth
            required
            error={errors ? errors["voivodeship"] : false}
            onBlur={onBlur}
            label="11. Województwo"
          />
        )}
        onChange={(event: any, newValue: Voivodeship) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            voivodeship: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
