import { SupportFormDocument } from "../../frontend_domain";

interface StakeholderType {
  id: string;
  label: string;
}

const stakeholders = [
  {
    id: "stakeholders_employer",
    label: "pracodawcy Uczestników",
  },
  {
    id: "stakeholders_recruitmentTeam",
    label: "osoby rekrutujące Uczestników do pracy",
  },
  {
    id: "stakeholders_managers",
    label: "kierownicy/przełożeni Uczestników",
  },
  {
    id: "stakeholders_coworkers",
    label: "współpracownicy Uczestników",
  },
  {
    id: "stakeholders_medical",
    label: "lekarze medycyny pracy konsultujący Uczestników",
  },
  {
    id: "stakeholders_healthAndSafety",
    label: "inspektorzy/specjaliści ds. BHP szkolący Uczestników",
  },
  {
    id: "stakeholders_contractor",
    label: "kontrahenci lub potencjalni kontrahenci  Uczestników",
  },
  {
    id: "stakeholders_other",
    label: "inne osoby/instytucje",
  },
  {
    id: "",
    label: "",
  },
];

export const stakeholdersOptions = {
  options: stakeholders,
  getOptionLabel: (option: StakeholderType) =>
    option && option.label ? option.label : "",
};

const mapStakeholderToLabel = (code: string) => {
  let found = "";
  stakeholders.forEach((value) => {
    if (value.id === code) {
      //   console.log(`adding mapped value ${value.label}`);
      found = value.label;
    }
  });
  return found;
};

export const updateStakeholders = (
  newValues: Array<StakeholderType>,
  supportForm: SupportFormDocument,
  setSupportForm: Function
) => {
  let codes = Array<string>();
  newValues.forEach((element) => {
    codes.push(element.id);
  });

  //   console.log(`new stakeholders codes ${JSON.stringify(codes)}`);

  console.log(`new stakeholders ${JSON.stringify(newValues)}`);

  let currentStakeholders = {};

  if (supportForm && Object.keys(supportForm).length > 0) {
    const supportFormKeys = Object.keys(supportForm) as Array<string>;
    supportFormKeys.reduce(function (_r, e: string) {
      if (e && e.startsWith("stakeholders")) {
        console.log(`e: ${e}`);
        if (codes.includes(e)) currentStakeholders[e] = true;
        else currentStakeholders[e] = false;
      }
    });
  } else {
    console.log(`support form is ${JSON.stringify(supportForm)}`);
  }
  console.log(`current stakeholders ${JSON.stringify(currentStakeholders)}`);
  console.log(
    `update state ${JSON.stringify({
      ...supportForm,
      ...currentStakeholders,
    })}`
  );

  setSupportForm({ ...supportForm, ...currentStakeholders });
};

export const getCurrentStakeholders = (supportForm: SupportFormDocument) => {
  let currentStakeholders = Array<StakeholderType>();

  if (supportForm && Object.keys(supportForm).length > 0) {
    // @ts-ignore
    Object.keys(supportForm).reduce(function (r, e) {
      /*  console.log(
        `r: ${r ? r : ""} e: ${e ? e : ""} value: ${
          supportForm[e] ? supportForm[e] : ""
        }`
      ); */
      if (e && e.startsWith("stakeholders")) {
        /* console.log(
            `r: ${r ? r : ""} e: ${e ? e : ""} value: ${item[e] ? item[e] : ""}`
          ); */
        // console.log(`typeof  ${typeof supportForm[e]}`);
        if (supportForm[e] === true || supportForm[e] === "true") {
          //   console.log(`adding stakeholder "${e}"`);
          const mappedLabel = mapStakeholderToLabel(e);
          //   console.log(`adding label "${mappedLabel}"`);
          if (mappedLabel)
            currentStakeholders.push({ id: e, label: mappedLabel });
        }
      }
    });
  }
  console.log(`current stakeholders ${JSON.stringify(currentStakeholders)}`);
  //   console.log(`current stakeholders typeof ${typeof currentStakeholders}`);
  return currentStakeholders;
};
