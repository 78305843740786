import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  AddressType as AddressTypeEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface AgeRange {
  id: string;
  label: string;
}

export const addressTypeOptions = Object.keys(AddressTypeEnum).map((key) => ({
  id: key,
  label: AddressTypeEnum[key],
}));

console.debug(`addressTypeOptions ${JSON.stringify(addressTypeOptions)}`);

interface ComponentProps {
  resource: ParticipationFormDocument;
  errors?: {};
  setResource: React.Dispatch<any>;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const AddressTypeComponent = ({
  resource,
  errors,
  setResource,
  onBlur,
}: ComponentProps) => {
  return (
    <Grid item xs={12}>
      <Autocomplete
        options={addressTypeOptions}
        disablePortal
        id="address_type"
        value={resource.address_type ? resource.address_type : ""}
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            onBlur={onBlur}
            required={true}
            error={errors ? errors["address_type"] : false}
            fullWidth
            label="10. Typ miejscowości"
          />
        )}
        onChange={(event: any, newValue: AgeRange) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            address_type: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
