import axios from "axios";
import { Platform } from "react-native";
import config from "../oneconfig";
import contentDisposition, { ContentDisposition } from "content-disposition";
import { handleError } from "../utils";
import { download } from "./download";

export const getAll = async () => {
  try {
    const response = await axios.get(config.api_url + "/support-form");
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const getAllForUser = async (userId: string) => {
  try {
    console.log(`get all forms for user ${userId}`);
    const response = await axios.get(
      config.api_url + `/support-forms-for-user/${userId}`
    );

    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const getResource = async (id: string) => {
  try {
    const response = await axios.get(config.api_url + `/support-form/${id}`);
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const getStats = async () => {
  const response = await axios({
    url: config.api_url + `/support-form-export`,
    method: "GET",
    responseType: "blob",
  });

  if (response) {
    console.log(`response headers ${JSON.stringify(response.headers)}`);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", "FormularzeWsparcia.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  } else {
    console.error("Error while calling  support-form-stats");
  }
};

export const deleteResource = async (id: string) => {
  try {
    const response = await axios.delete(
      config.api_url + `/support-form/${id}/delete`
    );
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const createResource = async (data: any) => {
  const createResourceUrl = `${config.api_url}/support-form`;
  console.log(`create support form via url  ${createResourceUrl} `);

  return await axios({
    url: createResourceUrl,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateResource = async (data: any) => {
  const updateUserUrl = `${config.api_url}/support-form/${data._id}`;
  console.log(`update  via  ${updateUserUrl} `);

  return await axios({
    url: updateUserUrl,
    method: "patch",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
