import { GridComparatorFn } from "@mui/x-data-grid";
import _ from "lodash";
import moment from "moment";

export const localizedDateComparator: GridComparatorFn<Date> = (v1, v2) => {
  //   console.log(`comparing ${v1} with ${v2}`);

  // console.log(`comparing ${v1} with ${v2}`);

  if (!_.isEmpty(v1) && !_.isEmpty(v2)) {
    const v1Date = moment(v1, "DD-MM-YYYY, HH:mm:ss");
    const v2Date = moment(v2, "DD-MM-YYYY, HH:mm:ss");
    const result = v1Date - v2Date;
    // console.log(`result ${result}`);
    return result;
  } else if (v1) {
    // console.log(`v2Date was undefined`);
    return 1;
  } else if (v2) {
    // console.log(`v1Date was undefined`);
    return -1;
  } else {
    // console.log(`both dates were undefined`);
    return 0;
  }
};
