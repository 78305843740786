import React, { useContext, useState } from "react";
import MyRTCView, { MediaStream } from "../../components/MyRTCView";
import dStyles from "./styles";
import { DebugContext } from "../../debug-context";

type RemoteVideosWebProps = {
  remoteStreams: Map<string, MediaStream>;
  remoteCamerasDisabled: Map<string, boolean>;
};

export const RemoteVideosWeb = ({
  remoteStreams,
  remoteCamerasDisabled,
}: RemoteVideosWebProps) => {
  const [chatOn] = useState(false);
  const { debug } = useContext(DebugContext);

  let listOfRemoteVideos = new Array();
  const styles = dStyles(debug, chatOn);

  console.log(
    `[Remote stream datachannel] remoteCamerasDisabled: ${JSON.stringify(
      remoteCamerasDisabled
    )}`
  );

  console.log(
    `[Remote stream] rendering listOfRemoteVideos remote streams: ${
      remoteStreams ? [...remoteStreams.keys()] : undefined
    }`
  );
  if (!remoteStreams || !remoteStreams || remoteStreams.size === 0) {
    console.log(`[Remote stream] not available or empty`);
    return <></>;
  } else {
    console.warn;
    if (remoteStreams && remoteStreams.size > 0) {
      {
        remoteStreams.forEach((value, key: string) => {
          console.log(
            `[Remote stream] size: ${remoteStreams.size} rendering   key: ${key}`
          );

          listOfRemoteVideos.push(
            <MyRTCView
              videoId={`remote_${key}`}
              key={`remote_${key}`}
              style={
                remoteStreams.size < 2 ? styles.video2Peer : styles.video34
              }
              stream={value}
              videoDisabled={remoteCamerasDisabled.get(key)}
            />
          );
        });
      }
    }
    return <>{listOfRemoteVideos}</>;
  }
};
