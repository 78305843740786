import * as React from "react";
import { Colors, ToggleButton, Text, IconButton } from "react-native-paper";
import { View, Platform } from "react-native";
import { Auth, AuthContext, AuthContextType } from "../../auth-context";
import { ConnectionQualityContext } from "../../connection-quality-context";

import { useNavigation } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { Loading } from "../Loading";
import { DebugContext } from "../../debug-context";
import { DynamicHeader } from "./DynamicHeader";
import oneconfig from "../../oneconfig";
import CustomDialog from "../CustomDialog";
import ConnectionQualityIcon from "../ConnectionQualityIcon";

const ck_env = oneconfig.ck_env;
console.log("[ck_env]", JSON.stringify(ck_env));
export const SMALL_MENU_WIDTH = 600;

const MenuUserInfo = () => {
  const smallScreen = window.matchMedia(
    `(max-width: ${SMALL_MENU_WIDTH}px)`
  ).matches;
  const navigation = useNavigation();
  const { auth, setAuth } = React.useContext(AuthContext) as AuthContextType;
  const debug = React.useContext(DebugContext).debug;
  const { connectionQuality } = React.useContext(ConnectionQualityContext);

  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogConfirmLogout, setDialogConfirmLogout] = React.useState(false);

  console.debug(`[MenuUserInfo] smallScreen? : ${JSON.stringify(smallScreen)}`);

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const confirmedLogout = () => {
    setshowSpinner(true);
    console.log("removing authentication from async storage");
    let emptyAuth;

    setAuth(emptyAuth);
    setshowSpinner(false);
    // navigation.navigate("SignIn");
    console.log("after navIgate to sign in");
  };

  return (
    <View
      style={{
        display: "flex",
        backgroundColor: debug === true ? "grey" : "undefined",
        flexDirection: "row",
      }}
    >
      {showSpinner && <Loading loading={showSpinner} />}
      <CustomDialog
        visible={dialogVisible}
        hideDialog={hideDialog}
        dialogTitle={""}
        dialogContent={dialogMessage}
      />

      {!smallScreen && (
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            testID="current_role"
            style={{
              // color: "red",
              display: "flex",
              textAlign: "center",
              padding: 5,
            }}
          >
            {!isEmpty(auth) ? auth.email : "niezalogowany"}
          </Text>
        </View>
      )}

      <View
        style={{
          flexDirection: "row",
          display: "flex",
          // backgroundColor: "blue",
          /*  maxWidth: ["development", "devtest"].includes(oneconfig.ck_env)
            ? 300
            : 120,
          minWidth: 220, */
          // backgroundColor: "red",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {!isEmpty(auth) && (
          <IconButton
            testID="profile"
            style={{ display: "flex" }}
            visible={!isEmpty(auth)}
            size={24}
            onPress={(event) => {
              console.log(event);
              navigation.navigate("Profile", { userId: auth.id });
              return;
            }}
            icon={
              auth.isAdmin
                ? "account-cog-outline"
                : auth.isTranslator
                ? "account-tie-voice-outline"
                : "account-outline"
            }
          ></IconButton>
        )}

        <DynamicHeader />
        <CustomDialog
          visible={dialogConfirmLogout}
          dialogType={"YesOrNo"}
          dialogActionNo={() => setDialogConfirmLogout(false)}
          dialogActionYes={() => confirmedLogout()}
          dialogTitle={"Ostrzeżenie"}
          dialogContent={"Czy na pewno chcesz się wylogować"}
          testID="logout_dialog"
        />
        {!isEmpty(auth) && (
          <ConnectionQualityIcon connectionQuality={connectionQuality} />
        )}
        {!isEmpty(auth) && (
          <IconButton
            size={24}
            testID="logout"
            style={{ display: "flex" }}
            onPress={() => {
              setDialogConfirmLogout(true);
            }}
            icon="logout"
          />
        )}
      </View>
    </View>
  );
};

export default MenuUserInfo;
