import React, { useContext, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { styles } from "./styles";
import { DebugContext } from "../../debug-context";
import CustomDialog from "../../components/CustomDialog";
import { SupportFormProps } from "../../navigation/types";
import _ from "lodash";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createResource,
  getResource,
  updateResource,
} from "../../services/supportforms.service";
import { AuthContext, AuthContextType } from "../../auth-context";
import { Loading } from "../../components/Loading";
import LABELS from "../../labels";
import {
  formsOfSupportOptions,
  getFormOfSupport,
  updateFormOfSupport,
} from "./FormOfSupport";
import {
  stakeholdersOptions,
  getCurrentStakeholders,
  updateStakeholders,
} from "./Stakeholders";
import {
  getSupportTypes,
  supportTypesOptions,
  updateSupportTypes,
} from "./SupportTypes";
import { handleError } from "../../utils";
import "dayjs/locale/pl";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  EMPTY_USER,
  getDeafUsersForSearch,
  getTranslatorsForSearch,
} from "../../services/user.service";
import {
  SupportFormDocument,
  SupportNoteDocument,
} from "../../frontend_domain";
import { LocalizationProvider } from "@mui/x-date-pickers";

export default function SupportFormView({
  navigation,
  ...props
}: SupportFormProps) {
  const { auth } = React.useContext(AuthContext) as AuthContextType;
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;
  const [showSpinner, setshowSpinner] = React.useState(false);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [deafUsers, setDeafUsers] = useState([]);
  const [translators, setTranslators] = useState([]);

  // @ts-ignore
  const [supportForm, setSupportForm] = React.useState<SupportFormDocument>({
    formOfSupportCode: undefined,
    supportType_jobAdContent: undefined,
    supportType_jobAdContact: undefined,
    supportType_recruitment: undefined,
    supportType_medicalSchedule: undefined,
    supportType_medicalExamine: undefined,
    supportType_healthAndSafety: undefined,
    supportType_everydayWork: undefined,
    supportType_documentTranslation: undefined,
    supportType_participationForm: undefined,
    supportType_other: undefined,
    supportType_otherDescription: undefined,
    stakeholders_employer: undefined,
    stakeholders_recruitmentTeam: undefined,
    stakeholders_managers: undefined,
    stakeholders_coworkers: undefined,
    stakeholders_medical: undefined,
    stakeholders_healthAndSafety: undefined,
    stakeholders_contractor: undefined,
    stakeholders_other: undefined,
    stakeholders_otherDescription: undefined,
  });

  const debug = useContext(DebugContext).debug;
  const [errors, setErrors] = React.useState({});
  const [validationMessages, setValidationMessages] = React.useState({});

  const deafUsersOptions = {
    options: deafUsers,
    getOptionLabel: (option: SearchUserType) =>
      option && option.name ? option.name : "",
  };

  const translatorsOptions = {
    options: translators,
    getOptionLabel: (option: SearchUserType) =>
      option && option.name ? option.name : "",
  };

  const getMatchingUser = () => {
    if (supportForm && supportForm.userId) {
      const filtered = deafUsers.find(
        (i: SearchUserType) =>
          i._id ===
          (supportForm && supportForm.userId ? supportForm.userId._id : -1)
      );
      if (filtered) return filtered;
      else return EMPTY_USER;
    } else {
      return EMPTY_USER;
    }
  };

  const loadSupportForm = async (resourceId: string) => {
    try {
      const resource = await getResource(resourceId);
      console.log(`[SupportFormView] resource ${JSON.stringify(resource)}`);
      console.log(`[SupportFormView] ${resource._id}`);
      console.log(`[SupportFormView] createdAt: ${resource.createdAt}`);

      for (const attribute in resource) {
        console.log(`setAttribute ${attribute}: ${resource[attribute]}`);

        if (attribute.endsWith("At")) {
          console.log(`updating format for ${attribute}`);
          resource[attribute] = resource[attribute]
            ? new Date(resource[attribute]).toLocaleString("pl-PL")
            : "";
        }
      }
      // causes rerender so It must be at the very end
      console.log(`resource ${JSON.stringify(resource)}`);
      setSupportForm(resource);
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  const stakeholdersSelected = () => {
    const selected =
      supportForm?.stakeholders_contractor === true ||
      supportForm?.stakeholders_coworkers === true ||
      supportForm?.stakeholders_employer === true ||
      supportForm?.stakeholders_healthAndSafety === true ||
      supportForm?.stakeholders_managers === true ||
      supportForm?.stakeholders_medical === true ||
      supportForm?.stakeholders_recruitmentTeam === true ||
      supportForm?.stakeholders_other === true;

    console.log(
      `stakeholdersSelected supportForm?.stakeholders_healthAndSafety? ${supportForm?.stakeholders_healthAndSafety}`
    );

    console.log(`stakeholdersSelected? ${selected}`);
    return selected;
  };
  const supportTypeSelected = () => {
    const selected =
      supportForm?.supportType_documentTranslation === true ||
      supportForm?.supportType_everydayWork === true ||
      supportForm?.supportType_healthAndSafety === true ||
      supportForm?.supportType_jobAdContact === true ||
      supportForm?.supportType_jobAdContent === true ||
      supportForm?.supportType_medicalExamine === true ||
      supportForm?.supportType_medicalSchedule === true ||
      supportForm?.supportType_other === true ||
      supportForm?.supportType_participationForm === true ||
      supportForm?.supportType_recruitment === true;

    console.log(
      `noSupportType supportForm?.supportType_participationForm? ${supportForm?.supportType_participationForm}`
    );

    console.log(`supportTypeSelected? ${selected}`);
    return selected;
  };

  const getMatchingTranslator = () => {
    console.log("getMatchingTranslator");
    if (supportForm && supportForm.translatorId) {
      const filtered = translators.find(
        (i: SearchUserType) =>
          i._id ===
          (supportForm && supportForm.translatorId
            ? supportForm.translatorId._id
            : -1)
      );
      if (filtered) return filtered;
      else return EMPTY_USER;
    } else {
      return EMPTY_USER;
    }
  };

  React.useEffect(() => {
    releaseUserMedia();
    const resourceId = props.route.params ? props.route.params.id : undefined;
    const p2pDetails = props.route.params ? props.route.params.p2p : undefined;

    console.log("!!!!SupportForm resourceId ", resourceId);
    try {
      (async () => {
        const results = await getDeafUsersForSearch();
        if (results) {
          console.log(`[SupportFormView] results ${JSON.stringify(results)}`);
          results.push(EMPTY_USER);
        }
        setDeafUsers(results);
      })();

      (async () => {
        const results = await getTranslatorsForSearch();
        if (results) {
          console.log(`[SupportFormView] results ${JSON.stringify(results)}`);
          results.push(EMPTY_USER);
        }
        setTranslators(results);
      })();

      (async () => {
        if (resourceId) {
          await loadSupportForm(resourceId);
        } else if (p2pDetails) {
          const form = await createResource(p2pDetails);
          console.log(
            `[SupportFormView] crated from p2p call ${JSON.stringify(
              form.data
            )}`
          );
          await loadSupportForm(form.data._id);
        } else {
          console.log("No support form");
        }
      })();

      validate();
    } catch (e) {
      handleError(
        setshowSpinner,
        e,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  }, [props.route]);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const validate = async () => {
    let validationErrors = {};
    let helperMessages = {};

    if (_.isEmpty(supportForm?.userId)) {
      validationErrors["userId"] = true;
      helperMessages["userId"] = 'Użytkownik jest wymagany"';
    }

    if (_.isEmpty(supportForm?.formOfSupportCode)) {
      validationErrors["formOfSupportCode"] = true;
      helperMessages["formOfSupportCode"] = 'Forma wsparcia jest wymagana"';
    }

    if (!supportTypeSelected()) {
      validationErrors["supportType"] = true;
      helperMessages["supportType"] = 'Rodzaj wsparcia jest wymagany"';
    }

    if (
      supportForm?.supportType_other &&
      _.isEmpty(supportForm?.supportType_otherDescription)
    ) {
      validationErrors["supportType_otherDescription"] = true;
      helperMessages["supportType_otherDescription"] =
        "Uzupełnij szczegóły dla: Inny rodzaj wsparcia";
    }

    if (!stakeholdersSelected()) {
      validationErrors["stakeholders"] = true;
      helperMessages["stakeholders"] = 'Interesariusze są wymagani"';
    }

    if (
      supportForm?.stakeholders_other &&
      _.isEmpty(supportForm?.stakeholders_otherDescription)
    ) {
      validationErrors["stakeholders_otherDescription"] = true;
      helperMessages["stakeholders_otherDescription"] =
        "Uzupełnij szczegóły dla: Inne osoby, instytucje";
    }

    setErrors(validationErrors);
    setValidationMessages(helperMessages);

    console.log(`validation result ${JSON.stringify(validationErrors)}`);
    console.log(`helperMessages  ${JSON.stringify(helperMessages)}`);

    if (Object.keys(validationErrors).length === 0) return true;
    else return false;
  };

  const onSubmit = async () => {
    const isValidated = await validate();

    console.log(`isValidated ${JSON.stringify(isValidated)}`);
    if (isValidated) {
      let result;
      setshowSpinner(true);
      try {
        console.log("executing request");
        console.log("executing create translation");

        if (supportForm?._id) result = await updateResource(supportForm);
        else result = await createResource(supportForm);

        console.log(`request done ${JSON.stringify(supportForm)}`);

        if (result) {
          setshowSpinner(true);
          setDialogMessage("Zapisano zmiany");
          setDialogVisible(true);
        }
      } catch (error) {
        handleError(
          setshowSpinner,
          error,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
      }
    }
  };

  // console.log(`item to render ${JSON.stringify(item)}`);

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("SupportForms");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pl"}>
      <View testID="form_container" style={styles.outerColumnContainer}>
        {showSpinner === true && <Loading />}

        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        {/* confirmation */}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={"Zapisano zmiany"}
        />
        <View
          testID="fields"
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" margin="normal" style={{ padding: 20 }}>
                Formularz wsparcia
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {" "}
            </Grid>
          </Grid>
          {supportForm?._id && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  // style={{ backgroundColor: "lightgrey" }}
                  label="Id"
                  fullWidth
                  name="id"
                  value={supportForm ? supportForm._id : ""}
                  disabled={true}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Data utworzenia"
                  name="createdAt"
                  value={supportForm ? supportForm.createdAt : ""}
                  disabled={true}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Data modyfikacji"
                  name="updatedAt"
                  value={supportForm ? supportForm.updatedAt : ""}
                  disabled={true}
                ></TextField>
              </Grid>
            </Grid>
          )}
          {/* user */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Text nativeID="users_loaded" style={{ display: "none" }}>
                {deafUsers && deafUsers.length > 0 ? "true" : "false"}
              </Text>

              <Autocomplete
                {...deafUsersOptions}
                clearOnBlur
                clearOnEscape
                disablePortal
                id="users_combo_box"
                sx={{ width: 300 }}
                value={getMatchingUser()}
                onChange={(event: any, newValue: SearchUserType | null) => {
                  console.log(`new value ${JSON.stringify(newValue)}`);
                  if (newValue)
                    setSupportForm({
                      ...supportForm,
                      userId: {
                        _id: newValue._id,
                        pesel: newValue.pesel,
                        email: newValue.email,
                        firstName: newValue.firstName,
                        lastName: newValue.lastName,
                      },
                    });
                  else {
                    console.log(`removing selected user`);
                    setSupportForm({
                      ...supportForm,
                      userId: { undefined },
                    });
                  }

                  // setValue(item);
                }}
                renderInput={(params) => (
                  <TextField
                    error={errors["userId"] ? true : false}
                    margin="normal"
                    {...params}
                    label="Użytkownik"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="normal"
                label="PESEL"
                fullWidth
                name="pesel"
                value={
                  supportForm && supportForm.userId && supportForm.userId.pesel
                    ? supportForm.userId.pesel
                    : ""
                }
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="normal"
                label="email"
                fullWidth
                name="user_email"
                value={
                  supportForm && supportForm.userId
                    ? supportForm.userId.email
                    : ""
                }
                disabled={true}
              />
            </Grid>
          </Grid>

          {/* translator */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                {...translatorsOptions}
                clearOnBlur
                clearOnEscape
                disablePortal
                id="translators_combo_box"
                sx={{ width: 300 }}
                value={getMatchingTranslator()}
                onChange={(event: any, newValue: SearchUserType | null) => {
                  let newSupportForm;
                  console.log(
                    `new value for translator ${JSON.stringify(newValue)}`
                  );
                  if (newValue) {
                    newSupportForm = supportForm as SupportFormDocument;
                    newSupportForm["translatorId"] = {
                      _id: newValue._id,
                      email: newValue.email,
                      firstName: newValue.firstName,
                      lastName: newValue.lastName,
                    };
                  } else {
                    let newSupportForm = supportForm as SupportFormDocument;
                    newSupportForm["translatorId"] = undefined;
                  }

                  setSupportForm({ ...newSupportForm });
                }}
                renderInput={(params) => (
                  <TextField margin="normal" {...params} label="Tłumacz" />
                )}
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <TextField
                margin="normal"
                label="email"
                fullWidth
                name="translator_email"
                value={
                  supportForm && supportForm.translatorId
                    ? supportForm.translatorId.email
                    : ""
                }
                disabled={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DesktopDateTimePicker
                label="Data i godzina rozpoczęcia wsparcia"
                value={
                  supportForm && supportForm.supportDateStart
                    ? supportForm.supportDateStart
                    : undefined
                }
                onChange={(newValue) => {
                  console.log(`DesktopDateTimePicker ${newValue}`);
                  setSupportForm({
                    ...supportForm,
                    supportDateStart: newValue,
                  });
                  // setValue(newValue);
                }}
                renderInput={(params) => {
                  console.debug(
                    `params for datetime picker ${JSON.stringify(params.error)}`
                  );
                  return (
                    <TextField
                      margin="normal"
                      error={false}
                      fullWidth
                      {...params}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DesktopDateTimePicker
                label="Data i godzina zakończenia wsparcia"
                value={
                  supportForm && supportForm.supportDateEnd
                    ? supportForm.supportDateEnd
                    : undefined
                }
                onChange={(newValue) => {
                  console.debug(`DesktopDateTimePicker ${newValue}`);
                  setSupportForm({
                    ...supportForm,
                    supportDateEnd: newValue,
                  });
                  // setValue(newValue);
                }}
                renderInput={(params) => {
                  console.debug(
                    `params for datetime picker ${JSON.stringify(params.error)}`
                  );
                  return (
                    <TextField
                      margin="normal"
                      error={false}
                      fullWidth
                      {...params}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          {/* forma wsparcia */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                {...formsOfSupportOptions}
                disablePortal
                id="support_form"
                value={getFormOfSupport(supportForm)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    error={errors["formOfSupportCode"] ? true : false}
                    onBlur={() => {
                      if (_.isEmpty(supportForm?.formOfSupportCode))
                        setErrors({ ...errors, formOfSupportCode: true });
                      else setErrors({ ...errors, formOfSupportCode: false });
                    }}
                    fullWidth
                    label="Forma wsparcia"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, newValue: []) => {
                  console.log(`support newValue ${JSON.stringify(newValue)}`);

                  updateFormOfSupport(newValue, supportForm, setSupportForm);
                }}
              />
            </Grid>
          </Grid>

          {/* rodzaj wsparcia */}
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                {...supportTypesOptions}
                disablePortal
                multiple
                id="support_type"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={getSupportTypes(supportForm)}
                //@ts-ignore
                onChange={(event: any, newValue: []) => {
                  console.log(`support newValue ${JSON.stringify(newValue)}`);
                  updateSupportTypes(newValue, supportForm, setSupportForm);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    onBlur={() => {
                      console.log(
                        `support form on blur ${JSON.stringify(supportForm)}`
                      );
                      if (!supportTypeSelected())
                        setErrors({ ...errors, supportType: true });
                      else setErrors({ ...errors, supportType: false });
                    }}
                    error={errors["supportType"] ? true : false}
                    label="Rodzaj wsparcia"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="normal"
                fullWidth
                id="supportType_otherDescription"
                label="Inny rodzaj wsparcia (jaki?)"
                required={supportForm?.supportType_other === true}
                value={
                  supportForm && supportForm.supportType_otherDescription
                    ? supportForm.supportType_otherDescription
                    : ""
                }
                error={errors["supportType_otherDescription"] ? true : false}
                onBlur={() => {
                  if (_.isEmpty(supportForm?.supportType_otherDescription))
                    setErrors({
                      ...errors,
                      supportType_otherDescription: true,
                    });
                  else
                    setErrors({
                      ...errors,
                      supportType_otherDescription: false,
                    });
                }}
                onChange={(event) => {
                  setSupportForm({
                    ...supportForm,
                    supportType_otherDescription: event.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>

          {/*  interesariusze */}

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                {...stakeholdersOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disablePortal
                multiple
                id="stakeholders"
                value={getCurrentStakeholders(supportForm)}
                //@ts-ignore
                onChange={(event: any, newValue: []) => {
                  console.log(`support newValue ${JSON.stringify(newValue)}`);

                  updateStakeholders(newValue, supportForm, setSupportForm);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    label="Interesariusze"
                    onBlur={() => {
                      console.log(
                        `support form on blur ${JSON.stringify(supportForm)}`
                      );
                      if (!stakeholdersSelected())
                        setErrors({ ...errors, stakeholders: true });
                      else setErrors({ ...errors, stakeholders: false });
                    }}
                    error={errors["stakeholders"] ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                id="stakeholders_otherDescription"
                label="inne osoby/instytucje, jakie"
                required={supportForm?.stakeholders_other === true}
                value={
                  supportForm && supportForm.stakeholders_otherDescription
                    ? supportForm.stakeholders_otherDescription
                    : ""
                }
                onChange={(event) => {
                  setSupportForm({
                    ...supportForm,
                    stakeholders_otherDescription: event.target.value,
                  });
                }}
                error={errors["stakeholders_otherDescription"] ? true : false}
                onBlur={() => {
                  if (_.isEmpty(supportForm?.stakeholders_otherDescription))
                    setErrors({
                      ...errors,
                      stakeholders_otherDescription: true,
                    });
                  else
                    setErrors({
                      ...errors,
                      stakeholders_otherDescription: false,
                    });
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                id="supportDescription"
                multiline
                fullWidth
                rows={4}
                label="Opis udzielonego wsparcia"
                value={
                  supportForm && supportForm.supportDescription
                    ? supportForm.supportDescription
                    : ""
                }
                onChange={(event) => {
                  setSupportForm({
                    ...supportForm,
                    supportDescription: event.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Status wsparcia
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={
                    supportForm && supportForm.status ? supportForm.status : ""
                  }
                  onChange={(event) => {
                    setSupportForm({
                      ...supportForm,
                      status: event.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="finished"
                    control={<Radio />}
                    label="zakończone"
                  />
                  <FormControlLabel
                    value="in_progress"
                    control={<Radio />}
                    label="wymaga kontynuacji"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Data wysłania ankiety"
                name="surveySentAt"
                InputLabelProps={{ shrink: true }}
                value={supportForm ? supportForm.surveySentAt : ""}
                disabled={true}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="statusDescription"
              multiline
              disabled={true}
              rows={2}
              label="Wymaga kontynuacji (to pole jest tylko do odczytu - użyj notatek poniżej)"
              value={
                supportForm && supportForm.statusDescription
                  ? supportForm.statusDescription
                  : ""
              }
              onChange={(event) => {
                console.log(
                  `statusDescription ${JSON.stringify(event.target.value)}`
                );
                setSupportForm({
                  ...supportForm,
                  statusDescription: event.target.value,
                });
              }}
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                margin="normal"
                style={{ paddingLeft: 0, paddingTop: 40, paddingBottom: 20 }}
              >
                {"Poprzednie notatki"}
              </Typography>
            </Grid>
          </Grid>

          {supportForm.notes &&
            supportForm.notes.map(function (note: SupportNoteDocument) {
              console.log(`Support note: ${JSON.stringify(note)}`);
              return (
                <Grid key={note.createdAt} paddingTop={2} container spacing={2}>
                  <Grid item xs={3}>
                    <Typography>
                      {new Date(note.createdAt).toLocaleString("pl-PL")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {note.translatorId
                        ? note.translatorId.firstName +
                          " " +
                          note.translatorId.lastName
                        : "?"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{note.note}</Typography>
                  </Grid>
                </Grid>
              );
            })}

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="statusDescription"
              multiline
              rows={2}
              label="Nowa notatka"
              value={
                supportForm && supportForm.newNote ? supportForm.newNote : ""
              }
              onChange={(event) => {
                console.log(`newNote ${JSON.stringify(event.target.value)}`);
                setSupportForm({
                  ...supportForm,
                  newNote: event.target.value,
                });
              }}
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              {!auth?.isUser && (
                <View
                  style={[
                    debug === true ? { backgroundColor: "orange" } : undefined,
                    styles.btnPrimary,
                  ]}
                >
                  <TouchableOpacity onPress={onSubmit} testID={"button_update"}>
                    <Text style={styles.buttonText}>{LABELS.save}</Text>
                  </TouchableOpacity>
                </View>
              )}
              <Grid item xs={4}></Grid>
            </Grid>
          </Grid>
        </View>
      </View>
    </LocalizationProvider>
  );
}
