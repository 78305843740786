import { Audio, ResizeMode, Video } from "expo-av";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useContext, useEffect, useState } from "react";
import { Platform, SafeAreaView, Text, View } from "react-native";
import RNRestart from "react-native-restart";
import { createRTCPeerConnection } from "./webrtc";
import WebRTCMetrics from "webrtcmetrics";
import InCallManager from "react-native-incall-manager";
import { FAB } from "react-native-paper";
import baseStyles from "../../styles/base";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

import { useFocusEffect } from "@react-navigation/native";
import {
  RTCSessionDescription,
  RTCIceCandidate,
} from "react-native-webrtc-web-shim";
import { useNavigation } from "@react-navigation/native";
import { AuthContext, AuthContextType } from "../../auth-context";
import Chat from "../../components/Chat";
import SupportFormsInCall from "../../components/SupportFormsInCall";
import CustomDialog from "../../components/CustomDialog";
import MyRTCView, { MediaStream } from "../../components/MyRTCView";
import TranslatorNotInCallView from "../../components/TranslatorNotInCallView";
import UserInCallRemoteVideoView from "../../components/UserInCallRemoteVideoView";
import UserNotInCallView from "../../components/UserNotInCallView";
import UserWaitingForAnswerCallView from "../../components/UserWaitingForAnswerCallView";
import { DebugContext } from "../../debug-context";
import {
  ConnectionQualityContext,
  ConnectionQualityContextType,
} from "../../connection-quality-context";
import {
  DisableMenuContext,
  DisableMenuContextType,
} from "../../disable-menu-context";
import { activateKeepAwake } from "expo-keep-awake";
import { ConnectedUserDTO, ConnectedUsersDTO } from "../../frontend_domain";
import GLOBAL_LABELS from "../../labels";
import { P2PCallProps } from "../../navigation/types";
import oneconfig from "../../oneconfig";
import CCButtonsSection from "./P2PButtonsSection";

import WebRTCStatsView from "../../components/WebRTCStatsView";

import dStyles from "./styles";

import { ChatMessage } from "../../components/Chat/ChatItem";
import _ from "lodash";
import { Loading } from "../../components/Loading";

export const SOCKET_URL = oneconfig.signaling_endpoint;
import ParticipationFormView from "../ParticipationForm/ParticipationFormView";
import { getResourceByEmail } from "../../services/participationforms.service";
import {
  keyStatsFromReport,
  KeyWebRTCStats,
} from "../../services/webrtcstats.service";
import { getAllForUser } from "../../services/supportforms.service";

export const CALL_STATUS = {
  NOTHING_YET: "Nothing yet", // before successful getUserMedia() call
  READY: "Ready to call",
  INCOMING_CALL: "Incoming call",
  OUTGOING_CALL: "Outgoing call",
  IN_CALL: "In call",
};

const P2PCall = ({ ...props }: P2PCallProps) => {
  const { localStream, getUserMedia, releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  const localStreamRef = useRef(null);

  useLayoutEffect(() => {
    console.log(`useLayoutEffect for localStream`);
    if (!localStreamRef.current) return;
  }, [localStreamRef.current]);

  const mounted = useRef(false);
  const metrics = useRef();

  const AudioPlayer = useRef(new Audio.Sound());
  const navigation = useNavigation();
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [IsPLaying, SetIsPLaying] = useState<boolean>(false);
  const [callStart, setCallStart] = useState<Date>();
  const [callEnd, setCallEnd] = useState<Date>();
  const conn = useRef<WebSocket>(null); // signaling connection (websockets)
  const ownMediaRecorder = useRef(null);
  const peerMediaRecorder = useRef(null);
  const [socketId, setSocketId] = useState(null);
  const yourConn = useRef(null); // RTCPeer connection
  const datachannel = useRef(null); // outgoing Data Channel for RTCPeer connection (toggle camera events)
  const otherDataChannel = useRef(null); // incoming Data Channel
  const [remoteCameraDisabled, setRemoteCameraDisabled] = useState(false);
  const [calling, setCalling] = useState(false);

  const [status, setStatus] = useState(CALL_STATUS.NOTHING_YET);
  const [
    participationFormFinishedForUser,
    setParticipationFormFinishedForUser,
  ] = useState();

  const [connectedUsers, setConnectedUsers] = useState<ConnectedUsersDTO>(
    new Map<string, ConnectedUserDTO>()
  );

  const [remoteStream, setRemoteStream] = useState<MediaStream>();

  const [callActive, setCallActive] = useState(false);
  const [incomingCall, setIncomingCall] = useState(false);
  const [otherId, setOtherId] = useState("");
  const [otherRole, setOtherRole] = useState("");

  const connectedUser = useRef(null);
  const offerRef = useRef(null);

  const { auth, setAuth } = useContext(AuthContext) as AuthContextType;
  const { debug } = useContext(DebugContext);
  const { menuDisabled, disableMenu } = React.useContext(
    DisableMenuContext
  ) as DisableMenuContextType;
  const { connectionQuality, updateConnectionQuality } = React.useContext(
    ConnectionQualityContext
  ) as ConnectionQualityContextType;

  console.debug("auth ", auth);

  const [dialogRecordingVisible, setDialogRecordingVisible] = useState(false);
  const [recordingAccepted, setRecordingAccepted] = useState(
    auth && auth.isTranslator ? true : true
  );

  const [disableCallButton, setDisableCallButton] = useState(false);
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [dialogInfofVisible, setDialogInfoVisible] = useState(false);
  const [dialogInfoMessage, setDialogInfoMessage] = useState("");
  const [dialogHangupBeforeReloadUser, setHangupDialogBeforeReloadUser] =
    useState(false);
  const [
    dialogHangupBeforeReloadMessageUser,
    setHangupDialogBeforeReloadMessageUser,
  ] = useState("");
  const [
    dialogHangupBeforeReloadTranslator,
    setHangupDialogBeforeReloadTranslator,
  ] = useState(false);
  const [
    dialogHangupBeforeReloadMessageTranslator,
    setHangupDialogBeforeReloadMessageTranslator,
  ] = useState("");

  const [cameraOn, setCameraOn] = useState(false);
  const [showWebRTCStats, setShowWebRTCStats] = useState(false);
  const [webRTCStats, setWebRTCStats] = useState<KeyWebRTCStats>();

  const toggleWebRTCStats = () => {
    if (!showWebRTCStats) {
      console.log(`checking if we should crate metrics...`);
      if (metrics && auth?.isTranslator) {
        console.log(`creating metrics...`);
        const probe = metrics.current.createProbe(yourConn.current, {
          // pname: "PeerConnection_1", // Optional. Name of the peer connection
          // cid: "call007984", // Optional. Call Id
          uid: auth.email, // Optional. User Id
          ticket: false, // Optional. Generate a ticket at the end of the call or not.
          record: false, // Optional. Record reports in the ticket or not.
          startAfter: 2000,
          stopAfter: 1000,
          verbose: false,
          refreshTimer: 10000,
        });

        probe.onreport = (report) => {
          // Do something with a report collected (JSON)
          // console.debug(`onreport ${JSON.stringify(report)}`);
        };

        probe.onticket = (ticket) => {
          // Do something with the ticket collected at the end of the call (JSON)
          console.log(`onticket ${JSON.stringify(ticket)}`);
        };

        // @ts-ignore
        metrics.current.onresult = (result) => {
          // Do something with the global report collected (JSON)
          console.info(`onresult, saving result...`);
          console.debug(`onresult ${JSON.stringify(result)}`);
          setWebRTCStats(keyStatsFromReport(result));
        };

        // Start collecting statistics
        // @ts-ignore
        metrics.current.startAllProbes();
      }
    } else {
      console.log(`checking if we should stop producing metrics...`);
      // @ts-ignore
      if (metrics.current.running) {
        setWebRTCStats(undefined);
        // @ts-ignore
        metrics.current.stopAllProbes();
      }
    }

    setShowWebRTCStats(!showWebRTCStats);
  };

  const PlayRecordedAudio = async () => {
    try {
      await AudioPlayer.current.loadAsync(
        { uri: "phone-ringing-6805.mp3" },
        {},
        true
      );

      // Get Player Status
      const playerStatus = await AudioPlayer.current.getStatusAsync();
      await AudioPlayer.current.setIsLoopingAsync(true);
      // Play if song is loaded successfully
      if (playerStatus.isLoaded) {
        if (playerStatus.isPlaying === false) {
          AudioPlayer.current.playAsync();
          SetIsPLaying(true);
        }
      }
    } catch (error) {}
  };

  useLayoutEffect(() => {
    return () => {
      // (async () => {
      console.log("releasing media before unmounting the DOM");
      releaseUserMedia();

      if (conn.current) {
        console.log(
          "Disconnecting from p2p signaling. Clearing client heartbeat"
        );
        clearInterval(heartbeatIntervalFunction.current);

        conn.current.close();
      }
    };
  }, []);

  // cleanup resources while navigating to other screen
  // it is alreed off
  useFocusEffect(
    React.useCallback(() => {
      const unsubscribe = () => {
        console.log(`focus off... time to clean ${localStream}`);

        releaseUserMedia();
      };

      return () => unsubscribe();
    }, [])
  );

  useEffect(() => {
    console.info("[Mounted] useEffect hook");
    mounted.current = true;
    metrics.current = new WebRTCMetrics();
    // @ts-ignore
    metrics.current.setupLogLevel("SILENT");

    return () => {
      mounted.current = false;
    };
  }, []);

  const StopPlaying = async () => {
    try {
      const playerStatus = await AudioPlayer.current.getStatusAsync();

      if (playerStatus.isLoaded === true)
        await AudioPlayer.current.unloadAsync();

      SetIsPLaying(false);
    } catch (error) {}
  };

  const toggleCamera = () => {
    localStream?.getVideoTracks().forEach((track) => {
      track.enabled = !cameraOn;
      console.log(`datachannel event, selfVideo: `, !cameraOn);
      if (datachannel.current)
        datachannel.current.send(JSON.stringify({ selfVideo: !cameraOn }));
    });

    setCameraOn(!cameraOn);
  };

  const [micOn, setMicOn] = useState(true);
  const toggleMic = () => {
    setMicOn(!micOn);
  };

  const toggleMuteLocalStream = () => {
    if (micOn && localStream) localStream.getAudioTracks()[0].enabled = false;
    else if (localStream) localStream.getAudioTracks()[0].enabled = true;

    toggleMic();
  };

  const [volumeOn, setVolumeOn] = useState(true);
  const toggleVolumeOn = () => {
    if (volumeOn && remoteStream)
      remoteStream.getAudioTracks()[0].enabled = false;
    else if (remoteStream) remoteStream.getAudioTracks()[0].enabled = true;

    setVolumeOn(!volumeOn);
  };

  const [chatOn, setIsChatOn] = useState(false);
  const toggleChat = () => {
    setNewChatNotification(false);
    setIsChatOn((isChatOn) => !isChatOn);
  };
  const [incomingChatMessage, setIncomingChatMessage] = useState<ChatMessage>();
  const [newChatNotification, setNewChatNotification] = useState(false);

  const heartbeatIntervalFunction = useRef();
  const notAnsweredPings = useRef(0);
  const [formsOn, setFormsOn] = useState(false);
  const toggleForms = () => setFormsOn((formsOn) => !formsOn);

  const [participationOn, setParticipationOn] = useState(false);
  const [supportFormsInProgress, setSupportFormsInProgress] = useState([]);

  const toggleParticipation = () => {
    console.log("toggleParticipation");
    setParticipationOn((participationOn) => !participationOn);
  };

  const styles = dStyles(debug, chatOn, participationOn);

  const heartbeat = () => {
    console.debug(
      `[HeartBeat] sending heartbeat request (ping) to server. Not answered pings# ${notAnsweredPings.current} status ${status}`
    );

    if (notAnsweredPings.current > 0) {
      updateConnectionQuality("poor");

      console.log(`[HeartBeat] notAnsweredPings ${notAnsweredPings.current}`);
      setDisableCallButton(true);
    }
    if (notAnsweredPings.current > 5 && status !== CALL_STATUS.IN_CALL) {
      reload();
    } else
      send(
        {
          type: "ping",
          from: auth?.email,
        },
        conn
      );

    notAnsweredPings.current += 1;
  };

  const handleServerPong = () => {
    updateConnectionQuality("good");
    notAnsweredPings.current = 0;
    setDisableCallButton(false);
  };

  const onOpen = (x) => {
    console.log("Web socket connection on open", x);

    // @ts-ignore
    heartbeatIntervalFunction.current = setInterval(heartbeat, 3000);

    console.log("[HeartBeat] scheduled.");
  };

  // console.log(`participation on change, switch autosave ${participationOn}`);

  // called only once because of []
  useEffect(() => {
    try {
      (async () => {
        console.log(`calling  getUserMedia form conf call screen`);
        getUserMedia({ audio: true, video: true });
      })();
    } catch (error) {
      console.error("Error during getUserMedia", error);
      console.error(error);
      console.error(`initLocalVideo `, error);
      setDialogErrorVisible(true);
      setDialogErrorMessage(
        `Brak dostępu do kamery/mikrofonu. Upewnij się, że urządzenia są włączone i nie blokowane przez przeglądarkę. Błąd z przeglądarki internetowej: "${error.message}"`
      );
    }
  }, []);

  useEffect(() => {
    if (localStream) {
      console.log(`localStream ${localStream}`);
      // Got stream!
      console.log(`set status to; ${CALL_STATUS.READY}`);
      setStatus(CALL_STATUS.READY);
      disableMenu(false);

      // setup stream listening

      yourConn.current = createRTCPeerConnection();

      console.log(`adding stream to  yourConn.current`);
      // @ts-ignore
      yourConn.current.addStream(localStream);

      console.log(`set mic and camera on`);
      setMicOn(true);
      setCameraOn(true);
    }
  }, [localStream]);

  useEffect(() => {
    try {
      console.log(`props ${JSON.stringify(props)}`); //@ts-ignore

      const uri = `${SOCKET_URL + auth?.authToken}`;

      console.log(`connecting to transcription service: ${uri}`);
      conn.current = new WebSocket(uri);

      updateConnectionQuality("good");
      console.log(`Connected`);
      // conn.current.onopen = () => console.log("ws opened");
      //@ts-ignore
      conn.current.onopen = onOpen;
      //@ts-ignore
      conn.current.onclose = (closeEvent) => {
        // this can happen during logout so

        console.log("ws closed: ", closeEvent);

        if (mounted) {
          console.log("component mounted, current auth", auth);
          console.log(
            "component mounted, current navigation",
            navigation.getState()
          );

          if (
            auth &&
            closeEvent &&
            (closeEvent.code === 3001 || closeEvent.code === 3004)
          ) {
            console.error("Rozłączono z serwerem. Kod: ", closeEvent.code);
            try {
              let emptyAuth;
              setAuth(emptyAuth);
              // setDialogErrorVisible(true);
            } catch (error) {
              console.warn("Cannot remove auth.");
            }
          } else {
            console.info(
              "Rozłączono z serwerem. Kod:",
              closeEvent ? closeEvent.code : ""
            );

            if (closeEvent.code === 1005)
              //No Status Rcvd
              console.warn("closed by No Status Rcvd");
            else {
              setDialogErrorMessage("Brak połączenia z serwerem.");
              setDialogErrorVisible(true);
            }
          }
        }
      };

      //@ts-ignore
      conn.current.onerror = function (err) {
        updateConnectionQuality("error");
        console.log("Got error", err);
      };

      return () => {
        console.log(`we are closing socket while leaving`);
        // we are closing socket while leaving
        const wsCurrent = conn.current;
        wsCurrent.close();
      };
    } catch (error) {
      console.error(`Websocket connection failed ${JSON.stringify(error)}`);
      setDialogErrorVisible(true);
      setDialogErrorMessage("Brak połączenia z serwerem.");
    }
  }, [conn]);

  useEffect(() => {
    if (!conn.current) return;
    console.log("is connected, on message");
    //@ts-ignore
    conn.current.onmessage = function (msg) {
      onMessage(msg);
    };
  }, [status]);

  const hangup = (peerId: string) => {
    console.log(`leaving call with ${peerId}, your name: ${auth?.email}`);

    disableMenu(false);
    setCallEnd(new Date());

    send(
      {
        name: auth?.email,
        fromRole: auth?.role,
        to: peerId,
        otherName: peerId,
        type: "hangupPeer",
      },
      conn
    );

    const bothTranslators = areBothUsersTranslators();

    if (auth?.isUser || bothTranslators) {
      if (Platform.OS === "web") window.location.reload();
      else {
        RNRestart.Restart();
      }

      // Once the call is finished, stop the analyzer when running
      //@ts-ignore
      if (metrics.current.running) {
        //@ts-ignore
        metrics.current.stopAllProbes();
      }
    } else {
      stopRecorder(ownMediaRecorder);
      stopRecorder(peerMediaRecorder);

      setHangupDialogBeforeReloadMessageTranslator(
        "Czy chcesz utworzyć formularz wsparcia?"
      );
      setHangupDialogBeforeReloadTranslator(true);
    }
  };

  const handleChatMessage = (data) => {
    const m = data;
    m.receivedAt = new Date();
    setIncomingChatMessage(m);
    if (chatOn === false) setNewChatNotification(true);
  };

  const handleCancel = (data) => {
    setCallActive(false);
    setOtherId("");
    setOtherRole("");
    setStatus(CALL_STATUS.READY);
    disableMenu(false);
    setIncomingCall(false);
    StopPlaying();
  };

  const areBothUsersTranslators = () => {
    console.log(
      `areBothUsersTranslators?, self: ${auth?.isTranslator} otherRole: ${otherRole}`
    );

    return auth?.isTranslator && otherRole === "translator";
  };

  const handleHangup = (data) => {
    setCallEnd(new Date());
    disableMenu(false);

    console.log(`handleHangup?,otherRole data...: ${JSON.stringify(data)}`);
    console.log(`handleHangup?, otherRole...: ${data.fromRole}`);

    const bothTranslators =
      data.fromRole === "translator" && auth?.role === "translator";

    if (auth?.isUser || bothTranslators) {
      setHangupDialogBeforeReloadUser(true);
      setHangupDialogBeforeReloadMessageUser(
        "Połączenie zakończone przez drugą stronę"
      );
    } else {
      stopRecorder(ownMediaRecorder);
      stopRecorder(peerMediaRecorder);
      setHangupDialogBeforeReloadTranslator(true);
      setHangupDialogBeforeReloadMessageTranslator(
        "Połączenie zakończone przez drugą stronę. Czy chcesz utworzyć formularz wsparcia?"
      );
    }

    stopInCallManager();
  };

  const hideDialogRecording = () => {
    setDialogRecordingVisible(false);
    setRecordingAccepted(true);
  };

  const hideDialogInfo = () => {
    setDialogInfoVisible(false);
  };

  const reload = () => {
    if (Platform.OS === "web") window.location.reload();
    else {
      RNRestart.Restart();
    }

    // @ts-ignore
    if (metrics.current.running) {
      // @ts-ignore
      metrics.current.stopAllProbes();
    }
  };

  const hideDialogBeforeReload = () => {
    if (Platform.OS === "web") window.location.reload();
    else {
      RNRestart.Restart();
    }

    // @ts-ignore
    if (metrics.current.running) {
      // @ts-ignore
      metrics.current.stopAllProbes();
    }
  };

  const hideDialogError = () => {
    console.log(`close hideDialogError `, dialogErrorMessage);
    setDialogErrorVisible(false);

    if (dialogErrorMessage === "Błąd uwierzytelniania. Odśwież stronę.") {
      console.log("get back to login screen");
      setAuth(undefined);
      navigation.navigate("SignIn");
    } else {
      reload();
    }
  };

  const displayRecordingInfo = () => {
    console.log("displayRecordingInfo");
    setDialogRecordingVisible(true);
  };

  const registerPeerEvents = (
    pc,
    send,
    setRemoteStream,
    conn,
    peerId,
    theOtherRole
  ) => {
    console.log(`registerPeerEvents for ${peerId}`);

    pc.ondatachannel = function (event) {
      console.log("datachannel ondatachannel", JSON.stringify(event));

      otherDataChannel.current = event.channel;
      otherDataChannel.current.onmessage = function (message) {
        console.log("datachannel remote on message", message);
        if (message.data) {
          const data = JSON.parse(message.data);
          console.log("remote selfVideo is: ", data?.selfVideo);

          setRemoteCameraDisabled(!data?.selfVideo);
        }
      };
    };

    datachannel.current = pc.createDataChannel("data");

    datachannel.current.onerror = (error) =>
      console.log("datachannel Error:", error);

    datachannel.current.onmessage = (event) => {
      console.log(`datachannel received: ${event.data}`);
    };

    datachannel.current.onopen = () => {
      console.log("datachannel open");
    };

    datachannel.current.onclose = () => {
      console.log("datachannel close");
    };

    pc.onaddstream = (event) => {
      console.log(`On Add Remote Stream ${peerId}`);

      setRemoteStream(event.stream);

      if (Platform.OS === "web") {
        // console.log(`On Add Remote Stream {}`);
        handleRecorder(status, peerId, peerMediaRecorder, conn, event.stream);
        handleRecorder(
          status,
          auth?.email,
          ownMediaRecorder,
          conn,
          localStream
        );
      }

      console.info(
        `checking if participation form is completed auth?.isTranslator: ${auth?.isTranslator} otherRole: ${theOtherRole}`
      );
      if (auth?.isTranslator && theOtherRole === "user") {
        (async () => {
          const participationForm = await getResourceByEmail(peerId);
          console.log(
            `Checking if participationForm is completed: ${participationForm.completed}`
          );
          if (participationForm)
            setParticipationFormFinishedForUser(participationForm.completed);
        })();

        (async () => {
          const supportFormsInProgress = await getAllForUser(peerId);
          console.log(
            `Support forms in progress count# ${supportFormsInProgress.count}`
          );
          if (supportFormsInProgress)
            setSupportFormsInProgress(supportFormsInProgress);
        })();
      }
    };

    // Setup ice handling
    pc.onicecandidate = (event) => {
      if (event && event.candidate) {
        // console.debug(`candidate !!! `, JSON.stringify(event));

        // mobile
        if (event.candidate.candidate && event.candidate.candidate.candidate)
          console.info(
            `candidate !!! `,
            JSON.stringify(event.candidate.candidate.candidate)
          );
        if (event.candidate.candidate && !event.candidate.candidate.candidate)
          console.info(
            `candidate !!! `,
            JSON.stringify(event.candidate.candidate)
          );

        const from = auth?.email;
        const to = peerId;
        console.log(`sending candidate ${from} to ${to}`);
        // console.log(`pc !!! `, JSON.stringify(pc));
        // console.log(`otherId !!! `, otherId);

        send(
          {
            type: "candidate",
            candidate: event.candidate,
            from,
            to,
          },
          conn
        );
      }
    };
  };

  const onMessage = (msg) => {
    const data = JSON.parse(msg.data);

    if (!["ping", "pong"].includes(data.type))
      console.log("Data --------------------->", data);
    else console.debug("Data --------------------->", data);

    switch (data.type) {
      case "id": // ck signaling
        setSocketId(data.id);
        break;
      case "ping": // ck signaling
        console.debug("[HeartBeat] sending answer for heartbeat (pong)");
        send({ type: "pong", from: auth?.email }, conn);
        break;
      case "pong": // ck signaling
        console.debug("[HeartBeat] received answer for heartbeat (pong)");
        handleServerPong();
        break;
      case "users": // ck signaling
        const receivedUsers: Map<string, ConnectedUserDTO> = new Map<
          string,
          ConnectedUserDTO
        >();

        console.log(
          `[p2p] received users update ${JSON.stringify(data.users)}`
        );
        let translatorNames = Array<String>();
        data.users.map((value) => {
          console.debug(`stringified: ${JSON.stringify(value)}`);
          const [id, v] = [...value];
          console.debug(`id: ${id}`);
          console.debug(`v: ${JSON.stringify(v)}`);

          if (v.role === "translator") {
            console.info(`[TRANSLATOR SELECTION]: ${v}`);
            console.info(`[TRANSLATOR SELECTION]: ${v.role}`);
            translatorNames.push(id);
          }

          receivedUsers.set(id, v);
        });

        // final step is to check if user we are in call is READY
        // likely this means refreshed browser (stopped call as a result)

        // console.log(
        //   `[p2p] received users update ${JSON.stringify(receivedUsers)}`
        // );
        // handlePossibleCancelWithNewUsersMessage(receivedUsers);

        setConnectedUsers(receivedUsers);

        // for user we need to add active translator ?
        // console.log(`is user? ${auth?.isUser}`);

        if (auth?.isUser) {
          console.log(
            `[TRANSLATOR SELECTION] translator names ${JSON.stringify(
              translatorNames
            )}`
          );

          const availableTranslatorId =
            translatorNames[Math.floor(Math.random() * translatorNames.length)];

          console.log(`[TRANSLATOR SELECTION] current status ${status}`);
          if (
            [CALL_STATUS.READY, CALL_STATUS.NOTHING_YET].includes(status) &&
            availableTranslatorId
          ) {
            console.log(
              `[TRANSLATOR SELECTION] randomly selected translator ${JSON.stringify(
                availableTranslatorId
              )}`
            );
            setOtherId(availableTranslatorId);
            console.log(
              `[TRANSLATOR SELECTION] Setting other id`,
              availableTranslatorId
            );
          } else if (
            [CALL_STATUS.READY, CALL_STATUS.NOTHING_YET].includes(status) &&
            _.isEmpty(availableTranslatorId)
          ) {
            console.log(`[TRANSLATOR SELECTION] No translator available`);
          } else {
            console.log(
              `[TRANSLATOR SELECTION] Not updating translators as call status is ${status}`
            );
          }
        }

        break;
      case "callPeer": // ck signaling, starts before webrtc signaling
        handleCallPeer(data);
        break;
      case "answerPeer": // ck signaling, starts before webrtc signaling
        handleAnswerPeer(data);
        break;
      case "hangupPeer": // ck signaling
        console.log("Hangup");
        handleHangup(data);
        break;
      case "cancelPeer": // ck signaling
        console.log("Cancel Peer");
        handleCancel(data);
        break;
      case "chat": // ck signaling
        console.log("chat");
        handleChatMessage(data);
        break;
      case "offer": // webrtc signaling, just proxy (fully automatic, no customer interaction here)
        console.log("Received offer ", data.name);
        // console.debug("Received offer ", data.offer);
        handleOffer(data.offer, data.name);
        break;
      case "answer": // webrtc signaling just proxy (fully automatic, no customer interaction here)
        console.log("Answer");
        handleAnswer(data.answer);
        break;
      case "candidate": // webrtc signaling just proxy (fully automatic, no customer interaction here)
        console.log("Received candidate");
        handleCandidate(data.candidate);
        break;

      default:
        break;
    }
  };

  const send = (message, conn) => {
    if (!["ping", "pong", "recording"].includes(message.type))
      console.info("Sending message type", JSON.stringify(message.type));
    else console.debug("Sending message type", JSON.stringify(message.type));
    if (message.type === "chat")
      console.debug(`chat content ${JSON.stringify(message)}`);
    // console.log("conn", conn);

    if (conn && conn.current) conn.current.send(JSON.stringify(message));
    else console.warn("No socket connection.");
  };

  const callPeer = (receiverId: string) => {
    console.log(`Calling  _${receiverId}_`);

    console.log(`setOtherId in callPeer with  _${receiverId}_`);

    setOtherId(receiverId);

    const otherRole = connectedUsers.get(receiverId)
      ? connectedUsers.get(receiverId)?.role
      : "UNKNOWN";
    console.log(`otherRole in callPeer with  _${otherRole}_`);
    setOtherRole(otherRole);

    console.log(`Registering for peer events  _${receiverId}_`);
    registerPeerEvents(
      yourConn.current,
      send,
      setRemoteStream,
      conn,
      receiverId,
      otherRole
    );

    setCalling(true);
    setStatus(CALL_STATUS.OUTGOING_CALL);
    console.log(`calling via ws conn ${conn}`);
    const otherUser = receiverId;
    //@ts-ignore

    connectedUser.current = otherUser;
    console.log("Calling to", otherUser);

    send(
      {
        type: "callPeer",
        fromRole: auth?.role,
        from: auth?.email,
        to: receiverId,
      },
      conn
    );
  };

  const cancelCall = (receiverId: string) => {
    console.log(`cancelling call with  _${receiverId}_`);

    setOtherId("");
    setOtherRole("");
    setCalling(false);
    setStatus(CALL_STATUS.READY);

    connectedUser.current = undefined;

    send(
      {
        type: "cancelPeer",
        from: auth?.email,
        to: receiverId,
      },
      conn
    );
  };
  const sendOffer = (receiverId: string) => {
    console.log(`SEND OFFER to ${receiverId}`);
    // create an offer
    yourConn.current.createOffer().then((offer) => {
      yourConn.current.setLocalDescription(offer).then(() => {
        console.log("Sending Offer");
        // console.log(offer);
        send(
          {
            type: "offer",
            offer,
            name: auth?.email, // webrtc
            from: auth?.email, // ck
            to: receiverId, // ck
          },
          conn
        );
        // Send pc.localDescription to peer
      });
    });
  };

  const handleCallPeer = async (message) => {
    // make sure notification is not in the way
    setDialogErrorVisible(false);
    setDialogInfoVisible(false);

    console.log(JSON.stringify(message));
    console.log("[p2p] " + message.from + " is calling you.");

    console.log("[p2p] playing ring sound...");

    PlayRecordedAudio();

    startInCallManager();

    setStatus(CALL_STATUS.INCOMING_CALL);
    connectedUser.current = message.from;

    setIncomingCall(true);

    console.log(`[p2p] setOtherId in callPeer with  _${message.from}_`);
    setOtherId(message.from);

    console.log(`[p2p] otherRole in callPeer with  _${message.fromRole}_`);

    setOtherRole(message.fromRole);
  };

  function stopRecorder(mediaRecorder: React.MutableRefObject<null>) {
    if (mediaRecorder.current) {
      // @ts-ignore
      mediaRecorder.current.ondataavailable = undefined;
      // @ts-ignore
      mediaRecorder.current = undefined;
    }
  }

  function handleRecorder(
    status: string,
    recordedParty: string,
    mediaRecorder: React.MutableRefObject<null>,
    conn: React.MutableRefObject<null>,
    streamToRecord: MediaStream
  ) {
    console.log(`[Media Recorder] handleRecorder ${recordedParty}`);

    if (!recordedParty) console.warn("Don't know which recording it is ");

    if (recordedParty && auth?.role === "translator") {
      const recorderOptions = {
        mimeType: "video/webm",
        // videoBitsPerSecond: 5000000,
        // ignoreMutedMedia: true,
        // mimeType: "video/webm; codecs=vp8",
        videoBitsPerSecond: 400000, // 0.2 Mbit/sec.
      };

      console.log(`in call steram to record ${typeof streamToRecord}`);
      if (!streamToRecord) {
        console.log(`[Media Recorder] stream is not ready yet`);
        return;
      } else {
        console.log(`[Media Recorder] creating stream`);
      }
      // @ts-ignore
      mediaRecorder.current = new MediaRecorder(
        streamToRecord
        // recorderOptions
      );
      // @ts-ignore

      if (_.isEmpty(mediaRecorder.current))
        console.warn("mediaRecorder.current is null");

      mediaRecorder.current.start(
        // oneconfig.ck_env === "development" ? 99000 :
        1000 // started with 1s
      ); // 1000 - the number of milliseconds to record into each Blob

      // @ts-ignore
      mediaRecorder.current.ondataavailable = async (event) => {
        console.debug("Got blob data:", event.data);
        if (event.data && event.data.size > 0) {
          // console.log(`sockets conn ${conn.current.readystate}`);
          console.debug(`event.type ${event.type} ${recordedParty}`);

          // Converting blob to base64
          let reader = new FileReader();
          reader.onloadend = () => {
            // console.log(`payload ${JSON.stringify(reader.result)}`);
            // console.log(reader.result);
            // conn.current.send(event.data);
            send(
              {
                type: "recording",
                recordedParty: recordedParty,
                payload: reader.result,
              },
              conn
            );
          };
          reader.readAsDataURL(event.data);
        } else {
          console.log(`no data?  size: ${event.data.size}`);
        }
      };
    }
  }

  const rejectCall = async (offerRef) => {
    StopPlaying();
    setIncomingCall(false);
    send(
      {
        type: "answerPeer",
        answer: "reject",
        from: auth?.email,
        to: otherId,
      },
      conn
    );

    if (Platform.OS === "web") window.location.reload();
    else RNRestart.Restart();
  };

  const acceptCallPeer = async (peerId: string) => {
    StopPlaying();

    console.log(`Accepting CALL from ${peerId}`);
    console.log(`Registering for peer events  _${peerId}_`);
    console.log(`otherRole while accepting call  _${otherRole}_`);
    registerPeerEvents(
      yourConn.current,
      send,
      setRemoteStream,
      conn,
      peerId,
      otherRole
    );

    setIncomingCall(false);
    setCallActive(true);
    setCallStart(new Date());
    activateKeepAwake();
    setStatus("In call");
    disableMenu(true);

    send(
      {
        type: "answerPeer",
        answer: "accept",
        from: auth?.email,
        to: peerId,
      },
      conn
    );
  };

  const rejectCallPeer = async (peerId: string) => {
    StopPlaying();

    console.log(`Rejecting CALL from ${peerId}`);
    setIncomingCall(false);
    setCallActive(true);
    setStatus("In call");

    send(
      {
        type: "answerPeer",
        answer: "reject",
        peer: otherId,
      },
      conn
    );
  };

  const handleOffer = async (offer, name) => {
    //@ts-ignore

    console.log("handling offer", name, offer);
    // @ts-ignore
    offerRef.current = { name, offer };

    console.debug("offer ref ", offerRef);
    console.log("offerRef.current ", offerRef.current);
    //@ts-ignore
    console.log("offerRef.current.name ", offerRef.current.name);
    //@ts-ignore
    // const name = offerRef.current.name;
    //@ts-ignore
    // const offer = offerRef.current.offer;

    yourConn.current
      .setRemoteDescription(offer)
      .then(function () {
        console.log(`prepare answer`);
        connectedUser.current = name;
        // @ts-ignore
        return yourConn.current.createAnswer();
      })
      .then(function (answer) {
        // @ts-ignore
        yourConn.current.setLocalDescription(answer);
        console.log(`sending answer to ${name}`);
        send(
          {
            type: "answer",
            name: auth?.email,
            answer,
            from: auth?.email,
            to: name,
          },
          conn
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAnswerPeer = (data) => {
    console.log(`answer ${data.answer}`);
    if (data.answer === "reject") {
      setCalling(false);
      setStatus(CALL_STATUS.READY);
      setDialogInfoMessage(``);
      setDialogInfoVisible(false);

      setDialogErrorVisible(true);
      setDialogErrorMessage("Połączenie zostało odrzucone przez drugą stronę.");
    } else {
      setCallStart(new Date());
      setDialogInfoMessage(``);
      setDialogInfoVisible(false);
      setCalling(false);
      setCallActive(true);
      setStatus("In call");
      activateKeepAwake();
      startInCallManager();
      disableMenu(true);
      console.log(`received answerPeer`, data.answer);

      console.log(`sending offer to ${data.from}`);
      sendOffer(data.from);
    }
  };

  const handleAnswer = (answer) => {
    console.log(`Handle WebRTC answer ${answer}`);
    // @ts-ignore
    yourConn.current.setRemoteDescription(new RTCSessionDescription(answer));
  };

  //when we got an ice candidate from a remote user
  const handleCandidate = (candidate) => {
    setCalling(false);
    const checkCandidate = new RTCIceCandidate(candidate);

    // @ts-ignore
    yourConn.current.addIceCandidate(
      checkCandidate,
      function () {
        console.log("Added Ice Candidate");
      },
      function (err) {
        if (
          err.name === "InvalidStateError" &&
          err.message === "The remote description was null"
        )
          console.info(
            "Known issue while adding candidates: ",
            err.name,
            err.message
          );
        else console.warn("error adding candidate: ", err.name, err.message);
      }
    );
  };

  // console.log(`localStream before render localStream ${localStream}`);
  console.log(`status ${status}`);
  // console.warn(
  //   `[ConnectionQuality]  ${connectionQuality ? connectionQuality : undefined}`
  // );

  return (
    <LocalStreamContext.Consumer>
      {() => {
        return (
          <SafeAreaView style={styles.body}>
            <View testID="P2PCallScreen" style={{ display: "none" }}></View>
            {showSpinner && <Loading loading={showSpinner} />}
            {/* recording dialog */}
            <CustomDialog
              visible={dialogRecordingVisible}
              hideDialog={hideDialogRecording}
              dialogTitle={"Info"}
              dialogContent={GLOBAL_LABELS.recordingInfo}
              testID="confirm_recording_button"
            />

            <FAB
              visible={false}
              disabled={true}
              icon={"account-tie-voice-outline"}
              testID="p2p_transcription_info"
              style={baseStyles.rightTopP2P}
              onPress={() => {}}
            />

            {/* errors */}
            <CustomDialog
              visible={dialogErrorVisible}
              hideDialog={hideDialogError}
              dialogTitle={"Błąd"}
              dialogContent={dialogErrorMessage}
              testID="error_dialog"
            />

            <CustomDialog
              visible={dialogInfofVisible}
              hideDialog={hideDialogInfo}
              dialogTitle={"Info"}
              dialogContent={dialogInfoMessage}
              testID="info_dialog"
            />

            <CustomDialog
              visible={dialogHangupBeforeReloadUser}
              hideDialog={hideDialogBeforeReload}
              dialogTitle={"Info"}
              dialogContent={dialogHangupBeforeReloadMessageUser}
              testID="info_dialog_and_reload_user"
            />

            <CustomDialog
              visible={dialogHangupBeforeReloadTranslator}
              dialogType="YesNoOther"
              otherLabel="Problem techniczny"
              dialogActionNo={hideDialogBeforeReload}
              dialogActionOther={() => {
                send({ type: "technicalIssue" }, conn);
                hideDialogBeforeReload();
              }}
              dialogActionYes={() => {
                navigation.navigate("SupportForm", {
                  p2p: {
                    formOfSupportCode: "<30m",
                    userEmail: otherId,
                    translatorId: auth?.id,
                    supportDateStart: callStart ? callStart : new Date(),
                    supportDateEnd: callEnd ? callEnd : new Date(),
                  },
                });
              }}
              dialogTitle={"Info"}
              dialogContent={dialogHangupBeforeReloadMessageTranslator}
              testID="info_dialog_and_reload_translator"
            />

            {/* contains conf call, chat and buttons */}
            <View
              nativeID="conf_call_view"
              style={{
                flex: 1,
                flexDirection: "column",
                borderRadius: 20,
                borderStyle: "solid",
              }}
            >
              {/* top part */}
              <View
                nativeID="conf_call_content_container"
                //@ts-ignore
                style={styles.confCallContentContainer}
              >
                <View
                  nativeID="conf_call_videos_container"
                  //@ts-ignore
                  style={styles.confCallVideosContainer}
                >
                  <>
                    <View
                      style={{
                        display: "flex",
                        zIndex: 100,
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        // backgroundColor: "blue",
                      }}
                    >
                      <MyRTCView
                        zOrder={1}
                        mirror={true}
                        style={styles.video2Self}
                        videoId={"self"}
                        stream={
                          cameraOn && localStream ? localStream : undefined
                        }
                        // videoDisabled={!cameraOn}
                        muted={true}
                      />
                    </View>
                    {status === CALL_STATUS.IN_CALL && (
                      <UserInCallRemoteVideoView
                        volumeOn={volumeOn}
                        remoteStream={remoteStream}
                        remoteCameraDisabled={remoteCameraDisabled}
                      />
                    )}
                    {status === CALL_STATUS.OUTGOING_CALL && auth?.isUser && (
                      <UserWaitingForAnswerCallView />
                    )}
                    {status !== CALL_STATUS.OUTGOING_CALL &&
                      status !== CALL_STATUS.IN_CALL &&
                      auth?.isUser && <UserNotInCallView />}
                    {status !== CALL_STATUS.IN_CALL && auth?.isTranslator && (
                      <TranslatorNotInCallView
                        callPeer={callPeer}
                        connectedUsers={connectedUsers}
                        ownStatus={status}
                      />
                    )}
                  </>
                </View>

                {showWebRTCStats && (
                  <View style={styles.video2Self}>
                    <WebRTCStatsView data={webRTCStats}></WebRTCStatsView>
                  </View>
                )}

                <Chat
                  testID="chat_outer"
                  visible={chatOn}
                  send={(msg) => {
                    send(msg, conn);
                  }}
                  peer={otherId}
                  incomingChatMessage={incomingChatMessage}
                />

                <SupportFormsInCall visible={formsOn} userId={otherId} />
                {Platform.OS === "web" &&
                  auth?.isTranslator &&
                  otherRole === "user" &&
                  status === CALL_STATUS.IN_CALL && (
                    <ParticipationFormView
                      visible={participationOn}
                      email={otherId}
                      autosave={participationOn === true ? true : false}
                    />
                  )}
              </View>

              {/* buttons */}
              <View
                nativeID="conf_call_buttons_view"
                //@ts-ignore
                style={styles.stickyFooter}
              >
                <CCButtonsSection
                  debug={debug}
                  displayRecordingInfo={displayRecordingInfo}
                  cameraOn={cameraOn}
                  toggleCamera={toggleCamera}
                  micOn={micOn}
                  toggleMic={toggleMuteLocalStream}
                  chatOn={chatOn}
                  toggleChat={toggleChat}
                  formsOn={formsOn}
                  toggleForms={toggleForms}
                  participationOn={participationOn}
                  participationFormFinishedForUser={
                    participationFormFinishedForUser
                  }
                  toggleParticipation={toggleParticipation}
                  status={status}
                  incomingChatMessage={newChatNotification}
                  hangup={() => {
                    hangup(otherId);
                  }}
                  call={() => {
                    callPeer(otherId);
                  }}
                  cancelCall={() => {
                    cancelCall(otherId);
                  }}
                  acceptCall={() => {
                    acceptCallPeer(otherId);
                  }}
                  rejectCall={() => {
                    rejectCall(otherId);
                  }}
                  incomingCall={incomingCall}
                  connectedUsers={connectedUsers}
                  volumeOn={volumeOn}
                  toggleVolumeOn={toggleVolumeOn}
                  toggleWebRTCStats={toggleWebRTCStats}
                  otherId={otherId}
                  otherRole={otherRole}
                  disableCallButton={disableCallButton}
                  anySupportFormInProgress={
                    supportFormsInProgress && supportFormsInProgress?.length > 0
                  }
                />
              </View>
            </View>
          </SafeAreaView>
        );
      }}
    </LocalStreamContext.Consumer>
  );
};

export default P2PCall;

function startInCallManager() {
  if (Platform.OS !== "web") {
    try {
      console.log("[p2p] starting incall manager...");
      if (!_.isEmpty(InCallManager)) InCallManager.start({ media: "video" });
      else console.warn("InCallManager is null");
    } catch {
      console.warn("InCallManager start failed");
    }
  } else {
    console.log("[p2p] incall manager is not available on web.");
  }
}

function stopInCallManager() {
  if (Platform.OS !== "web") {
    try {
      console.log("[p2p] stopping incall manager...");
      if (!_.isEmpty(InCallManager)) InCallManager.stop();
      else console.warn("InCallManager is null");
    } catch {
      console.warn("InCallManager start failed");
    }
  }
}
