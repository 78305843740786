import React, { useEffect, useRef, useState } from "react";
import { Linking, Platform } from "react-native";
import { View, Image, Text, StyleSheet } from "react-native";
import { ResizeMode, Video } from "expo-av";
import { SafeAreaView } from "react-native";
import { ScrollView } from "react-native";
import { Button, Headline } from "react-native-paper";
import { DebugContext } from "../../debug-context";
import { useContext } from "react";
import { getPolicyVideoLink } from "../Policy";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

const Content = () => {
  const [uri, setUri] = useState();
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  useEffect(() => {
    releaseUserMedia();
  }, []);

  useEffect(() => {
    async function getContactVideo() {
      console.log(`fetch contact video link`);
      const response = await getPolicyVideoLink("contact");

      if (!uri) if (response && response.data) setUri(response.data.url);
    }

    getContactVideo();
  }, [uri]);

  return (
    <View
      testID="contact_container"
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      <View style={styles.top}>
        <Headline
          style={{
            display: "flex",
            flexGrow: 1,
            fontSize: 18,
            paddingTop: 40,
            textAlign: "center",
            textAlignVertical: "center",
            // backgroundColor: "yellow",
          }}
        >
          Jeśli masz problem z aplikacją, sugestie, bądź chciałbyś się z nami
          skontaktować, napisz e-maila lub wyślij film w PJM na adres:
        </Headline>

        <View
          style={{
            flex: 1,
            flexDirection: "column",
            // backgroundColor: "yellow",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
            // height: "50%",
            flexGrow: 0.1,
          }}
        >
          <Headline
            style={{ fontSize: 18, fontWeight: "bold" }}
            onPress={() => {
              console.log("Pressed");
              Linking.openURL("mailto:kontakt@centrumkomunikacji.org");
            }}
          >
            kontakt@centrumkomunikacji.org
          </Headline>
        </View>
      </View>

      <View style={styles.middle}>
        {Platform.OS === "web" && (
          <video
            style={styles.video}
            autoPlay={true}
            controls={true}
            src={uri}
          ></video>
        )}

        {uri && Platform.OS !== "web" && (
          <Video
            style={styles.videoMobile}
            source={{
              uri: uri,
            }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            isLooping
            shouldPlay
          />
        )}
      </View>
    </View>
  );
};

const Contact = () => {
  let debug = useContext(DebugContext).debug;
  return (
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <Content />
      </ScrollView>
    </SafeAreaView>
  );
};

export default Contact;

export const styles = StyleSheet.create({
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    alignSelf: "center", // center itself horizontally(always in outer container)
    maxWidth: "80%", // healthy maxWidth for big screen but will be 95 % for small screen
  },
  outerColumnContainerMobile: {
    display: "flex",
    height: "100%",
    overflow: "scroll",
  },
  justifiedText: {
    textAlign: "justify",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  centeredText: {
    textAlign: "center",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  top: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    paddingTop: Platform.OS === "web" ? 50 : 10,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  middle: {
    // width: Platform.OS === "web" ? "80%" : "100%",
    paddingTop: Platform.OS === "web" ? 50 : 20,
    paddingBottom: Platform.OS === "web" ? 50 : 20,
    // backgroundColor: "blue",
    // height: "100%",
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  bottom: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    // paddingTop: Platform.OS === "web" ? 50 : 20,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
  logo: {
    width: Platform.OS === "web" ? 120 : 60,
    height: Platform.OS === "web" ? 70 : 35,
    margin: 10,
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    // justifyContent: "center",
    padding: 10,
    width: "100%",
    height: 300,
    display: "flex",
    // borderStyle: "solid",
    // borderColor: "black",
    // position: "relative",
    // backgroundColor: "orange",
  },
});
