import { version } from "./package.json";
import { expo } from "./app.json";

const iosBuildNumber = expo.ios.buildNumber;
const androidVersionCode = expo.android.versionCode;

type OneConfigGeneric = {
  version: string;
  iosBuildNumber: string;
  androidVersionCode: string;
};

export type OneConfigFinal = OneConfigGeneric & {
  ck_env: string;
  marketing_url: string;
  api_url: string;
  signaling_endpoint: string;
  debug_ui_enabled: boolean;
};

const oneconfig: OneConfigGeneric = {
  version,
  iosBuildNumber,
  androidVersionCode,
};

export default oneconfig;
