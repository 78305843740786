import React, { useContext } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { Avatar, IconButton, TextInput, Button } from "react-native-paper";
import CheckBox from "expo-checkbox";

import { styles } from "./styles";
import { useController } from "react-hook-form";

interface ValidatedCheckboxProps {
  // value: boolean,
  toggle: (value: boolean) => void;
  label: string;
  errors: any;
  name: string;
  control: any;
  value: boolean;
  disabled?: boolean;
}

export default function ValidatedCheckbox(props: ValidatedCheckboxProps) {
  const { field } = useController({
    control: props.control,
    defaultValue: false,
    name: props.name,
  });

  // console.log(`checkbox value ${JSON.stringify(field.value)}`);
  // console.log(`props.rules ${JSON.stringify(props.rules)}`);
  // console.log(`props.errors ${JSON.stringify(props.errors)}`);
  // console.log(`props ${JSON.stringify(props.name)}`);
  // console.log(`props ${JSON.stringify(props.errors[props.name])}`);

  console.log(`ValidatedCheckbox ${props.label}  ${field.value}`);

  return (
    <View style={styles.inputCheckboxContainer} nativeID={field.name}>
      <CheckBox
        value={field.value}
        testID={field.name}
        onChange={field.onChange}
        disabled={props.disabled}
        onValueChange={props.toggle ? props.toggle : field.onChange}
      />

      <View style={[styles.acceptanceText, {}]}>
        <Text style={[styles.acceptanceText, {}]}>{props.label}</Text>

        {props.errors[props.name] &&
        props.errors[props.name].type == "required" ? (
          <Text style={styles.validationText}>Oświadczenie jest wymagane.</Text>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
}
