import { Platform } from "react-native";
import Constants from "expo-constants";
import { useWindowSize } from "../../hooks/window_size";
import { SELF_VIDEO_SIZE_MOBILE_HEIGHT_TRANSCRIPTION } from "../../styles/base";
import { SELF_VIDEO_SIZE_MOBILE_WIDTH_TRANSCRIPTION } from "../../styles/base";
import { SELF_VIDEO_SIZE_WEB_HEIGHT_TRANSCRIPTION } from "../../styles/base";

export const genericStyles = (
  debug: boolean,
  chatOn: boolean,
  participationOn: boolean
) => {
  const smallScreen = useWindowSize().smallScreen;
  return {
    container: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "red",
      padding: 10,
      margin: 10,
      // background-color: rgba(0,0,0,.35);
    },
    video: {
      display: "flex",
      justifyContent: "center",
      zIndex: -100,
      maxWidth: "100%",
      height: "auto",
      width: "100%",
      padding: 10,
      overflow: "scroll",
      position: "relative",
    },
    introVideo: {
      display: "flex",
      justifyContent: "center",
      zIndex: -100,
      maxWidth: "100%",
      // height: "auto",
      width: "100%",
      // padding: 10,
      top: SELF_VIDEO_SIZE_WEB_HEIGHT_TRANSCRIPTION,
      // overflow: "scroll",
      position: "relative",
    },
    videoMobile: {
      justifyContent: "center",
      padding: 30,
      width: "100%",
      height: "80%",
      borderStyle: "solid",
      borderColor: "black",
      position: "relative",
      // backgroundColor: "orange",
    },
    body: {
      // paddingHorizontal: 20,
      // paddingRight: 20,
      flex: 1,
      backgroundColor: `${debug == true ? "pink" : "white"}`,
    },
    confCallContentContainer: {
      flex: 1,
      flexDirection: "row",
      borderRadius: 20,
      borderStyle: "solid",
      flexGrow: 1,
      backgroundColor: `${debug == true ? "yellow" : undefined}`,
      // backgroundColor: `${debug == true ? "yellow" : undefined}`,
    },
    confCallVideosContainer: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",

      alignItems: Platform.OS === "web" ? "space-between" : "center",
      /*     flexGrow:
        Platform.OS === "web" && participationOn == true
          ? 12
          : Platform.OS === "web" && smallScreen === false && chatOn == true
          ? 16
          : 8, // so chat takes 1/4 of the space on web and more on mobile */
      backgroundColor: `${debug == true ? "orange" : undefined}`,
      borderRadius: 20,
    },
    confCallVideosContainerForTranscriber: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",

      alignItems: Platform.OS === "web" ? "space-between" : "center",
      flexGrow:
        Platform.OS === "web" && participationOn == true
          ? 3
          : Platform.OS === "web" && smallScreen === false && chatOn == true
          ? 4
          : 2, // so chat takes 1/4 of the space on web and more on mobile
      backgroundColor: `${debug == true ? "orange" : undefined}`,
      borderRadius: 20,
    },
    stickyFooter: {
      position: "absolute",
      left: 0,
      bottom: 0,
      right: 0,
    },
    video2PeerWrapper: {
      display: "flex",
      maxWidth: "100%",
      width: "100%",
      alignItems: "center",
    },
    introVideo2PeerWrapper: {
      display: "flex",
      maxWidth: "100%",
      width: "100%",
      alignItems: "center",
      backGroundColor: "red",
    },
    transcriberVideoSelf: {
      display: "none",
    },
    userVideo2Self: {
      padding: 2,
      width:
        Platform.OS === "web"
          ? "auto"
          : SELF_VIDEO_SIZE_MOBILE_WIDTH_TRANSCRIPTION,
      height:
        Platform.OS === "web"
          ? SELF_VIDEO_SIZE_WEB_HEIGHT_TRANSCRIPTION
          : SELF_VIDEO_SIZE_MOBILE_HEIGHT_TRANSCRIPTION,
      /* height:
        Platform.OS === "web"
          ? SELF_VIDEO_SIZE_WEB_HEIGHT
          : SELF_VIDEO_SIZE_MOBILE_HEIGHT,
 */
      zIndex: 300,
      // top: Platform.OS === "web" ? 0 : 48, // xman no problem with app bar overlap on web
      // maxWidth: '20vw',
      // position: "relative",
      backgroundColor: debug ? "yellow" : "undefined",
      // opacity: 0.5,
      // borderStyle: "solid",
      // borderStyle: 'ridge',
      borderWeight: 1,
    },
    introVideoMobile: {
      paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      zIndex: -100,
      backgroundColor: debug ? "blue" : "undefined",
      width: "100%",
      padding: 5,
      height: "80%", // xman hackish, leave space for self video
      position: "relative",
    },
    connectedUsers: {},

    button: {
      // flex: 1,
      width: 45,
      height: 45,
      borderRadius: 15,
      justifyContent: "center",
      alignItems: "center",
    },
  };
};
