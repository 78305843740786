import React from "react";
import { View } from "react-native";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { IconButton } from "react-native-paper";

interface CCButtonProps {
  testID?: string;
  disabled?: boolean;
  color?: string;
  icon: string;
  tooltip?: string;
  onPress: () => void;
}

const CCButton = ({
  testID,
  color,
  disabled,
  icon,
  tooltip,
  onPress,
}: CCButtonProps) => {
  const buttonRef = React.useRef(null);
  /* ref doesn't work in react-native-elements and react-native-paper for web so wrapping in RN View */
  return (
    <Tooltip title={tooltip} placement="top">
      <View ref={buttonRef}>
        <IconButton
          testID={testID}
          icon={icon}
          onPress={onPress}
          disabled={disabled}
          color={color}
        ></IconButton>
      </View>
    </Tooltip>
  );
};

export default CCButton;
