import React from "react";

function ToggleNumberOfParticipants() {
  return <>
    {/*    <TouchableOpacity style={styles.button} onPress={() => setParticipantsNumber(2)}>
      <View style={styles.button}>
        <MaterialCommunityIcons name="numeric-2-box-outline" size={24} color="black" />
      </View>
    </TouchableOpacity>
    <TouchableOpacity style={styles.button} onPress={() => setParticipantsNumber(3)}>
      <View style={styles.button}>
        <MaterialCommunityIcons name="numeric-3-box-outline" size={24} color="black" />
      </View>
    </TouchableOpacity>
    <TouchableOpacity style={styles.button} onPress={() => setParticipantsNumber(6)}>
      <View style={styles.button}>
        <MaterialCommunityIcons name="numeric-6-box-outline" size={24} color="black" />
      </View>
    </TouchableOpacity> */}
  </>;

}
export default ToggleNumberOfParticipants;