import * as React from "react";

import { useIsFocused } from "@react-navigation/native";
import CustomDialog from "../../components/CustomDialog";
import { useNavigation } from "@react-navigation/native";
import { getAll, getChatItems } from "../../services/calls.service";
import { getRecording } from "../../services/recordings.service";
import { AuthContext } from "../../auth-context";
import ChatIcon from "@mui/icons-material/ChatOutlined";
import RecordingIcon from "@mui/icons-material/Movie";
import { ChatContentView } from "./ChatContentView";
import { RecordingView } from "./RecordingView";
import { Text } from "react-native-paper";
import _ from "lodash";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Loading } from "../../components/Loading";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { plPL } from "../../localization/plPL";
import { handleError } from "../../utils";
import { Portal, Button } from "react-native-paper";
import { View } from "react-native";

const theme = createTheme({}, plPL);

const CallsView = () => {
  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [chatOn, setChatOn] = React.useState(false);
  const [chatContent, setChatContent] = React.useState([]);
  const [recordingOn, setRecordingOn] = React.useState(false);
  const [recordingContent, setRecordingContent] = React.useState({});

  console.log(`navigation translations ${JSON.stringify(navigation)}`);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const columns: GridColumns = [
    { field: "_id", headerName: "id", width: 250 },
    {
      field: "actions",
      type: "actions",
      headerName: "Czat",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        // console.log(`id: ${id}, row: ${JSON.stringify(row)}`);

        const chatAction =
          row.chatPresent && row.chatPresent === true ? (
            <GridActionsCellItem
              icon={<ChatIcon />}
              data-testid={"chat_" + row._id}
              label="Czat"
              onClick={() => {
                handleShowChat(row._id);
              }}
              color="inherit"
            />
          ) : (
            <></>
          );

        return [
          chatAction,
          <GridActionsCellItem
            icon={<RecordingIcon />}
            data-testid={"recording_" + row._id}
            label="Nagrania"
            onClick={() => {
              handleShowRecording(row._id);
            }}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "callTime",
      headerName: "Data wywołania",
      width: 250,
      editable: false,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "cancelTime",
      headerName: "Data anulowania",
      width: 250,
      editable: false,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "answerTime",
      headerName: "Data odebrania/odrzucenia",
      width: 250,
      editable: false,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "closedTime",
      headerName: "Data zakończenia",
      width: 250,
      editable: false,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("pl-PL"),
    },
    {
      field: "caller",
      headerName: "Dzwoniący",
      type: "string",
      editable: false,
      width: 250,
    },
    {
      field: "callee",
      headerName: "Odbierający",
      type: "string",
      editable: false,
      width: 250,
    },
    {
      field: "closedBy",
      headerName: "Zakończone przez",
      type: "string",
      editable: false,
      width: 250,
    },
    {
      field: "closedReason",
      headerName: "Sposób zakończenia",
      type: "string",
      editable: false,
      width: 100,
    },
    {
      field: "waitingSeconds",
      headerName: "Czas oczekiwania",
      type: "string",
      editable: false,
      width: 100,
    },
    {
      field: "durationSeconds",
      headerName: "Czas trwania",
      type: "string",
      editable: false,
      width: 100,
    },
    {
      field: "isAnswered",
      headerName: "Połączenie odebrane",
      renderCell: (params: GridRenderCellParams<boolean>) => (
        <Text>{params.value === true ? "Tak" : "Nie"}</Text>
      ),
      width: 100,
      editable: false,
    },
    {
      field: "technicalIssue",
      headerName: "Tłumacz zgłosił problem techniczny",
      renderCell: (params: GridRenderCellParams<boolean>) => (
        <Text>{params.value === true ? "Tak" : "Nie"}</Text>
      ),
      width: 100,
      editable: false,
    },
  ];

  const handleShowChat = (id: string) => {
    getChatItems(id)
      .then((result) => {
        console.log(`[Chat content] ${JSON.stringify(result)}`);
        setChatOn(true);
        setChatContent(result);
      })
      .catch((error) => {
        console.log("error:", error);
        alert("Błąd w trakcie pobierania listy połączeń.");
      });
  };

  const handleShowRecording = async (id: string) => {
    try {
      const result = await getRecording(id);
      console.log(`[recordings content] ${JSON.stringify(result)}`);
      setRecordingOn(true);
      setRecordingContent({ recording: result, callId: id });
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };

  React.useEffect(() => {
    if (_.isEmpty(auth)) {
      return;
    } else {
      console.log("[Calls] useEffect (with auth), auth:", auth);
      setshowSpinner(true);
      if (results.length === 0) {
        console.log("fetching data using ", auth);

        getAll()
          .then((results) => {
            setshowSpinner(false);
            console.log(`[Resources] useEffect (with auth), results:`, results);
            setResults(results);
          })
          .catch((error) => {
            handleError(
              setshowSpinner,
              error,
              setDialogErrorMessage,
              setDialogErrorVisible
            );
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, [auth]);

  return (
    // {/* <Surface> */}
    <div style={{ height: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />

        <Portal>
          <View
            style={{
              display: recordingOn ? "flex" : "none",
              height: "80%",
              width: "60%",
              flex: 1,
              // paddingTop: 100,
              marginTop: "20%",
              marginLeft: "20%",
              marginBottom: "10%",
              marginRight: "20%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "lightgray",
              /*       borderStyle: "solid",
              borderColor: "black", */
            }}
          >
            <RecordingView recordingContent={recordingContent} />

            <Button
              icon="keyboard-backspace"
              testID="back_recording"
              mode="contained"
              style={{ margin: 5 }}
              onPress={() => {
                setRecordingOn(false);
                setRecordingContent({});
              }}
            >
              Powrót
            </Button>
          </View>
        </Portal>

        <Portal>
          <View
            style={{
              display: chatOn ? "flex" : "none",
              height: "80%",
              width: "60%",
              flex: 1,
              // paddingTop: 100,
              marginTop: "20%",
              marginLeft: "20%",
              marginBottom: "10%",
              marginRight: "20%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "lightgray",
              /*       borderStyle: "solid",
              borderColor: "black", */
            }}
          >
            <ChatContentView chatContent={chatContent} />

            <Button
              icon="keyboard-backspace"
              mode="contained"
              testID="back_chat"
              style={{ margin: 5 }}
              onPress={() => {
                setChatOn(false);
                setChatContent([]);
              }}
            >
              Powrót
            </Button>
          </View>
        </Portal>

        <DataGrid
          style={{ backgroundColor: "#fff" }}
          rows={results}
          getRowId={(row) => row._id}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          disableSelectionOnClick
        />
      </ThemeProvider>
    </div>
  );
};

export default CallsView;
