import { now } from "lodash";
import moment from "moment";
import { MyEvent } from "./MyEvent";

export const translatorStats = (
  events,
  translator: string,
  asOfDate?: string
) => {
  const useDate = asOfDate ? asOfDate : moment().toISOString();
  // console.log(`[event stats] all events: ${events.length}`);
  // console.log(`len: ${events.length}`);
  const eventsForTranslator = events.filter(
    (event) => event?.resourceId === translator
  );

  const d = dayEventsLength(eventsForTranslator, translator, useDate);
  const w = weekEventsLength(eventsForTranslator, translator, useDate);
  const m = monthEventsLength(eventsForTranslator, translator, useDate);

  return { d: d, w: w, m: m };
};

function dayEventsLength(
  eventsForTranslator: any,
  translator: string,
  asOfDate: string
) {
  console.log(`calculate day stats ${translator} as of ${asOfDate}`);
  const todayStart = moment(asOfDate).startOf("day");
  const todayEnd = moment(asOfDate).add(1, "day").startOf("day");

  return eventsDurationBetweenDates(eventsForTranslator, todayStart, todayEnd);
}

function weekEventsLength(
  eventsForTranslator: any,
  translator: string,
  asOfDate: string
) {
  console.log(`calculate week stats ${translator} as of ${asOfDate}`);
  const slotStart = moment(asOfDate).startOf("week");
  const slotEnd = moment(asOfDate).add(1, "week").startOf("week");

  return eventsDurationBetweenDates(eventsForTranslator, slotStart, slotEnd);
}

function monthEventsLength(
  eventsForTranslator: any,
  translator: string,
  asOfDate: string
) {
  console.log(`calculate month stats ${translator} as of ${asOfDate}`);
  const slotStart = moment(asOfDate).startOf("month");
  const slotEnd = moment(asOfDate).add(1, "month").startOf("month");

  return eventsDurationBetweenDates(eventsForTranslator, slotStart, slotEnd);
}

function eventsDurationBetweenDates(
  eventsForTranslator: any,
  slotStart,
  slotEnd
) {
  const dayEvents = eventsForTranslator.filter((event) => {
    // console.log(`[event stats] todayStart start ${todayStart}`);
    /* console.log(
      `[event stats] eventStart  ${moment(event.start).toISOString()}`
    ); */
    // console.log(`[event stats] eventEnd    ${event.end}`);

    return (
      event.start > slotStart &&
      event.start < slotEnd &&
      event.title !== "L4" &&
      event.title !== "Urlop"
    );
  });

  const todayEventsCount = dayEvents.length;
  // console.log(
  //   `[event stats] today eventsFor ${translator} ${todayEventsCount}`
  // );
  let durationHours = 0;

  if (todayEventsCount > 0) {
    // console.log(`[event stats] there are events for ${translator}`);
    dayEvents.map((event) => {
      // console.log(`event start ${event.start}`);
      // console.log(`event end ${event.end}`);

      // console.log(`event start type ${typeof event.start}`);
      const eventDuration = moment(event.end).diff(event.start, "hours");
      // console.log(`[event stats] duration  ${eventDuration}`);
      durationHours += eventDuration;
    });
  } /* else {
    console.log(`[event stats] no events for ${translator}`);
  } */

  /*  console.log(
    `[event stats] today eventsFor ${translator} ${todayEventsCount}, duration ${durationHours}`
  ); */
  return durationHours;
}
