import React from "react";
import { Platform, View } from "react-native";
import {
  SELF_VIDEO_SIZE_MOBILE_HEIGHT,
  SELF_VIDEO_SIZE_WEB_HEIGHT,
} from "../../styles/base";
import { useEffect } from "react";
import axios from "axios";
import oneconfig from "../../oneconfig";
import Constants from "expo-constants";
import { Button, Colors, IconButton, Paragraph } from "react-native-paper";

type UserNotInCallViewProps = {};

export default function UserNotInCallView({}: UserNotInCallViewProps) {
  const introVideo = React.useRef(null);
  const [introVideoLink, setIntroVideoLink] = React.useState("");
  const [serviceDialogVisible, setServiceDialogVisible] = React.useState(true);

  function hideServiceDialog() {
    setServiceDialogVisible(false);
  }

  const colors = [Colors.red100, Colors.red900];
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    const id = setInterval(() => setCount((oldCount) => oldCount + 1), 500);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    async function getIntroVideo() {
      const response = await axios({
        url: `${oneconfig.api_url}/welcome-movie`,
        method: "GET",
        // responseType: "application/json",
      });

      // console.log(`policy link response ${JSON.stringify(response)}`);
      if (!introVideoLink)
        if (response && response.data) setIntroVideoLink(response.data.url);
    }

    getIntroVideo();
  }, [introVideoLink]);

  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // width: "100%",
          margin: 0,
          top:
            Platform.OS === "web"
              ? -1 * SELF_VIDEO_SIZE_WEB_HEIGHT
              : -1 * SELF_VIDEO_SIZE_MOBILE_HEIGHT,
        }}
      >
        <View
          style={{
            display: "flex",
            maxWidth: Platform.OS === "web" ? "100vw" : undefined,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: Platform.OS === "web" ? "100vw" : "100%",
            height: Platform.OS === "web" ? "90vh" : "90%",
            // backgroundColor: "red",
          }}
        >
          <Button
            icon="phone-ring-outline"
            labelStyle={{ fontSize: 40 }}
            color={colors[count % colors.length]}
          >
            Proszę czekać
          </Button>
        </View>
      </View>
    </>
  );
}

const styles = {
  introVideo: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    // height: "auto",
    width: "100%",
    // padding: 10,
    top: SELF_VIDEO_SIZE_WEB_HEIGHT,
    // overflow: "scroll",
    position: "relative",
  },
  introVideoMobile: {
    paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
    zIndex: -100,
    width: "100%",
    padding: 5,
    height: "80%", // xman hackish, leave space for self video
    position: "relative",
  },
};
