import React, { useContext, useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { Button, Checkbox, Colors } from "react-native-paper";
import { styles } from "../styles";
import { ResizeMode, Video } from "expo-av";
import LABELS from "./labels";
import { DebugContext } from "../../debug-context";
import { useNavigation } from "@react-navigation/native";
import { AxiosError } from "axios";
import CustomDialog from "../CustomDialog";
import ValidatedPolicyCheckbox from "./ValidatedPolicyCheckbox";
import PolicyInfo from "./PolicyInfo";
import ValidatedTextInput from "../ValidatedTextInput";
import { useForm } from "react-hook-form";
import { Loading } from "../Loading";
import { handleError, minPasswordStrength } from "../../utils";
import MUI_THEME from "../../styles/index.native";
import { signUpCall } from "../../services/user.service";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

import {
  PickDocumentInput,
  UploadedDocumentMetadata,
} from "../PickDocumentInput";
import { getPolicyVideoLink } from "../../screens/Policy";
import ValidatedCheckbox from "../ValidatedCheckbox";
type FormData = {
  firstName: string;
  lastName: string;
  password: string;
  password2: string;
  email: string;
  phone: string;
  declaredRefugee: boolean;
  ckGPRDChecked: boolean;
  ckPrivacyPolicyConsent: boolean;
  ckRecordingConsent: boolean;
  disabilityDocument: UploadedDocumentMetadata;
  disabilityDocumentFileName: string | undefined;
};

export default function Registration() {
  const navigation = useNavigation();
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [helpVideoUri, setHelpVideoUri] = useState();
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  useEffect(() => {
    releaseUserMedia();
  }, []);

  useEffect(() => {
    console.log(`fetch contact video link`);
    getPolicyVideoLink("registration_help")
      .then((response) => {
        /*  console.log(
          `fetch contact video link ${JSON.stringify(response.data)}`
        ); */
        setHelpVideoUri(response.data.url);
      })
      .catch((error) => console.log(error));
  }, [helpVideoUri]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur", // "onChange"
  });

  // console.log(`errors ${JSON.stringify(errors)}`);
  let debug = useContext(DebugContext).debug;

  const [showSpinner, setshowSpinner] = React.useState(false);

  const [image, setImage] = React.useState(null);

  const [consent1, setConsent1] = React.useState(false);
  const [consent2, setConsent2] = React.useState(false);
  const [consent3, setConsent3] = React.useState(false);
  const [consent4, setConsent4] = React.useState(false);
  const [consent5, setConsent5] = React.useState(false);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const [visible, setVisible] = React.useState(false);

  const hideDialog = () => {
    setVisible(false);
    navigation.navigate("SignIn");
  };
  const onSubmit = async (data: {}) => {
    console.log(
      "react-form-hook validation ok, checking disability document..."
    );

    setshowSpinner(true);

    try {
      const fetchResponse = await signUpCall(data);
      console.log(`fetchResponse ${JSON.stringify(fetchResponse)}`);
      console.log(
        `fetchResponse.response ${JSON.stringify(fetchResponse.response)}`
      );
      console.log(
        `fetchResponse.response.error ${fetchResponse.response.error}`
      );

      if (fetchResponse && fetchResponse.response.error) {
        console.log(`throw error ${fetchResponse.response.error}`);
        if (typeof fetchResponse.response.error === "object")
          throw new Error(fetchResponse.response.error.message);
        else throw new Error(fetchResponse.response.error);
      } else {
        setshowSpinner(false);
        navigation.navigate("RegistrationFirstStep");
      }
    } catch (error) {
      console.log(`signUpCall failed`);
      setshowSpinner(false);
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  return (
    <View
      testID="registration_container" // used i.e. in appium
      // style={{ backgroundColor: "red" }}
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      {showSpinner && <Loading loading={showSpinner} />}
      <CustomDialog
        visible={dialogVisible}
        hideDialog={hideDialog}
        dialogTitle={""}
        dialogContent={LABELS.signUpDialogFirstStep}
      />
      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />

      {/* fields  */}
      <View
        testID="fields"
        style={[
          { padding: 10 },
          debug === true ? { backgroundColor: "blue" } : undefined,
        ]}
      >
        <View>
          {Platform.OS === "web" && (
            <video
              style={styles.video}
              autoPlay={true}
              controls={true}
              src={helpVideoUri}
            ></video>
          )}

          {Platform.OS !== "web" && (
            <Video
              style={styles.videoMobile}
              source={{
                uri: helpVideoUri,
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              isLooping
              shouldPlay
            />
          )}
        </View>

        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="firstName"
            autoCorrect={true}
            control={control}
            rules={{ required: true }}
            placeholder={"Imię"}
            errors={errors}
            leftIconName={"account-check-outline"}
          />
        </View>

        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="lastName"
            autoCorrect={true}
            control={control}
            rules={{ required: true }}
            placeholder={"Nazwisko"}
            errors={errors}
            leftIconName="account-check-outline"
          />
        </View>
        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="phone"
            autoCorrect={true}
            control={control}
            rules={{ required: false }}
            placeholder={"Numer telefonu"}
            errors={errors}
            leftIconName="phone-outline"
          />
        </View>
        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="email"
            autoCorrect={true}
            control={control}
            rules={{
              required: "To pole jest wymagane",
              pattern: {
                value:
                  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                message: "Nieprawidłowy adres email",
              },
            }}
            placeholder={"Email"}
            errors={errors}
            leftIconName="email-outline"
          />
        </View>
        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="password"
            placeholder="Hasło"
            autoCorrect={false}
            rules={{
              required: "To pole jest wymagane",
              validate: {
                required: (value: boolean) => {
                  return value ? true : false;
                },
                minPasswordStrength: minPasswordStrength(3),
              },
            }}
            errors={errors}
            control={control}
            secureTextEntry={true}
            leftIconName={"form-textbox-password"}
          />
        </View>

        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="password2"
            placeholder="Powtórz hasło"
            autoCorrect={false}
            rules={{
              required: "To pole jest wymagane",
              validate: {
                required: (value: boolean) => {
                  return value ? true : false;
                },
                minPasswordStrength: minPasswordStrength(3),
              },
            }}
            errors={errors}
            required={true}
            control={control}
            secureTextEntry={true}
            leftIconName={"form-textbox-password"}
          />
        </View>

        <PickDocumentInput
          documentName="disabilityDocument"
          downloadAvailable={false}
          label="Orzeczenie"
          // download={downloadFile}
          getValues={getValues}
          setValue={setValue}
          rules={{ required: true }}
          required={true}
          control={control}
          errors={errors}
        />

        {/* Checkbox container */}
        <View
          style={{
            flex: 1,
            margin: 10,
            display: "flex",
            height: "100%",
          }}
        >
          <ValidatedCheckbox
            name="preferredWrittenCommunication"
            control={control}
            label={"Preferowana komunikacja tylko pomocą pisma"}
            errors={errors}
          />

          <ValidatedCheckbox
            name="declaredRefugee"
            control={control}
            disabled={true}
            // rules={{ required: false }}
            // toggle={setDeclaredRefugee}
            label={"Posiadam status uchodźcy/Я маю статус УКР"}
            // consent={"oswiadczenie1_zgoda_na_przetwarzanie_danych_osobowych"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent1"
            control={control}
            rules={{ required: true }}
            toggle={setConsent1}
            label={LABELS.ck1}
            consent={"oswiadczenie1_zgoda_na_przetwarzanie_danych_osobowych"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent2"
            control={control}
            rules={{ required: true }}
            toggle={setConsent2}
            label={LABELS.ck2}
            consent={"oswiadczenie2_zgoda_na_upowszechnianie_wizerunku"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent3"
            control={control}
            rules={{ required: true }}
            toggle={setConsent3}
            label={LABELS.ck3}
            consent={"oswiadczenie3_zgoda_na_nagrywanie"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent4"
            control={control}
            rules={{ required: true }}
            toggle={setConsent4}
            label={LABELS.ck4}
            consent={"oswiadczenie4_zapoznanie_sie_z_regulaminem"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent5"
            control={control}
            rules={{ required: true }}
            toggle={setConsent5}
            label={LABELS.ck5}
            consent={"oswiadczenie5_zapoznanie_sie_z_rodo"}
            errors={errors}
          />

          <PolicyInfo label={LABELS.termsOfService} consent={"regulamin"} />
          <PolicyInfo
            label={LABELS.privacyPolicy}
            consent={"polityka_prywatnosci"}
          />
        </View>
      </View>

      {/* buton sign up  */}
      <View
        style={[
          debug === true ? { backgroundColor: "yellow" } : {},
          {
            display: "flex",
          },
        ]}
      >
        <View
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <Button
            testID="button_sign_up"
            onPress={handleSubmit(onSubmit)}
            mode="contained"
            color={Colors.blue500}
            labelStyle={{ color: "white", fontWeight: "bold" }}
            style={{ padding: 5 }}
          >
            {LABELS.signUp}
          </Button>
        </View>
      </View>

      {/* already sign up  */}
      <View
        testID="already_sign_up"
        style={[
          debug === true ? { backgroundColor: "cyan" } : undefined,
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            marginTop: 5,
            marginHorizontal: 30,
            marginBottom: 20,
          },
        ]}
      >
        <Text
          style={{
            color: MUI_THEME.palette.secondary.main,
            fontWeight: "bold",
          }}
        >
          {LABELS.alreadySignUpQuestion}
        </Text>
        <TouchableOpacity
          style={[]}
          onPress={() => navigation.navigate("SignIn")}
        >
          <Text
            style={{
              color: MUI_THEME.palette.primary.main,
              fontWeight: "bold",
            }}
          >
            {LABELS.signIn}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
