import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { View, Image, Text, StyleSheet } from "react-native";
import { ResizeMode, Video } from "expo-av";
import { SafeAreaView } from "react-native";
import { ScrollView } from "react-native";
import { DebugContext } from "../../debug-context";
import { useContext } from "react";
import { getPolicyVideoLink } from "../Policy";

const Content = () => {
  const [uri, setUri] = useState();

  useEffect(() => {
    console.log(`fetch contact video link`);
    getPolicyVideoLink("project_info")
      .then((response) => {
        console.log(
          `fetch contact video link ${JSON.stringify(response.data)}`
        );

        setUri(response.data.url);
      })
      .catch((error) => console.log(error));
  }, [uri]);

  return (
    <View
      testID="registration_container"
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      <View style={styles.top}>
        <Text style={styles.justifiedText}>
          Centrum Komunikacji jest instrumentem wsparcia aktywności zawodowej
          osób z niepełnosprawnościami komunikujących się w języku migowym i
          powstało w ramach projektu "Włączenie wyłączonych - aktywne
          instrumenty wsparcia osób niepełnosprawnych na rynku pracy".
        </Text>
        <Text style={styles.justifiedText}>
          Projekt realizowany jest przez Biuro Pełnomocnika Rządu ds. Osób
          Niepełnosprawnych w partnerstwie z Polskim Związkiem Głuchych, Polską
          Organizacją Pracodawców Osób Niepełnosprawnych oraz Stowarzyszeniem
          Czas, Przestrzeń, Tożsamość.
        </Text>
        <Text style={styles.justifiedText}>
          Realizowany jest w ramach Programu Operacyjnego Wiedza Edukacja Rozwój
          na lata 2014-2020, Działania 2.6 Wysoka jakość polityki na rzecz
          włączenia społecznego i zawodowego osób niepełnosprawnych.
        </Text>
        <Text style={styles.centeredText}>
          Centrum Komunikacji testowane będzie do 30.05.2023 r.
        </Text>
        <Text style={styles.centeredText}>
          Centrum działa od poniedziałku do piątku poza świętami i dniami
          ustawowo wolnymi od pracy w godzinach od 8.00-20.00.
        </Text>
      </View>

      <View style={styles.middle}>
        {Platform.OS === "web" && (
          <video
            style={styles.video}
            autoPlay={true}
            controls={true}
            src={uri}
          ></video>
        )}

        {Platform.OS !== "web" && (
          <Video
            style={styles.videoMobile}
            source={{
              uri: uri,
            }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            isLooping
          />
        )}
      </View>
    </View>
  );
};

const Info = () => {
  let debug = useContext(DebugContext).debug;
  return (
    <SafeAreaView
      style={[
        styles.body,
        debug === true ? { backgroundColor: "green" } : undefined,
      ]}
    >
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <Content />
      </ScrollView>
    </SafeAreaView>
  );
};

export default Info;

export const styles = StyleSheet.create({
  outerColumnContainer: {
    flex: 1, // flex with default direction 'row'
    marginTop: 10, // marginTop healthy margin
    flexDirection: "column", // override of default direction to 'column'
    alignSelf: "center", // center itself horizontally(always in outer container)
    // justifyContent: 'space-around',     // xman space around to fill all height available, commented out because on large screens it takes to spread
    maxWidth: "80%", // healthy maxWidth for big screen but will be 95 % for small screen
  },
  outerColumnContainerMobile: {
    display: "flex",
    height: "100%",
    overflow: "scroll",
    // overflow: 'visible',
    // minHeight: 1200,
    // overFlow: 'auto'
    // wrap: 'wrap'
    // flex: "flex:",
    // flexGrow: ,
    // flexDirection: 'column',
    // justifyContent: 'space-around',
    // backgroundColor: 'red'
  },
  justifiedText: {
    textAlign: "justify",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  centeredText: {
    textAlign: "center",
    padding: Platform.OS === "web" ? 20 : 10,
  },
  top: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    paddingTop: Platform.OS === "web" ? 50 : 10,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  middle: {
    // width: Platform.OS === "web" ? "80%" : "100%",
    paddingTop: Platform.OS === "web" ? 50 : 20,
    paddingBottom: Platform.OS === "web" ? 50 : 20,
    // backgroundColor: "blue",
    // height: "100%",
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 3,
  },
  bottom: {
    // width: Platform.OS === "web" ? "80%" : "95%",
    // paddingTop: Platform.OS === "web" ? 50 : 20,
    maxWidth: 1000,
    justifyContent: "space-around",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
  logo: {
    width: Platform.OS === "web" ? 120 : 60,
    height: Platform.OS === "web" ? 70 : 35,
    margin: 10,
  },
  video: {
    display: "flex",
    justifyContent: "center",
    zIndex: -100,
    maxWidth: "100%",
    height: "auto",
    width: "100%",
    padding: 10,
    overflow: "scroll",
    position: "relative",
  },
  videoMobile: {
    // justifyContent: "center",
    padding: 10,
    width: "100%",
    height: 300,
    display: "flex",
    // borderStyle: "solid",
    // borderColor: "black",
    // position: "relative",
    // backgroundColor: "orange",
  },
});
