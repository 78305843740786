import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";
import { CheckboxComponent } from "./CheckboxComponent";

type SelfCommunicativeProps = {
  resource: ParticipationFormDocument;
  errors?: {};
  setResource: React.Dispatch<any>;
};

export const SelfCommunicative = ({
  resource,
  errors,
  setResource,
}: SelfCommunicativeProps) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} style={{ paddingTop: 15 }}>
        <Typography variant="body1">
          15. Proszę podać sposób w jaki się Pan/Pani komunikuje (można wskazać
          kilka):
        </Typography>
      </Grid>
      <CheckboxComponent
        name="communicative_pjm"
        label="W polskim języku migowym"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="communicative_polish_spoken"
        label="W języku polskim w mowie"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="communicative_polish_written"
        label="W języku polskim w piśmie"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="communicative_reading_lips"
        label="Odczytuję mowę z ruchu ust"
        resource={resource}
        setResource={setResource}
      />

      <TextField
        margin="normal"
        id="communicative_other"
        fullWidth
        label="W inny sposób, jaki?"
        value={
          resource && resource.communicative_other
            ? resource.communicative_other
            : ""
        }
        onChange={(event) => {
          setResource({
            ...resource,
            communicative_other: event.target.value,
          });
        }}
      />
    </Grid>
  );
};
