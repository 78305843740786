import { Platform } from "react-native";
import Constants from "expo-constants";
// const {debug} = useContext(DebugContext)
import { useWindowSize } from "../../hooks/window_size";

// export const SELF_VIDEO_SIZE_MOBILE_HEIGHT = 100;
// export const SELF_VIDEO_SIZE_MOBILE_WIDTH = 100;

import { SELF_VIDEO_SIZE_MOBILE_HEIGHT } from "../../styles/base";
import { SELF_VIDEO_SIZE_MOBILE_WIDTH } from "../../styles/base";
import { SELF_VIDEO_SIZE_WEB_HEIGHT } from "../../styles/base";
import { SELF_VIDEO_SIZE_WEB_WIDTH } from "../../styles/base";

export const genericStyles = (debug: boolean, chatOn: boolean) => {
  const smallScreen = useWindowSize().smallScreen;

  return {
    outerColumnContainer: {
      flex: 1, // flex with default direction 'row'
      marginTop: 10, // marginTop healthy margin
      flexDirection: "column", // override of default direction to 'column'
      alignSelf: "center", // center itself horizontally(always in outer container)
      maxWidth: Platform.OS === "web" ? "80%" : undefined, // healthy maxWidth for big screen but will be 95 % for small screen
    },
    outerColumnContainerMobile: {
      display: "flex",
      height: "100%",
      overflow: "scroll",
    },
    container: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "red",
      padding: 10,
      margin: 10,
      // background-color: rgba(0,0,0,.35);
    },
    video: {
      display: "flex",
      justifyContent: "center",
      zIndex: -100,
      maxWidth: "100%",
      height: "auto",
      width: "100%",
      padding: 10,
      overflow: "scroll",
      position: "relative",
    },
    introVideo: {
      display: "flex",
      justifyContent: "center",
      zIndex: -100,
      maxWidth: "100%",
      // height: "auto",
      width: "100%",
      // padding: 10,
      // top: SELF_VIDEO_SIZE_WEB_HEIGHT,
      // overflow: "scroll",
      position: "relative",
    },
    videoMobile: {
      justifyContent: "center",
      padding: 30,
      width: "100%",
      height: "80%",
      // height: Platform.OS === "web" ? "80%" : undefined,
      borderStyle: "solid",
      borderColor: "black",
      position: "relative",
      // backgroundColor: "orange",
    },
    body: {
      flex: 1,
      backgroundColor: `${debug == true ? "pink" : "white"}`,
    },
    confCallContentContainer: {
      flex: 1,
      flexDirection: "row",
      // borderRadius: 20,
      borderStyle: "solid",
      flexGrow: 1,
      backgroundColor: `${debug == true ? "yellow" : undefined}`,
      // backgroundColor: "orange",
    },
    confCallVideosContainer: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      // alignItems: 'center',
      justifyContent: "space-between",
      alignItems: "stretch",
      flexGrow:
        Platform.OS === "web" && smallScreen === false
          ? 3
          : chatOn == true && smallScreen === false
          ? 0.2
          : 1, // so chat takes 1/4 of the space on web and more on mobile
      backgroundColor: `${debug == true ? "orange" : undefined}`,
      // backgroundColor: "yellow",
      borderRadius: 20,
    },
    confCallVideosContainerMobile: {
      flex: 1,
      zIndex: 10,
      flexDirection: "column",
      // flexWrap: "wrap",
      // alignItems: 'center',
      // padding: 5,
      justifyContent: "space-between",
      flexGrow: 1, // so chat takes 1/4 of the space on web and more on mobile
      backgroundColor: `${debug == true ? "orange" : undefined}`,
      // backgroundColor: "red",
      // borderRadius: 20,
    },
    stickyFooter: {
      position: "absolute",
      left: 0,
      bottom: 0,
      right: 0,
    },
    video2PeerWrapper: {
      display: "flex",
      maxWidth: "100%",
      width: "100%",
      alignItems: "center",
    },
    introVideo2PeerWrapper: {
      display: "flex",
      maxWidth: "100%",
      width: "100%",
      alignItems: "center",
      backGroundColor: "red",
    },
    video2Self: {
      padding: 5,
      width: Platform.OS === "web" ? SELF_VIDEO_SIZE_WEB_WIDTH : 1920 / 8,
      height: Platform.OS === "web" ? SELF_VIDEO_SIZE_WEB_HEIGHT : 1080 / 8,
      zIndex: 300,
      top: Platform.OS === "web" ? 0 : 48, // xman no problem with app bar overlap on web
      // maxWidth: '20vw',
      position: "relative",
      backgroundColor: debug ? "yellow" : "undefined",
      // opacity: 0.5,
      // borderStyle: "solid",
      // borderStyle: 'ridge',
      borderWeight: 1,
    },
    video2SelfMobile: {
      padding: 5,
      width: 1920 / 8,
      height: 1080 / 8,
      zIndex: 500,
      left: -1920 / 24,
      top: 0, // xman no problem with app bar overlap on web
      // maxWidth: '20vw',
      // position: "relative",
      // backgroundColor: "yellow",
      position: "relative",
      backgroundColor: debug ? "yellow" : "undefined",
      // opacity: 0.5,
      // borderStyle: "solid",
      // borderStyle: 'ridge',
      borderWeight: 1,
    },
    video2Peer: {
      paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
      // Platform.OS === "web"
      //   ? SELF_VIDEO_SIZE_WEB_HEIGHT
      //   : SELF_VIDEO_SIZE_MOBILE_HEIGHT,
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      top:
        Platform.OS === "web"
          ? -1 * (SELF_VIDEO_SIZE_WEB_HEIGHT + 20) // additional padding as of self video
          : -1 * SELF_VIDEO_SIZE_MOBILE_HEIGHT + 20,
      maxHeight: Platform.OS === "web" ? "90vh" : "90%", // xman leave space for appbar and statusbar
      // maxHeight: '100vh',
      zIndex: -100,
      // backgroundColor: "blue",
      backgroundColor: debug ? "blue" : "undefined",
      width: "100%",
      padding: 5,
      // overflow: 'scroll',
      // top: -200,
      height: "100%", // xman manageable but hackinsh  1,2,3,4
      position: "relative",
    },

    introVideoMobile: {
      paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      zIndex: -100,
      backgroundColor: debug ? "blue" : "undefined",
      width: "100%",
      padding: 5,
      height: Platform.OS === "web" ? "80%" : undefined, // xman hackish, leave space for self video
      position: "relative",
    },
    connectedUsers: {},

    video2PeerMobile: {
      paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0,
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      maxHeight: "100%",
      zIndex: -100,
      backgroundColor: debug ? "blue" : "undefined",
      width: "100%",
      height: "100%",
      position: "relative",
    },
    video23MobileShiftedUnderSelfVideo: {
      width: "100%",
      height: Platform.OS === "web" ? "100%" : "50%",
      position: "relative",
      paddingTop: SELF_VIDEO_SIZE_MOBILE_HEIGHT,
    },
    video23Mobile: {
      width: "100%",
      height: Platform.OS === "web" ? "100%" : "50%",
      position: "relative",
      top: -20, //?
    },
    video56: {
      height: "33%", // xman manageable but hackish 5, 6
      width: "50%",
    },

    button: {
      // flex: 1,
      width: 45,
      height: 45,
      borderRadius: 15,
      justifyContent: "center",
      alignItems: "center",
    },
  };
};
