export type MyWebSocket = WebSocket & { id: string };

export type ConnectedUserDTO = {
  id: string;
  role: string;
  peer?: string;
  firstName: string;
  lastName: string;
  status: string;
  platform?: string;
  mode?: string;
};

export type ConnectedUsersDTO = Map<string, ConnectedUserDTO>;

export const CALL_STATUS = {
  READY: "Ready to call",
  INCOMING_CALL: "Incoming call",
  OUTGOING_CALL: "Outgoing call",
  IN_CALL: "In call",
};

export const CONFCALL_STATUS = {
  WAITING: "Waiting for others",
  IN_CONFCALL: "In conf call",
};

export enum SelfDeafType {
  NO_ANSWER = "Brak odpowiedzi",
  DEAF = "Głucha",
  LISTENING_IMPAIRED = "Słabosłysząca",
  DEAF_AND_BLIND = "Głuchoniewidoma",
}

export type TranslationDocument = {
  _id: string;
  userId: string;
  translatorId: string;
  createdAt: Date;
  requestedAt: Date;
  updatedAt: Date;
  translatedAt: Date;
  description: string;
  status: string;

  documentFileName: string;
  documentStorageId: string;
  documentSize: number;
  documentMimeType: string;

  translationFileName: string;
  translationStorageId: string;
  translationSize: number;
  translationMimeType: string;
};

export const mapTranslationStatus = (value) => {
  if (value === "new") return "Nowy";
  else if (value === "submitted") return "Oczekujący";
  else if (value === "in_progress") return "W realizacji";
  else if (value === "verification") return "Weryfikacja";
  else if (value === "translated") return "Gotowy";
  else return value;
};

export type SupportNoteDocument = {
  createdAt: Date;
  translatorId: { firstName: string; lastName: string };
  note: String;
};

export type SupportFormInCallType = {
  _id: number;
  translatorId: {
    firstName: string;
    lastName: string;
  };
  supportDescription: string;
  formOfSupportCode: string;
  supportDate: Date;
  statusDescription: string;

  notes: [SupportNoteDocument];
};

export type SupportFormDocument = {
  _id: string;
  userId:
    | {
        _id: string;
        firstName: string;
        lastName: string;
        email: string;
        pesel: string;
      }
    | undefined;
  translatorId:
    | {
        _id: string;
        firstName: string;
        lastName: string;
        email: string;
      }
    | undefined;
  translationId: string;
  callId: string;
  createdAt: Date;
  updatedAt: Date;
  supportDate: Date;
  supportDateStart: Date;
  supportDateEnd: Date;
  formOfSupportCode: string;
  supportType_jobAdContent: boolean;
  supportType_jobAdContact: boolean;
  supportType_recruitment: boolean;
  supportType_medicalSchedule: boolean;
  supportType_medicalExamine: boolean;
  supportType_healthAndSafety: boolean;
  supportType_everydayWork: boolean;
  supportType_documentTranslation: boolean;
  supportType_participationForm: boolean;
  supportType_other: boolean;
  supportType_otherDescription: string;

  stakeholders_employer: boolean;
  stakeholders_recruitmentTeam: boolean;
  stakeholders_managers: boolean;
  stakeholders_coworkers: boolean;
  stakeholders_medical: boolean;
  stakeholders_healthAndSafety: boolean;
  stakeholders_contractor: boolean;
  stakeholders_other: boolean;
  stakeholders_otherDescription: string;

  supportDescription: string;
  status: string; // "finished", "in_progress"
  statusDescription: string;
  surveySentAt: Date;

  newNote: String;
  notes: [SupportNoteDocument];
};

export type ScheduledConfCall = {
  _id: string;
  createdBy:
    | {
        _id: string;
        firstName: string;
        lastName: string;
        email: string;
        pesel: string;
      }
    | undefined;
  updatedBy:
    | {
        _id: string;
        firstName: string;
        lastName: string;
        email: string;
      }
    | undefined;
  participants: Array<{
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
  }>;
  token: string;
  createdAt: Date;
  updatedAt: Date;
  scheduledStartAt: Date | undefined | string | null;
  scheduledFinishAt: Date | undefined | string | null;
};

export type ParticipationFormDocument = {
  _id: string;
  userId:
    | {
        _id: string;
        firstName: string;
        lastName: string;
        email: string;
        pesel: string;
      }
    | undefined;
  translatorId:
    | {
        _id: string;
        firstName: string;
        lastName: string;
        email: string;
      }
    | undefined;
  createdAt: Date;
  updatedAt: Date;
  completed: boolean;
  kk_confirmed: boolean;
  kkEvidenceBy: string;

  phone: string;
  birthDate: Date;
  gender: string;
  pesel: string;
  age_range: string;
  address_street: string;
  address_house_no: string;
  address_flat_no: string;
  postal_code: string;
  city: string;
  county: string;
  community: string;
  address_type: AddressType;
  voivodeship: Voivodeship;
  disability: boolean;

  disability_01: boolean; // "01-U osoba z niepełnosprawnością intelektualną";
  disability_02: boolean; // "02-P osoba ze schorzeniem psychicznym";
  disability_03: boolean; // "03-L osoba niesłysząca lub słabosłysząca";
  disability_04: boolean; // "04-O osoba niewidoma lub słabowidząca";
  disability_05: boolean; //  "05-R osoba z uszkodzeniem narządu ruchu";
  disability_06: boolean; // "06-E osoba z epilepsją";
  disability_07: boolean; // "07-S osoba z chorobami układu oddechowego i krążenia";
  disability_08: boolean; // "08-T osoba z chorobami układu pokarmowego w tym nowotwory";
  disability_09: boolean; // "09-M osoba z chorobami układu moczowo-płciowego w tym nowotwory";
  disability_10: boolean; // "10-N osoba z chorobami neurologicznymi i rdzenia kręgowego";
  disability_11: boolean; // "11-I inne w tym schorzenia endokrynologiczne i metaboliczne";
  disability_12: boolean; // "12-C całościowe zaburzenia rozwojowe powstałe przed 16 rokiem życia";

  self_deaf_type: SelfDeafType;

  communicative_pjm: boolean;
  communicative_polish_spoken: boolean;
  communicative_polish_written: boolean;
  communicative_reading_lips: boolean;
  communicative_other: string;

  polish_level: PolishLevel;
  work_status: WorkStatus;

  ethnical_or_national_minority: SensitiveQuestionAnswer;
  homeless_or_impacted_by_homelessness: boolean;
  other_situation: SensitiveQuestionAnswer;

  self_disability_level: SelfDisabilityLevel;
  county_disability_level: CountyDisabilityLevel;
  zus_disability_level: ZusDisabilityLevel;
  krus_disability_level: KrusDisabilityLevel;

  disability_county: boolean;
  disability_zus: boolean;
  disability_krus: boolean;
  disability_military: boolean;
  disability_county_forever: boolean;
  disability_zus_forever: boolean;
  disability_krus_forever: boolean;
  disability_military_forever: boolean;

  disability_expire_county: Date;
  disability_expire_zus: Date;
  disability_expire_krus: Date;
  disability_expire_military: Date;

  education: Education;
  education_other: string;

  date_of_education_finish: Date;
  type_of_employment: TypeOfEmployment;
  type_of_employment_other: string;

  employer_name: string;
  employer_address: string;
  employer_type: string;
  employer_sector: EmployerSector;
  employer_category_private: EmployerCategoryPrivate;
  employer_category_public: EmployerCategoryPublic;
  employer_category_public_other: string;
  employer_category_3rd_sector: EmployerCategory3rdSector;
  employer_category_3rd_sector_other: string;

  employer_size: CompanySize;
  employer_disability_type: EmployerDisabilityType;

  economic_activity_start: boolean;
  economic_activity_name: string;
  economic_activity_address: string;
  economic_activity_size: CompanySize;
  economic_activity_sector: string;

  additional_info: string;
};

export enum AgeRange {
  _18_26 = "18-26",
  _27_35 = "27-35",
  _36_45 = "36-45",
  _46_55 = "46-55",
  _56_65 = "56-65",
  _65_PLUS = "powyżej 65",
}

export enum CommunicativeType {
  PJM = "W polskim języku migowym",
  PL_SPOKEN = "W języku polskim w mowie",
  PL_WRITTEN = "W języku polskim w piśmie",
  PL_LIPS_READING = "Odczytuję mowę z ruchu ust",
  PL_OTHER = "Inny",
}

export enum AddressType {
  VILLAGE = "Wieś",
  LESS_20k = "miasto do 20 tys.",
  FROM_20_TO_50K = "miasto powyżej 20 tys. do 50 tys.",
  FROM_50_TO_100K = "miasto powyżej 50 tys. do 100 tys.",
  FROM_100_TO_200K = "miasto od 100 tys. do 200 tys.",
  FROM_200_TO_500K = "miasto od 200 tys. do 500 tys",
  FROM_500K_UP = "miasto powyżej 500 tys.",
}

export enum SensitiveQuestionAnswer {
  YES = "Tak",
  NO = "Nie",
  NO_ANSWER = "Odmawiam podania informacji",
}
export enum PolishLevel {
  NIE_ROZUMIEM = "nie rozumiem",
  ROZUMIEM_TROCHE = "rozumiem trochę",
  ROZUMIEM = "rozumiem",
  ROZUMIEM_DOBRZE = "rozumiem dobrze",
  ROZUMIEM_BARDZO_DOBRZE = "rozumiem bardzo dobrze",
}

export enum TypeOfEmployment {
  UMOWA_O_PRACE = "Umowa o pracę /spółdzielcza umowa o pracę",
  UMOWA_ZLECENIE = "Umowa zlecenia",
  INNE = "Inne",
}

export enum SelfDisabilityLevel {
  GLUCHA = "Głucha",
  SLABOSLYSZACA = "Słabosłysząca",
  GLUCHONIEWIDOMA = "Głuchoniewidoma",
}

export enum CountyDisabilityLevel {
  ZNACZNY = "Znaczny stopień",
  UMIARKOWANY = "Umiarkowany stopień",
  LEKKI = "Lekki stopień",
  BRAK = "Nie dotyczy",
}

export enum ZusDisabilityLevel {
  CALKOWITA_SAMODZIELNEJ_EGZYSTENCJI = "Całkowita niezdolność do pracy i samodzielnej egzystencji",
  CALKOWITA = "Całkowita niezdolność do pracy",
  CZESCIOWA = "Częściowa niezdolność do pracy",
  BRAK = "Nie dotyczy",
}

export enum KrusDisabilityLevel {
  CALKOWITA = "Całkowita niezdolność do pracy w gospodarstwie rolnym",
  CALKOWITA_SAMODZIELNEJ_EGZYSTENCJI = "Całkowita niezdolność do pracy w gospodarstwie rolnym i niezdolności do samodzielnej egzystencji",
  BRAK = "Nie dotyczy",
}
export enum Voivodeship {
  DSL = "dolnośląskie",
  K_P = "kujawsko-pomorskie",
  LBL = "lubelskie",
  LBU = "lubuskie",
  LDZ = "łódzkie",
  MŁP = "małopolskie",
  MAZ = "mazowieckie",
  OPO = "opolskie",
  PKR = "podkarpackie",
  PDL = "podlaskie",
  POM = "pomorskie",
  SL = "śląskie",
  SW = "świętokrzyskie",
  W_M = "warmińsko-mazurskie",
  WLKP = "wielkopolskie",
  ZPM = "zachodniopomorskie",
}

export enum WorkStatus {
  LOOKING = "Osoba poszukująca pracy",
  EMPLOYED = "Osoba pracująca",
  HAVING_ECONOMIC_ACTIVITY = "Osoba prowadząca działalność gospodarczą",
  WILLING_TO_HAVE_ECONOMIC_ACTIVITY = "Osoba, która chce założyć działalność gospodarczą",
  UNEMPLOYED = "Osoba bezrobotna",
}

export enum Education {
  PODSTAWOWE = "podstawowe",
  GIMNAZJALNE = "gimnazjalne",
  ZASADNIZE_ZAWODOWE = "zasadnicze zawodowe",
  SREDNIE = "średnie",
  SREDNIE_ZAWODOWE = "średnie zawodowe",
  POLICEALNE_POMATURALNE = "policealne/pomaturalne",
  POMATURALNE = "pomaturalne",
  WYZSZE_LICENCJAT_INZYNIER = "wyższe - licencjat/inżynier",
  WYZSZE_MAGISTERSKIE = "wyższe – magisterskie",
  PODYPLOMOWE_DOKTORSKIE = "studia podyplomowe/doktorat",
  INNE = "inne",
}

export enum EmployerSector {
  PUBLICZNE = "Podmiot publiczny",
  PRYWATNE = "Podmiot prywatny",
  TRZECI_SEKTOR = "Podmiot trzeciego sektora, w tym ekonomii społecznej",
}

export enum EmployerCategoryPrivate {
  PODMIOT_PRYWATNY = "Podmiot prywatny",
}

export enum EmployerCategoryPublic {
  SAMORZAD = "Jednostka samorządu terytorialnego",
  ADMINISTRACJA = "Jednostka administracji rządowej",
  SLUZBA_ZESPOLONA = "Służba zespolona",
  SPOLKI_SAMORZADOWE = "Spółki samorządowe",
  SZKOLA = "Szkoła",
  PRZEDSZKOLE = "Przedszkole",
  INNE = "Inna placówka",
}

export enum EmployerCategory3rdSector {
  ORGANIZACJA_POZARZADOWA = "Organizacja pozarządowa",
  SPOLDZIELNIA_SOCJALNA = "Spółdzielnia socjalna",
  ZAKLAD_AKTYWNOSCI_ZAWODOWEJ = "Zakład aktywności zawodowej",
  INNY = "Inny",
}

export enum CompanySize {
  MIKRO = "Mikro- do 9 pracowników",
  MALY = "Mały – 10-49 pracowników",
  SREDNI = "Średni- 50-248 pracowników",
  DUZY = "Duży -249 pracowników i więcej",
}

export enum EmployerDisabilityType {
  OTWARTY = "Otwarty",
  CHRONIONY = "Chroniony",
}

export type Recording = {
  recordedBy: string;
  recorded: string;
  createdAt: Date;
};

export type PermanentConfCallRecording = Recording & { room: string };
export type ScheduledConfCallRecording = Recording & { callId: string };
