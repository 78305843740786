import React from "react";
import { GestureResponderEvent, View, Text, Platform } from "react-native";
import { AuthContext, AuthContextType } from "../../auth-context";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";

import { CALL_STATUS, ConnectedUsersDTO } from "../../frontend_domain";
import CCButton from "../../components/CCButton";
import _ from "lodash";

interface CCButtonsSectionProps {
  debug: boolean;
  displayRecordingInfo: (event: GestureResponderEvent) => void;
  cameraOn: boolean;
  toggleCamera: () => void;
  micOn: boolean;
  toggleMic: () => void;
  chatOn: boolean;
  toggleChat: (event: GestureResponderEvent) => void;
  formsOn: boolean;
  toggleForms: (event: GestureResponderEvent) => void;
  participationOn: boolean;
  toggleParticipation: (event: GestureResponderEvent) => void;

  incomingChatMessage: boolean;
  status: {};
  call: (event: GestureResponderEvent) => void;
  cancelCall: (event: GestureResponderEvent) => void;
  hangup: () => void;
  acceptCall: (event: GestureResponderEvent) => void;
  rejectCall: (event: GestureResponderEvent) => void;
  incomingCall: boolean;
  connectedUsers: ConnectedUsersDTO;
  volumeOn: boolean;
  toggleVolumeOn: (event: GestureResponderEvent) => void;
  participationFormFinishedForUser: boolean | undefined;

  toggleWebRTCStats: (event: GestureResponderEvent) => void;
  otherId: string;
  disableCallButton: boolean;
  anySupportFormInProgress: boolean;
  otherRole: string;
}

export const CCButtonsSection = ({
  debug,
  displayRecordingInfo,
  cameraOn,
  toggleCamera,
  micOn,
  toggleMic,
  chatOn,
  toggleChat,
  formsOn,
  toggleForms,
  participationOn,
  toggleParticipation,
  incomingChatMessage,
  status,
  call,
  cancelCall,
  hangup,
  acceptCall,
  rejectCall,
  incomingCall,
  connectedUsers,
  volumeOn,
  toggleVolumeOn,
  participationFormFinishedForUser,
  toggleWebRTCStats,
  otherId,
  disableCallButton,
  anySupportFormInProgress,
  otherRole,
}: CCButtonsSectionProps) => {
  const { auth } = React.useContext(AuthContext) as AuthContextType;

  // console.log(`[CCButtons] status: `, status);
  // console.log(`[CCButtons] otherId: `, otherId);
  // console.log(`[HeartBeat] disableCallButton: `, disableCallButton);

  const otherConnectedUser = connectedUsers.get(otherId);
  const otherUserName = otherConnectedUser
    ? otherConnectedUser.firstName + " " + otherConnectedUser.lastName
    : undefined;

  return (
    <View
      style={{
        backgroundColor: `${debug == true ? "blue" : "lightgray"}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: Platform.OS === "web" ? 10 : 0,
        paddingRight: Platform.OS === "web" ? 10 : 0,
        // padding: 10,
        // width: "100%",
      }}
    >
      {/* first section is for translators (left side ) */}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgray",
        }}
      ></View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgray",
        }}
      >
        {auth?.isTranscriber && status === CALL_STATUS.IN_CALL && (
          <CCButton
            testID={"button_toggle_support_forms"}
            icon={"form-select"}
            // onPress={toggleForms}
            onPress={status === CALL_STATUS.IN_CALL ? toggleForms : () => {}}
            // tooltip={"Formularze wsparcia"}
            color={
              status === CALL_STATUS.IN_CALL
                ? anySupportFormInProgress === true
                  ? "red"
                  : "black"
                : "gray"
            }
            disabled={!CALL_STATUS.IN_CALL}
            tooltip={"Otwarte formularze wsparcia"}
          ></CCButton>
        )}

        {auth?.isTranscriber &&
          status === CALL_STATUS.IN_CALL &&
          otherRole === "user" && (
            <CCButton
              testID={"button_toggle_participation_form"}
              icon={"account-outline"}
              // onPress={toggleForms}
              onPress={
                status === CALL_STATUS.IN_CALL ? toggleParticipation : () => {}
              }
              // tooltip={"Formularz uczestnictwa"}
              color={
                status === CALL_STATUS.IN_CALL
                  ? participationFormFinishedForUser === true
                    ? "green"
                    : "red"
                  : "gray"
              }
              tooltip={"Formularz uczestnictwa"}
            ></CCButton>
          )}

        {/* {!incomingCall &&
          auth?.isTranscriber &&
          status === CALL_STATUS.IN_CALL && (
            <CCButton
              testID={"button_webrtc_stats"}
              icon={"help-network-outline"}
              color={"blue"}
              onPress={toggleWebRTCStats}
            />
          )} */}
      </View>

      {/* second section is all users (middle side ) */}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgray",
        }}
      >
        {auth?.role === "user" && (
          <CCButton
            testID={"button_toggle_camera"}
            icon={cameraOn ? "camera-outline" : "camera-off-outline"}
            color={cameraOn ? "black" : "red"}
            onPress={toggleCamera}
            tooltip={cameraOn ? "Wyłącz kamerę" : "Włącz kamerę"}
          />
        )}
        {status === CALL_STATUS.IN_CALL && (
          <CCButton
            testID={"button_toggle_mic"}
            icon={micOn ? "microphone-outline" : "microphone-off"}
            color={micOn ? "black" : "red"}
            onPress={toggleMic}
            tooltip={micOn ? "Wyłącz mikrofon" : "Włącz mikrofon"}
          />
        )}

        {status === CALL_STATUS.IN_CALL && (
          <CCButton
            testID={"button_toggle_volume"}
            icon={volumeOn ? "volume-plus" : "volume-variant-off"}
            onPress={toggleVolumeOn}
            tooltip={"Wycisz"}
            color={volumeOn ? "black" : "red"}
          ></CCButton>
        )}

        {status === CALL_STATUS.IN_CALL && (
          <CCButton
            testID={"button_toggle_chat"}
            icon={incomingChatMessage ? "chat-alert" : "chat"}
            onPress={status === CALL_STATUS.IN_CALL ? toggleChat : () => {}}
            tooltip={"Czat"}
            color={
              incomingChatMessage
                ? "red"
                : status === CALL_STATUS.IN_CALL
                ? "black"
                : "gray"
            }
            disabled={!CALL_STATUS.IN_CALL}
          ></CCButton>
        )}

        {/* make and finish call */}

        {!incomingCall &&
          auth?.isUser &&
          status !== CALL_STATUS.OUTGOING_CALL && (
            <CCButton
              testID={
                status !== CALL_STATUS.IN_CALL ? "button_call" : "button_hangup"
              }
              tooltip={status !== CALL_STATUS.IN_CALL ? "Zadzwoń" : "Zakończ"}
              icon={status === CALL_STATUS.IN_CALL ? "phone-off" : "phone"}
              color={
                status === CALL_STATUS.READY &&
                connectedUsers.size > 0 &&
                !_.isEmpty(otherId) &&
                disableCallButton === false
                  ? "green"
                  : status === CALL_STATUS.IN_CALL
                  ? "red"
                  : "gray"
              }
              disabled={connectedUsers.size === 0 || disableCallButton === true}
              onPress={
                status === CALL_STATUS.READY &&
                connectedUsers.size > 0 &&
                !_.isEmpty(otherId)
                  ? call
                  : status === CALL_STATUS.IN_CALL
                  ? hangup
                  : connectedUsers.size === 0 || disableCallButton === true
                  ? () => {
                      alert("Brak dostępnych tłumaczy");
                    }
                  : undefined
              }
            />
          )}

        {/* cancel call */}

        {status === CALL_STATUS.OUTGOING_CALL && (
          <CCButton
            testID={"cancel_call"}
            tooltip={"Anuluj"}
            icon={"phone-off"}
            color={"red"}
            disabled={connectedUsers.size === 0}
            onPress={cancelCall}
          />
        )}

        {!incomingCall &&
          auth?.isTranscriber &&
          status === CALL_STATUS.IN_CALL && (
            <CCButton
              testID={
                status !== CALL_STATUS.IN_CALL ? "button_call" : "button_hangup"
              }
              icon={status === CALL_STATUS.IN_CALL ? "phone-off" : "phone"}
              color={status === CALL_STATUS.IN_CALL ? "red" : "gray"}
              onPress={
                status === CALL_STATUS.READY
                  ? () => {}
                  : status === CALL_STATUS.IN_CALL
                  ? hangup
                  : () => {}
              }
              tooltip={status !== CALL_STATUS.IN_CALL ? "Zadzwoń" : "Zakończ"}
            />
          )}

        {/* incoming call decision */}
        {incomingCall && (
          <>
            <CCButton
              testID="button_accept"
              icon="phone-outline"
              size={24}
              color="darkgreen"
              onPress={acceptCall}
              tooltip={"Odbierz"}
            />

            <CCButton
              icon="phone-off-outline"
              testID="button_reject"
              size={24}
              color="red"
              onPress={rejectCall}
              tooltip={"Odrzuć"}
            />
          </>
        )}
      </View>

      {/* third section is all users (right side - just information) */}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgray",
        }}
      >
        {status === CALL_STATUS.IN_CALL && (
          <CCButton
            icon="record-circle"
            tooltip="Nagrywanie rozmowy"
            color={status === CALL_STATUS.IN_CALL ? "red" : "gray"}
            onPress={displayRecordingInfo}
          />
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgray",
          // minWidth: 10,
        }}
      >
        {(status == CALL_STATUS.IN_CALL || incomingCall) &&
          otherUserName &&
          auth?.isTranscriber && (
            <View style={{ paddingRight: 5 }}>
              <Text>{otherUserName.substring(0, 40)}</Text>
            </View>
          )}
        <View style={{ paddingRight: 5 }}>
          <Text>{}</Text>
        </View>
        {status == CALL_STATUS.IN_CALL && (
          <>
            <CountdownCircleTimer
              isPlaying
              strokeWidth={0}
              size={32}
              duration={60}
              initialRemainingTime={30 * 60}
              onComplete={() => {
                hangup();
              }}
              colors="#A30000"
            >
              {({ remainingTime }) => {
                const minutes = Math.floor(remainingTime / 60);
                const seconds = remainingTime % 60;
                if (minutes < 5)
                  return (
                    <Text
                      testID="countdown"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        paddingRight: 10,
                      }}
                    >
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </Text>
                  );
                else
                  return (
                    <Text style={{ paddingRight: 10 }}>
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </Text>
                  );
              }}
            </CountdownCircleTimer>
          </>
        )}
      </View>
    </View>
  );
};

export default CCButtonsSection;
