import React from "react";
import { View, Text, Platform } from "react-native";
import { IconButton } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import { download } from "../../services/download";
import { download as downloadNative } from "../../services/download.native";
import ValidatedTextInput from "../ValidatedTextInput";
import {
  useForm,
  UseFormGetValues,
  UseFormSetValue,
  FieldValues,
} from "react-hook-form";
import _ from "lodash";
import oneconfig from "../../oneconfig";

interface PickDocumentProps {
  downloadAvailable: boolean | undefined;
  downloadLink: string | undefined;
  downloadPresignedLink: string | undefined;
  downloadPresignedAction: Function;
  onDownloadProgress;
  progressIndication: Function;
  documentName: string;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  control: {};
  errors: {};
  folder: string;
  label: string;
  required: boolean;
  mimeType: string[];
  rules: any;
  disabled: boolean;
  loading: Function;
}

export type UploadedDocumentMetadata = {
  name?: string;
  storageId?: string;
  mimeType?: string;
  size?: number;
  folder?: string | undefined;
  uri: string;
};

export type MyUploadedDocument =
  | UploadedDocumentMetadata
  | DocumentPicker.DocumentResult
  | undefined;

export function PickDocumentInput({
  getValues,
  setValue,
  documentName,
  control,
  errors,
  label,
  downloadLink,
  downloadAvailable,
  downloadPresignedLink,
  downloadPresignedAction,
  onDownloadProgress,
  progressIndication,
  required,
  mimeType,
  rules,
  disabled,
  loading,
}: PickDocumentProps) {
  /*  console.log(
    `downloadPresignedLink in pick document: ${downloadPresignedLink}`
  ); */

  // console.log(`downloadPresignedLink errors: ${JSON.stringify(errors)}`);

  const documentPickerOptions: DocumentPicker.DocumentPickerOptions = {
    type: mimeType,
    copyToCacheDirectory: true,
  };

  const pickPhotoFromCamera = async () => {
    // No permissions request is necessary for launching the image library
    let result;
    try {
      const permissionResult =
        await ImagePicker.requestCameraPermissionsAsync();

      if (permissionResult.granted === false) {
        alert("Nie zezwolono na skorzystanie z aparatu.");
        return;
      }
      result = await ImagePicker.launchCameraAsync({
        // mediaTypes: ImagePicker.MediaTypeOptions.All,
        // allowsEditing: true,
        // aspect: [4, 3],
        // quality: 0,
        base64: true,
      });
    } catch (error) {
      if (error && error.code === "ERR_CAMERA_UNAVAILABLE_ON_SIMULATOR") {
        // console.log(`error ${JSON.stringify(error)}`);
        alert(error);
      } else alert(`Błąd dostępu do kamer ${error}`);

      return;
    }
    // console.log(result);

    if (!result.cancelled) {
      if (loading) loading(true);
      // preferred fiLename - exist on iOS only (and still can be null)

      console.log(`photo picker result ${JSON.stringify(result)}`);
      console.log(`photo picker initial filename ${result.fileName}`);

      if (result.fileName) setValue(documentName + "FileName", result.fileName);
      else {
        // console.log("new file is null, getting it from uri... ");
        const fileName = result.uri.split("/").pop();
        // console.log(`... ${fileName}`);

        setValue(documentName + "FileName", fileName);
        result.fileName = fileName;
      }

      setValue(documentName + "Changed", true);

      console.log(`image picked result size ${result.size}`);

      console.log(`result.fileName ${result.fileName}`);
      // result.originalFilename  = result.fileName;

      const standardizedResult = result as UploadedDocumentMetadata;
      standardizedResult.mimeType = "image/jpeg";
      standardizedResult.name = result.fileName;
      const extension = standardizedResult.name?.substring(
        standardizedResult.name.lastIndexOf(".") + 1
      );

      console.log(`extension ${extension}`);
      if (Platform.OS === "ios") standardizedResult.size = result.fileSize;
      else standardizedResult.size = result.base64?.length;

      if (Platform.OS === "android") standardizedResult.uri = result.uri;
      else
        standardizedResult.uri =
          `data:image/${extension};base64,` + result.base64;

      // @ts-ignore
      delete standardizedResult.base64; // remove residue form image picker
      standardizedResult.name = result.fileName;

      setValue(documentName, standardizedResult);

      if (loading) loading(false);
      console.log(`fileToUpload...............${JSON.stringify(result)}`);
    } else {
      console.log("picking image cancelled ");
      setValue(documentName + "FileName", "");
    }
  };

  const pickDocument = async () => {
    const result = (await DocumentPicker.getDocumentAsync(
      documentPickerOptions
    )) as DocumentPicker.DocumentResult;

    if (result.type === "success") {
      if (loading) loading(true);
      console.log(
        `fileToUpload: ${documentName + "FileName"} ${JSON.stringify(
          result.name
        )}`
      );
      console.log(
        `fileToUpload: ${documentName + "Changed"}  ${JSON.stringify(true)}`
      );

      if (Platform.OS === "web")
        console.log(
          `file size ${(result.file?.size / 1024 / 1024).toFixed(2)} MB`
        );
      else
        console.log(`file size ${(result?.size / 1024 / 1024).toFixed(2)} MB`);
      // cost document = UploadedDocumentMetadata

      // console.log(`final save ${JSON.stringify(result)}`);

      setValue(documentName, result);

      console.log("file added to browser");

      setValue(documentName + "FileName", result.name);
      setValue(documentName + "Changed", true);
      if (loading) {
        console.log("stop loading indication");
        loading(false);
      }
    } else {
      console.log("Error or cancel while picking document: ", result.type);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 5,
        display: "flex",
        height: "100%",
        // overflow: 'scroll',
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <ValidatedTextInput
          control={control}
          placeholder={label}
          rules={rules}
          required={required}
          disabled={oneconfig.ck_env !== "development" || disabled}
          name={documentName + "FileName"}
          errors={errors}
          leftIconName="file-document-outline"
        />
        {/* {!_.isEmpty(errors) && (
          <>
            <Text style={{ color: "red" }}>To pole jest wymagane </Text>
          </>
        )} */}
      </View>

      <View
        style={{
          flex: 1,
          flexDirection: "row",
          maxWidth: "40%",
          justifyContent: "flex-end",
          // backgroundColor: "red",
        }}
      >
        {downloadAvailable && !_.isEmpty(getValues(documentName)) && (
          <IconButton
            icon="download"
            testID={`download_${documentName}`}
            size={20}
            // style={{ backgroundColor: "orange" }}
            disabled={_.isEmpty(getValues(documentName))}
            onPress={() => {
              if (Platform.OS === "web")
                download(
                  downloadLink ? downloadLink : "",
                  onDownloadProgress,
                  progressIndication
                );
              else {
                if (downloadPresignedAction) {
                  console.log(`downloading  downloadPresignedAction`);

                  downloadPresignedAction();
                } else {
                  console.log(`downloading  downloadNative`);
                  downloadNative(
                    downloadLink ? downloadLink : "",
                    onDownloadProgress,
                    progressIndication
                  );
                }
              }
            }}
          />
        )}

        {Platform.OS !== "web" && (
          <IconButton
            testID={`${documentName}_attach_photo`}
            icon="camera-outline"
            size={20}
            onPress={pickPhotoFromCamera}
          />
        )}

        <IconButton
          testID={`${documentName}_attach_document`}
          disabled={disabled}
          icon="file-document-outline"
          size={20}
          onPress={pickDocument}
        />
      </View>
    </View>
  );
}
