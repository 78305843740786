import React from "react";
import { GestureResponderEvent, View } from "react-native";
import ToggleParticipantsNumber from "./ToggleParticipantsNumber";
import { AuthContext, AuthContextType } from "../../auth-context";

import {
  CALL_STATUS,
  CONFCALL_STATUS,
  ConnectedUsersDTO,
} from "../../frontend_domain";
import CCButton from "../../components/CCButton";

interface CCButtonsSectionProps {
  debug: boolean;
  displayRecordingInfo: (event: GestureResponderEvent) => void;
  cameraOn: boolean;
  toggleCamera: () => void;
  micOn: boolean;
  toggleMic: () => void;
  chatOn: boolean;
  toggleChat: (event: GestureResponderEvent) => void;
  formsOn: boolean;
  toggleForms: (event: GestureResponderEvent) => void;
  incomingChatMessage: boolean;
  status: {};
  connectedUsers: ConnectedUsersDTO;
  volumeOn: boolean;
  toggleVolumeOn: (event: GestureResponderEvent) => void;
  exitConfCall: (event: GestureResponderEvent) => void;
}

export const CCButtonsSection = ({
  debug,
  displayRecordingInfo,
  cameraOn,
  toggleCamera,
  micOn,
  toggleMic,
  chatOn,
  toggleChat,
  formsOn,
  toggleForms,
  incomingChatMessage,
  status,
  connectedUsers,
  volumeOn,
  toggleVolumeOn,
  exitConfCall,
}: CCButtonsSectionProps) => {
  const { auth } = React.useContext(AuthContext) as AuthContextType;

  // console.log(`[CCButtons] incomingChatMessage: `, incomingChatMessage);

  return (
    <View
      style={{
        backgroundColor: `${debug == true ? "blue" : "lightgray"}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgray",
        }}
      >
        <ToggleParticipantsNumber />

        <CCButton
          testID={"button_toggle_camera"}
          icon={cameraOn ? "camera-outline" : "camera-off-outline"}
          color={cameraOn ? "black" : "red"}
          onPress={toggleCamera}
          tooltip={cameraOn ? "Wyłącz kamerę" : "Włącz kamerę"}
        />

        <CCButton
          testID={"button_toggle_mic"}
          icon={micOn ? "microphone-outline" : "microphone-off"}
          color={micOn ? "black" : "red"}
          onPress={toggleMic}
          tooltip={micOn ? "Wyłącz mikrofon" : "Włącz mikrofon"}
        />

        <CCButton
          testID={"button_toggle_volume"}
          icon={volumeOn ? "volume-plus" : "volume-variant-off"}
          onPress={toggleVolumeOn}
          tooltip={"Wycisz"}
          color={volumeOn ? "black" : "red"}
        ></CCButton>

        {auth?.isTranslator && (
          <CCButton
            testID={"button_toggle_support_forms"}
            icon={"form-select"}
            // onPress={toggleForms}
            onPress={status === CALL_STATUS.IN_CALL ? toggleForms : () => {}}
            tooltip={"Formularze wsparcia"}
            color={status === CALL_STATUS.IN_CALL ? "black" : "gray"}
            disabled={!CALL_STATUS.IN_CALL}
          ></CCButton>
        )}
        <CCButton
          testID={"button_toggle_chat"}
          icon={incomingChatMessage ? "chat-alert" : "chat"}
          onPress={toggleChat}
          tooltip={"Czat"}
          color={incomingChatMessage ? "red" : "black"}
        ></CCButton>

        <CCButton
          testID={"button_exit_confcall"}
          icon={"phone-off"}
          color={"red"}
          onPress={exitConfCall}
          tooltip={"Zakończ"}
        />

        <CCButton
          icon="record-circle"
          tooltip="Nagrywanie rozmowy"
          color={"red"}
          onPress={displayRecordingInfo}
        />
      </View>
    </View>
  );
};

export default CCButtonsSection;
