import axios from "axios";
import config from "../oneconfig";

export const getConnectedUsers = async () => {
  console.log(`[getAll] translations default headers `);
  const response = await axios.get(
    config.api_url + "/signaling/connectedUsers"
  );
  return response.data;
};

export const flush = async () => {
  console.log(`[getAll] translations default headers `);
  const response = await axios.get(config.api_url + "/signaling/flush");
  return response.data;
};
