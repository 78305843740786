import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ParticipationFormDocument } from "../../frontend_domain";
import { CheckboxComponent } from "./CheckboxComponent";

type SelfDisabilityTypeAssessmentProps = {
  resource: ParticipationFormDocument;
  errors?: {};
  setResource: React.Dispatch<any>;
};

export const SelfDisabilityTypeAssessment = ({
  resource,
  errors,
  setResource,
}: SelfDisabilityTypeAssessmentProps) => {
  return (
    <Grid container spacing={0} id="self_disability_level">
      <Grid item xs={12} style={{ paddingTop: 15 }}>
        <Typography
          style={{
            color:
              errors && errors["self_disability_level"] === true
                ? "red"
                : undefined,
          }}
          variant="body1"
        >
          13. Proszę zaznaczyć rodzaj niepełnosprawności według kategorii w
          orzeczeniu *
        </Typography>
      </Grid>
      <CheckboxComponent
        name="disability_01"
        label="01-U osoba z niepełnosprawnością intelektualną"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_02"
        label="02-P osoba ze schorzeniem psychicznym"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_03"
        label="03-L osoba niesłysząca lub słabosłysząca"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_04"
        label="04-O osoba niewidoma lub słabowidząca"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_05"
        label="05-R osoba z uszkodzeniem narządu ruchu"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_06"
        label="06-E osoba z epilepsją"
        resource={resource}
        setResource={setResource}
      />

      <CheckboxComponent
        name="disability_07"
        label="07-S osoba z chorobami układu oddechowego i krążenia"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_08"
        label="08-T osoba z chorobami układu pokarmowego w tym nowotwory"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_09"
        label="09-M osoba z chorobami układu moczowo-płciowego w tym nowotwory"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_10"
        label="10-N osoba z chorobami neurologicznymi i rdzenia kręgowego"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_11"
        label="11-I inne w tym schorzenia endokrynologiczne i metaboliczne"
        resource={resource}
        setResource={setResource}
      />
      <CheckboxComponent
        name="disability_12"
        label="12-C całościowe zaburzenia rozwojowe powstałe przed 16 rokiem życia"
        resource={resource}
        setResource={setResource}
      />
    </Grid>
  );
};
