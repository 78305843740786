import React from "react";

import {
  mediaDevices,
  RTCPeerConnection,
  MediaStream,
  RTCSessionDescription,
  RTCIceCandidate,
} from "react-native-webrtc-web-shim";

import { MyRTCViewProps } from "./types";
import { IconButton } from "react-native-paper";

import { SELF_VIDEO_SIZE_WEB_WIDTH } from "../../styles/base";

export default function MyRTCView({
  stream,
  style,
  muted,
  mirror,
  videoId,
  videoDisabled, // this is valid only for remote video case so instead of black box we can display something nicer
  showNoVideoIcon,
  noVideoIconStyle,
}: MyRTCViewProps) {
  // console.debug("[MyRTCView]: styles:", JSON.stringify(style));
  // console.log('getMediaDevices:', mediaDevices);

  const videoRef = React.createRef<HTMLVideoElement>();
  React.useEffect(() => {
    console.debug("[MyRTCView]: stream changed ", stream);
    console.debug("[MyRTCView]: videoDisabled:", videoDisabled);

    if (stream && videoRef.current) videoRef.current.srcObject = stream;
  }, [stream, videoDisabled]);

  const genericNoVideoStyle = {
    ...style,
    height: "auto",
    padding: SELF_VIDEO_SIZE_WEB_WIDTH / 4,
    backgroundColor: "white",
  };

  if (stream && videoDisabled !== true) {
    // console.log(`[Remote stream]: MyRTCView for ${videoId} is live`);
    return (
      <video
        srcobject={stream}
        style={{ ...style, transform: mirror ? `scale(-1,1)` : undefined }}
        autoPlay
        muted={muted}
        source={stream}
        id={`video_${videoId}`}
        ref={videoRef}
      />
    );
  } else {
    // console.log(`[Remote stream]: MyRTCView for ${videoId} is empty`);

    return (
      <>
        {showNoVideoIcon && (
          <IconButton
            // testID={}
            icon="camera-off-outline"
            size={SELF_VIDEO_SIZE_WEB_WIDTH / 3}
            color="gray"
            style={noVideoIconStyle ? noVideoIconStyle : genericNoVideoStyle}
          ></IconButton>
        )}
        {/* // it must be there so audio is heard */}
        <video
          style={{ display: "none" }}
          width={0}
          height={0}
          srcobject={stream}
          autoPlay
          muted={muted}
          source={stream}
          id={`video_${videoId}`}
          ref={videoRef}
        />
      </>
    );
  }
}

export {
  mediaDevices,
  RTCPeerConnection,
  MediaStream,
  RTCSessionDescription,
  RTCIceCandidate,
};
