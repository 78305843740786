import React, { useEffect } from "react";
import { DebugContext, DebugContextType } from "../debug-context";

import { Linking } from "react-native";

import { MyDrawerScreens } from "./MyDrawerScreens";

import { useNavigation } from "@react-navigation/native";

export default function MyDrawer() {
  const navigation = useNavigation();

  // console.log(`navigation in MyDrawer ${JSON.stringify(navigation)}`);
  useEffect(() => {
    Linking.getInitialURL().then((url) => {
      console.log(`use effect in MyDrawer, initial URL ${url}`);

      if (url?.endsWith("accountActive"))
        //@ts-ignore
        navigation.navigate("Policy", { initial: false });
      if (url?.endsWith("contact"))
        //@ts-ignore
        navigation.navigate("Contact", { initial: false });
      if (url?.endsWith("emailConfirmed"))
        //@ts-ignore
        navigation.navigate("RegistrationSecondStep", { initial: false });
      if (url && url.includes("passwordReset")) {
        const token = url.substring(url.lastIndexOf("=") + 1, url.length);
        console.log(`password reset token ${token}`);
        //@ts-ignore
        navigation.navigate("PasswordResetUpdate", { token: token });
      }
      if (url && url.includes("confcall")) {
        const token = url.substring(url.lastIndexOf("=") + 1, url.length);
        console.log(`password reset token ${token}`);
        //@ts-ignore
        navigation.navigate("MultiPartyConfCall", { token: token });
      }
    });
  }, []);

  return (
    <DebugContext.Consumer>{() => <MyDrawerScreens />}</DebugContext.Consumer>
  );
}
