import axios from "axios";
import { Platform } from "react-native";
import config from "../oneconfig";
import { MyValidationError } from "../utils";
import { Auth } from "../auth-context";

if (Platform.OS !== "web")
  global.Buffer = global.Buffer || require("buffer").Buffer;

export const getAll = async () => {
  console.log(`[getAll] translations default headers `);
  const response = await axios.get(config.api_url + "/translation");
  return response.data;
};

export const getItem = async (id: string) => {
  try {
    // console.log(`[AXIOS] default headers `, JSON.stringify(axios.defaults.headers.common));
    const response = await axios.get(config.api_url + `/translation/${id}`);
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const deleteItem = async (id: string) => {
  try {
    // console.log(`[AXIOS] default headers `, JSON.stringify(axios.defaults.headers.common));
    const response = await axios.delete(
      config.api_url + `/translation/${id}/delete`
    );
    return response.data;
  } catch (error) {
    console.log("[error]", error, JSON.stringify(error));
    throw error;
  }
};

export const createTranslationAndGetPresignedUrlUpload = async (
  data: any,
  onUploadProgress: Function,
  onFinalizeProgress: Function,
  auth: Auth
) => {
  data.submit = true;

  return await sendCreateDocumentRequest(
    data,
    onUploadProgress,
    onFinalizeProgress,
    auth
  );
};

export const updateItem = async (
  data: any,
  status: string,
  onUploadProgress: Function,
  onFinalizeProgress: Function
) => {
  const updateResourceUrl = `${config.api_url}/translation/${data.id}`;
  console.log(`form ${JSON.stringify(data)}`);
  console.log(`update  via  ${updateResourceUrl} `);

  return await sendUpdateTranslationRequest(
    data,
    status,
    "patch",
    updateResourceUrl,
    onUploadProgress,
    onFinalizeProgress
  );
};

async function sendCreateDocumentRequest(
  data: any,
  onUploadProgress: Function,
  onFinalizeProgress: Function,
  auth: Auth
) {
  console.log(`creating translation ${JSON.stringify(data)}`);
  const form = new FormData();

  if (data.userId) {
    console.log(`Creating translation for ${data.userId?.email}`);
    form.append("userId", data.userId._id);
  }

  form.append("description", data.description);

  let responseData: {};

  if (Platform.OS === "android") {
    console.log(`using auth?.authToken ${auth?.authToken} `);
    let myHeaders = new Headers({
      "x-access-token": auth?.authToken,
    });
    const resp = await fetch(`${config.api_url}/translation`, {
      method: "POST",
      body: form,
      headers: myHeaders,
    });

    responseData = await resp.json();
  } else {
    const response = await axios({
      url: `${config.api_url}/translation`,
      method: "post",
      data: form,
    });

    console.log(
      `create translation response ${response} ${JSON.stringify(response)}`
    );
    console.log(
      `create translation response ${response.data._id} ${JSON.stringify(
        response.data
      )}`
    );
    responseData = response.data;
  }

  data.id = responseData._id;
  data.userId = responseData.userId;

  if (data.document && data.documentChanged) {
    console.log(`new document file name`, data.document.name);
    console.log(`uploading changed file name`, data.document.name);

    console.log(`config.ck_env`, config.ck_env);

    if (data.document.uri === "data:" && config.ck_env !== "development") {
      throw new MyValidationError("Nie można wysłać pustego pliku");
    }

    return handleDocumentChange(
      data,
      onUploadProgress,
      form,
      onFinalizeProgress
    );
  }
}

async function sendUpdateDocumentRequest(
  data: any,
  status: string,
  updateResourceUrl: string,
  onUploadProgress: Function,
  onFinalizeProgress: Function
) {
  const form = new FormData();

  form.append("id", data.id);
  form.append("description", data.description);
  form.append("status", status);

  if (data.document && data.documentChanged) {
    console.log(`new document file name`, data.document.name);
    console.log(`uploading changed file name`, data.document.name);
    console.log(`ck_env ${config.ck_env}`);
    if (data.document.uri === "data:") {
      throw new MyValidationError("Nie można wysłać pustego pliku");
    }

    return handleDocumentChange(
      data,
      onUploadProgress,
      form,
      onFinalizeProgress
    );
  } else {
    console.log("Document to translate not changed.");
  }
}

async function sendUpdateTranslationRequest(
  data: any,
  status: string,
  type: string,
  updateResourceUrl: string,
  onUploadProgress: Function,
  onFinalizeProgress: Function
) {
  const form = new FormData();

  form.append("id", data.id);
  form.append("description", data.description);
  form.append("status", status);

  if (data.document && data.documentChanged) {
    return handleDocumentChange(
      data,
      onUploadProgress,
      form,
      onFinalizeProgress
    );
  } else {
    console.log("Document to translate not changed.");
  }

  if (data.translation && data.translationChanged) {
    return handleTranslationChange(
      data,
      onUploadProgress,
      form,
      updateResourceUrl,
      onFinalizeProgress,
      type
    );
  } else {
    console.log("Translation document not changed.");

    return await axios({
      url: updateResourceUrl,
      method: type,
      data: form,
      onUploadProgress: (progressEvent) => {
        console.log(`UPLOAD progress ${JSON.stringify(progressEvent)}`);
        const uploadProgress = progressEvent.loaded / progressEvent.total;
        onUploadProgress(uploadProgress);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const handleDocumentChange = async (
  data: any,
  onUploadProgress: Function,
  form: FormData,
  onFinalizeProgress: Function
) => {
  console.log(`new document file name`, data.document.name);
  console.log(`new document file `, data.document.file);
  console.log(`uploading changed file name`, data.document.name);
  // console.log(`data ${JSON.stringify(data)}`);

  const updateResourceUrl = `${config.api_url}/translation/${data.id}`;
  console.log(`updateResourceUrl ${JSON.stringify(updateResourceUrl)}`);
  console.log(`ck_env ${config.ck_env}`);

  if (data.document.uri === "data:") {
    throw new MyValidationError("Nie można wysłać pustego pliku");
  }

  // presigned url
  const key = `${data.userId._id}/translations/${data.id}/${Math.ceil(
    Math.random() * 10 ** 10
  )}`;

  if (Platform.OS === "web")
    await processUploadOnWeb(
      data,
      onUploadProgress,
      form,
      updateResourceUrl,
      onFinalizeProgress
    );
  else {
    await processUploadOnMobile(
      data,
      onUploadProgress,
      form,
      updateResourceUrl,
      onFinalizeProgress
    );
  }
};

const processUploadOnMobile = async (
  data: any,
  onUploadProgress: Function,
  form: FormData,
  updateResourceUrl: string,
  onFinalizeProgress: Function
) => {
  onUploadProgress(0.1, "Wczytywanie pliku do wysłania.");

  console.log(`loading file ${JSON.stringify(data.document)}`);
  const size = data.document.size;
  console.log(`${Date()} - fetch from url`);
  onUploadProgress(0.11, "Wczytywanie pliku do wysłania.");
  const response = await fetch(data.document.uri);
  onUploadProgress(0.12, "Wczytywanie pliku do wysłania.");
  console.log(`${Date()} - create blob`);
  const blob = await response.blob();
  onUploadProgress(0.13, "Wczytywanie pliku do wysłania.");
  console.log(`${Date()} - creating reader`);
  var reader = new FileReader();
  onUploadProgress(0.14, "Wczytywanie pliku do wysłania.");
  // blob.stream

  // console.log(`blob ${blob}`);
  reader.onabort = () => console.log("file reading was aborted");
  reader.onerror = () => console.log("file reading has failed");

  reader.onload = async () => {
    console.log(`${Date()} - strip base64`);
    const binaryStr = reader.result;
    // console.log(`type binaryStr ${typeof binaryStr}`);

    let strip = binaryStr.replace(/^data:(.*?);base64,/, ""); // <--- make it any type
    strip = strip.replace(/ /g, "+"); // <--- this is important
    //console.log(`strip binaryStr ${strip}`);

    console.log(`${Date()} - create new blob`);
    const readBlob = new Blob([reader.result]);
    onUploadProgress(0.15, "Wczytywanie pliku do wysłania.");

    console.log(`${Date()} - get presigned link`);
    // const buf = Buffer.from(reader.result, "base64");

    // presigned url
    const key = `${data.userId._id}/translations/${data.id}/${Math.ceil(
      Math.random() * 10 ** 10
    )}`;

    onUploadProgress(0.16, "Wczytywanie pliku do wysłania.");

    const responsePresignedUrl = await axios({
      url: `${config.api_url}/storage-upload-presigned-url`,
      method: "post",
      data: {
        fileName: data.document.name,
        bucket: "ck-users",
        contentType: data.document.mimeType,
        key: key,
      },
    });
    onUploadProgress(0.17, "Wczytywanie pliku do wysłania.");
    console.log(
      `presignedUrl response ${JSON.stringify(responsePresignedUrl.data.url)}`
    );
    const uploadUrl = responsePresignedUrl.data.url;

    console.log(`${Date()} - create buffer binaryToUpload`);
    onUploadProgress(0.18, "Wczytywanie pliku do wysłania.");
    // onUploadProgress(0.1, "Przygotowywanie pliku do wysłania");

    console.log(`${Date()} - AAA`);
    const binaryToUpload = Buffer.from(strip, "base64");
    console.log(`${Date()} - BBB`);
    onUploadProgress(0.2, "Wysyłanie danych...");

    console.log(`${Date()} - uploading file ${data.document.name} to s3`);
    const uploadResponse = await axios.put(
      uploadUrl,
      //  readBlob.stream(),
      binaryToUpload,
      {
        // const uploadResponse = await axios.put(uploadUrl, blob, {
        // const uploadResponse = await axios.put(uploadUrl, readBlob, {
        headers: {
          "Content-Type": data.document.mimeType,
          "Content-Encoding": "base64",
          // "Response-Type": "blob",
          "Content-Length": readBlob.size,
        },
        onUploadProgress: (progressEvent) => {
          console.log(`UPLOAD progress ${JSON.stringify(progressEvent)}`);
          const uploadProgress = progressEvent.loaded / progressEvent.total;
          onUploadProgress(0.2 + uploadProgress * 0.7, "Wysyłanie danych...");
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        timeout: 1000 * 60 * 60, //60 minutes
      }
    );

    console.log(`upload response ${JSON.stringify(uploadResponse.data)}`);

    form.append("documentFileName", data.document.name);
    form.append("documentStorageId", key.replace(/^.*[\\\/]/, ""));
    form.append("documentSize", data.document.size);
    form.append("documentMimeType", data.document.mimeType);
    form.append("status", "submitted");

    console.log(`executing patch with new file name`);
    const patchResult = await axios({
      url: updateResourceUrl,
      onUploadProgress: (progressEvent) => {
        console.log(
          `FINISHING with patch progress ${JSON.stringify(progressEvent)}`
        );
        onFinalizeProgress(progressEvent);
      },
      method: "patch",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    onUploadProgress(1, "Zakończono");
    console.log(`patch done ${JSON.stringify(patchResult.data)}`);
    return patchResult;
  };
  reader.readAsDataURL(blob);
};

const processUploadOnWeb = async (
  data: any,
  onUploadProgress: Function,
  form: FormData,
  updateResourceUrl: string,
  onFinalizeProgress: Function
) => {
  const reader = new FileReader();

  onUploadProgress(0.1, "Przygotowywanie pliku do wysłania");

  reader.onabort = () => console.log("file reading was aborted");
  reader.onerror = () => console.log("file reading has failed");
  reader.onload = async () => {
    const binaryStr = reader.result;
    console.log(`binary str size ${binaryStr}`);

    const blob = new Blob([reader.result]);
    if (blob.size < 1024 * 1024)
      console.log(`binary str size ${blob.size} bytes`);
    else
      console.log(`binary str size ${(blob.size / 1024 / 1024).toFixed(2)} MB`);

    // presigned url
    const key = `${data.userId._id}/translations/${data.id}/${Math.ceil(
      Math.random() * 10 ** 10
    )}`;

    onUploadProgress(0.2, "Wysyłanie danych...");

    const responsePresignedUrl = await axios({
      url: `${config.api_url}/storage-upload-presigned-url`,
      method: "post",
      data: {
        fileName: data.document.name,
        bucket: "ck-users",
        contentType: data.document.mimeType,
        key: key,
      },
    });

    console.log(
      `presignedUrl response ${JSON.stringify(responsePresignedUrl.data.url)}`
    );
    const uploadUrl = responsePresignedUrl.data.url;

    console.log(`uploading file ${data.document.name} to s3`);

    // await put(uploadUrl, blob);

    const uploadResponse = await axios.put(uploadUrl, blob, {
      headers: {
        "Content-Type": data.document.mimeType,
      },
      onUploadProgress: (progressEvent) => {
        //console.log(`UPLOAD progress ${JSON.stringify(progressEvent)}`);
        const uploadProgress = progressEvent.loaded / progressEvent.total;
        onUploadProgress(0.2 + uploadProgress * 0.7, "Wysyłanie danych...");
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 1000 * 60 * 60, //60 minutes
    });

    console.log(`upload response ${JSON.stringify(uploadResponse.data)}`);

    form.append("documentFileName", data.document.name);
    form.append("documentStorageId", key.replace(/^.*[\\\/]/, ""));
    form.append("documentSize", data.document.size);
    form.append("documentMimeType", data.document.mimeType);
    form.append("status", "submitted");
    console.log(`executing patch with new file name`);
    const patchResult = await axios({
      url: updateResourceUrl,
      method: "patch",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(`patch done ${JSON.stringify(patchResult.data)}`);
    onUploadProgress(1, "Zakończono");

    return patchResult;
  };
  reader.readAsArrayBuffer(data.document.file);
};

function handleTranslationChange(
  data: any,
  onUploadProgress: Function,
  form: FormData,
  updateResourceUrl: string,
  onFinalizeProgress: Function,
  type: string
) {
  onUploadProgress(0.1, "Wczytywanie pliku do wysłania.");
  console.log(`new translation file name`, data.translation.name);
  console.log(`new translation file `, data.translation.file);
  console.log(`uploading changed file name`, data.translation.name);
  console.log(`data.userId`, data.userId);
  console.log(`ck_env ${config.ck_env}`);
  if (data.translation.uri === "data:") {
    throw new MyValidationError("Nie można wysłać pustego pliku");
  }

  // presigned url
  const key = `${data.userId._id}/translations/${data.id}/${Math.ceil(
    Math.random() * 10 ** 10
  )}`;

  const reader = new FileReader();

  reader.onabort = () => console.log("file reading was aborted");
  reader.onerror = () => console.log("file reading has failed");
  reader.onload = async () => {
    const binaryStr = reader.result;
    console.log(`binary str size ${binaryStr}`);

    const blob = new Blob([reader.result]);
    if (blob.size < 1024 * 1024)
      console.log(`binary str size ${blob.size} bytes`);
    else
      console.log(`binary str size ${(blob.size / 1024 / 1024).toFixed(2)} MB`);

    // presigned url
    const key = `${data.userId._id}/translations/${data.id}/${Math.ceil(
      Math.random() * 10 ** 10
    )}`;

    const responsePresignedUrl = await axios({
      url: `${config.api_url}/storage-upload-presigned-url`,
      method: "post",
      data: {
        fileName: data.translation.name,
        bucket: "ck-users",
        contentType: data.translation.mimeType,
        key: key,
      },
    });

    console.log(
      `presignedUrl response ${JSON.stringify(responsePresignedUrl.data.url)}`
    );
    const uploadUrl = responsePresignedUrl.data.url;

    console.log(`uploading file ${data.translation.name} to s3`);
    const uploadResponse = await axios.put(uploadUrl, blob, {
      headers: {
        "Content-Type": data.translation.mimeType,
      },
      onUploadProgress: (progressEvent) => {
        console.log(`UPLOAD progress ${JSON.stringify(progressEvent)}`);
        const uploadProgress = progressEvent.loaded / progressEvent.total;
        onUploadProgress(0.2 + uploadProgress * 0.7, "Wysyłanie danych...");
        onUploadProgress(uploadProgress);
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 1000 * 60 * 60, //60 minutes
    });

    console.log(`upload response ${JSON.stringify(uploadResponse.data)}`);

    form.append("translationFileName", data.translation.name);
    form.append("translationStorageId", key.replace(/^.*[\\\/]/, ""));
    form.append("translationSize", data.translation.size);
    form.append("translationMimeType", data.translation.mimeType);

    console.log(`executing patch with new file name}`);
    const patchResult = await axios({
      url: updateResourceUrl,
      onUploadProgress: (progressEvent) => onFinalizeProgress(progressEvent),
      method: type,
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(`patch done ${JSON.stringify(patchResult)}`);
    return patchResult;
  };
  reader.readAsArrayBuffer(data.translation.file);
}
