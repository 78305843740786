import React, { useContext, useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Platform, Image } from "react-native";
import { Button, Colors } from "react-native-paper";
import { styles } from "../styles";
import LABELS from "./labels";
import MUI_THEME from "../../styles/index.native";
import { DebugContext } from "../../debug-context";
import { useNavigation } from "@react-navigation/native";
import CustomDialog from "../CustomDialog";
import { login } from "../../services/auth.service";
import { AuthContext, Auth } from "../../auth-context";
import { useForm } from "react-hook-form";
import ValidatedTextInput from "../ValidatedTextInput";
import { handleError } from "../../utils";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

export default function Login() {
  const navigation = useNavigation();
  const debug = useContext(DebugContext).debug;
  const { auth, setAuth } = React.useContext(AuthContext);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [showSpinner, setshowSpinner] = React.useState(false);

  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  useEffect(() => {
    releaseUserMedia();
  }, []);

  const {
    control,
    getValues,
    handleSubmit,

    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
  });

  const listener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      console.log(getValues("email"));
      event.preventDefault();

      handleSubmit(
        onSubmit({
          email: getValues("email"),
          password: getValues("password"),
        })
      );
    }
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      console.log("adding on enter pressed listener");
      document.addEventListener("keydown", listener, true);
      return () => {
        document.removeEventListener("keydown", listener, true);
      };
    }
  }, []);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
    navigation.navigate("SignIn");
  };

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("SignIn");
  };

  // console.log(`form filled  ${!isEmpty(password) && !isEmpty(email)}`);

  const onSubmit = async (data: {
    email: string;
    password: string;
    version: string;
    iosBuildNumber: string;
    androidVersionCode: string;
    platform: string;
  }) => {
    setshowSpinner(false);

    console.log(`Authentication metadata version: ${data.version}`);
    console.log(
      `Authentication metadata iosBuildNumber: ${data.iosBuildNumber}`
    );
    console.log(
      `Authentication metadata androidVersionCode: ${data.androidVersionCode}`
    );
    console.log(`Authentication metadata platform: ${data.platform}`);

    try {
      const auth = await login(data.email, data.password);

      setshowSpinner(false);
      setAuth(auth);
      console.log(`authenticatedUser ${auth.id}`);
      console.log("Navigate to profile...");
      console.log(`authenticatedUser ${JSON.stringify(auth)}`);

      if (auth.declaredRefugee === true) {
        navigation.navigate("MultiPartyConfCallUA", {
          initial: false,
        });
      } else navigation.navigate("Home");
    } catch (error) {
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minWidth: Platform.OS === "web" ? 557 : 278,
      }}
    >
      <View
        testID="registration_container" // used i.e. in appium
        style={
          Platform.OS === "web"
            ? styles.outerColumnContainer
            : styles.outerColumnContainerMobile
        }
      >
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />

        {/* fields  */}
        <View
          testID="fields"
          style={[
            {
              paddingTop: Platform.OS === "web" ? 40 : 20,
              paddingLeft: 10,
              paddingRight: 10,
            },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="email"
              accessibilityLabel={"email"}
              control={control}
              rules={{
                required: "To pole jest wymagane",
                pattern: {
                  value:
                    /^\s*[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\s*$/,
                  message: "Nieprawidłowy adres email",
                },
              }}
              placeholder={"Email"}
              errors={errors}
              leftIconName="email-outline"
            />
          </View>
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="password"
              placeholder="Hasło"
              accessibilityLabel={"Hasło"}
              rules={{
                required: "To pole jest wymagane",
              }}
              errors={errors}
              control={control}
              secureTextEntry={true}
              leftIconName={"form-textbox-password"}
            />
          </View>

          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 40,
            }}
          ></View>
        </View>

        <View
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          <Button
            testID="button_sign_in"
            onPress={handleSubmit(onSubmit)}
            mode="contained"
            color={Colors.blue500}
            labelStyle={{ color: "white", fontWeight: "bold" }}
            style={{ padding: 5 }}
          >
            {LABELS.signIn}
          </Button>
        </View>
        <View
          testID="already_sign_up"
          style={[
            debug === true ? { backgroundColor: "yellow" } : undefined,
            {
              flexDirection: "row",
              padding: 10,
              alignItems: "flex-end",
              justifyContent: "center",
              marginTop: 5,
              marginHorizontal: 30,
              marginBottom: 5,
            },
          ]}
        >
          <TouchableOpacity
            testID="signup_to_log_in"
            onPress={() => navigation.navigate("SignUp")}
          >
            <Text
              accessibilityLabel={LABELS.signUpToLogIn}
              style={{
                color: MUI_THEME.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              {LABELS.signUpToLogIn}
            </Text>
          </TouchableOpacity>
        </View>

        <View
          testID="already_sign_up"
          style={[
            debug === true ? { backgroundColor: "yellow" } : undefined,
            {
              flexDirection: "row",
              padding: 5,
              alignItems: "flex-end",
              justifyContent: "center",
              marginTop: 0,
              marginHorizontal: 30,
              marginBottom: 0,
            },
          ]}
        >
          <TouchableOpacity
            testID="navigate_to_password_reset"
            onPress={() => navigation.navigate("PasswordResetRequest")}
          >
            <Text
              testID="password_reset"
              style={{
                color: MUI_THEME.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              {LABELS.passwordReset}
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View
        testID="logo_partners"
        style={{
          display: "flex",
          flexShrink: 0,
          marginTop: Platform.OS === "web" ? 100 : 50,
          width: "100%",
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={require("../../assets/logotypy_unijne.png")}
          style={{
            resizeMode: "contain",
            height: Platform.OS === "web" ? 71 : 35,
            width: Platform.OS === "web" ? 557 : 278,
          }}
        />

        <Text style={{ padding: 20, textAlign: "center" }}>
          „Włączenie wyłączonych - aktywne instrumenty wsparcia osób
          niepełnosprawnych na rynku pracy” Nr projektu:
          POWR.02.06.00-00-0065/19
        </Text>

        <Text style={{ paddingTop: 20, paddingBottom: 20 }}>Partnerzy:</Text>

        <Image
          source={require("../../assets/logotypy_partnerow.png")}
          style={{
            display: "flex",
            resizeMode: "contain",
            width: Platform.OS === "web" ? 484 : 242,
            height: Platform.OS === "web" ? 45 : 23,
            justifyContent: "center",
          }}
        />
      </View>
    </View>
  );
}
