import React, { useContext } from "react";
import { View, Text, Platform } from "react-native";
import { DebugContext } from "../../debug-context";
import { styles } from "./styles";
import { AuthContext, AuthContextType } from "../../auth-context";
import { TouchableOpacity } from "react-native-gesture-handler";
import _ from "lodash";

export type ChatMessage = {
  id: number;
  increment: number;
  receivedAt: Date;
  type: string;
  chatType: string;
  from: string;
  to: string;
  content: string;
  fromInitials: string;
};

interface ChatItemProps {
  message: ChatMessage;
}

export default function ChatItem({ message }: ChatItemProps) {
  const debugContext = useContext(DebugContext);
  const { auth } = useContext(AuthContext) as AuthContextType;
  const debug = debugContext.debug;
  // const debug = true //debugContext.debug
  const own = message.from === auth?.email;

  // console.log("message increment: ", JSON.stringify(message?.increment));
  // console.log('rendering chat is_incoming: ', JSON.stringify(props.is_incoming))
  // console.log("rendering chat item: ", JSON.stringify(message));
  // console.log(`auth  ${JSON.stringify(auth)}`);

  return (
    <View
      style={{
        flexDirection: "column",
        padding: 5,
        justifyContent: "space-between",
        height: "auto",
      }}
    >
      {/* icon */}
      <View
        style={{
          flexDirection: "row",
          padding: 2,
          backgroundColor: debug ? "blue" : "undefined",
          justifyContent: own ? "flex-start" : "flex-end",
        }}
      >
        <View style={styles.avatar}>
          <Text style={styles.avatarLetters}>
            {own ? auth?.initials : message.fromInitials}
          </Text>
        </View>
      </View>

      {/* text */}
      <View
        style={{
          height: "auto",
          padding: 2,
          alignContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            display: own ? "none" : "flex",
            flexGrow: 1,
            borderRadius: 5,
            padding: 2,
          }}
        >
          <Text style={{ display: "flex", flexGrow: 1 }}></Text>
        </View>
        <View
          style={{
            display: "flex",
            backgroundColor: own ? "#0018ae" : "darkgreen",
            borderRadius: 5,
            padding: 2,
          }}
        >
          <TouchableOpacity style={{ flex: 1 }}>
            <Text
              selectable
              testID={
                message.receivedAt
                  ? `chat_message_${message.increment}`
                  : "chat_message_live"
              }
              style={{
                color: "white",
                fontSize: 18,
                display: "flex",
                maxWidth: Platform.OS === "web" ? "25vw" : undefined,
                wordBreak: "break-all", //https://stackoverflow.com/questions/3247358/how-do-i-wrap-text-with-no-whitespace-inside-a-td
              }}
            >
              {message.content}
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            display: own ? "flex" : "none",
            flexGrow: 1,
            borderRadius: 5,
            padding: 2,
          }}
        >
          <Text style={{ display: "flex", flexGrow: 1 }}></Text>
        </View>
      </View>

      {/* timestamp */}
      <View
        style={{
          backgroundColor: debug ? "green" : "undefined",
        }}
      >
        <Text
          style={{
            fontSize: 8,
            padding: 2,
            textAlign: own ? "left" : "right",
          }}
        >
          {message.receivedAt?.toLocaleString("pl-PL")}
        </Text>
      </View>
    </View>
  );
}
