import React from "react";
import { View, Text } from "react-native";
import { KeyWebRTCStats } from "../../services/webrtcstats.service";

interface WebRTCStatsViewProps {
  data: KeyWebRTCStats;
}

interface WebRTCMetricProps {
  value: string | number;
  label: string;
  unit: string;
}
const WebRTCMetric = ({ value, label, unit }: WebRTCMetricProps) => {
  let valueToShow: string;

  if (value) {
    if (typeof value === "number") valueToShow = Math.round(value).toString();
    else valueToShow = value;
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Text>{label}</Text>
      <Text>
        {valueToShow ? valueToShow : "?"} {unit}
      </Text>
    </View>
  );
};

const WebRTCStatsView = ({ data }: WebRTCStatsViewProps) => {
  if (!data)
    return (
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Text style={{ fontWeight: "600" }}>
          Ładownie statystyk połączenia...
        </Text>
      </View>
    );
  else
    return (
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Text style={{ fontWeight: "600" }}>Przychodzące</Text>
        <WebRTCMetric
          label={"dostępny transfer (tylko safari)"}
          value={data.inbound.availableBitrateKbs}
          unit={"Kbs"}
        />

        <WebRTCMetric
          label={"wykorzystany transfer"}
          value={data.inbound.utilizedBitrateKbs}
          unit={"Kbs"}
        />

        <WebRTCMetric
          label={"Rozdzielczość"}
          value={`${data.inbound.width}x${data.inbound.height}`}
          unit={"px"}
        />

        <WebRTCMetric
          label={"fps"}
          value={data.inbound.framerate}
          unit={"fps"}
        />

        <Text> </Text>

        <Text style={{ fontWeight: "600" }}>Wychodzące</Text>
        <WebRTCMetric
          label={"dostępny transfer"}
          value={data.outbound.availableBitrateKbs}
          unit={"Kbs"}
        />

        <WebRTCMetric
          label={"wykorzystany transfer"}
          value={data.outbound.utilizedBitrateKbs}
          unit={"Kbs"}
        />

        <WebRTCMetric
          label={"fps"}
          value={data.outbound.framerate}
          unit={"fps"}
        />

        <WebRTCMetric
          label={"Rozdzielczość"}
          value={`${data.outbound.width}x${data.outbound.height}`}
          unit={"px"}
        />

        <WebRTCMetric
          label={"Oczekiwany fps"}
          value={data.outbound.askedFramerate}
          unit={"fps"}
        />

        <WebRTCMetric
          label={"Oczekiwana rozdzielczość"}
          value={`${data.outbound.askedWidth}x${data.outbound.askedHeight}`}
          unit={"px"}
        />

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text>{"Ograniczenia"}</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text>{`${JSON.stringify(data.outbound.limitation)}`}</Text>
        </View>

        <Text> </Text>

        <Text style={{ fontWeight: "600" }}>Lokalny strumień danych</Text>
        <WebRTCMetric
          label={"typ"}
          value={data.local.candidateType}
          unit={""}
        />
        <WebRTCMetric
          label={"protokół"}
          value={data.local.candidateProtocol}
          unit={""}
        />
        <WebRTCMetric
          label={"protokół relay"}
          value={data.local.candidateRelayProtocol}
          unit={""}
        />

        <Text> </Text>
        <Text style={{ fontWeight: "600" }}>Zdalny strumień danych</Text>
        <WebRTCMetric
          label={"typ"}
          value={data.remote.candidateType}
          unit={""}
        />
        <WebRTCMetric
          label={"protokół"}
          value={data.remote.candidateProtocol}
          unit={""}
        />
        {/* <WebRTCMetric
        label={"protokół relay"}
        value={data.remote.candidateRelayProtocol}
        unit={""}
      /> */}
      </View>
    );
};

export default WebRTCStatsView;
