import * as React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { translatorStats } from "./stats";
import { Typography } from "@mui/material";
import {
  CalendarEventContext,
  CalendarEventContextType,
} from "../../calendar-event-context";

interface TranslatorsSelectionProps {
  translators: { name: string; selected: boolean };
  events: any;
  updateTranslators: (translators: { name: string; selected: boolean }) => void;
}

export default function TranslatorsSelection(props: TranslatorsSelectionProps) {
  const { selectedEvent, selectEvent, events, updateEvents, selectedDate } =
    React.useContext(CalendarEventContext) as CalendarEventContextType;

  console.log("selectedDate", selectedDate);

  const [state, setState] = React.useState(props.translators);

  React.useEffect(() => {
    // console.log('useEffect, rows:', props.translators);
    setState(props.translators);
  }, [props.translators]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('event', event.target.name)
    // console.log('event', event.target.checked)
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    props.updateTranslators({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  // console.log('state before RENDER', state);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexGrow: 5, borderColor: "red" }}>
          <Typography style={{ flex: 1, padding: 10 }}>Tłumacz</Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            // borderColor: "red",
            textAlign: "center",
          }}
        >
          <Typography style={{ flex: 1, padding: 10 }}>d</Typography>
          <Typography style={{ flex: 1, padding: 10 }}>t</Typography>
          <Typography style={{ flex: 1, padding: 10 }}>m</Typography>
        </div>
      </div>

      {Object.entries(state).map(([key, value]) => {
        const stats = translatorStats(events, key, selectedDate?.toISOString());

        return (
          <div
            key={`div ${key}`}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              style={{ display: "flex", flexGrow: 5, borderColor: "red" }}
              key={key}
              control={
                <Checkbox
                  key={key}
                  checked={value}
                  onChange={handleChange}
                  name={key}
                />
              }
              label={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // backgroundColor: "red",
                    flexGrow: 1,
                    justifyContent: "space-between",
                    alignItems: "space-between",
                  }}
                  key={`stats ${key}`}
                >
                  {`${key}`}
                </div>
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // backgroundColor: "orange",
              }}
            >
              <Typography style={{ flex: 1, padding: 10 }}>
                {stats.d}
              </Typography>
              <Typography style={{ flex: 1, padding: 10 }}>
                {stats.w}
              </Typography>
              <Typography style={{ flex: 1, padding: 10 }}>
                {stats.m}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}
