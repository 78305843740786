import React, { useContext, useState } from "react";
import MyRTCView, { MediaStream } from "../../components/MyRTCView";
import dStyles from "./styles";
import { DebugContext } from "../../debug-context";
import { Platform } from "react-native";

type RemoteVideosMobileProps = {
  remoteStreams: Map<string, MediaStream>;
  remoteCamerasDisabled: Map<string, boolean>;
};

export const RemoteVideosMobile = ({
  remoteStreams,
  remoteCamerasDisabled,
}: RemoteVideosMobileProps) => {
  const [chatOn] = useState(false);
  const { debug } = useContext(DebugContext);

  let listOfRemoteVideos = new Array();
  const styles = dStyles(debug, chatOn);

  console.log(
    `[Remote stream] rendering listOfRemoteVideos remote streams: ${
      remoteStreams ? [...remoteStreams.keys()] : undefined
    }`
  );
  if (!remoteStreams || !remoteStreams || remoteStreams.size === 0) {
    console.log(`[Remote stream] not available or empty`);
    return <></>;
  } else {
    let item = 0;

    if (remoteStreams && remoteStreams.size > 0) {
      {
        remoteStreams.forEach((value, key: string) => {
          console.log(
            `[Remote stream] size: ${remoteStreams.size} rendering   key: ${key}, item ${item}`
          );

          console.log(
            `remoteCamerasDisabled ${
              remoteCamerasDisabled.has(key)
                ? remoteCamerasDisabled.get(key)
                : "?"
            }`
          );

          const disabled = remoteCamerasDisabled
            ? remoteCamerasDisabled.get(key)
            : false;
          if (item === 0 && Platform.OS === "android")
            listOfRemoteVideos.push(
              <MyRTCView
                videoId={`remote_${key}`}
                key={`remote_${key}`}
                style={
                  remoteStreams.size < 2
                    ? styles.video2PeerMobile
                    : styles.video23MobileShiftedUnderSelfVideo
                }
                videoDisabled={disabled}
                stream={value}
              />
            );
          else
            listOfRemoteVideos.push(
              <MyRTCView
                videoId={`remote_${key}`}
                key={`remote_${key}`}
                style={
                  remoteStreams.size < 2
                    ? styles.video2PeerMobile
                    : styles.video23Mobile
                }
                videoDisabled={disabled}
                stream={value}
              />
            );
          item = item + 1;
        });
      }
    }
    return <>{listOfRemoteVideos}</>;
  }
};
