import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { FocusEventHandler } from "react";
import {
  ZusDisabilityLevel as ZusDisabilityLevelEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface ZusDisabilityLevel {
  id: string;
  label: string;
}

export const zusDisabilityLevelOptions = Object.keys(
  ZusDisabilityLevelEnum
).map((key) => ({
  id: key,
  label: ZusDisabilityLevelEnum[key],
}));

console.debug(
  `zusDisabilityLevelOptions ${JSON.stringify(zusDisabilityLevelOptions)}`
);

interface ZusDisabilityLevelProps {
  resource: ParticipationFormDocument;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setResource: React.Dispatch<any>;
}

export const ZusDisabilityLevel = ({
  resource,
  errors,
  setResource,
  onBlur,
}: ZusDisabilityLevelProps) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body1">
        22. Posiadany stopień orzeczenia lekarza Orzecznika ZUS
      </Typography>
      <Autocomplete
        options={zusDisabilityLevelOptions}
        disablePortal
        id="zus_disability_level"
        value={
          resource.zus_disability_level ? resource.zus_disability_level : ""
        }
        isOptionEqualToValue={(option, value) => option.label === value}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            required={true}
            error={errors ? errors["zus_disability_level"] : false}
            fullWidth
            onBlur={onBlur}
            label="Stopień orzeczenia ZUS"
          />
        )}
        onChange={(event: any, newValue: ZusDisabilityLevel) => {
          console.log(`newValue ${JSON.stringify(newValue)}`);
          setResource({
            ...resource,
            zus_disability_level: newValue ? newValue.label : "",
          });
        }}
      />
    </Grid>
  );
};
