import React, { useEffect } from "react";
import { DebugContext, DebugContextType } from "../debug-context";
import { GLOBAL_LABELS } from "../labels";
import { isEmpty } from "lodash";
import { StyleSheet, View, Image, Platform, Linking } from "react-native";

import Translation from "../screens/Translation";
import { getHeaderTitle } from "@react-navigation/elements";
import MyHeader from "./MyHeader";
import { useNavigation } from "@react-navigation/native";

import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from "@react-navigation/drawer";
import { AuthContext, AuthContextType } from "../auth-context";
import { MyDrawerMenu } from "./MyDrawerMenu";

import { RootStackParamList } from "./types";

import Profile from "../screens/Profile";
import RegistrationInfo from "../screens/RegistrationInfo";
import Users from "../screens/Users";
import SupportForms from "../screens/SupportForms";
import SupportForm from "../screens/SupportForm";
import Info from "../screens/Info";
import P2PCall from "../screens/P2PCall";
import P2PTranscription from "../screens/P2PTranscription";
import MultiPartyConfCall from "../screens/MultiPartyConfCall";
import P2PStats from "../screens/P2PStats";
import Translations from "../screens/Translations";
import ScheduledConfCall from "../screens/ScheduledConfCall";
import ScheduledConfCalls from "../screens/ScheduledConfCalls";
import MultiPartyRecordings from "../screens/MultiPartyRecordings";
import ParticipationForm from "../screens/ParticipationForm";
import ParticipationForms from "../screens/ParticipationForms";
import SignUp from "../screens/SignUp";
import SignIn from "../screens/SignIn";
import Policy from "../screens/Policy";
import Contact from "../screens/Contact";
import EndOfService from "../screens/EndOfService";
import PasswordResetRequest from "../screens/PasswordResetRequest";
import PasswordResetUpdate from "../screens/PasswordResetUpdate";
import RegistrationFirstStep from "../screens/RegistrationFirstStep";
import RegistrationSecondStep from "../screens/RegistrationSecondStep";
import ConnectedUsers from "../screens/ConnectedUsers";
import CloseAccount from "../screens/CloseAccount";
import Calendar from "../screens/Calendar";
import TranslatorsCalendar from "../screens/TranslatorsCalendar";
import {
  DisableMenuContext,
  DisableMenuContextType,
} from "../disable-menu-context";

export function Divider() {
  return (
    <View
      style={{
        marginVertical: 8,
        borderBottomColor: "#737373",
        borderBottomWidth: StyleSheet.hairlineWidth,
      }}
    />
  );
}

const Drawer = createDrawerNavigator<RootStackParamList>();

function CustomDrawerContent({
  navigation,
  ...props
}: DrawerContentComponentProps) {
  const { debug, toggleDebugFunction } = React.useContext(
    DebugContext
  ) as DebugContextType;
  const { auth } = React.useContext(AuthContext) as AuthContextType;

  // console.log(`[CustomDrawerContent] build ${build}`);

  console.debug(`[CustomDrawerContent] auth ${JSON.stringify(auth)}`);
  console.debug(`debug ${debug}`);

  // console.log(`DebugContext ${debug}`);
  // console.log('[AuthContext] admin? ', auth && auth.isAdmin);

  return <MyDrawerMenu />;
}

export const MyDrawerScreens = (...props) => {
  const { auth } = React.useContext(AuthContext);
  const [disableGestures, setDisableGestures] = React.useState(true);
  const { menuDisabled } = React.useContext(
    DisableMenuContext
  ) as DisableMenuContextType;

  const navigation = useNavigation();
  // console.log(`[MyDrawerScreens] Drawer object in ${Drawer}`);
  console.log(`[MyDrawerScreens] auth  ${JSON.stringify(auth)}`);

  return (
    <Drawer.Navigator
      useLegacyImplementation //xman without this drawer stopped autohiding in Expo 46m
      screenOptions={{
        drawerStyle: {
          // width: 240,
        },
        unmountOnBlur: true, // xman without this menu icon was rendered for each screen
        sceneContainerStyle: {
          // backgroundColor: "yellow",
          // minWidth: 350,
        },
        header: ({ navigation, route, options }) => {
          const title = getHeaderTitle(options, route.name);
          return <MyHeader title={title} style={options.headerStyle} />;
        },
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      {/* Ad hoc testing - first screen comes here */}

      {/* default page for logged UA user */}
      {auth?.declaredRefugee === true && (
        <Drawer.Screen
          name="MultiPartyConfCallUA"
          options={{ title: GLOBAL_LABELS.multiPartyConfCall }}
          component={MultiPartyConfCall}
        />
      )}

      {/* default page for not logged user */}
      {isEmpty(auth) && (
        <Drawer.Screen
          name="EndOfService"
          options={{ title: GLOBAL_LABELS.signIn }}
          component={EndOfService}
        />
      )}

      {isEmpty(auth) && (
        <Drawer.Screen
          name="SignIn"
          options={{ title: GLOBAL_LABELS.signIn }}
          component={SignIn}
        />
      )}
      {isEmpty(auth) && (
        <Drawer.Screen
          name="SignUp"
          options={{ title: GLOBAL_LABELS.signUp }}
          component={SignUp}
        />
      )}
      {/* default page for logged admin */}
      {auth && auth.isAdmin && (
        <Drawer.Screen
          name="Users"
          options={{ title: GLOBAL_LABELS.users }}
          component={Users}
        />
      )}

      {/* default page for logged transcriber */}
      {auth && auth.isTranscriber && (
        <Drawer.Screen
          name="P2PTranscription"
          initialParams={{ mode: "transcription" }}
          options={{ title: "Transkrypcja" }}
          component={P2PTranscription}
        />
      )}

      {/* default page for logged user (transcription) */}
      {auth &&
        auth.isUser &&
        auth.declaredRefugee !== true &&
        auth.preferredWrittenCommunication === true && (
          <Drawer.Screen
            name="P2PTranscription"
            options={{ title: "Transkrypcja" }}
            component={P2PTranscription}
          />
        )}

      {/* default page for logged user (pjm) */}
      {auth && auth.isUserOrTranslator && auth.declaredRefugee !== true && (
        <Drawer.Screen
          name="P2PCall"
          options={{
            title: GLOBAL_LABELS.adHocCall,
            swipeEnabled: menuDisabled === true ? false : true,
          }}
          component={P2PCall}
        />
      )}

      {/* default page for logged user (transcription) */}
      {auth && auth.isUser && auth.preferredWrittenCommunication === false && (
        <Drawer.Screen
          name="P2PTranscription"
          options={{ title: "Transkrypcja" }}
          component={P2PTranscription}
        />
      )}

      {auth && auth.isTranslatorOrAdmin && (
        <Drawer.Screen
          name="Calendar"
          options={{ title: "Kalendarz (Google)" }}
          component={Calendar}
        />
      )}

      {auth?.declaredRefugee !== true && auth?.isUser && (
        <Drawer.Screen
          name="MultiPartyConfCall"
          options={{ title: GLOBAL_LABELS.multiPartyConfCall }}
          initialParams={{ token: auth?.email }}
          component={MultiPartyConfCall}
        />
      )}

      {auth && auth.isTranslator && (
        <Drawer.Screen
          name="MultiPartyConfCall"
          options={{ title: GLOBAL_LABELS.multiPartyConfCall }}
          component={MultiPartyConfCall}
        />
      )}

      {auth && auth.isAdmin && (
        <Drawer.Screen
          name="ConnectedUsers"
          options={{ title: GLOBAL_LABELS.connectedUsers }}
          component={ConnectedUsers}
        />
      )}

      {Platform.OS === "web" && !isEmpty(auth) && auth.isTranslatorOrAdmin && (
        <Drawer.Screen
          name="TranslatorsCalendar"
          options={{ title: "Kalendarz" }}
          component={TranslatorsCalendar}
        />
      )}

      {!isEmpty(auth) && auth.declaredRefugee !== true && (
        <Drawer.Screen
          name="Translations"
          options={{ title: GLOBAL_LABELS.translations }}
          component={Translations}
        />
      )}

      {auth && auth.isUser === false && (
        <Drawer.Screen
          name="P2PStats"
          options={{ title: GLOBAL_LABELS.confCallStats }}
          component={P2PStats}
        />
      )}

      {auth && auth.isAdmin && (
        <Drawer.Screen
          name="MultiPartyRecordings"
          options={{ title: GLOBAL_LABELS.multiPartyRecordings }}
          component={MultiPartyRecordings}
        />
      )}

      {/* users are available for translator but a bit later */}
      {auth && auth.isTranslator && (
        <Drawer.Screen
          name="Users"
          options={{ title: GLOBAL_LABELS.users }}
          component={Users}
        />
      )}

      <Drawer.Screen
        name="Profile"
        options={{ title: "Mój profil" }}
        // initialParams={{ userId: "63c47e20a6958bac2dab4780" }}
        component={Profile}
      />
      {auth && auth.isAdmin && (
        <Drawer.Screen
          name="ScheduledConfCalls"
          options={{
            title: GLOBAL_LABELS.confCallSchedule,
          }}
          component={ScheduledConfCalls}
        />
      )}
      {auth && !auth.isUser && (
        <Drawer.Screen
          name="SupportForms"
          options={{ title: GLOBAL_LABELS.supportForms }}
          component={SupportForms}
        />
      )}
      {auth && !auth.isUser && (
        <Drawer.Screen
          name="ParticipationForms"
          options={{ title: GLOBAL_LABELS.participationForms }}
          component={ParticipationForms}
        />
      )}
      <Drawer.Screen
        name="DividerAdmin"
        options={{ title: GLOBAL_LABELS.signIn }}
        component={Divider}
      />
      <Drawer.Screen
        name="Info"
        options={{ title: GLOBAL_LABELS.info }}
        component={Info}
      />
      <Drawer.Screen
        name="Policy"
        options={{ title: "Polityka prywatności" }}
        component={Policy}
      />
      <Drawer.Screen
        name="Contact"
        options={{ title: GLOBAL_LABELS.info }}
        component={Contact}
      />
      <Drawer.Screen
        name="CloseAccount"
        options={{ title: GLOBAL_LABELS.closeAccount }}
        component={CloseAccount}
      />
      <Drawer.Screen
        name="RegistrationInfo"
        options={{ title: "Informacja o rejestracji" }}
        // initialParams={{ userId: "63c47e20a6958bac2dab4780" }}
        component={RegistrationInfo}
      />

      {/* screens not accessible from menu */}

      {!isEmpty(auth) && (
        <Drawer.Screen
          name="Translation"
          options={{ title: GLOBAL_LABELS.translations }}
          // initialParams={{ translationId: "63556f3fbd4d94153070993b" }}
          component={Translation}
        />
      )}
      {auth && auth.isAdmin && (
        <Drawer.Screen
          name="ScheduledConfCall"
          options={{
            title: GLOBAL_LABELS.confCallSchedule,
          }}
          // initialParams={{ id: "63543897b89d99f1ff1cdb9f" }}
          component={ScheduledConfCall}
        />
      )}
      <Drawer.Screen
        name="PasswordResetUpdate"
        options={{ title: "Zmiana hasła" }}
        // initialParams={{ token: "6fc90559-c54a-4be1-bed0-c2a3766f19c9" }}
        component={PasswordResetUpdate}
      />
      <Drawer.Screen
        name="PasswordResetRequest"
        options={{ title: "Prośba o zmianę hasła" }}
        component={PasswordResetRequest}
      />
      {auth && !auth.isUser && (
        <Drawer.Screen
          name="SupportForm"
          options={{
            title: GLOBAL_LABELS.supportForm,
          }}
          /* initialParams={{
            id: "63a76d6812c904f0aa1982f1",
          }} */
          //  initialParams={{
          //   p2p: {
          //     formOfSupportCode: "<30m",
          //     // userId: "634f06e144fd2ea64a6c2afc",
          //     userEmail: "pprzybylek@gazeta.pl",
          //     translatorId: "634f06e144fd2ea64a6c2afa",
          //     supportDateStart: new Date(),
          //     supportDateEnd: new Date(),
          //   },
          // }}
          component={SupportForm}
        />
      )}
      {auth && auth.isTranslatorOrAdmin && (
        <Drawer.Screen
          name="ParticipationForm"
          options={{ title: GLOBAL_LABELS.participationForm }}
          // initialParams={{ id: "6385dfb4b6b200924b36c08e" }}
          //  initialParams={{
          //  email: "fishy-user-123e12fsda@gazeta.pl",
          //  autosave: true,
          // }}
          component={ParticipationForm}
        />
      )}

      <Drawer.Screen
        name="RegistrationFirstStep"
        options={{ title: "Potwierdzenie rejestracji" }}
        component={RegistrationFirstStep}
      />
      <Drawer.Screen
        name="RegistrationSecondStep"
        options={{ title: "Potwierdzenie adresu email" }}
        component={RegistrationSecondStep}
      />
    </Drawer.Navigator>
  );
};
