import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import config from "../oneconfig";
import contentDisposition, { ContentDisposition } from "content-disposition";
import axios from "axios";

const prefix = config.api_url as string;

export const download = async (
  uri: string,
  onDownloadProgress: (progressEvent: any) => void,
  progressIndication: Function
) => {
  console.log(`Download NATIVE`);

  return await processResponse(uri, onDownloadProgress, progressIndication);
};

const processResponse = async (
  uri: string,
  onDownloadProgress,
  progressIndication
) => {
  // console.log('[download]', uri);
  // console.log('[download final link]', prefix + uri);

  const filename = "unknown_file_name";

  try {
    const dir = FileSystem.documentDirectory;

    if (progressIndication) progressIndication(true);
    const response = await axios({
      url: prefix + uri,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: onDownloadProgress,
    });
    const disposition = contentDisposition.parse(
      response.headers["content-disposition"]
    ) as ContentDisposition;
    console.log(`disposition... ${JSON.stringify(disposition)} `);
    console.log(
      `disposition filename... ${JSON.stringify(
        disposition.parameters["filename"]
      )} `
    );
    const filename = disposition ? disposition.parameters["filename"] : "file";
    console.log(`printing filename ${filename}`);
    const downloadPath = dir ? dir : "" + filename.replace(/[^\x00-\x7F]/g, "");
    const finalDownloadPath = downloadPath.replace(/ /g, "");

    console.log(`download async... ${finalDownloadPath}`);
    let { uri: localUrl, headers } = await FileSystem.downloadAsync(
      prefix + uri,
      finalDownloadPath
    );
    // console.log(`[localUrl] ${localUrl}\n [download path] ${downloadPath}`);

    if (progressIndication) progressIndication(false);

    console.log(`share async... ${localUrl}`);
    await Sharing.shareAsync(localUrl);

    console.log(`printing filename ${filename}`);
    return filename;
  } catch (e) {
    console.error(e);
  }
};

export default processResponse;
