const LABELS = {
  signIn: "Zaloguj się",
  signUp: "Rejestracja",
  passwordReset: "Zapomniałeś hasła?",
  signUpToLogIn: "Zarejestruj sie, aby się zalogować",
  alreadySignUpQuestion: "Masz już konto?",
  firstName: "Imię",
  lastName: "Nazwisko",
  password: "Hasło",
  email: "Email",
  pesel: "PESEL",
  registrationConfirmation: "Rejestrując się w systemie zgadzasz się z jego ",
  and: " i ",
  termsOfService: "regulaminem",
  privacyPolicy: "polityką prywatności",
  ckGPRD:
    "oświadczam, iż wyrażam zgodę na przetwarzanie moich danych osobowych",
  ckInformationClause:
    "oświadczam, iż zapoznałem/łam się z obowiązkiem informacyjnym realizowanym w związku z art.13 i art.14 Rozporządzenia PE i Rady(UE)2016/679.",
  ckMyPictureClause:
    "oświadczam, iż wyrażam zgodę na upowszechnianie mojego wizerunku",
  ckRegistrationClause:
    "oświadczam,  iż zapoznałem/łam się z „Regulaminem Centrum Komunikacji dla Uczestników”",
};

export default LABELS;
