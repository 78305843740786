import * as React from "react";
import { styles } from "./styles";
import { View, SafeAreaView, ScrollView, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";

import TranslationsView from "./TranslationsView";

const Translations = (props) => {
  const navigation = useNavigation();

  console.log(`Translations props ${JSON.stringify(navigation)}`);
  return (
    <SafeAreaView style={[styles.body]}>
      {Platform.OS !== "web" && (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={[{ backgroundColor: "lightBlue" }]}
        >
          <TranslationsView />
        </ScrollView>
      )}

      {Platform.OS === "web" && <TranslationsView />}
    </SafeAreaView>
  );
};

export default Translations;
