import React from "react";

export type DisableMenuContextType = {
  menuDisabled: boolean;
  disableMenu: (state: boolean) => void;
};

// Context has been created
const DisableMenuContext = React.createContext<DisableMenuContextType>(
  {} as DisableMenuContextType
);

// Provider
const DisableMenuProvider = ({ children }) => {
  const [menuDisabled, setDisableMenu] = React.useState(false);
  // console.warn(`in call provider ${menuDisabled}`);
  const disableMenu = (state: boolean) => {
    setDisableMenu(state);
  };
  return (
    <DisableMenuContext.Provider value={{ menuDisabled, disableMenu }}>
      {children}
    </DisableMenuContext.Provider>
  );
};
export { DisableMenuContext, DisableMenuProvider };
