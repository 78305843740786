import React, { useEffect, useState } from "react";
import { View, Platform } from "react-native";
import { IconButton } from "react-native-paper";
import { download, downloadAs } from "../../services/download";

import _ from "lodash";
import { handleError } from "../../utils";
import CustomDialog from "../CustomDialog";

interface DownloadFileWithProgressProps {
  fileName: string;
  size: number;
  downloadLink: string | undefined;
  progressIndication: Function;
  setDownloadProgress: Function;
  sizeRequest: Function;
}

export function DownloadFileWithProgress({
  fileName,
  size,
  downloadLink,
  progressIndication,
  setDownloadProgress,
  sizeRequest,
}: DownloadFileWithProgressProps) {
  const [dataSizeBySizeRequest, setDataSizeBySizeRequest] =
    React.useState(undefined);
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [showSpinner, setshowSpinner] = React.useState(false);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const onDownloadProgress = (progressEvent: ProgressEvent) => {
    // console.log(`onDownloadProgress ${JSON.stringify(progressEvent)}`);

    if (size) {
      console.log(`total size ${JSON.stringify(size)}`);
      console.log(
        `onTranslationDownloadProgress ${JSON.stringify(
          progressEvent.loaded / size
        )}`
      );

      setDownloadProgress(progressEvent.loaded / size);
    }
    // const totalSize = getValues("translation").size;
    else if (dataSizeBySizeRequest) {
      console.log(`total size ${JSON.stringify(dataSizeBySizeRequest)}`);
      console.log(
        `onTranslationDownloadProgress ${JSON.stringify(
          progressEvent.loaded / dataSizeBySizeRequest
        )}`
      );

      setDownloadProgress(progressEvent.loaded / dataSizeBySizeRequest);
    } else {
      console.warn(`Neither size nor sizeRequest() provided size of file`);
    }
  };

  React.useEffect(() => {
    console.log(`dataSizeBySizeRequest useEffect ? ${dataSizeBySizeRequest}`);
    if (dataSizeBySizeRequest) {
      console.log(
        `dataSizeBySizeRequest useEffect ?  - ${dataSizeBySizeRequest}`
      );

      downloadAs(
        downloadLink ? downloadLink : "",
        onDownloadProgress,
        progressIndication,
        fileName
      );
    }
  }, [dataSizeBySizeRequest]);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 5,
        display: "flex",
        height: "100%",
        // overflow: 'scroll',
      }}
    >
      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          maxWidth: "40%",
          justifyContent: "flex-end",
          // backgroundColor: "red",
        }}
      >
        <IconButton
          icon="download"
          testID={`download_${fileName}`}
          size={20}
          onPress={async () => {
            try {
              console.log(`downloading  ${downloadLink}`);

              if (sizeRequest) {
                console.log(`running sizeRequest() to get file size `);

                const s = await sizeRequest();
                console.log(`sizeRequest() result  ${s}`);
                if (s) {
                  setDataSizeBySizeRequest(s);
                } else {
                  throw Error("Nagranie nie jest dostępne.");
                }
              } else {
                console.log(`static dataSize  ${JSON.stringify(size)}`);

                downloadAs(
                  downloadLink ? downloadLink : "",
                  onDownloadProgress,
                  progressIndication,
                  fileName
                );
              }
            } catch (error) {
              handleError(
                setshowSpinner,
                error,
                setDialogErrorMessage,
                setDialogErrorVisible
              );
            }
          }}
        />
      </View>
    </View>
  );
}
