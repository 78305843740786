import axios from "axios";
import config from "../oneconfig";
import contentDisposition, { ContentDisposition } from "content-disposition";
import { isEmpty } from "lodash";

// most of the time Linking.openURL(uri) is good enough
export const downloadPublic = async (uri: string) => {
  const link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  // link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const downloadAs = async (
  uri: string,
  onDownloadProgress: (progressEvent: any) => void,
  progressIndication: Function,
  filename: string
) => {
  const prefix = config.api_url as string;

  console.log("[download]", uri);
  console.log("[download final link]", prefix + uri);

  let response;
  if (progressIndication) progressIndication(true);
  // try {
  response = await axios({
    url: prefix + uri,
    method: "GET",
    responseType: "blob",

    onDownloadProgress: (e) => {
      // console.log(`downloadAs progress ${JSON.stringify(e)}`);
      onDownloadProgress(e);
    },
  });

  if (!isEmpty(response.headers)) {
    console.log(`[response.headers]`, JSON.stringify(response.headers));
    console.log(`[response.headers]`, JSON.stringify(response));

    console.log(`[download as]`, filename);

    processResponse(response, filename);
  } else
    alert(
      "Preflight response. This works from express web server and mobile clients."
    );

  if (progressIndication) progressIndication(false);
  // } catch (error) {
  //   console.log(`error ${JSON.stringify(error)}`);
  //   if (error.status === 404) alert("Plik z nagraniem jest niedostępny");
  //   else
  //     alert(`Błąd w trakcie pobierania pliku. ${error.code} ${error.status}`);
  // }
};

export const download = async (
  uri: string,
  onDownloadProgress: (progressEvent: any) => void,
  progressIndication: Function
) => {
  const prefix = config.api_url as string;

  console.log("[download]", uri);
  console.log("[download final link]", prefix + uri);

  let response;
  if (progressIndication) progressIndication(true);
  response = await axios({
    url: prefix + uri,
    method: "GET",
    responseType: "blob",

    onDownloadProgress: onDownloadProgress,
  });

  if (progressIndication) progressIndication(false);

  console.log(`[response.headers] progress`, JSON.stringify(response.headers));

  if (!isEmpty(response.headers)) {
    console.log(`[response.headers]`, JSON.stringify(response));

    console.log(`contentDisposition ${JSON.stringify(contentDisposition)}`);

    const disposition = contentDisposition.parse(
      response.headers["content-disposition"]
    ) as ContentDisposition;
    const filename = disposition ? disposition.parameters["filename"] : "file";

    console.log(`[download as]`, filename);

    processResponse(response, filename);
  } else
    alert(
      "Preflight response. This works from express web server and mobile clients."
    );
};

const processResponse = (response: any, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};
