import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { plPL } from "../../localization/plPL";
// import { plPL } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FAB, Portal, Subheading, Text } from "react-native-paper";
import { View } from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { getConnectedUsers, flush } from "../../services/signaling.service";
import { Auth, AuthContext } from "../../auth-context";
import _ from "lodash";
import CustomDialog from "../../components/CustomDialog";
import { styles } from "./styles";
import baseStyles from "../../styles/base";
import { Loading } from "../../components/Loading";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import { ConnectedUsersView } from "../../components/ConnectedUsersView";
import { ConnectedUserDTO, ConnectedUsersDTO } from "../../frontend_domain";
import { handleError } from "../../utils";

const theme = createTheme({}, plPL);

export default function ConnectedUsers() {
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;
  const [connectedUsers, setConnectedUsers] = React.useState<ConnectedUsersDTO>(
    new Map<string, ConnectedUserDTO>()
  );
  const { auth } = React.useContext(AuthContext);
  const isFocused = useIsFocused();
  const [showSpinner, setshowSpinner] = React.useState(false);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");
  const [dialogConfirmDelete, setDialogConfirmDelete] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState("");

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const hideDialogError = () => {
    // console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const navigation = useNavigation();

  React.useEffect(() => {
    releaseUserMedia();

    const run = async () => {
      console.log("fetching data using ", auth);

      try {
        const data = await getConnectedUsers();
        // console.log(`[Users] useEffect (with auth), freshUsers:`, data);
        setshowSpinner(false);

        const receivedUsers: Map<string, ConnectedUserDTO> = new Map<
          string,
          ConnectedUserDTO
        >();

        // console.log(`[ConnectedUsers] received users  ${JSON.stringify(data)}`);

        data.forEach((element) => {
          receivedUsers.set(element.id, element);
        });

        console.log(
          `[ConnectedUsers] setting users ${JSON.stringify(receivedUsers)}`
        );
        setConnectedUsers(receivedUsers);
      } catch (error) {
        handleError(
          setshowSpinner,
          error,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
      }
    };

    // console.log(`[ConnectedUsers] scheduling refresh every 3 seconds`);

    run();
    const interval = setInterval(() => run(), 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const confirmedFlush = () => {
    flush()
      .then((response) => {
        // console.log(`[ConnectedUsers] flushed:`, response);
        setDialogConfirmDelete(false);
        getConnectedUsers()
          .then((freshUsers) => {
            console.log(
              `[Users] useEffect (with auth), freshUsers:`,
              freshUsers
            );
            setConnectedUsers(freshUsers);
          })
          .catch((error) => {
            console.log("error:", error);
            alert("Błąd pobierania aktualnej listy użytkowników");
          });
      })
      .catch((err) => {
        console.log("error:", err);
        setUserToDelete("");
        alert("Błąd w trakcie usuwania użytkownika");
      });
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <ThemeProvider theme={theme}>
        <View testID="ConnectedUsersScreen" style={{ display: "none" }}></View>
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={dialogMessage}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <CustomDialog
          visible={dialogConfirmDelete}
          dialogType={"YesOrNo"}
          dialogActionNo={() => setDialogConfirmDelete(false)}
          dialogActionYes={() => confirmedFlush()}
          dialogTitle={"Ostrzeżenie"}
          dialogContent={
            "Czy na pewno chcesz usunąć wszystkie sesje podłączonych użytkowników"
          }
          testID="error_dialog"
        />
        <Portal>
          <FAB
            visible={isFocused}
            icon="delete"
            testID="flush_connected_users"
            style={baseStyles.addItemFab}
            onPress={() => {
              setDialogConfirmDelete(true);
            }}
          />
        </Portal>

        <View
          style={{
            display: "flex",
            flexGrow: 1,
            // flexDirection: "column",
            height: "auto",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "orange",
          }}
        >
          {connectedUsers?.size === 0 && (
            <Subheading>Brak zalogowanych użytkowników</Subheading>
          )}

          <ConnectedUsersView users={connectedUsers} status={""} />
        </View>
      </ThemeProvider>
    </div>
  );
}
