import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import React, { FocusEventHandler } from "react";
import {
  Education as EducationEnum,
  ParticipationFormDocument,
} from "../../frontend_domain";

export interface Education {
  id: string;
  label: string;
}

export const educationOptions = Object.keys(EducationEnum).map((key) => ({
  id: key,
  label: EducationEnum[key],
}));

console.debug(`educationOptions ${JSON.stringify(educationOptions)}`);

interface EducationComponentProps {
  resource: ParticipationFormDocument;
  setResource: React.Dispatch<any>;
  errors?: {};
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const EducationComponent = ({
  resource,
  errors,
  setResource,
  onBlur,
}: EducationComponentProps) => {
  return (
    <>
      <Grid item xs={12} style={{ margin: "1", padding: "1" }}>
        <Grid item xs={12} style={{ color: "white" }}>
          .
        </Grid>
        <Typography variant="body1" id="education">
          25. Proszę zaznaczyć posiadany poziom wykształcenia:
        </Typography>
        <Autocomplete
          options={educationOptions}
          disablePortal
          value={resource.education ? resource.education : ""}
          isOptionEqualToValue={(option, value) => option.label === value}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              error={errors ? errors["education"] : { undefined }}
              onBlur={onBlur}
              fullWidth
              //
              label="Poziom wykształcenia *"
            />
          )}
          onChange={(event: any, newValue: Education) => {
            console.log(`newValue ${JSON.stringify(newValue)}`);
            setResource({
              ...resource,
              education: newValue ? newValue.label : "",
            });
          }}
        />
      </Grid>
      <TextField
        margin="normal"
        id="education_other"
        fullWidth
        label="Inne (jakie?)"
        value={
          resource && resource.education_other ? resource.education_other : ""
        }
        onChange={(event) => {
          setResource({
            ...resource,
            education_other: event.target.value,
          });
        }}
      />
      <Typography variant="body1" id={"date_of_education_finish"}>
        Data ukończenia edukacji formalnej – uzyskania świadectwa, dyplomu
      </Typography>
      <Grid item xs={12}>
        <DesktopDatePicker
          disableFuture={true}
          label=""
          views={["year", "month", "day"]}
          value={
            resource && resource.date_of_education_finish
              ? resource.date_of_education_finish
              : ""
          }
          onChange={(newValue) => {
            setResource({
              ...resource,
              date_of_education_finish: newValue,
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                margin="normal"
                error={errors ? errors["date_of_education_finish"] : false}
                fullWidth
                required={true}
                onBlur={onBlur}
                {..._.omit(params, ["error"])}
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
