import generic, { OneConfigFinal } from "./oneconfig.generic";

const oneconfig: OneConfigFinal = {
  ck_env: "production",
  api_url: "https://centrumkomunikacji.org/api",
  marketing_url: "https://centrumkomunikacji.org",
  signaling_endpoint: "wss://centrumkomunikacji.org?token=",
  debug_ui_enabled: false,
  version: generic.version,
  iosBuildNumber: generic.iosBuildNumber,
  androidVersionCode: generic.androidVersionCode,
};

console.log(`One config ${JSON.stringify(oneconfig)}`);
export default oneconfig;
