import axios, { AxiosError } from "axios";

export class MyValidationError extends Error {
  constructor(message: string) {
    super();
    this.message = message;
    this.name = "MyValidationError";
  }
}

export function handleError(
  setshowSpinner: React.Dispatch<React.SetStateAction<boolean>>,
  error: unknown,
  setDialogErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setDialogErrorVisible: React.Dispatch<React.SetStateAction<boolean>>
) {
  setshowSpinner(false);

  console.log(`Handling error ${JSON.stringify(error.response)}`);
  if (error && error.response && error.response.data)
    console.log(`Handling error ${error.response.data.message}`);

  let resMessage;
  if (axios.isAxiosError(error)) {
    resMessage = handleAxiosError(error, resMessage);
  } else {
    // console.log(`request done ${JSON.stringify(error)}`);
    console.log(`Handling not axios error ${JSON.stringify(error)}`);

    if (error.response && error.response.data)
      resMessage = error.response.data.error;
    else resMessage = error.message;
  }
  setDialogErrorMessage(resMessage);
  setDialogErrorVisible(true);
}

function handleAxiosError(error: AxiosError, resMessage: any) {
  console.log(`Handling axios error ${JSON.stringify(error.response)}`);
  // console.log(`Handling axios error ${error.response.data.message}`);
  if (error.message === "Network Error") {
    resMessage = "Błąd sieci.";
  } else {
    // console.log(`error axios  ${JSON.stringify(error.response)}`);
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message
    )
      resMessage = error.response.data.error.message;
    else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    )
      resMessage = error.response.data.message;
    else if (error.response && error.response.data)
      resMessage = error.response.data.error;
    else resMessage = error.message;
  }
  return resMessage;
}

export function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export const minPasswordStrength = (minStrength: number) => (value: string) => {
  const mediumStrength = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );

  // console.log(`password strength for ${value} `, mediumStrength.test(value));
  return (
    mediumStrength.test(value) ||
    "Użyj silniejszego hasła (sugerujemy co najmniej jedną cyfrę, dużą literę, znak specjalny, minimum 8 znaków"
  );
};
