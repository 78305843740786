import { SupportFormDocument } from "../../frontend_domain";

interface FormOfSupport {
  id: string;
  label: string;
}

const formsOfSupport = [
  {
    id: "<30m",
    label: "Usługa tłumacza polskiego języka migowego- online – do 30 minut",
  },
  {
    id: "30m<x<4h",
    label:
      "Usługa tłumacza polskiego języka migowego- online – od 30 minut do 4 godzin",
  },
  {
    id: "doc-translate",
    label:
      "Usługa tłumaczenia dokumentów – realizowana najpóźniej do 3 dni roboczych",
  },
  {
    id: "transcription",
    label: "Usługa transkrypcji",
  },
  {
    id: "",
    label: "",
  },
];

export const formsOfSupportOptions = {
  options: formsOfSupport,
  getOptionLabel: (option: FormOfSupport) =>
    option && option.label ? option.label : "",
};

const mapFormOfSupportToLabel = (code: string) => {
  let found = "";
  formsOfSupport.forEach((value) => {
    if (value.id === code) {
      //   console.log(`adding mapped value ${value.label}`);
      found = value.label;
    }
  });
  return found;
};

export const getFormOfSupport = (supportForm: SupportFormDocument) => {
  let currentFormOfSupport = { id: "", label: "" };

  if (supportForm) {
    let code = supportForm["formOfSupportCode"];
    if (!code) code = "";

    console.debug(`currentFormOfSupport code ${JSON.stringify(code)}`);

    const label = mapFormOfSupportToLabel(code);

    currentFormOfSupport = { id: code, label: label };
    console.debug(
      `currentFormOfSupport${JSON.stringify(currentFormOfSupport)}`
    );
    console.debug(`currentFormOfSupport typeof ${typeof currentFormOfSupport}`);
  }

  console.debug(
    `currentFormOfSupport: ${JSON.stringify(currentFormOfSupport)}`
  );
  return currentFormOfSupport;
};

export const updateFormOfSupport = (
  newValue: FormOfSupport,
  supportForm: SupportFormDocument,
  setSupportForm: Function
) => {
  const updatedFormOfSupport = { formOfSupportCode: newValue.id };

  setSupportForm({ ...supportForm, ...updatedFormOfSupport });
};
