import * as React from "react";
import { styles } from "./styles";
import { View, Text, SafeAreaView, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import CallsView from "./CallsView";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";

const ConfCallStats = () => {
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;

  React.useEffect(() => {
    releaseUserMedia();
  }, []);

  return (
    <SafeAreaView style={[styles.body]}>
      {Platform.OS !== "web" && <></>}

      {Platform.OS === "web" && <CallsView />}
    </SafeAreaView>
  );
};

export default ConfCallStats;
