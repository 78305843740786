import React, { useContext, useState } from "react";
import { View, Text, TouchableOpacity, Platform, Linking } from "react-native";
import { styles } from "./styles";
import { DebugContext } from "../../debug-context";
import CustomDialog from "../../components/CustomDialog";
import { TranslationProps } from "../../navigation/types";
import config from "../../oneconfig";
import { mapTranslationStatus } from "../../frontend_domain";
import {
  PickDocumentInput,
  UploadedDocumentMetadata,
} from "../../components/PickDocumentInput";
import _ from "lodash";
import { useForm } from "react-hook-form";
import {
  createTranslationAndGetPresignedUrlUpload,
  getItem,
  updateItem,
} from "../../services/translation.service";
import { AuthContext, AuthContextType } from "../../auth-context";
import { ProgressDialog } from "../../components/ProgressDialog";
import LABELS from "../../labels";
import ValidatedTextInput from "../../components/ValidatedTextInput";
import { handleError, MyValidationError } from "../../utils";
import axios from "axios";
import { TextInput } from "react-native-paper";
import {
  EMPTY_USER,
  getDeafUsersForSearch,
  SearchUserType,
} from "../../services/user.service";
import {
  LocalStreamContext,
  LocalStreamContextType,
} from "../../localstream-context";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

type FormData = {
  id: string;
  userId: { _id: string; email: string };
  userName: string;
  translatorId: string;
  translatorName: string;
  description: string;
  status: string;

  document: UploadedDocumentMetadata | undefined;
  documentChanged: boolean | undefined;
  documentFileName: string | undefined;
  documentLink: string | undefined;

  translation: UploadedDocumentMetadata | undefined;
  translationFileName: string | undefined;
  translationLink: string | undefined;

  createdAt: string | undefined;
  submittedAt: string | undefined;
  inProgressAt: string | undefined;
  toVerifyAt: string | undefined;
  updatedAt: string | undefined;
  translatedAt: string | undefined;

  documentRequestPresignedLink: string | undefined;
  translationRequestPresignedLink: string | undefined;
};

export default function TranslationView({
  navigation,
  ...props
}: TranslationProps) {
  const { releaseUserMedia } = React.useContext(
    LocalStreamContext
  ) as LocalStreamContextType;
  const { auth } = React.useContext(AuthContext) as AuthContextType;
  const [deafUsers, setDeafUsers] = useState([]);
  const [showSpinner, setshowSpinner] = React.useState(false);
  const [displayProgress, setDisplayProgress] = React.useState(false);
  const [progressFinished, setProgressFinished] = React.useState(false);
  const [saveButtonVisible, setSaveButtonVisible] = React.useState(true);

  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [progressStage, setProgressStage] = React.useState("");

  const [finalizeProgress, setFinalizeProgress] = React.useState(0);
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
  });

  const [item, setItem] = React.useState(undefined);
  const debug = useContext(DebugContext).debug;

  const deafUsersOptions = {
    options: deafUsers,
    getOptionLabel: (option: SearchUserType) =>
      option && option.name ? option.name + " (" + option.email + ") " : "",
  };

  React.useEffect(() => {
    releaseUserMedia();

    try {
      (async () => {
        if (auth?.isTranslator || auth?.isEditor) {
          const results = await getDeafUsersForSearch();
          if (results) {
            console.log(`[TranslationView] users ${JSON.stringify(results)}`);
            results.push(EMPTY_USER);
          }
          setDeafUsers(results);
        }
      })();
    } catch (e) {
      handleError(
        setshowSpinner,
        e,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  }, [props.route]);

  React.useEffect(() => {
    async function load() {
      // console.log("!!!!Translation useEffect props ", props);
      const translationId = props.route.params
        ? props.route.params.translationId
        : undefined;
      console.log("!!!!Translation translationId ", translationId);
      try {
        if (translationId) {
          setshowSpinner(true);
          const resource = await getItem(translationId);
          console.log(
            `[TranslationView], assets in useEffect:  ${JSON.stringify(
              resource
            )}`
          );
          console.log(`[TranslationView], resource._id ${resource._id}`);
          console.log(
            `[TranslationView] updatedDate,  ${resource.activatedAt}`
          );
          console.log(`[TranslationView] useEffect,  ${resource.status}`);

          setValue("id", resource._id);
          setValue("userId", resource.userId ? resource.userId : "");
          setValue("userName", resource.userId ? resource.userId.email : "");
          setValue("translatorId", resource.translatorId);
          setValue("status", resource.status);
          setValue(
            "translatorName",
            resource.translatorId ? resource.translatorId.email : ""
          );
          setValue(
            "description",
            resource.description ? resource.description : ""
          );

          setValue(
            "createdAt",
            resource.createdAt
              ? new Date(resource.createdAt).toLocaleString("pl-PL")
              : ""
          );

          setValue(
            "submittedAt",
            resource.submittedAt
              ? new Date(resource.submittedAt).toLocaleString("pl-PL")
              : ""
          );

          setValue(
            "inProgressAt",
            resource.inProgressAt
              ? new Date(resource.inProgressAt).toLocaleString("pl-PL")
              : ""
          );
          setValue(
            "toVerifyAt",
            resource.toVerifyAt
              ? new Date(resource.toVerifyAt).toLocaleString("pl-PL")
              : ""
          );
          setValue(
            "updatedAt",
            resource.updatedAt
              ? new Date(resource.updatedAt).toLocaleString("pl-PL")
              : ""
          );
          setValue(
            "translatedAt",
            resource.translatedAt
              ? new Date(resource.translatedAt).toLocaleString("pl-PL")
              : ""
          );

          await loadDocument(resource);
          await loadTranslation(resource);
          // causes rerender so It must be at the very end
          setItem(resource);
        } else {
          console.debug("No translation. View to create new one.");
        }

        setshowSpinner(false);
      } catch (e) {
        handleError(
          setDisplayProgress,
          e,
          setDialogErrorMessage,
          setDialogErrorVisible
        );
      }
    }
    load();
  }, [props.route]);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const downloadPresignedDocumentAction = () => {
    const link = getValues("documentRequestPresignedLink");
    console.log(`presigned link: ${link}`);
    if (link)
      Linking.canOpenURL(link)
        .then((response) => {
          Linking.openURL(link);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
  };

  const downloadPresignedTranslationAction = () => {
    const link = getValues("translationRequestPresignedLink");
    console.log(`presigned link: ${link}`);
    if (link)
      Linking.canOpenURL(link)
        .then((response) => {
          Linking.openURL(link);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
  };

  const onTranslationDownloadProgress = (progressEvent: ProgressEvent) => {
    const totalSize = getValues("translation")?.size;
    if (totalSize) {
      console.log(`total size ${JSON.stringify(totalSize)}`);
      console.log(
        `onTranslationDownloadProgress ${JSON.stringify(
          progressEvent.loaded / totalSize
        )}`
      );

      setUploadProgress(progressEvent.loaded / totalSize);
    }
  };

  const onUploadProgress = (progress: number, description: string) => {
    console.log(`!!!! onUploadProgress in TranslationView `, description);
    setUploadProgress(progress);
    setProgressStage(description);
  };

  const onFinalizeProgress = (progressEvent: ProgressEvent) => {
    console.log(
      `finalize progress loaded ${progressEvent.loaded} ${
        progressEvent.total
      } ${(progressEvent.loaded / progressEvent.total).toFixed(2)}`
    );
    setFinalizeProgress(progressEvent.loaded / progressEvent.total);

    if (progressEvent.loaded === progressEvent.total) {
      console.log(`all finished. updating ui`);
      setSaveButtonVisible(false);
      setProgressFinished(true);
      setUploadProgress(1);
      setshowSpinner(false);
    }
  };

  const onRequestUpdate = async (data: {}) => {
    await onSubmit(data, "new");
  };

  const onInProgressStart = async (data: {}) => {
    await onSubmit(data, "in_progress");
  };
  const onRequestApproval = async (data: {}) => {
    await onSubmit(data, "verification");
  };
  const onApprove = async (data: {}) => {
    await onSubmit(data, "translated");
  };
  const onSubmit = async (data: {}, status: string) => {
    console.log(`onSubmit status ${status}`);

    let result;
    setDisplayProgress(true);
    setProgressFinished(false);
    try {
      console.log("executing request");

      console.log("executing update translation");
      result = await updateItem(
        data,
        status,
        onUploadProgress,
        onFinalizeProgress
      );

      console.log(`full update request done ${JSON.stringify(result)}`);

      if (result) {
        setProgressFinished(true);
        setSaveButtonVisible(false);
        setshowSpinner(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      handleError(
        setDisplayProgress,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  const onRequestTranslation = async (data: FormData) => {
    console.log("start onRequestTranslation", new Date().toISOString());
    try {
      setDisplayProgress(true);
      setProgressFinished(false);

      console.log(`ck_env ${config.ck_env}`);
      console.log(
        `onRequestTranslation create translation ${JSON.stringify(data)}`
      );

      if (
        (data.document && data.documentChanged) ||
        config.ck_env === "development"
      ) {
        if (!(data.document && data.documentChanged)) {
          console.warn("TEST mode - fake file");
          data = {
            description: "abc",
            userId: data.userId ? data.userId : undefined,
            documentFileName: "current.log",
            document: {
              type: "success",
              uri: "data:application/octet-stream;base64,YXBwbGljYXRpb24taW5mby0yMDIzLTAyLTI2LmxvZw==",
              mimeType: "",
              name: "abc.log",
              file: await (
                await fetch(
                  "data:application/octet-stream;base64,YXBwbGljYXRpb24taW5mby0yMDIzLTAyLTI2LmxvZw=="
                )
              ).blob(),
              lastModified: 1677429818034,
              size: 31,
              output: { "0": {} },
            },
            documentChanged: true,
          };
          //data.document = { name: "test" };
        }
        console.log(`real document`, JSON.stringify(data));
        console.log(`new document file name`, data.document?.name);
        console.log(`uploading changed file name`, data.document?.name);

        console.log(`loading file on mobile with size ${data.document?.size}`);
        if (auth?.isUser && data.document.size > 30 * 1024 * 1024) {
          setDisplayProgress(false);
          setProgressFinished(true);
          throw new MyValidationError(
            "Maksymalny rozmiar pliku możliwy do wysłania to 30MB."
          );
        }
        setDisplayProgress(true);
        setProgressFinished(false);

        console.log("executing request");
        console.log(`executing create translation ${JSON.stringify(data)}`);
        console.log(`executing with auth ${JSON.stringify(auth)}`);
        onUploadProgress(0.02, "Wczytywanie pliku do wysłania");
        await createTranslationAndGetPresignedUrlUpload(
          data,
          onUploadProgress,
          onFinalizeProgress,
          auth
        );

        setProgressFinished(true);
      } else {
        setDisplayProgress(false);
        setProgressFinished(true);
        console.error("Nie można wysłać pustego pliku");
        throw new MyValidationError("Nie można wysłać pustego pliku");
      }
    } catch (error) {
      console.error(
        "Błąd w trakcie tworzenia dokumentu do tłumaczenia",
        JSON.stringify(error)
      );
      handleError(
        setshowSpinner,
        error,
        setDialogErrorMessage,
        setDialogErrorVisible
      );
    }
  };

  const getMatchingUser = () => {
    if (item && item.userId) {
      const filtered = deafUsers.find(
        (i: SearchUserType) =>
          i._id === (item && item.userId ? item.userId._id : -1)
      );
      if (filtered) return filtered;
      else return EMPTY_USER;
    } else {
      return EMPTY_USER;
    }
  };

  if (uploadProgress && uploadProgress > 0)
    console.log(
      `progress uploadProgress ${uploadProgress} ${typeof uploadProgress}`
    );

  console.log(
    `before render progress? display: ${displayProgress} progress: ${uploadProgress} stage: ${progressStage}`
  );

  return (
    <View
      testID="form_container"
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      {displayProgress && (
        <ProgressDialog
          visible={true}
          progressActual={uploadProgress}
          progressStage={progressStage}
          progressFinished={progressFinished}
          onFinishAction={() => {}}
          finishedMessage={"OK"}
          onCancelAction={() => {
            console.log(`cancel request`);
            setDisplayProgress(false);
            // navigation.goBack();
          }}
          onOKAction={() => {
            setDisplayProgress(false);
            navigation.navigate("Translations");
          }}
        />
      )}

      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />

      <>
        <View
          testID="fields"
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          {getValues("id") && (
            <View style={styles.inputContainer} testID="id">
              <ValidatedTextInput
                placeholder="Id"
                name="id"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="account-check-outline"
                autoCorrect={undefined}
              ></ValidatedTextInput>
            </View>
          )}
          {getValues("id") && (
            <View style={styles.inputContainer}>
              <ValidatedTextInput
                placeholder="Użytkownik"
                name="userName"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="account-check-outline"
                autoCorrect={undefined}
              ></ValidatedTextInput>
            </View>
          )}
          {getValues("id") && (
            <View style={styles.inputContainer} testID="translatorName">
              <ValidatedTextInput
                placeholder="Tłumacz"
                name="translatorName"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="account-check-outline"
                autoCorrect={undefined}
              ></ValidatedTextInput>
            </View>
          )}

          {auth?.isUser === false &&
            Platform.OS === "web" &&
            !getValues("id") && (
              <>
                <Text nativeID="users_loaded" style={{ display: "none" }}>
                  {deafUsers && deafUsers.length > 0 ? "true" : "false"}
                </Text>

                <Autocomplete
                  {...deafUsersOptions}
                  clearOnBlur
                  clearOnEscape
                  disablePortal
                  id="users_combo_box"
                  // sx={{ width: 300 }}
                  value={getMatchingUser()}
                  onChange={(event: any, newValue: SearchUserType | null) => {
                    console.log(`Deaf user : ${JSON.stringify(newValue)}`);
                    if (newValue) {
                      setItem({
                        ...item,
                        userId: {
                          _id: newValue._id,
                          pesel: newValue.pesel,
                          email: newValue.email,
                          firstName: newValue.firstName,
                          lastName: newValue.lastName,
                        },
                      });
                      console.log(`create translation setvalue("userId")`);
                      setValue("userId", {
                        _id: newValue?._id,
                        email: newValue.email,
                      });
                    } else {
                      console.log(`removing selected user`);
                      setItem({
                        ...item,
                        userId: { undefined },
                      });
                    }
                  }}
                  renderOption={(props: object, option: any, state: object) => {
                    console.log(`option ${JSON.stringify(option)}`);
                    console.log(`state ${JSON.stringify(state)}`);
                    return (
                      <div
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 0,
                          zIndex: 100,
                        }}
                      >
                        <p style={{ padding: 0, zIndex: 100 }}>{option.name}</p>
                        <p style={{ padding: 0, zIndex: 100 }}>
                          {option.email}
                        </p>
                      </div>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          variant="filled"
                          error={_.isEmpty(getValues("userId"))}
                          margin="normal"
                          {...params}
                          label="Użytkownik *"
                        />
                        {_.isEmpty(getValues("userId")) && (
                          <Text
                            nativeID={"validation_error_userId"}
                            style={{ color: "red" }}
                          >
                            Pole jest wymagane.
                          </Text>
                        )}
                      </>
                    );
                  }}
                />
              </>
            )}
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="description"
              testID="description"
              placeholder="Opis"
              rules={{
                required: "To pole jest wymagane",
              }}
              control={control}
              errors={errors}
              leftIconName="arrow-right-bold-box-outline"
            />
          </View>

          {getValues("id") && (
            <View style={styles.inputContainer}>
              <TextInput
                label="Status"
                value={mapTranslationStatus(getValues("status"))}
                left={<TextInput.Icon name="arrow-right-bold-box-outline" />}
              />
            </View>
          )}

          {/* {showSpinner === true && <Loading />} */}

          <PickDocumentInput
            documentName="document"
            downloadAvailable={getValues("id") ? true : false}
            label="Dokument do przetłumaczenia"
            downloadLink={getValues("documentLink")}
            progress={uploadProgress}
            downloadPresignedLink={getValues("documentRequestPresignedLink")}
            downloadPresignedAction={downloadPresignedDocumentAction}
            progressIndication={setDisplayProgress}
            getValues={getValues}
            setValue={setValue}
            rules={{
              required: "To pole jest wymagane",
            }}
            control={control}
            errors={errors}
          />

          {getValues("id") &&
            !["new", "submitted"].includes(getValues("status")) && (
              <>
                <PickDocumentInput
                  documentName="translation"
                  downloadAvailable={true}
                  label="Tłumaczenie"
                  downloadLink={getValues("translationLink")}
                  downloadPresignedLink={getValues(
                    "translationRequestPresignedLink"
                  )}
                  downloadPresignedAction={downloadPresignedTranslationAction}
                  onDownloadProgress={onTranslationDownloadProgress}
                  progressIndication={setDisplayProgress}
                  disabled={auth && auth.isUser ? true : false}
                  getValues={getValues}
                  setValue={setValue}
                  setValue={setValue}
                  rules={{
                    required: "To pole jest wymagane",
                  }}
                  // required={false}
                  control={control}
                  errors={errors}
                  loading={(loading: boolean) => {
                    console.log(`loading indication ${loading}`);
                    setshowSpinner(loading);
                  }}
                />
                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="createdAt"
                    placeholder="Data utworzenia"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                    autoCorrect={undefined}
                  />
                </View>

                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="submittedAt"
                    placeholder="Data wysłania do tłumaczenia"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                    autoCorrect={undefined}
                  />
                </View>

                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="inProgressAt"
                    placeholder="Data rozpoczęcia tłumaczenia"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                    autoCorrect={undefined}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="toVerifyAt"
                    placeholder="Data wysłania do weryfikacji"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                    autoCorrect={undefined}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="translatedAt"
                    placeholder="Data aktualizacji tłumaczenia"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                    autoCorrect={undefined}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="updatedAt"
                    placeholder="Data ostatniej modyfikacji"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                    autoCorrect={undefined}
                  />
                </View>
              </>
            )}
        </View>
        <View
          style={debug === true ? { backgroundColor: "yellow" } : undefined}
        >
          {!getValues("id") &&
            saveButtonVisible === true &&
            !["validation", "translated"].includes(getValues("status")) && (
              <View
                style={
                  auth?.isTranslator
                    ? [styles.btnPrimaryForTranslator]
                    : [styles.btnPrimary]
                }
              >
                <TouchableOpacity
                  onPress={() => {
                    setDisplayProgress(true);
                    onUploadProgress(0.01, "Wczytywanie pliku do wysłania");
                    console.log("init done ", new Date().toISOString());

                    setTimeout(function () {
                      handleSubmit(onRequestTranslation)();
                    }, 500);
                  }}
                  testID={"button_submit"}
                  style={{ zIndex: 0 }}
                >
                  <Text style={styles.buttonText}>
                    {LABELS.sentForTranslation}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

          {auth?.isUserOrTranslator &&
            getValues("status") === "new" &&
            saveButtonVisible === true && (
              <View
                style={[
                  debug === true ? { backgroundColor: "orange" } : undefined,
                  styles.btnPrimary,
                ]}
              >
                <TouchableOpacity
                  onPress={handleSubmit(onRequestUpdate)}
                  testID={"button_update_document"}
                >
                  <Text style={styles.buttonText}>{LABELS.save}</Text>
                </TouchableOpacity>
              </View>
            )}

          {auth?.isEditor &&
            getValues("status") === "verification" &&
            saveButtonVisible === true && (
              <View
                style={[
                  debug === true ? { backgroundColor: "orange" } : undefined,
                  styles.btnPrimary,
                ]}
              >
                <TouchableOpacity
                  onPress={handleSubmit(onRequestUpdate)}
                  testID={"button_update_document"}
                >
                  <Text style={styles.buttonText}>{LABELS.save}</Text>
                </TouchableOpacity>
              </View>
            )}

          {auth?.isEditor &&
            getValues("status") === "submitted" &&
            saveButtonVisible === true && (
              <View
                style={[
                  debug === true ? { backgroundColor: "orange" } : undefined,
                  styles.btnPrimary,
                ]}
              >
                <TouchableOpacity
                  onPress={handleSubmit(onInProgressStart)}
                  testID={"button_in_progress"}
                >
                  <Text style={styles.buttonText}>{LABELS.inProgres}</Text>
                </TouchableOpacity>
              </View>
            )}
          {auth?.isEditor &&
            getValues("status") === "in_progress" &&
            saveButtonVisible === true && (
              <View
                style={[
                  debug === true ? { backgroundColor: "orange" } : undefined,
                  styles.btnPrimary,
                ]}
              >
                <TouchableOpacity
                  onPress={handleSubmit(onRequestApproval)}
                  testID={"button_translation"}
                >
                  <Text style={styles.buttonText}>
                    {LABELS.sendForApproval}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          {auth?.isAdmin &&
            getValues("status") === "verification" &&
            saveButtonVisible === true && (
              <View
                style={[
                  debug === true ? { backgroundColor: "orange" } : undefined,
                  styles.btnPrimary,
                ]}
              >
                <TouchableOpacity
                  onPress={handleSubmit(onApprove)}
                  testID={"button_approve"}
                >
                  <Text style={styles.buttonText}>{LABELS.approve}</Text>
                </TouchableOpacity>
              </View>
            )}
        </View>
      </>
    </View>
  );

  async function loadTranslation(resource: any) {
    const translationDocumentMetadata: UploadedDocumentMetadata = {
      name: resource.translationFileName,
      size: resource.translationSize,
      storageId: resource.translationStorageId,
      mimeType: resource.translationMimeType,
      uri: "",
    };

    if (translationDocumentMetadata) {
      setValue("translation", translationDocumentMetadata);
      setValue(
        "translationFileName",
        translationDocumentMetadata.name ? translationDocumentMetadata.name : ""
      );

      if (resource.userId)
        setValue(
          "translationLink",
          translationDocumentMetadata
            ? `/storage/ck-users/${resource.userId._id}/translations/${resource._id}/translation`
            : ""
        );

      console.log(
        `get presigned url via ${`/api/translation-file/${resource._id}`}`
      );
      const responseTranslation = await axios.get(
        `${config.api_url}/translation-file/${resource._id}`
      );

      console.log(
        `got presigned response  ${JSON.stringify(responseTranslation)}`
      );

      const presignedUrl = responseTranslation.data.url;
      console.log(`presigned url for translation_ ${presignedUrl}`);
      setValue("translationRequestPresignedLink", presignedUrl);
    } else {
      setValue("translation", undefined);
      setValue("translationFileName", "");
      setValue("translationLink", "");
    }
  }

  async function loadDocument(resource: any) {
    const documentMetadata: UploadedDocumentMetadata = {
      name: resource.documentFileName,
      size: resource.documentSize,
      storageId: resource.documentStorageId,
      mimeType: resource.documentMimeType,
      uri: "",
    };

    if (documentMetadata) {
      setValue("document", documentMetadata);
      setValue(
        "documentFileName",
        documentMetadata.name ? documentMetadata.name : ""
      );

      if (resource.userId)
        setValue(
          "documentLink",
          documentMetadata
            ? `/storage/ck-users/${resource.userId._id}/translations/${resource._id}/document`
            : ""
        );

      console.log(
        `get presigned url via ${`/api/to-translate-file/${resource._id}`}`
      );
      try {
        const responseToTranslate = await axios.get(
          `${config.api_url}/to-translate-file/${resource._id}`
        );

        console.log(
          `got presigned response  ${JSON.stringify(responseToTranslate)}`
        );

        const presignedUrl = responseToTranslate.data.url;
        console.log(`presigned url for document_ ${presignedUrl}`);
        setValue("documentRequestPresignedLink", presignedUrl);
      } catch (e) {
        console.log(e);
      }
    } else {
      setValue("document", "");
      setValue("documentFileName", "");
      setValue("documentLink", "");
    }
    console.log(`[values set`);
  }
}
