import { StyleSheet } from "react-native";
import MUI_THEME from "./index.native";

export default StyleSheet.create({
  centered: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  addItemFab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 60,
  },

  rightTop: {
    position: "absolute",
    margin: 16,
    right: 16,
    top: 60,
  },

  rightTopP2P: {
    position: "absolute",
    margin: 16,
    right: 16,
    top: 16,
    // color: "gray",
  },

  btnPrimary: {
    backgroundColor: MUI_THEME.palette.primary.main,
    height: 50,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
    marginHorizontal: 30,
  },

  buttonText: {
    color: MUI_THEME.palette.primary.contrastText,
    fontWeight: "bold",
    fontSize: 20,
  },
});

export const SELF_VIDEO_SIZE_MOBILE_HEIGHT = 120; //portrait only
export const SELF_VIDEO_SIZE_MOBILE_WIDTH = 67.5; // portrait only
export const SELF_VIDEO_SIZE_MOBILE_HEIGHT_TRANSCRIPTION = 120; //portrait only
export const SELF_VIDEO_SIZE_MOBILE_WIDTH_TRANSCRIPTION = 67.5; // portrait only

export const SELF_VIDEO_SIZE_WEB_HEIGHT = 270;
export const SELF_VIDEO_SIZE_WEB_WIDTH = 400;

export const SELF_VIDEO_SIZE_WEB_HEIGHT_TRANSCRIPTION = 135;
export const SELF_VIDEO_SIZE_WEB_WIDTH_TRANSCRIPTION = 200;
