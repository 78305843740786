import { styles } from "./styles";

import React, { useContext, useRef } from "react";
import { View, ScrollView, Text } from "react-native";
import { IconButton, TextInput } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { getAllForUser } from "../../services/supportforms.service";
import _ from "lodash";
import { DebugContext } from "../../debug-context";
import { AuthContext, AuthContextType } from "../../auth-context";
import { handleError } from "../../utils";
import { Loading } from "../../components/Loading";
import CustomDialog from "../../components/CustomDialog";
import SupportFormInCall from "./SupportFormInCall";

const BUTTON_ROWS_HEIGHT = 64;

type SupportFormsInCallProps = {
  userId: string;
  visible: boolean;
};

export default function SupportFormsInCall({
  userId,
  visible,
}: SupportFormsInCallProps) {
  const [text, setText] = React.useState("");
  const debugContext = useContext(DebugContext);
  const debug = debugContext.debug;
  const { auth } = useContext(AuthContext) as AuthContextType;
  const [increment, increase] = React.useState(0);
  const [showSpinner, setshowSpinner] = React.useState(false);

  const [data, setData] = React.useState([]);

  const scrollViewRef = useRef();
  const inputElementRef = useRef();

  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [dialogErrorVisible, setDialogErrorVisible] = React.useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState("");

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const receivedMessages = [];

  React.useEffect(() => {
    if (!_.isEmpty(userId) && auth?.isTranslator) {
      console.log(`userId ${JSON.stringify(userId)}`);
      console.log(`support forms visible ${JSON.stringify(visible)}`);

      if (data.length === 0) {
        getAllForUser(userId)
          .then((freshItems) => {
            console.log(
              `[SupportForms] useEffect (with auth), freshItems:`,
              freshItems.length
            );
            setshowSpinner(false);
            setData(freshItems);
          })
          .catch((error) => {
            console.error(JSON.stringify(error));
            handleError(
              setshowSpinner,
              error,
              setDialogErrorMessage,
              setDialogErrorVisible
            );
          });
      } else {
        console.log("data already fetched");
      }
    }
  }, [userId]);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  // console.log('data... ', data);
  return (
    <SafeAreaView style={[styles.body, { display: visible ? "flex" : "none" }]}>
      {showSpinner && <Loading loading={showSpinner} />}

      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />
      <View
        style={{
          flex: 1,
          justifyContent: "space-between",
          backgroundColor: debug ? "pink" : "undefined",
          zIndex: 500,
          bottom: BUTTON_ROWS_HEIGHT,
        }}
      >
        {/* chat messages */}
        <View
          style={{
            flex: 1,
            paddingTop: 64,
            backgroundColor: debug ? "yellow" : "undefined",
          }}
        >
          <ScrollView
            ref={scrollViewRef}
            onContentSizeChange={(contentWidth, contentHeight) => {
              scrollViewRef.current.scrollToEnd({ animated: false });
            }}
            style={
              {
                // maxHeight: vh(85i),
              }
            }
          >
            {(!data || data.length === 0) && (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    padding: 5,
                    justifyContent: "space-between",
                    height: "auto",
                    borderColor: "black",
                    borderStyle: "solid",
                    borderRadius: 13,
                    backgroundColor: "lightgrey",
                    marginBottom: 5,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    Brak poprzednich formularzy wsparcia
                  </Text>
                </View>
              </View>
            )}

            <View>
              {data.map((item: SupportFormInCall, index) => {
                // const key = Date.now();
                /*  console.log(
                  `chat item key: ${key} message: ${JSON.stringify(message)} `
                ); */
                return <SupportFormInCall supportForm={item} key={item._id} />;
              })}
            </View>
          </ScrollView>
        </View>

        {/* Input */}
        <View
          style={{
            // bottom: '0', position: 'absolute', width: '100%'
            flexDirection: "row",
            alignItems: "stretch",

            backgroundColor: debug ? "grey" : "undefined",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              padding: 10,
              flexGrow: 5,
              backgroundColor: debug ? "orange" : "undefined",
            }}
          ></View>

          <View
            style={{
              display: "flex",
              // padding: 4,
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                flex: 1,
                // backgroundColor: 'red',
                padding: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            ></View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
