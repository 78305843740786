import React from "react";
import { ScrollView, SafeAreaView } from "react-native";
import { styles } from "./styles";
import { ScheduledConfCallProps } from "../../navigation/types";
import ScheduledConfCallView from "./ScheduledConfCallView";

export default function ScheduledConfCall(props: ScheduledConfCallProps) {
  // console.log('Translation props? ', props);

  return (
    <SafeAreaView style={[styles.body]}>
      <ScrollView
        // showsVerticalScrollIndicator={true}
        bounces={false}
        style={[
          {
            backgroundColor: "lightBlue",
          },
        ]}
      >
        <ScheduledConfCallView {...props} />
      </ScrollView>
    </SafeAreaView>
  );
}
