const LABELS = {
  signIn: "Zaloguj się",
  signUp: "Rejestracja",
  signUpToLogIn: "Zarejestruj sie, aby się zalogować",
  signUpDialogFirstStep:
    "Na adres email zostało wysłana prośba o jego potwierdzenie. Po jego potwierdzeniu rejestracja będzie weryfikowana przez PZG. O jej zakończeniu poinformujemy w kolejnym mailu.",
  alreadySignUpQuestion: "Masz już konto?",
  firstName: "Imię",
  lastName: "Nazwisko",
  password: "Hasło",
  email: "Email",
  pesel: "PESEL",
  registrationConfirmation: "Rejestrując się w systemie zgadzasz się z jego ",
  and: " i ",
  termsOfService: "Regulamin",
  privacyPolicy: "Polityka prywatności",

  ck1: "oświadczam, że wyrażam zgodę na przetwarzanie danych osobowych",
  ck2: "oświadczam, że wyrażam zgodę na upowszechnianie wizerunku",
  ck3: "oświadczam, że wyrażam zgodę na nagrywanie połączeń",
  ck4: "oświadczam, że zapoznałem się z regulaminem",
  ck5: "oświadczam, że zapoznałem się z obowiązkiem informacyjnym dotyczącym danych osobowych",
};

export default LABELS;
