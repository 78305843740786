import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import oneconfig from "../oneconfig";
import { Auth } from "../auth-context";
import { Platform } from "react-native";
// console.log('[oneconfig from oneconfig]', JSON.stringify(oneconfig));

const prefix = oneconfig.api_url;

// console.log('[prefix]', JSON.stringify(prefix));

const API_URL = `${prefix}/auth/`;

export const login = (email: string, password: string) => {
  // console.log(`login via url `, API_URL + "signin") ;

  const finalEmail = email.trim();

  console.log(`login ${finalEmail} with platform `, Platform.OS);
  return axios
    .post(API_URL + "signin", {
      email: finalEmail,
      password: password,
      version: oneconfig.version,
      iosBuildNumber: oneconfig.iosBuildNumber,
      androidVersionCode: oneconfig.androidVersionCode,
      platform: Platform.OS,
    })
    .then((response) => {
      const auth: Auth = {
        authToken: response.data.authToken,
        id: response.data.id,
        role: response.data.role,
        email: response.data.email,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        initials: response.data.initials,
        isAdmin: response.data.role === "admin",
        isUser: response.data.role === "user",
        isTranscriber: response.data.role === "transcriber",
        isUserOrTranslator: ["user", "translator"].includes(response.data.role),
        isTranslatorOrAdmin: ["admin", "translator"].includes(
          response.data.role
        ),
        isUserOrTranscriber: ["user", "transcriber"].includes(
          response.data.role
        ),
        isTranslator: response.data.role === "translator",
        isEditor: response.data.role === "editor",
        declaredRefugee: response.data.declaredRefugee,
        preferredWrittenCommunication:
          response.data.preferredWrittenCommunication,
      };

      // console.log('setting async storage on login', response.data);
      AsyncStorage.setItem("auth", JSON.stringify(auth));

      return auth;
    });
};

export const requestReset = async (email: string) => {
  // console.log(`login via url `, API_URL + "signin");
  return await axios({
    url: `${oneconfig.api_url}/auth/request-reset`,
    method: "post",
    data: { email: email },
  });
};

export const resetPassword = async (
  password: string,
  password2: string,
  token: string
) => {
  console.log(`reset password using token ${token}`);
  return await axios({
    url: `${oneconfig.api_url}/auth/new-password`,
    method: "post",
    data: { password: password, password2: password2, token: token },
  });
};

export const logout = () => {
  AsyncStorage.removeItem("user");
};

export const getCurrentUser = async () => {
  const userStr = await AsyncStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  return null;
};
