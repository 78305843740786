import React, { useContext, useState } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { styles } from "./styles";
import { validatePolish } from "validate-polish";
import { AuthContext, AuthContextType } from "../../auth-context";
import { ProfileProps } from "../../navigation/types";
import LABELS from "../../labels";
import CustomDialog from "../../components/CustomDialog";
import { DebugContext } from "../../debug-context";
import {
  PickDocumentInput,
  UploadedDocumentMetadata,
} from "../../components/PickDocumentInput";
import ValidatedCheckbox from "../../components/ValidatedCheckbox";
import { getUser, createUser, updateUser } from "../../services/user.service";
import { useForm } from "react-hook-form";
import ValidatedTextInput from "../../components/ValidatedTextInput";
import _ from "lodash";
import { minPasswordStrength } from "../../utils";
import { Loading } from "../../components/Loading";
import { AxiosError } from "axios";

type FormData = {
  id: string;
  pesel: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isEditor: boolean;
  isTranslator: boolean;
  isTranscriber: boolean;
  isAdmin: boolean;
  isActive: boolean;
  isParticipationFormFinished: boolean;
  disabilityDocument: UploadedDocumentMetadata;
  disabilityDocumentFileName: string | undefined;
  disabilityDocumentChanged: boolean;
  activatedAt: string | undefined;
  createdAt: string | undefined;
  updatedAt: string;
  lastSuccessfulLoginAt: string | undefined;
  lastNotSuccessfulLoginAt: string | undefined;
  closedAt: string | undefined;
  closedReason: string | undefined;
  phone: string;
  declaredRefugee: boolean;
  preferredWrittenCommunication: boolean;
};

export default function ProfileView({
  navigation,
  containerStyle,
  ...props
}: ProfileProps) {
  const { auth } = React.useContext(AuthContext) as AuthContextType;

  const [showSpinner, setshowSpinner] = React.useState(false);
  const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,

    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur", // "onChange"
  });

  const [user, setUser] = React.useState(undefined);
  const debug = useContext(DebugContext).debug;
  // console.log('containerStyle ', containerStyle);

  const hideDialogError = () => {
    console.log(`close hideDialogError`);
    setDialogErrorVisible(false);
  };

  const toggleIsActive = (value) => {
    console.log(`toggle is active ${getValues("isActive")}`);
    setValue("isActive", value);
  };
  const togglePreferredWrittenCommunication = (value) => {
    console.log(
      `toggle  preferredWrittenCommunication ${getValues(
        "preferredWrittenCommunication"
      )}`
    );
    setValue("preferredWrittenCommunication", value);
  };
  const toggleDeclaredRefugee = (value) => {
    console.log(
      `toggle is toggleDeclaredRefugee ${getValues("declaredRefugee")}`
    );
    setValue("declaredRefugee", value);
  };
  const toggleIsTranslator = (value) => {
    console.log(`toggle value ${value}`);
    console.log(`toggle isTranslator ${getValues("isTranslator")}`);
    setValue("isTranslator", value);
    setValue("isEditor", false);
    setValue("isAdmin", false);
    setValue("isTranscriber", false);
  };
  const toggleIsTranscriber = (value) => {
    console.log(`toggle value ${value}`);
    console.log(`toggle isTranscriber ${getValues("isTranscriber")}`);
    setValue("isTranscriber", value);
    setValue("isEditor", false);
    setValue("isAdmin", false);
    setValue("isTranslator", false);
  };
  const toggleIsEditor = (value) => {
    console.log(`toggle isEditor ${getValues("isEditor")}`);
    setValue("isEditor", value);
    setValue("isTranslator", false);
    setValue("isAdmin", false);
    setValue("isTranscriber", false);
  };
  const toggleIsAdmin = (value) => {
    console.log(`toggle isAdmin ${getValues("isAdmin")}`);
    setValue("isAdmin", value);
    setValue("isTranslator", false);
    setValue("isEditor", false);
    setValue("isTranscriber", false);
  };

  React.useEffect(() => {
    console.log("!!!!Profile useEffect props ", props);
    const userId = props.route.params ? props.route.params.userId : undefined;

    try {
      if (userId)
        getUser(userId)
          .then((resource) => {
            console.log("[Profile], assets in useEffect", resource);
            console.log(`[Profile],  ${resource._id}`);
            console.log(`[Profile] updatedDate,  ${resource.activatedAt}`);

            setValue("id", resource._id);
            setValue("firstName", resource.firstName);
            setValue("lastName", resource.lastName);
            setValue("phone", resource.phone);
            setValue("declaredRefugee", resource.declaredRefugee);
            setValue(
              "preferredWrittenCommunication",
              resource.preferredWrittenCommunication
            );
            setValue("email", resource.email);
            setValue("pesel", resource.pesel);
            setValue("role", resource.role);
            setValue("isEditor", resource.role === "editor" ? true : false);
            setValue(
              "isTranslator",
              resource.role === "translator" ? true : false
            );
            setValue(
              "isTranscriber",
              resource.role === "transcriber" ? true : false
            );
            setValue("isAdmin", resource.role === "admin" ? true : false);
            setValue("isActive", resource.isActive);
            setValue(
              "isParticipationFormFinished",
              resource.isParticipationFormFinished
            );
            setValue(
              "activatedAt",
              resource.activatedAt
                ? new Date(resource.activatedAt).toLocaleString("pl-PL")
                : undefined
            );
            setValue(
              "createdAt",
              new Date(resource.createdAt).toLocaleString("pl-PL")
            );
            setValue(
              "updatedAt",
              new Date(resource.updatedAt).toLocaleString("pl-PL")
            );
            setValue(
              "lastSuccessfulLoginAt",
              resource.lastSuccessfulLoginAt
                ? new Date(resource.lastSuccessfulLoginAt).toLocaleString(
                    "pl-PL"
                  )
                : undefined
            );
            setValue(
              "lastNotSuccessfulLoginAt",
              resource.lastNotSuccessfulLoginAt
                ? new Date(resource.lastNotSuccessfulLoginAt).toLocaleString(
                    "pl-PL"
                  )
                : undefined
            );

            setValue(
              "closedAt",
              resource.closedAt
                ? new Date(resource.closedAt).toLocaleString("pl-PL")
                : undefined
            );

            const disabilityDocumentMetadata: UploadedDocumentMetadata = {
              name: resource.disabilityDocumentFileName || undefined,
              size: resource.disabilityDocumentSize,
              storageId: resource.disabilityDocumentStorageId,
              mimeType: resource.disabilityDocumentMimeType,
              folder: "disability",
              uri: "",
            };

            setValue("disabilityDocument", disabilityDocumentMetadata);
            setValue(
              "disabilityDocumentFileName",
              disabilityDocumentMetadata.name
            );

            setValue("closedReason", resource.closedReason);

            console.log(`[values set`);

            // causes rerender so It must be at the very end
            setUser(resource);
          })
          .catch((e) => {
            console.log(`Błąd wczytania zasobu ${userId}`, e);
          });
    } catch (e) {
      setDialogMessage(`Nie zdefiniowano zasobu: ${userId}`);
      setDialogVisible(true);
      console.log("Resource not defined", e);
    }
  }, [props.route]);

  const hideDialog = () => {
    setDialogVisible(false);
    navigation.navigate("Users");
  };
  const onSubmit = async (data: {}) => {
    let result;

    try {
      console.log("executing request");
      if (_.isEmpty(getValues("id"))) {
        console.log("executing createTranslator");
        result = await createUser(data);
      } else {
        console.log("executing updateUser");
        result = await updateUser(data);
      }

      console.log(`request done ${JSON.stringify(result)}`);

      if (result) {
        setshowSpinner(false);

        // if (result.status === 200) {
        setDialogMessage("Zapisano zmiany");
        setDialogVisible(true);
      }
    } catch (error) {
      setshowSpinner(false);
      const err = error as AxiosError;

      console.log(`response ${JSON.stringify(err.response)}`);
      const resMessage =
        err.response && err.response.data
          ? err.response.data.error
          : err.response.toString();

      setDialogErrorMessage(resMessage);
      setDialogErrorVisible(true);
    }
  };

  return (
    <View
      testID="form_container"
      style={
        Platform.OS === "web"
          ? styles.outerColumnContainer
          : styles.outerColumnContainerMobile
      }
    >
      {showSpinner && <Loading loading={showSpinner} />}
      <CustomDialog
        visible={dialogVisible}
        hideDialog={hideDialog}
        dialogTitle={""}
        dialogContent={"Zapisano zmiany"}
      />
      {/* errors */}
      <CustomDialog
        visible={dialogErrorVisible}
        hideDialog={hideDialogError}
        dialogTitle={"Błąd"}
        dialogContent={dialogErrorMessage}
        testID="error_dialog"
      />
      <View
        testID="fields"
        style={[
          { padding: 10 },
          debug === true ? { backgroundColor: "blue" } : undefined,
        ]}
      >
        {getValues("id") && (
          <View style={containerStyle} testID="id">
            <ValidatedTextInput
              placeholder="Id"
              name="id"
              disabled={true}
              errors={errors}
              control={control}
              leftIconName="account-check-outline"
            ></ValidatedTextInput>
          </View>
        )}
        <View style={containerStyle} testID="first-name">
          <ValidatedTextInput
            placeholder="Imię"
            name="firstName"
            disabled={!auth || auth.isUser}
            errors={errors}
            control={control}
            leftIconName="account-check-outline"
          />
        </View>
        <View style={styles.inputContainer}>
          <ValidatedTextInput
            name="lastName"
            placeholder="Nazwisko"
            disabled={auth?.isUser}
            required={true}
            // onChangeText={(lastName) => setLastName(lastName)}
            errors={errors}
            control={control}
            leftIconName="account-check-outline"
          />
        </View>
        {auth?.isAdmin && (
          <View style={styles.inputContainer}>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                // backgroundColor: "orange",
                justifyContent: "space-around",
              }}
            >
              <ValidatedCheckbox
                name="isTranslator"
                label="Tłumacz"
                control={control}
                errors={errors}
                value={getValues("isTranslator")}
                toggle={toggleIsTranslator}
              />
              <ValidatedCheckbox
                name="isTranscriber"
                label="Transkrybent"
                control={control}
                errors={errors}
                value={getValues("isTranscriber")}
                toggle={toggleIsTranscriber}
              />
              <ValidatedCheckbox
                name="isEditor"
                label="Filmowiec"
                control={control}
                errors={errors}
                value={getValues("isEditor")}
                toggle={toggleIsEditor}
              />
              <ValidatedCheckbox
                name="isAdmin"
                label="Administrator"
                control={control}
                errors={errors}
                value={getValues("isAdmin")}
                toggle={toggleIsAdmin}
              />
            </View>
          </View>
        )}
        {_.isEmpty(getValues("id")) && (
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="password"
              placeholder="Hasło"
              required={true}
              rules={{
                validate: {
                  required: (value: boolean) => {
                    return value ? true : "To pole jest wymagane";
                  },
                  minPasswordStrength: minPasswordStrength(3),
                },
              }}
              errors={errors}
              control={control}
              secureTextEntry={true}
              leftIconName={"form-textbox-password"}
            />
          </View>
        )}
        {_.isEmpty(getValues("id")) && (
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="password2"
              placeholder="Powtórz hasło"
              rules={{ required: true }}
              errors={errors}
              required={true}
              control={control}
              secureTextEntry={true}
              leftIconName={"form-textbox-password"}
            />
          </View>
        )}

        {auth && !auth.isUser && !_.isEmpty(getValues("id")) && (
          <View style={containerStyle} testID="role">
            <ValidatedTextInput
              placeholder="Rola"
              name="role"
              disabled="true"
              errors={errors}
              required={true}
              control={control}
              leftIconName="account-check-outline"
            />
          </View>
        )}
        <View style={containerStyle}>
          <ValidatedTextInput
            name="email"
            required={true}
            control={control}
            errors={errors}
            disabled={auth?.isUser}
            placeholder={"Email"}
            leftIconName="email-outline"
          />
        </View>
        <View style={containerStyle}>
          <ValidatedTextInput
            name="phone"
            required={true}
            control={control}
            errors={errors}
            disabled={auth?.isUser}
            placeholder={"Telefon"}
            leftIconName="phone-outline"
          />
        </View>
        {!auth?.isUser && !_.isEmpty(getValues("id")) && (
          <View style={containerStyle}>
            <ValidatedTextInput
              control={control}
              rules={{
                validate: (value: string) => {
                  if (value) {
                    console.log(
                      `validated pesel? ${validatePolish.pesel(value)}`
                    );
                    return validatePolish.pesel(value)
                      ? true
                      : "Nieprawidłowy PESEL";
                  } else return true;
                },
              }}
              errors={errors}
              name="pesel"
              placeholder={"PESEL"}
              disabled={auth?.isUser}
              leftIconName={"account-check-outline"}
            />
          </View>
        )}

        {!_.isEmpty(getValues("id")) && (
          <PickDocumentInput
            documentName="disabilityDocument"
            downloadAvailable={true}
            label="Orzeczenie o niepełnosprawności"
            downloadLink={`/storage/ck-users/${getValues("id")}/disability/${
              getValues("disabilityDocument").storageId
            }`}
            getValues={getValues}
            setValue={setValue}
            control={control}
            errors={errors}
            mimeType={["image/*", "application/pdf"]}
          />
        )}

        {!_.isEmpty(getValues("id")) && (
          <>
            <View style={styles.inputContainer}>
              <ValidatedCheckbox
                name="declaredRefugee"
                label="Deklarowany status uchodźcy (rejestracja)"
                control={control}
                errors={errors}
                value={getValues("declaredRefugee")}
                toggle={toggleDeclaredRefugee}
                disabled={auth?.isUser}
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedCheckbox
                name="isActive"
                label="Aktywny?"
                control={control}
                errors={errors}
                value={getValues("isActive")}
                toggle={toggleIsActive}
                disabled={auth?.isUser}
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedCheckbox
                name="preferredWrittenCommunication"
                label="Preferowana komunikacja za pomocą pisma"
                control={control}
                errors={errors}
                value={getValues("preferredWrittenCommunication")}
                toggle={togglePreferredWrittenCommunication}
                disabled={auth?.isUser}
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedCheckbox
                name="isParticipationFormFinished"
                label="Formularz korzystania z usług Centrum Komunikacji wypełniony?"
                disabled={true}
                control={control}
                errors={errors}
                value={getValues("isParticipationFormFinished")}
                toggle={() => {}}
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedTextInput
                name="activatedAt"
                placeholder="Data aktywowania konta"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="calendar-check-outline"
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedTextInput
                name="createdAt"
                placeholder="Data utworzenia konta"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="calendar-check-outline"
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedTextInput
                name="updatedAt"
                placeholder="Data ostatniej modyfikacji konta"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="calendar-check-outline"
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedTextInput
                name="lastSuccessfulLoginAt"
                placeholder="Ostatniej udane logowanie"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="calendar-check-outline"
              />
            </View>
            <View style={styles.inputContainer}>
              <ValidatedTextInput
                name="lastNotSuccessfulLoginAt"
                placeholder="Ostatniej nieudane logowanie"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="calendar-check-outline"
              />
            </View>
            {user.closedAt && (
              <>
                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="closedAt"
                    placeholder="Data zamknięcia konta"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                  />
                </View>
                <View style={styles.inputContainer}>
                  <ValidatedTextInput
                    name="closedReason"
                    placeholder="Powód zamknięcia konta"
                    disabled={true}
                    errors={errors}
                    control={control}
                    leftIconName="calendar-check-outline"
                  />
                </View>
              </>
            )}
          </>
        )}
      </View>
      {auth && !auth?.isUser && (
        <View
          style={debug === true ? { backgroundColor: "yellow" } : undefined}
        >
          <View
            style={[
              debug === true ? { backgroundColor: "orange" } : undefined,
              styles.btnPrimary,
            ]}
          >
            <TouchableOpacity
              onPress={handleSubmit(onSubmit)}
              testID={"button_update_profile"}
            >
              <Text style={styles.buttonText}>{LABELS.save}</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
}
