import React, { useContext, useState } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { styles } from "../../screens/RegistrationInfo/styles";
import { AuthContext, AuthContextType } from "../../auth-context";
import { RegistrationInfoProps } from "../../navigation/types";
import LABELS from "./labels";
import CustomDialog from "../CustomDialog";
import { DebugContext } from "../../debug-context";

import { getUser, createUser, updateUser } from "../../services/user.service";
import { useForm } from "react-hook-form";
import ValidatedTextInput from "../ValidatedTextInput";
import _ from "lodash";
import { Loading } from "../Loading";
import { AxiosError } from "axios";
import ValidatedPolicyCheckbox from "./ValidatedPolicyCheckbox";

const RegistrationInfoView = React.forwardRef(
  ({ navigation, containerStyle, ...props }: RegistrationInfoProps, ref) => {
    const { auth } = React.useContext(AuthContext) as AuthContextType;

    const [showSpinner, setshowSpinner] = React.useState(false);
    const [dialogErrorVisible, setDialogErrorVisible] = useState(false);
    const [dialogErrorMessage, setDialogErrorMessage] = useState("");
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const {
      control,
      handleSubmit,
      setValue,
      getValues,

      formState: { errors },
    } = useForm<FormData>({
      mode: "onBlur", // "onChange"
    });

    const [user, setUser] = React.useState(undefined);
    const debug = useContext(DebugContext).debug;
    console.log("containerStyle ", containerStyle);

    const hideDialogError = () => {
      console.log(`close hideDialogError`);
      setDialogErrorVisible(false);
    };

    React.useEffect(() => {
      console.log("!!!!Profile useEffect props ", props);
      const userId = props.route.params ? props.route.params.userId : undefined;

      try {
        if (userId)
          getUser(userId)
            .then((resource) => {
              console.log("[RegistrationInfo], assets in useEffect", resource);
              console.log(`[RegistrationInfo],  ${resource._id}`);

              setValue("id", resource._id);
              setValue("firstName", resource.firstName);
              setValue("lastName", resource.lastName);
              setValue("email", resource.email);
              setValue("consent1", true);
              setValue("consent2", true);
              setValue("consent3", true);
              setValue("consent4", true);
              setValue("consent5", true);

              setValue(
                "activatedAt",
                resource.activatedAt
                  ? new Date(resource.activatedAt).toLocaleString("pl-PL")
                  : undefined
              );
              setValue(
                "createdAt",
                new Date(resource.createdAt).toLocaleString("pl-PL")
              );
              setValue(
                "updatedAt",
                new Date(resource.updatedAt).toLocaleString("pl-PL")
              );

              // causes rerender so It must be at the very end
              setUser(resource);
            })
            .catch((e) => {
              console.log(`Błąd wczytania zasobu ${userId}`, e);
            });
      } catch (e) {
        setDialogMessage(`Nie zdefiniowano zasobu: ${userId}`);
        setDialogVisible(true);
        console.log("Resource not defined", e);
      }
    }, [props.route]);

    const hideDialog = () => {
      setDialogVisible(false);
      navigation.navigate("Users");
    };

    return (
      <View
        testID="form_container"
        ref={ref}
        style={
          Platform.OS === "web"
            ? styles.outerColumnContainer
            : styles.outerColumnContainerMobile
        }
      >
        {showSpinner && <Loading loading={showSpinner} />}
        <CustomDialog
          visible={dialogVisible}
          hideDialog={hideDialog}
          dialogTitle={""}
          dialogContent={"Zapisano zmiany"}
        />
        {/* errors */}
        <CustomDialog
          visible={dialogErrorVisible}
          hideDialog={hideDialogError}
          dialogTitle={"Błąd"}
          dialogContent={dialogErrorMessage}
          testID="error_dialog"
        />
        <View
          testID="fields"
          style={[
            { padding: 10 },
            debug === true ? { backgroundColor: "blue" } : undefined,
          ]}
        >
          {getValues("id") && (
            <View style={containerStyle} testID="id">
              <ValidatedTextInput
                placeholder="Id"
                name="id"
                disabled={true}
                errors={errors}
                control={control}
                leftIconName="account-check-outline"
              ></ValidatedTextInput>
            </View>
          )}
          <View style={containerStyle} testID="first-name">
            <ValidatedTextInput
              placeholder="Imię"
              name="firstName"
              disabled={!auth || auth.isUser}
              errors={errors}
              control={control}
              leftIconName="account-check-outline"
            />
          </View>
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="lastName"
              placeholder="Nazwisko"
              disabled={auth?.isUser}
              required={true}
              // onChangeText={(lastName) => setLastName(lastName)}
              errors={errors}
              control={control}
              leftIconName="account-check-outline"
            />
          </View>

          <View style={containerStyle}>
            <ValidatedTextInput
              name="email"
              required={true}
              control={control}
              errors={errors}
              disabled={auth?.isUser}
              placeholder={"Email"}
              leftIconName="email-outline"
            />
          </View>
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="createdAt"
              placeholder="Data utworzenia konta"
              disabled={true}
              errors={errors}
              control={control}
              leftIconName="calendar-check-outline"
            />
          </View>
          <View style={styles.inputContainer}>
            <ValidatedTextInput
              name="activatedAt"
              placeholder="Data aktywowania konta"
              disabled={true}
              errors={errors}
              control={control}
              leftIconName="calendar-check-outline"
            />
          </View>
        </View>

        {/* Checkbox container */}
        <View
          style={{
            flex: 1,
            margin: 10,
            display: "flex",
            height: "100%",
          }}
        >
          <ValidatedPolicyCheckbox
            name="consent1"
            control={control}
            rules={{ required: true }}
            label={LABELS.ck1}
            consent={"oswiadczenie1_zgoda_na_przetwarzanie_danych_osobowych"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent2"
            control={control}
            rules={{ required: true }}
            label={LABELS.ck2}
            consent={"oswiadczenie2_zgoda_na_upowszechnianie_wizerunku"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent3"
            control={control}
            rules={{ required: true }}
            label={LABELS.ck3}
            consent={"oswiadczenie3_zgoda_na_nagrywanie"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent4"
            control={control}
            value={"true"}
            rules={{ required: true }}
            label={LABELS.ck4}
            consent={"oswiadczenie4_zapoznanie_sie_z_regulaminem"}
            errors={errors}
          />

          <ValidatedPolicyCheckbox
            name="consent5"
            control={control}
            defaultValue={true}
            rules={{ required: true }}
            label={LABELS.ck5}
            consent={"oswiadczenie5_zapoznanie_sie_z_rodo"}
            errors={errors}
          />

          {/* <PolicyInfo label={LABELS.termsOfService} consent={"regulamin"} />
        <PolicyInfo
          label={LABELS.privacyPolicy}
          consent={"polityka_prywatnosci"}
        /> */}
        </View>
      </View>
    );
  }
);

export default RegistrationInfoView;
