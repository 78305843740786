import React from "react";
import { Paragraph } from "react-native-paper";
import { View } from "react-native";

export const TranscriptionDialogText = () => (
  <>
    <View testID="transcription_dialog_text">
      <Paragraph style={{ textAlign: "center" }}>
        Usługa transkrypcji mowy na żywo
      </Paragraph>
      <Paragraph style={{ textAlign: "center" }}>
        jest dostępna od poniedziałku do piątku w godzinach 8:00-17:00.
      </Paragraph>
      <Paragraph style={{ textAlign: "center" }}>{}</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>{}</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>{}</Paragraph>
      <Paragraph style={{ textAlign: "center" }}>
        Usługa transkrypcji mowy na żywo odbywa się bez obrazu video.
      </Paragraph>
    </View>
  </>
);
